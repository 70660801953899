import React, { useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import InputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'

const UnInsured = ({ formik, setValidationSchema2, disableAllFields }) => {
  const [id, setId] = useState('dl')
  const { t } = useTranslation()

  return (
    <Stack>
      <FormControl>
        <Typography sx={{ mb: 1 }}>
          {`${t('registerPatient.unInsured.chooseId')} *`}
        </Typography>
        <RadioGroup
          onChange={(_evt, value) => {
            setId(value)
            setValidationSchema2(value)
          }}
          value={id}
        >
          <FormControlLabel
            control={<Radio />}
            label={t('registerPatient.unInsured.driverLicense')}
            value='dl'
          />
          <FormControlLabel
            control={<Radio />}
            label={t('registerPatient.unInsured.ssn')}
            value='ssn'
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{ my: 2 }}>
        {id === 'dl'
          ? (
            <TextField
              label={`${t('registerPatient.unInsured.enterId')} *`}
              variant='outlined'
              type='text'
              name='uninsured.driverLicense'
              id='driverLicense'
              value={formik.values?.uninsured?.driverLicense || ''}
              onChange={formik.handleChange}
              helperText={t(formik.errors?.uninsured?.driverLicense)}
              disabled={disableAllFields}
              fullWidth
              error={
              (Boolean(formik.errors?.uninsured?.driverLicense))
            }
            />
            )
          : null}
        {id === 'ssn'
          ? (
            <InputMask
              mask='999-99-9999'
              disabled={disableAllFields}
              maskChar=''
              value={formik.values.uninsured.socialSecurityNo || ''}
              onChange={formik.handleChange}
              id='socialSecurityNo'
            >
              {() => (
                <TextField
                  label={`${t('registerPatient.unInsured.enterId')} *`}
                  helperText={
                  formik.touched?.uninsured?.socialSecurityNo &&
                  Boolean(formik.errors?.uninsured?.socialSecurityNo)
                    ? t(formik.errors?.uninsured?.socialSecurityNo)
                    : null
                }
                  name='uninsured.socialSecurityNo'
                  id='socialSecurityNo'
                  fullWidth
                  error={
                  Boolean(formik.touched?.uninsured?.socialSecurityNo) &&
                  Boolean(formik.errors?.uninsured?.socialSecurityNo)
                }
                  sx={{
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: disableAllFields ? 'not-allowed' : 'inherit',
                      WebkitTextFillColor: disableAllFields
                        ? '#666666a6'
                        : '#212121'
                    }
                  }}
                />
              )}
            </InputMask>
            )
          : null}
        {/* <TextField
          label='Social Security Number'
          variant='outlined'
          type='text'
          name='uninsured.socialSecurityNo'
          id='socialSecurityNo'
          value={formik.values?.uninsured?.socialSecurityNo}
          onChange={formik.handleChange}
          disabled={disableAllFields}
          // helperText=' '
          // helperText='Enter atleast one'
          helperText={formik.touched.uninsured?.socialSecurityNo && Boolean(formik.errors.uninsured?.socialSecurityNo) ? formik.errors.uninsured?.socialSecurityNo : '*Required field'}
          error={formik.touched.uninsured?.socialSecurityNo && Boolean(formik.errors?.uninsured?.socialSecurityNo)}
          fullWidth
          FormHelperTextProps={{
            sx: { color: 'primary.red' }
          }}
        /> */}
      </Box>
    </Stack>
  )
}

export default UnInsured
