import React, { useEffect, useState } from 'react'
import {
  Box,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Stack,
  Link
} from '@mui/material'
import InputMask from 'react-input-mask'
import Address from './Address'
import { styled } from '@mui/material/styles'
import { createFilterOptions } from '@mui/material/Autocomplete'

import {
  gender,
  race,
  ethnicity,
  GenderDialog,
  sexAssignedAtBirth,
  SexAssignedAtBirthDialog
} from 'components/PatientComponents/data/dropDownOptions'
import { useTranslation } from 'react-i18next'
import { Kit } from '../../../../types/db/Kit'
import { SiteI } from '../../../../types/db/SiteI'
import useFormik from '../../../../hooks/useFormik'
import { useLocation } from 'react-router'

const MedicalData = ({
  formik,
  editMode,
  disableAllFields,
  isAdmin,
  site
}: MedicalDataProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [openGenderDialog, setOpenGenderDialog] = useState(false)
  const [openSexAssignedDialog, setOpenSexAssignedDialog] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!genderOptions.find((gen) => gen.value === formik.values.gender)) {
      formik.setFieldValue('gender', '')
    }
  }, [])

  const customField = site?.register_patient?.custom_field

  const genderOptions = gender?.map((data, i) => ({
    label: t(data.name),
    value: data.value,
    info: data.info,
    id: i
  }) as IdentityDropdownOptionI)

  const sexAssignedAtBirthOptions = sexAssignedAtBirth.map((data, i) => ({
    label: t(data.name),
    value: data.value,
    id: i
  }) as IdentityDropdownOptionI)

  const raceOptions = race?.map((data, i) => ({
    label: t(data.name),
    value: data.value,
    id: i
  }) as IdentityDropdownOptionI)

  const ethnicityOptions = ethnicity.map((ethnicity) => ethnicity)

  useEffect(() => {
    if (formik.values.patientId === 'new') return

    // We're forcing the user with 'Prefer not to answer' in the sex assigned at birth to select a valid option
    if (formik.values.sexAssignedAtBirth && !['male', 'female'].includes(formik.values.sexAssignedAtBirth.toLowerCase())) {
      formik.setFieldValue('sexAssignedAtBirth', '')
      formik.setFieldTouched('sexAssignedAtBirth', true)
    }
  }, [formik.values.sexAssignedAtBirth])

  // const minimumAgeAllowed = (kit?.kitTypeConfig?.prefix && siteInformation?.[0]?.age_config?.[kit?.kitTypeConfig?.prefix]?.patientMinAge) || 18
  // const isSupervisionRequired = (kit?.kitTypeConfig?.prefix && siteInformation?.[0]?.age_config?.[kit?.kitTypeConfig?.prefix]?.canUseWithSupervision === 'yes') || false

  // The field should be the only enabled field in the form when an admin is updating a patient in the provider portal while registering a test on the test page
  const shouldSexAssignedAtBirthBeDisabled = isAdmin && location.pathname.includes('tests/create') ? false : disableAllFields

  return (
    <Root>
      <Box
        sx={ {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2
        } }
      >
        { editMode && (
          <Typography variant="h5" component="h2" mr="auto" mt={ 1 }>
            { t('registerPatient.medicalDetails.title') }
          </Typography>
        ) }
        <Grid container spacing={ 2 } mt={ 1 }>
          <Grid item md={ 6 } xs={ 12 }>
            <TextField
              autoFocus
              label={ `${t('registerPatient.medicalDetails.firstName')} *` }
              variant="outlined"
              type="text"
              name="firstName"
              id="firstName"
              autoComplete="off"
              fullWidth
              className={ classes.disabled }
              disabled={ disableAllFields }
              value={ formik.values.firstName || '' }
              onChange={ formik.handleChange }
              onFocus={ (event) =>
                event.target.setAttribute('autocomplete', 'off')
              }
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName && Boolean(formik.errors.firstName)
                  ? t(formik.errors.firstName) as string
                  : ''
              }
              FormHelperTextProps={ {
                sx: { color: 'primary.red' }
              } }
              sx={ {
                '& .Mui-disabled': {
                  color: '#666666a6'
                },
                '& .MuiInputBase-input': {
                  cursor: disableAllFields ? 'not-allowed' : 'inherit',
                  WebkitTextFillColor: disableAllFields
                    ? '#666666a6'
                    : '#212121'
                }
              } }
            />
          </Grid>
          <Grid item md={ 6 } xs={ 12 }>
            <TextField
              label={ `${t('registerPatient.medicalDetails.lastName')} *` }
              variant="outlined"
              type="text"
              name="lastName"
              id="lastName"
              autoComplete="off"
              fullWidth
              disabled={ disableAllFields }
              value={ formik.values.lastName || '' }
              onChange={ formik.handleChange }
              error={ formik.touched.lastName && Boolean(formik.errors.lastName) }
              onFocus={ (event) =>
                event.target.setAttribute('autocomplete', 'off')
              }
              helperText={
                formik.touched.lastName && Boolean(formik.errors.lastName)
                  ? t(formik.errors.lastName) as string
                  : ''
              }
              FormHelperTextProps={ {
                sx: { color: 'primary.red' }
              } }
              sx={ {
                '& .Mui-disabled': {
                  color: '#666666a6'
                },
                '& .MuiInputBase-input': {
                  cursor: disableAllFields ? 'not-allowed' : 'inherit',
                  WebkitTextFillColor: disableAllFields
                    ? '#666666a6'
                    : '#212121'
                }
              } }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <Address
              formik={ formik }
              name="medicalAddress"
              disabled={ disableAllFields }
              copyAddress={ false }
            />
          </Grid>

          <Grid item md={ 6 } xs={ 12 }>
            <Stack>
              <Autocomplete
                id="sexAssignedAtBirth"
                autoComplete={ false }
                isOptionEqualToValue={ (option, value) => option.value === value?.value }
                filterOptions={ filterOptions }
                options={ sexAssignedAtBirthOptions }
                disabled={ shouldSexAssignedAtBirthBeDisabled }
                value={ sexAssignedAtBirthOptions.find(option => option.value === formik.values.sexAssignedAtBirth) || null }
                onChange={ (e, newValue) => {
                  if (newValue?.label) {
                    formik.setFieldValue('sexAssignedAtBirth', newValue?.value)
                  } else {
                    formik.setFieldValue('sexAssignedAtBirth', '')
                  }
                } }
                renderInput={ (params) => (
                  <TextField
                    { ...params }
                    label={ `${t('registerPatient.medicalDetails.sexAssigned')} *` }
                    autoComplete="off"
                    onFocus={ (event) =>
                      event.target.setAttribute('autocomplete', 'off')
                    }
                    sx={ {
                      '& .Mui-disabled': {
                        color: '#666666a6'
                      },
                      '& .MuiInputBase-input': {
                        cursor: shouldSexAssignedAtBirthBeDisabled ? 'not-allowed' : 'inherit',
                        WebkitTextFillColor: shouldSexAssignedAtBirthBeDisabled
                          ? '#666666a6'
                          : '#212121'
                      }
                    } }
                    name="sexAssignedAtBirth"
                    disabled={ shouldSexAssignedAtBirthBeDisabled }
                    error={
                      (formik.touched.sexAssignedAtBirth &&
                        Boolean(formik.errors.sexAssignedAtBirth))
                    }
                    helperText={
                      formik.touched.sexAssignedAtBirth &&
                        Boolean(formik.errors.sexAssignedAtBirth)
                        ? t(formik.errors.sexAssignedAtBirth) as string
                        : ''
                    }
                    FormHelperTextProps={ {
                      sx: { color: 'primary.red' }
                    } }
                  />
                ) }
              />

              <Link
                onClick={ () => setOpenSexAssignedDialog(true) }
                color="primary"
                variant="caption"
                sx={ { pl: '10px', cursor: 'pointer' } }
              >
                { t('registerPatient.medicalDetails.learnMoreSexAssignedAtBirth') }
              </Link>
            </Stack>
          </Grid>

          <Grid item md={ 6 } xs={ 12 }>
            <Stack>
              <Autocomplete
                id="gender"
                autoComplete={ false }
                filterOptions={ filterOptions }
                options={ genderOptions }
                disabled={ disableAllFields }
                value={ genderOptions.find(option => option.value === formik.values.gender) || null }
                renderOption={ (props: any, option) => (
                  <Stack sx={ { p: '5px', px: '10px' } } { ...props }>
                    <Typography sx={ { width: '100%' } }>
                      { option.label }
                    </Typography>
                    <Typography
                      sx={ { width: '100%', color: '#00000099' } }
                      variant={ 'caption' }
                    >
                      { option.info }
                    </Typography>
                  </Stack>
                ) }
                onChange={ (e, newValue) => {
                  if (newValue?.label) {
                    formik.setFieldValue('gender', newValue?.value)
                    // setInputValue1(newValue)
                  } else {
                    formik.setFieldValue('gender', '')
                  }
                } }
                renderInput={ (params) => (
                  <TextField
                    { ...params }
                    label={ `${t('registerPatient.medicalDetails.gender')} *` }
                    autoComplete="off"
                    onFocus={ (event) =>
                      event.target.setAttribute('autocomplete', 'off')
                    }
                    sx={ {
                      '& .Mui-disabled': {
                        color: '#666666a6'
                      },
                      '& .MuiInputBase-input': {
                        cursor: disableAllFields ? 'not-allowed' : 'inherit',
                        WebkitTextFillColor: disableAllFields
                          ? '#666666a6'
                          : '#212121'
                      }
                    } }
                    value={ formik.values.gender }
                    name="gender"
                    disabled={ disableAllFields }
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={
                      formik.touched.gender && Boolean(formik.errors.gender)
                        ? t(formik.errors.gender) as string
                        : ''
                    }
                    FormHelperTextProps={ {
                      sx: { color: 'primary.red' }
                    } }
                  />
                ) }
              />
              <Link
                onClick={ () => setOpenGenderDialog(true) }
                color="primary"
                variant="caption"
                sx={ { pl: '10px', cursor: 'pointer' } }
              >
                { t('registerPatient.medicalDetails.learnMoreGender') }
              </Link>
            </Stack>
          </Grid>

          { isAdmin && (
            <Grid item xs={ 12 }>
              <TextField
                label={ t('registerPatient.medicalDetails.email') }
                variant="outlined"
                type="text"
                name="email"
                id="email"
                onFocus={ (event) =>
                  event.target.setAttribute('autocomplete', 'off')
                }
                // disabled={diabledCondition || disableAllFields}
                value={ formik.values.email || '' }
                onChange={ formik.handleChange }
                error={ formik.touched.email && Boolean(formik.errors.email) }
                helperText={
                  formik.touched.email && Boolean(formik.errors.email)
                    ? t(formik.errors.email) as string
                    : `*${t('registerPatient.validation.required')}`
                }
                fullWidth
                FormHelperTextProps={ {
                  sx: { color: '#D32F2F' }
                } }
                sx={ {
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disableAllFields ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor: disableAllFields
                      ? '#666666a6'
                      : '#212121'
                  }
                } }
              />
            </Grid>
          ) }

          <Grid item xs={ 12 } md={ 6 }>
            <Autocomplete
              id="race"
              autoComplete={ false }
              filterOptions={ filterOptions }
              options={ raceOptions }
              disabled={ disableAllFields }
              value={ raceOptions.find(option => option.value === formik.values.race) || null }
              fullWidth
              onChange={ (e, newValue) => {
                if (newValue?.label) {
                  formik.setFieldValue('race', newValue.value)
                } else {
                  formik.setFieldValue('race', '')
                }
              } }
              renderInput={ (params) => (
                <TextField
                  { ...params }
                  label={ `${t('registerPatient.medicalDetails.race')} *` }
                  autoComplete="off"
                  onFocus={ (event) =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  sx={ {
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: disableAllFields ? 'not-allowed' : 'inherit',
                      WebkitTextFillColor: disableAllFields
                        ? '#666666a6'
                        : '#212121'
                    }
                  } }
                  name="race"
                  disabled={ disableAllFields }
                  error={ formik.touched.race && Boolean(formik.errors.race) }
                  helperText={
                    formik.touched.race && Boolean(formik.errors.race)
                      ? t(formik.errors.race) as string
                      : ''
                  }
                  FormHelperTextProps={ {
                    sx: { color: 'primary.red' }
                  } }
                />
              ) }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <Autocomplete
              id="ethnicity"
              isOptionEqualToValue={ (option, value) =>
                option.value === value.value
              }
              autoComplete={ false }
              options={ ethnicityOptions }
              getOptionLabel={ (option) => t(option.name) }
              disabled={ disableAllFields }
              value={ ethnicityOptions.find(option => option.value === formik.values.ethnicity) || null }
              onChange={ (_event, newInputValue) => {
                formik.setFieldValue('ethnicity', newInputValue?.value)
              } }
              renderInput={ (params) => (
                <TextField
                  { ...params }
                  label={ `${t('registerPatient.medicalDetails.ethnicity')} *` }
                  sx={ {
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: disableAllFields ? 'not-allowed' : 'inherit',
                      WebkitTextFillColor: disableAllFields
                        ? '#666666a6'
                        : '#212121'
                    }
                  } }
                  name="ethnicity"
                  autoComplete="off"
                  onFocus={ (event) =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  error={
                    Boolean(formik.errors.ethnicity)
                  }
                  helperText={
                    formik.errors.ethnicity
                      ? t(formik.errors.ethnicity) as string
                      : ''
                  }
                  FormHelperTextProps={ {
                    sx: { color: 'primary.red' }
                  } }
                  disabled={ disableAllFields }
                />
              ) }
            />
          </Grid>

          <Grid item xs={ 12 }>
            <InputMask
              mask="99-99-9999"
              disabled={ disableAllFields }
              maskChar=""
              name="dob"
              value={ formik.values.dob }
              onChange={ formik.handleChange }
            >
              {/* @ts-ignore */ }
              { () => (
                <TextField
                  label={ `${t('registerPatient.medicalDetails.dob')} *` }
                  helperText={
                    formik.touched.dob && Boolean(formik.errors.dob)
                      ? t(formik.errors.dob) as string
                      : null
                  }
                  name="dob"
                  id="dob"
                  placeholder="mm-dd-yyyy"
                  fullWidth
                  error={ formik.touched.dob && Boolean(formik.errors.dob) }
                  FormHelperTextProps={ {
                    sx: { color: 'primary.red' }
                  } }
                  sx={ {
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: disableAllFields ? 'not-allowed' : 'inherit',
                      WebkitTextFillColor: disableAllFields
                        ? '#666666a6'
                        : '#212121'
                    }
                  } }
                />
              ) }
            </InputMask>
          </Grid>

          <Grid item xs={ 12 }>
            <InputMask
              mask="(999) 999-9999"
              disabled={ disableAllFields }
              maskChar=""
              value={ formik.values.phone || '' }
              onChange={ formik.handleChange }
              id={ disableAllFields ? 'disabledPhone' : 'phone' }
            >
              {/* @ts-ignore */ }
              { () => (
                <TextField
                  label={ `${t('registerPatient.medicalDetails.phone')} *` }
                  helperText={
                    formik.touched.phone && Boolean(formik.errors.phone)
                      ? t(formik.errors.phone) as string
                      : null
                  }
                  name="phone"
                  id="phone"
                  autoComplete="off"
                  // disabled={props.id === 'disabledPhone' && true}
                  fullWidth
                  error={ formik.touched.phone && Boolean(formik.errors.phone) }
                  FormHelperTextProps={ {
                    sx: { color: 'primary.red' }
                  } }
                  sx={ {
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: disableAllFields ? 'not-allowed' : 'inherit',
                      WebkitTextFillColor: disableAllFields
                        ? '#666666a6'
                        : '#212121'
                    }
                  } }
                />
              ) }
            </InputMask>
          </Grid>

          {/* { formik.values.dob.length === 10 &&
            moment().diff(
              moment(formik.values.dob, 'MM-DD-YYYY'),
              'years',
              false
            ) < minimumAgeAllowed && isSupervisionRequired
            ? (
              <Stack sx={ { gap: 2, p: 2 } }>
                <Typography sx={ { fontWeight: 900 } }>
                  { t('registerPatient.medicalDetails.guardianDetails') }
                </Typography>
                <Stack sx={ { gap: 2, flexDirection: 'row', flexWrap: 'wrap' } }>
                  <TextField
                    fullWidth
                    label={ `${t('registerPatient.medicalDetails.guardianFirstName')} *` }
                    name="guardianFirstName"
                    id="guardianFirstName"
                    onChange={ formik.handleChange }
                    onFocus={ (event) =>
                      event.target.setAttribute('autocomplete', 'off')
                    }
                    value={ formik.values.guardianFirstName }
                    error={
                      formik.touched.guardianFirstName &&
                      Boolean(formik.errors.guardianFirstName)
                    }
                    helperText={
                      formik.touched.guardianFirstName &&
                        Boolean(formik.errors.guardianFirstName)
                        ? t(formik.errors.guardianFirstName) as string
                        : null
                    }
                    FormHelperTextProps={ {
                      sx: { color: '#D32F2F' }
                    } }
                    sx={ {
                      '& .Mui-disabled': {
                        color: '#666666a6'
                      },
                      '& .MuiInputBase-input': {
                        cursor: disableAllFields ? 'not-allowed' : 'inherit',
                        WebkitTextFillColor: disableAllFields
                          ? '#666666a6'
                          : '#212121'
                      }
                    } }
                  />
                  <TextField
                    fullWidth
                    label={ `${t('registerPatient.medicalDetails.guardianLastName')} *` }
                    name="guardianLastName"
                    onChange={ formik.handleChange }
                    id="guardianLastName"
                    onFocus={ (event) =>
                      event.target.setAttribute('autocomplete', 'off')
                    }
                    value={ formik.values.guardianLastName }
                    error={
                      formik.touched.guardianLastName &&
                      Boolean(formik.errors.guardianLastName)
                    }
                    helperText={
                      formik.touched.guardianLastName &&
                        Boolean(formik.errors.guardianLastName)
                        ? t(formik.errors.guardianLastName) as string
                        : null
                    }
                    FormHelperTextProps={ {
                      sx: { color: '#D32F2F' }
                    } }
                    sx={ {
                      '& .Mui-disabled': {
                        color: '#666666a6'
                      },
                      '& .MuiInputBase-input': {
                        cursor: disableAllFields ? 'not-allowed' : 'inherit',
                        WebkitTextFillColor: disableAllFields
                          ? '#666666a6'
                          : '#212121'
                      }
                    } }
                  />

                  <InputMask
                    mask="99-99-9999"
                    disabled={ disableAllFields }
                    maskChar=""
                    name="guardianDob"
                    value={ formik.values.guardianDob }
                    onChange={ formik.handleChange }
                  >
                    {
                      // @ts-ignore
                      () => (
                        <TextField
                          label={ `${t('registerPatient.medicalDetails.guardianDob')} *` }
                          helperText={
                            formik.touched.guardianDob &&
                              Boolean(formik.errors.guardianDob)
                              ? t(formik.errors.guardianDob) as string
                              : null
                          }
                          name="guardianDob"
                          id="guardianDob"
                          placeholder="mm-dd-yyyy"
                          fullWidth
                          error={
                            formik.touched.guardianDob &&
                            Boolean(formik.errors.guardianDob)
                          }
                          FormHelperTextProps={ {
                            sx: { color: 'primary.red' }
                          } }
                          sx={ {
                            '& .Mui-disabled': {
                              color: '#666666a6'
                            },
                            '& .MuiInputBase-input': {
                              cursor: disableAllFields
                                ? 'not-allowed'
                                : 'inherit',
                              WebkitTextFillColor: disableAllFields
                                ? '#666666a6'
                                : '#212121'
                            }
                          } }
                        />
                      )
                    }
                  </InputMask>
                </Stack>
              </Stack>
              )
            : null } */}

          { site && customField?.enabled && (
            <Grid xs={ 12 } item>
              <TextField
                name={ customField?.value }
                value={ formik.values[customField?.value] || '' }
                onChange={ formik.handleChange }
                fullWidth
                label={ `${customField?.label}${customField?.required ? '*' : ''
                  }` }
                id="customFieldValue"
                onFocus={ (event) =>
                  event.target.setAttribute('autocomplete', 'off')
                }
                error={
                  formik.touched[customField?.value] &&
                  Boolean(formik.errors?.[customField?.value])
                }
                helperText={ t(formik.errors?.[customField?.value], { label: customField?.label }) as string }
                FormHelperTextProps={ {
                  sx: { color: '#D32F2F' }
                } }
                sx={ {
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disableAllFields ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor: disableAllFields
                      ? '#666666a6'
                      : '#212121'
                  }
                } }
              />
            </Grid>
          ) }

          {/* <Grid item xs={12}>
            {!editMode && <Questionairre formik={formik} questions={questions} />}
          </Grid> */}
        </Grid>
        <GenderDialog
          open={ openGenderDialog }
          onClose={ () => setOpenGenderDialog(false) }
        />
        <SexAssignedAtBirthDialog
          open={ openSexAssignedDialog }
          onClose={ () => setOpenSexAssignedDialog(false) }
        />
      </Box>
    </Root>
  )
}

// Utils
const filterOptions = createFilterOptions<IdentityDropdownOptionI>({
  matchFrom: 'start',
  stringify: (option) => option.label
})

const PREFIX = 'Medical Data'

const classes = {
  disabled: `${PREFIX}-disabled`
}

const Root = styled('div')(() => ({
  [`& .${classes.disabled}`]: {}
}))

// Types and interfaces
interface MedicalDataProps {
  formik: ReturnType<typeof useFormik>
  editMode: boolean
  disableAllFields: boolean
  isAdmin: boolean
  site: SiteI | undefined
  kit?: Kit | undefined
}

interface IdentityDropdownOptionI {
  label: string
  value: string
  info?: string
  id: number
}

export default MedicalData
