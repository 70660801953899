import { Stack, Tooltip, Typography } from '@mui/material'
import { Definition } from 'components'
import React, { useEffect, useState } from 'react'
import { flatQuestions } from './utils'
import { useTranslation } from 'react-i18next'
import { QuestionI } from '../../types/db/KitTypeConfigI'
import { getJSONFromString } from '../../utils/utilFuntions'
import { useGetKitTypeConfig } from '../../hooks/kitTypeConfig'
import useGetServerTranslation from '../../i18n/useGetServerTranslation'
import LanguageTabs from '../Translation/LanguageTabs'

export function QuestionnairePreview ({ kitTypeConfigId, children = <></> }: QuestionnairePreviewProps) {
  const { data, isLoading } = useGetKitTypeConfig(kitTypeConfigId as string)
  const { translation, isLoading: tIsLoading } = useGetServerTranslation(data?.screeningQuestions as string)
  const [screeningQuestions, setScreeningQuestions] = useState<QuestionI[]>([])

  const [tabValue, setTabValue] = useState<number>(0)

  useEffect(() => {
    if (!data || isLoading || tIsLoading || !translation) return

    switch (tabValue) {
      case 0:
        setScreeningQuestions(getJSONFromString(translation.english, []))
        break
      case 1:
        setScreeningQuestions(getJSONFromString(translation.spanish, []))
        break
      default:
        setScreeningQuestions([])
        break
    }
  }, [tabValue, translation, data])

  return (
    <Stack sx={ { gap: 3 } }>
      <Typography variant='h5'>Screening questions</Typography>
      <Typography>
        Patients will answer screening questions during test registration to
        help providers track patient symptoms/history and determine the
        appropriate test order.
      </Typography>
      <LanguageTabs
        title='Questionnaire'
        tabValue={ tabValue }
        setTabValue={ setTabValue }
      >

        <QuestionnaireSummary questionnaire={ screeningQuestions } />
      </LanguageTabs>
      { children }
    </Stack>
  )
}

export function QuestionnaireSummary ({ questionnaire }: QuestionnaireSummaryProps) {
  if (typeof questionnaire === 'string') questionnaire = getJSONFromString(questionnaire, [])
  return (
    <Stack sx={ { gap: 2 } }>
      { questionnaire.map((question, idx) => (
        <Question
          key={ question.id }
          question={ question }
          questionNumber={ idx + 1 }
        />
      )) }
    </Stack>
  )
}

function Question ({ question, questionNumber }: QuestionProps) {
  const { t } = useTranslation()
  const [mainQuestion, ...subQuestions] = flatQuestions(question) as QuestionI[]
  const options = mainQuestion.config?.options

  return (
    <>
      <Stack>
        <Typography>
          <Typography sx={ { fontWeight: 700 } } component='span'>
            Q{ questionNumber }:{ ' ' }
          </Typography>
          { t(mainQuestion.name) }

          {
            !mainQuestion.isActive
              ? (<Tooltip title='The question will not be displayed to the end user' arrow>
                <Typography component='span' variant='caption' color='error' sx={ { ml: 1 } }>(Inactive)</Typography>
                </Tooltip>)
              : null
          }
        </Typography>
        { options
          ? (
            <Stack component='ul' sx={ { m: 0, p: 0, pl: 3 } }>
              { options.map((option, idx) => (
                <Typography key={ option.id } component='li'>
                  <Definition title={ `A${idx + 1}:` }>{ t(option.name) }</Definition>
                </Typography>
              )) }
            </Stack>
            )
          : null }
      </Stack>
      { subQuestions.length
        ? subQuestions.map((subQuestion, idx) => (
          <Question
            key={ question.id }
            question={ { ...subQuestion, isActive: mainQuestion?.isActive } }
            questionNumber={ `${questionNumber}.${idx + 1}` }
          />
        ))
        : null }
    </>
  )
}

interface QuestionnairePreviewProps {
  kitTypeConfigId: string | number
  children?: React.ReactNode
}

interface QuestionnaireSummaryProps {
  questionnaire: QuestionI[]
}

interface QuestionProps {
  question: QuestionI
  questionNumber: number | string
}
