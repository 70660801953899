import React from 'react'
import {
  Box,
  Stack,
  Typography
} from '@mui/material'
import moment from 'moment'
import { gender, race, ethnicity } from 'components/PatientComponents/data/dropDownOptions'
import { useTranslation } from 'react-i18next'
import { PatientI } from '../../types/db/PatientI'

function PatientInfoFormProgram ({ patient }: { patient: PatientI | null }) {
  const { t } = useTranslation()
  const genderName = gender.find(g => g.value === patient?.gender)?.name || ''
  const raceValue = race.find(g => g.value === patient?.race)?.name || ''
  const ethnicityValue = ethnicity.find(g => g.value === patient?.ethnicity)?.name || ''

  return (
    <Stack gap={3}>
      <Typography variant="h5" component="h2">
        {t('healthplan.patientinfoform.patientinfo')}
      </Typography>
      <Box
        sx={{
          p: '12px'
        }}
      >
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.firstname')}:
          </Typography>
          <Typography variant="body1">
            {patient?.first_name || ''}
          </Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.lastname')}:
          </Typography>
          <Typography variant="body1">{patient?.last_name || ''}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.dateofbirth')}:
          </Typography>
          <Typography variant="body1">{moment(patient?.dob).isValid() ? moment(patient?.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.phonenum')}:
          </Typography>
          <Typography variant="body1">{patient?.phone || ''}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.address')}:
          </Typography>
          <Typography variant="body1">{`${patient?.address?.delivery_line_1 ? patient?.address?.delivery_line_1 + ',' : ''} ${patient?.address?.city_name ? patient?.address?.city_name + ',' : ''} ${patient?.address?.state_abbreviation ? patient?.address?.state_abbreviation + ',' : ''} ${patient?.address?.zipcode ? patient?.address?.zipcode : ''}`}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.gender')}:
          </Typography>
          <Typography variant="body1">{t(genderName)}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.ethnicity')}:
          </Typography>
          <Typography variant="body1">{t(ethnicityValue)}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} justifyContent={'left'}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('healthplan.patientinfoform.race')}:
          </Typography>
          <Typography variant="body1">{t(raceValue)}</Typography>
        </Stack>
      </Box>
      <Typography variant="body1">
        {t('healthplan.patientinfoform.pleaseensure')}{' '}
        <u
          // @ts-ignore
          onClick={() => window.Beacon('toggle')}
          style={{ cursor: 'pointer' }}
        >
          {t('healthplan.patientinfoform.reachout')}
        </u>
      </Typography>
    </Stack>
  )
}

PatientInfoFormProgram.propTypes = {}

export default PatientInfoFormProgram
