import { getSubdomain, isShkDomain } from 'utils/utilFuntions'

const isBeaconEnabled = !['health', 'provider'].includes(getSubdomain()) && isShkDomain()

if (isBeaconEnabled) {
  window.Beacon('init', '345286ed-ece7-498f-b62c-85903c5c8303')
  window.Beacon('config', {
    messaging: {
      chatEnabled: true
    }
  })
}
