import { getCookie, subdomainType, getSubDomain } from './routerUtils'
import { getSubdomain } from './utilFuntions'

export function checkWalmart () {
  const subdomain = getSubdomain()

  const redirectionCookie = getCookie('redirectTo')
  const regex = new RegExp(`^https://${subdomainType.walmart}.*$`)

  // Return true if subdomain is walmart or redirection cookie subdomain is walmart
  return subdomainType.walmart === subdomain || (redirectionCookie && regex.test(redirectionCookie))
}

export function checkLacare () {
  const subdomain = getSubdomain()

  const redirectionCookie = getCookie('redirectTo')
  const regex = new RegExp(`^https://${subdomainType.lacare}.*$`)

  // Return true if subdomain is lacare or redirection cookie subdomain is lacare
  return subdomainType.lacare === subdomain || (redirectionCookie && regex.test(redirectionCookie))
}

export function checkCvs () {
  const subdomain = getSubdomain()

  const redirectionCookie = getCookie('redirectTo')
  const regex = new RegExp(`^https://${subdomainType.cvs}.*$`)

  // Return true if subdomain is cvs or redirection cookie subdomain is cvs
  return subdomainType.cvs === subdomain || (redirectionCookie && regex.test(redirectionCookie))
}

export function checkWalgreens () {
  const subdomain = getSubdomain()

  const redirectionCookie = getCookie('redirectTo')
  const regex = new RegExp(`^https://${subdomainType.walgreens}.*$`)

  // Return true if subdomain is walgreens or redirection cookie subdomain is walgreens
  return subdomainType.walgreens === subdomain || (redirectionCookie && regex.test(redirectionCookie))
}

export function getAffiliate () {
  if (checkWalmart()) return 'walmart'
  if (checkCvs()) return 'cvs'
  if (checkWalgreens()) return 'walgreens'

  return 'app'
}

export function getAffiliateUrl (APP_URL) {
  const affiliate = getAffiliate()

  return (APP_URL || process.env.REACT_APP_URL).replace('app', affiliate)
}

export function checkAffiliate () {
  return checkWalmart() || checkCvs() || checkWalgreens()
}

export function checkPortalApp () {
  const subdomain = getSubDomain(window.location.hostname)

  return subdomain === subdomainType.app ||
    subdomain === subdomainType.walmart ||
    subdomain === subdomainType.lacare ||
    subdomain === subdomainType.cvs ||
    subdomain === subdomainType.walgreens
}
