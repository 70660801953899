import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material'
import useFormik from 'hooks/useFormik'
import { Heading } from '.'
import {
  MultiStepFormNavigation,
  useMultiStepForm
} from 'components/MultiStepForm'
import type { TPrepProgram } from './schemas'
import * as schemas from './schemas'
import type { PrEPProgramConfig } from 'types/db/SiteI'

function LifestyleInfo( {
  prepProgramConfig
}: {
  prepProgramConfig: PrEPProgramConfig
} ) {
  const { data, update } = useMultiStepForm<TPrepProgram>()

  const lifestyleInfoContent = prepProgramConfig.content.lifeStyleInfo
  const questions = lifestyleInfoContent.questions

  const formik = useFormik( {
    initialValues: data.lifeStyleInfo,
    validationSchema: schemas.lifeStyleInfo,
    onSubmit: formData => {
      update( {
        ...data,
        lifeStyleInfo: formData as TPrepProgram[ 'lifeStyleInfo' ]
      } )
    }
  } )

  return (
    <>
      <Heading>{lifestyleInfoContent.title}</Heading>
      <Stack
        gap={2}
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{ py: '18px' }}
      >
        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {questions[ 'situationsCurrentlyApply' ].title}
        </Typography>
        <FormControl error={formik.isError( 'situationsCurrentlyApply' )}>
          <FormGroup>
            {questions[ 'situationsCurrentlyApply' ].options.map( option => (
              <>
                <FormControlLabel
                  control={<Checkbox />}
                  label={option.label}
                  key={option.value}
                  {...formik.registerCheckbox(
                    'situationsCurrentlyApply',
                    option.value
                  )}
                />
              </>
            ) )}
            {formik.isError( 'situationsCurrentlyApply' ) ? (
              <FormHelperText error>
                {`${formik.errors[ 'situationsCurrentlyApply' ] || ''}`}
              </FormHelperText>
            ) : null}
          </FormGroup>
        </FormControl>
        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {questions[ 'currentlyUsingDailyPrep' ].title}
        </Typography>
        <FormControl error={formik.isError( 'currentlyUsingDailyPrep' )}>
          <RadioGroup
            name={'currentlyUsingDailyPrep'}
            value={formik.values[ 'currentlyUsingDailyPrep' ]}
            onChange={formik.handleChange}
          >
            {questions[ 'currentlyUsingDailyPrep' ].options.map( option => (
              <FormControlLabel
                label={option.label}
                value={option.value}
                control={<Radio />}
              />
            ) )}
          </RadioGroup>
          {formik.isError( 'currentlyUsingDailyPrep' ) ? (
            <FormHelperText>
              {`${formik.errors[ 'currentlyUsingDailyPrep' ] || ''}`}
            </FormHelperText>
          ) : null}
        </FormControl>
        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {questions[ 'situationsApply' ].title}
        </Typography>
        <FormControl error={formik.isError( 'situationsApply' )}>
          <FormGroup>
            {questions[ 'situationsApply' ].options.map( option => (
              <>
                <FormControlLabel
                  key={option.value}
                  control={<Checkbox />}
                  label={option.label}
                  {...formik.registerCheckbox( 'situationsApply', option.value )}
                />
              </>
            ) )}
            {formik.isError( 'situationsApply' ) ? (
              <FormHelperText>{`${formik.errors[ 'situationsApply' ] || ''}`}</FormHelperText>
            ) : null}
          </FormGroup>
        </FormControl>
        <MultiStepFormNavigation />
      </Stack>
    </>
  )
}

export default LifestyleInfo
