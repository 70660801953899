import $ from 'jquery'
import _ from 'lodash'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import { store } from '../state/store'
import { auth } from 'utils/auth0Service'

$.ajaxSetup({
  beforeSend (xhr, settings) {
    xhr.url = settings.url
    if (settings.type !== 'GET' && settings.json && _.isObject(settings.json)) {
      settings.contentType = 'application/json'
      settings.data = JSON.stringify(settings.json)
      xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8')
    }
  }
})

export default async function (
  path,
  { method = 'GET', data = null, prefix = '/api/', dispatch = false, type = 'add', deleteFirst = false }
) {
  try {
    // should send optionally
    const token = window.sessionStorage.getItem('token')
    const tokenData = jwt.decode(token)

    if (tokenData?.exp <= moment().unix()) {
      window.sessionStorage.removeItem('token')
      auth.logout({
        returnTo: window.location.origin
      })
      return
    }

    const options = {
      method,
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
        'Accept-Language': window.localStorage.getItem('i18nextLng')
      }
    }
    if (method === 'GET') options.data = data
    else options.json = data
    if (dispatch) store.dispatch({ type: `ui/${dispatch}/ajax`, payload: { status: 'loading' } })

    const resp = await new Promise((resolve, reject) => {
      // Simulating an asynchronous operation
      $.ajax(`${prefix}${path}`, options)
        .done(function (data, status, xhr) {
          if (typeof data === 'object' && data !== null) { data.headers = xhr.getAllResponseHeaders() }
          resolve(data)
        }).fail(function (xhr) {
          reject(xhr) // reject with error response to the Promise in case of an error
        })
    })

    if (dispatch) {
      if (type === 'update') {
        store.dispatch({ type: `${dispatch}/update`, payload: resp })
      } else if (type === 'remove') {
        store.dispatch({ type: `${dispatch}/remove`, payload: resp })
      } else {
        store.dispatch({ type: `${dispatch}/add`, payload: resp, deleteFirst })
      }
      store.dispatch({ type: `ui/${dispatch}/ajax`, payload: null })
    }
    return resp
  } catch (e) {
    if (dispatch) store.dispatch({ type: `ui/${dispatch}/ajax`, payload: Object.assign({ status: 'error' }, e.responseJSON) })
    console.log('error', e.responseJSON)
    throw e
  }
}
