import { Alert, Button, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React from 'react'

function ExpiredKit () {
  const navigate = useNavigate()

  return (
    <Stack
      sx={{ gap: 3, alignItems: 'center' }}
    >
      <Typography variant='h5' component='h2'>This Kit Has Expired</Typography>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Alert severity='warning' sx={{ display: 'flex', alignItems: 'center' }}>
            Unfortunately this kit has expired. This means that any sample collected with this kit can no longer be processed.
        </Alert>
      </Stack>
      <Button variant='outlined' onClick={() => { navigate('/') }}>Go back to dashboard</Button>
    </Stack>
  )
}

export default ExpiredKit
