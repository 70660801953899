import Layout from 'components/Layout'
import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { getSubdomain } from './utils/utilFuntions'
import MfaPage from 'pages/Auth/MfaPage'
import GoogleSaml from 'pages/Auth/GoogleSaml'

const Login = lazy(() => import('./pages/Auth/Login'))
const ProcessAuthHash = lazy(() => import('./pages/Auth/ProcessAuthHash'))
const Signup = lazy(() => import('./pages/Auth/Signup'))

function PreAuth ({
  additionalRoutes
}: {
  additionalRoutes?: React.ReactElement
}) {
  const location = useLocation()
  const subdomain = getSubdomain()

  if (location.hash.startsWith('#access_token')) return <ProcessAuthHash />

  if (window.location.pathname !== '/') {
    window.sessionStorage.setItem('redirectTo', window.location.href)
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={['provider', 'customeradmin'].includes(subdomain) ? false : <Layout />}>
          <Route index element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/verify-otp/:token' element={<MfaPage />} />
          <Route path='/login-sso' element={<GoogleSaml />} />
        </Route>
        <Route path='*' element={additionalRoutes || <Navigate to={`/?redirectTo=${location.pathname}`} />} />
      </Routes>
    </Suspense>
  )
}

export default PreAuth
