import React from 'react'
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  Typography
} from '@mui/material'
import { useFormik } from 'formik'
import SectionRender from './components-prep/SectionRender'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import CardRender from './components-prep/CardRender'
import { useMultiStepForm } from 'components/MultiStepForm'
import { Heading } from '.'
import useDocumentTitle from 'hooks/useDocumentTitle'
import type { TPrepProgram } from './schemas'
import * as schemas from './schemas'
import { PrEPProgramConfig } from 'types/db/SiteI'

function Home ({ prepProgramConfig }: { prepProgramConfig: PrEPProgramConfig }) {
  const { update, data } = useMultiStepForm<TPrepProgram>()

  const homeContent = prepProgramConfig.content.home

  useDocumentTitle(homeContent.heading.title)

  const formik = useFormik({
    initialValues: data.home,
    onSubmit: () => {
      update({ ...data, home: formik.values })
    },
    validationSchema: schemas.home
  })

  return (
    <Stack gap={2} component='form' onSubmit={formik.handleSubmit}>
      <Heading>{homeContent.heading.title}</Heading>
      {homeContent.headerSections.map(section => (
        <SectionRender
          key={section.title || section.body}
          title={section.title}
          body={section.body}
        />
      ))}
      {homeContent.cardData.map(section => (
        <CardRender
          title={section.title}
          bgColor={section.bgColor}
          src={section.src}
          color={section.color}
          key={section.title}
        />
      ))}
      {homeContent.footerSections.map(section => (
        <SectionRender
          title={section.title}
          body={section.body}
          key={section.title}
        />
      ))}
      <FormControl
        error={!!formik.errors.disclaimer}
        sx={{ textAlign: 'start' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.disclaimer}
              name='disclaimer'
              onChange={formik.handleChange}
            />
          }
          label={
            <Typography variant='subtitle1'>
              {homeContent.disclaimer}
            </Typography>
          }
        />
        <FormHelperText>{formik.errors.disclaimer}</FormHelperText>
      </FormControl>
      <Button type='submit' fullWidth variant='contained'>
        Start the questionnaire
      </Button>
    </Stack>
  )
}

export default Home
