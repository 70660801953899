// TODO: remove @ts-nocheck once the whole file is typed
// @ts-nocheck
import moment from 'moment'
import { appendQueryString } from 'utils/utilFuntions'
import ajax from './ajax'
import { PromotionalWidgetI, SiteI, TPrepProgramConfigSite } from 'types/db/SiteI'
import { PatientI } from 'types/db/PatientI'
import { TPrepProgram } from 'pages/PrepProgram/schemas'
import { TRegisteredPrepProgram } from 'types/db/RegisteredPrepProgram'
import {
  downloadFileRequestData,
  kitFulfillmentJSONData
} from 'health-app/pages/Reports/KitFulfillmentReport'
import { MemberUploadPayload } from 'health-app/components/MemberCSVModal'
import { KitFromLimsI, KitsFromDBI } from 'types/KitFromLimsI'
import { ResultFromLimsI } from 'types/ResultFromLimsI'
import { KitTypeConfigI } from 'types/db/KitTypeConfigI'
import { AnalyticsReportResponse } from 'types/AnalyticsReport'
import {
  HedisFileJSONData
} from 'health-app/pages/Reports/HedisExtract'
import { AuthUser } from '../types/AuthUser'
import { Kit } from '../types/db/Kit'
import { ConsultI, Pharmacy } from '../types/db/Consult'
import { HealthPlanUtilizationAnalyticsI } from '../types/healthPlan/HealthPlanUtilizationAnalyticsI'
import { UseQueryOptions } from '@tanstack/react-query'
import { HealthPlanTestsAnalyticsI } from '../types/healthPlan/HealthPlanTestsAnalyticsI'
import { HealthPlanOverviewAnalyticsI } from '../types/healthPlan/HealthPlanOverviewAnalyticsI'
import { PatientAnalyticsI } from 'types/healthPlan/PatientAnalyticsI'
import { HealthPlanMembersAnalyticsI } from 'types/healthPlan/HealthPlanMembersAnalyticsI'
import { PopulationHealthDownloadI, ProgramInsightsDownloadI, ProgramInsightsI } from '../types/ProgramInsightsI'
import { PopulationHealthI } from '../types/PopulationHealthI'
import { AppNotificationI } from '../types/db/AppNotificationI'
import { OperationsAnalyticsI } from '../types/OperationsAnalyticsI'
import { HealthPlanAnalyticsRawData } from '../types/healthPlan/HealthPlanAnalyticsRawData'
import { PatientsTableFiltersI } from '../admin-app/pages/Patients'
import { SampleI } from '../types/db/SampleI'
import { AnalyteConfigI } from '../types/db/AnalyteConfigI'
import { PatientFromLimsI } from '../types/PatientFromLimsI'
import { HealthFeedbackAnalytics } from 'types/healthPlan/FeedbackAnalytics'
import { THealthPlanNotificationSettings } from 'types/db/HealthPlanNotificationSettings'
import { HealthPlanMemberI } from '../types/db/HealthPlanMemberI'
import { MemberDetailsType } from 'health-app/components/MembersTable/MemberDetails'
import { TranslationI } from '../types/TranslationI'
import { AvailibilityDaysAPI, AvailibilitySlotsAPI, CancelAppointmentAPI, CompleteCareCoachingCheckoutAPI, CompleteConsultCheckoutAPI, SendConsultFormAPI } from 'types/Consult'
import { ProgramConfig, ProgramI } from '../types/db/ProgramI'
import { CohortI } from '../types/db/CohortI'
import { SkuResponse } from 'types/db/HealthOptInProgramI'
import { MailerSettingsI } from '../types/db/MailerSettingsI'
import { SsoSettingsI } from 'types/db/SsoSettingsI'
import MemberJourney from 'health-app/components/MembersTable/MemberJourney'
import { Sku } from '../types/db/Sku'
import { GridSortDirection } from '@mui/x-data-grid'
import { MailOnHoldI } from '../types/MailsOnHoldI'

const api = {
  admin: {
    resultsHeatMap: function (queryString: any) {
      const url = appendQueryString('a/results-heat-map', { ...queryString })
      return ajax(url, { method: 'GET' })
    },
    getRequisitions: function ({
      patientId,
      maxTestsAge,
      page,
      pageSize,
      sortingField,
      sortingDir,
      showApprovalRecords,
      selectedTests,
      textFilter
    }: {
      patientId?: string
      textFilter?: string
      maxTestsAge?: string,
      page?: number,
      pageSize?: number,
      sortingField?: string,
      sortingDir?: GridSortDirection,
      showApprovalRecords?: string | null,
      selectedTests?: string[]
    }): Promise<KitsFromDBI> {
      return ajax('a/requisitions', {
        method: 'GET',
        data: {
          patientId,
          maxTestsAge,
          page,
          pageSize,
          sortingField,
          sortingDir,
          showApprovalRecords,
          selectedTests,
          textFilter
        }
      })
    },
    getNonLimsRequisitions: function ({
      startDate,
      endDate,
      status
    }: {
      startDate?: string
      endDate?: string
      status?: string[]
    }): Promise<{ data: any[], siteId: string }> {
      return ajax('a/non-lims-requisitions', {
        method: 'GET',
        data: {
          startDate,
          endDate,
          status
        }
      })
    },
    getPatient: function (data) {
      return ajax(`a/patient/${data.id}`, {
        method: 'GET'
      })
    },
    getPatients: function (queryString: PatientsTableFiltersI = {}): Promise<{
      headers: string,
      patients: PatientI[]
      pagination: {
        count: number
        offset: number
        limit: number
      }
    }> {
      const url = appendQueryString('a/patients', { ...queryString })

      return ajax(url, {
        method: 'GET'
      })
    },
    getPatientByLims: function (data): Promise<{
      patient: PatientI
    }> {
      return ajax(`a/patient-by-lims/${data.id}`, {
        method: 'GET'
      })
    },
    triggerReqSyncCron: function () {
      return ajax('a/trigger-req-sync-cron', { method: 'GET' })
    },
    triggerKitTracking: function () {
      return ajax('a/trigger-kit-tracking', { method: 'GET' })
    },
    triggerUSSLCron: function () {
      return ajax('a/trigger-ussl-cron', { method: 'GET' })
    },
    triggerNotificationsCron: function (data) {
      return ajax('a/cron/notifications', { method: 'GET', data })
    },
    triggerReqDeletionCron: function () {
      return ajax('a/trigger-req-deletion-cron', { method: 'GET' })
    },
    triggerMailerCron: function (): Promise<{ success: boolean }> {
      return ajax('a/trigger-mailer-cron', { method: 'GET' })
    },
    clearDBCache: function () {
      return ajax('a/clear-db-cache', { method: 'GET' })
    },
    fetchSitesPatients: function (data: { sitecode: string }): Promise<{ patients: PatientFromLimsI[] }> {
      return ajax('a/get-sites-patients', { method: 'POST', data })
    },
    fetchSitesAllPatients: function (data: { siteids: number[] }): Promise<{ patients: PatientFromLimsI[] }> {
      return ajax('a/get-sites-all-patients', { method: 'POST', data })
    },
    checkKit: function (data) {
      return ajax('a/check-kit', {
        method: 'GET',
        data: { kitcode: data.kitcode, sitecode: data.sitecode }
      })
    },
    registerPatientToNewSite: function (data) {
      return ajax('a/duplicate-patient', { method: 'POST', data })
    },
    updateKit: function (data) {
      return ajax('a/update-kit', { method: 'PATCH', data })
    },
    deleteKit: function (data) {
      return ajax('a/delete-kit', { method: 'POST', data })
    },
    syncSites: function () {
      return ajax('a/sync-sites', { method: 'GET' })
    },
    getSites: function () {
      return ajax('a/sites', { method: 'GET' }) as Promise<SiteI[]>
    },
    getAllPromotionalWidgets: function () {
      return ajax('a/promotional-widgets', { method: 'GET' }) as Promise<PromotionalWidgetI[]>
    },
    getResultDetails: function (id: string, queryString: string = '') {
      return ajax(`a/result-details/${id}?${queryString}`, {
        data: { id },
        method: 'GET'
      }) as Promise<{ data: SampleI[], token: string }>
    },
    getResultsDetails: function (
      ids: string[]
    ): Promise<ResponseGetResultsDetails> {
      return ajax('a/results-details', {
        data: { ids },
        method: 'GET'
      })
    },
    newDownloadResult: function (kitcode) {
      return ajax(`a/new-download-result/${kitcode}`, {
        method: 'GET'
      })
    },
    fetchQuestions: function (id) {
      return ajax(`a/fetch-questions/${id}`, {
        method: 'GET'
      })
    },
    accessSites: function (params?: string = ''): Promise<SiteI[]> {
      return ajax(`a/access-sites${params}`, { method: 'GET' })
    },
    updateApprovalSites: function (id, data) {
      return ajax(`a/site/${id}/approval-sites`, {
        method: 'PATCH',
        data
      })
    },
    updateKitPaymentOption: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/payment-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitAftercareConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/aftercare-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitAgeConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/age-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitVideoTutorialConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/video-tutorial-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateProductLinkConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/product-link-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateOutreachConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/outreach-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateAftercareLockConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/update-aftercare-lock-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateApprovalLockConfig: function ({ siteId, kitType, ...data }) {
      return ajax(`a/site/${siteId}/update-approval-lock-config/${kitType}`, {
        method: 'PATCH',
        data
      })
    },
    updateKitsOnHoldStatus: function (ids) {
      return ajax('a/update-kits-on-hold', { method: 'PATCH', data: { ids } })
    },
    fetchRoles: function () {
      return ajax('a/fetch-roles', { method: 'GET' })
    },
    inviteUser: function (data) {
      return ajax('a/invite-user', { method: 'POST', data })
    },
    getApiAuditLogs: function ({
      limit = 10,
      offset = 0,
      date = moment().format('l')
    } = {}) {
      return ajax(
        `a/api-audit-logs?limit=${limit}&offset=${offset}&date=${date}`,
        { method: 'GET' }
      )
    },
    registerPatient: function (data) {
      return ajax('a/register-patient', { method: 'POST', data })
    },
    validateSite: function (sitecode) {
      return ajax(`a/validate-site/${sitecode}`, {
        method: 'GET',
        data: { sitecode }
      })
    },
    resendNotification: function (data) {
      return ajax('a/resend-notification', { method: 'POST', data })
    },
    resendBulkNotification: function (data) {
      return ajax('a/resend-bulk-notifications', { method: 'POST', data })
    },
    forceSyncKit: function (data) {
      return ajax('a/force-sync-kit', { method: 'POST', data })
    },
    linkLimsPatient: function (data) {
      return ajax('a/link-lims-patient', { method: 'POST', data })
    },
    askPatientForUpdate: function (data) {
      return ajax('a/ask-patient-for-update', { method: 'POST', data })
    },
    approveAndNotifyTests: function (data: { kitCodes: string[] }) {
      return ajax('a/approve-notify', { method: 'POST', data })
    },
    toggleSiteCodeVisibility: function (data) {
      return ajax('a/toggle-sitecode-visibility', { method: 'POST', data })
    },
    updatePatientNotes: function ({ patientId, ...data }) {
      return ajax(`a/patient/${patientId}/notes`, { method: 'PATCH', data })
    },
    updateSiteNotes: function ({ siteId, ...data }) {
      return ajax(`a/site/${siteId}/notes`, { method: 'PATCH', data })
    },
    updateKitNotes: function ({ kitId, ...data }) {
      return ajax(`a/kit/${kitId}/notes`, { method: 'PATCH', data })
    },
    updatePatientInsurance: function (data) {
      return ajax('a/update-patient-insurance', { method: 'POST', data })
    },
    fetchUsersWithRole: function (siteId) {
      return ajax(`a/users-with-role?site=${siteId}`, { method: 'GET' })
    },
    inviteUserToSite: function (data) {
      return ajax('a/invite-user-to-site', {
        method: 'POST',
        data
      })
    },
    fetchEmailFromToken: function (token) {
      return ajax(`a/fetch-email?token=${token}`, {
        method: 'GET'
      })
    },
    removeFromSite: function (data) {
      return ajax('a/remove-from-site', { method: 'POST', data })
    },
    updatePatientDetails: function (data) {
      return ajax('a/update-patient-details', {
        data,
        method: 'PATCH'
      })
    },
    downloadResultExternal: function ({ id, ...data }): Promise<DownloadResultResp> {
      return ajax(`a/external/download-result/${id}`, {
        data,
        method: 'POST'
      })
    },
    sendPaymentLink: function (data) {
      return ajax('a/send-payment-link', {
        data,
        method: 'POST'
      })
    },
    checkKitStatus: function (code) {
      return ajax(`a/kits/status/${code}`, { method: 'GET' })
    },
    getConsultsAssociatedToSiteCodes: function () {
      return ajax('a/consults', { method: 'GET' })
    },
    getAnalyteNames: function () {
      return ajax('a/analytes/names', { method: 'GET' })
    },
    getActionsLog: function (data) {
      return ajax('a/user-actions', { method: 'POST', data })
    },
    getEventReports: function (data) {
      return ajax('a/event-reports', { method: 'POST', data })
    },
    updateOverrideScreeningQuestions: function (siteId: string, data: SiteI['override_screening_questions']): Promise<{ sucess: true, site: SiteI }> {
      return ajax(`a/site/${siteId}/override-screening-questions`, {
        method: 'PATCH',
        data
      })
    },
    copyConfig: function (data) {
      return ajax('a/copy-config', { method: 'POST', data })
    },
    exportRegistrationData: function (data: { kitCodes: string[] }) {
      return ajax('a/export-registration-data', { method: 'POST', data })
    },
    resetPwWidget: function (data) {
      return ajax('a/temp-pw', { method: 'POST', data })
    },
    postResultsToSftp: function (data) {
      return ajax('a/post-results-to-sftp', { method: 'POST', data })
    },
    populateLimsDataToDb: function () {
      return ajax(`a/populate-lims-data-db`, {
        method: 'GET'
      })
    },
  },
  kitTypeConfig: {
    create: function (data) {
      return ajax('a/kit-type-config/', { method: 'POST', data })
    },
    getAll: function (translate: boolean): Promise<KitTypeConfigI[]> {
      return ajax(`a/kit-type-config?translate=${translate}`, { method: 'GET' })
    },
    getAllIdentifiers: function (): Promise<Pick<KitTypeConfigI, 'identifier' | 'id'>[]> {
      return ajax('a/kit-type-config/get-all-identifiers', { method: 'GET' })
    },
    getKitTypesInfo: function (): Promise<{
      kitTypeConfigs: KitTypeConfigI[],
      translation: KitTypeConfigI[]
    }> {
      return ajax('a/kit-type-config/info', { method: 'GET' })
    },
    getById: function (id: string, translate: boolean = false): Promise<KitTypeConfigI> {
      return ajax(`a/kit-type-config/${id}?translate=${translate}`, { method: 'GET' })
    },
    update: function (data) {
      return ajax(`a/kit-type-config/${data.id}`, { method: 'PATCH', data })
    },
    remove: function (id) {
      return ajax(`a/kit-type-config/${id}`, { method: 'DELETE' })
    }
  },
  portalAuth: {
    activateMfa: function (data, token) {
      return ajax(`portal-auth/activate-mfa/${token}`, {
        data,
        method: 'POST'
      })
    },
    signup: function (data) {
      return ajax('portal-auth/register', {
        data,
        method: 'POST'
      })
    },
    send2FaOtp: function (data) {
      return ajax('portal-auth/send-2fa-otp', {
        data,
        method: 'POST'
      })
    },
    login: function (data) {
      return ajax('portal-auth/login', {
        data,
        method: 'POST'
      })
    },
    grafanaToken: function () {
      return ajax('portal-auth/grafana-token', {
        method: 'POST'
      })
    },
    refresh: function () {
      return ajax('portal-auth/refresh', {
        method: 'POST'
      })
    },
    logout: function (data) {
      return ajax('portal-auth/logout', {
        method: 'POST',
        data,
        credentials: 'same-origin'
      })
    },
    emailVerify: function (token) {
      return ajax(`portal-auth/email-verify/${token}`, {
        method: 'GET'
      })
    },
    checkOtpToken: function (token) {
      return ajax(`portal-auth/verify-opt-token/${token}`, {
        method: 'GET'
      })
    },
    checkOtp: function (token, data) {
      return ajax(`portal-auth/verify-opt/${token}`, {
        method: 'POST',
        data
      })
    },
    setCookie: function (query) {
      const url = appendQueryString('portal-auth/set-cookie', query)
      return ajax(url, { method: 'GET' })
    },
    checkDomain: function (query = {}) {
      const url = appendQueryString('portal-auth/login-check', query)
      return ajax(url, { method: 'GET' })
    }
  },
  auth: {
    getSubdomain: function (email) {
      return ajax(`auth/get-subdomain/${email}`, {
        method: 'GET'
      })
    },
    sendResetPasswordMail: function (data) {
      return ajax('auth/send-reset-password-mail', {
        data,
        method: 'POST'
      })
    },
    sendQuery: function (data) {
      return ajax('auth/sendQuery', { method: 'POST', data })
    },
    sendFeedback: function (data) {
      return ajax('auth/sendFeedback', { method: 'POST', data })
    },
    sendVerificationMailLink: function (data) {
      return ajax('auth/send-verification-mail-link', {
        data,
        method: 'POST'
      })
    },
    updateProfile: function (data: UpdateProfileData): Promise<AuthUser> {
      return ajax('auth/profile', { method: 'PATCH', data, dispatch: false })
    },
    checkTicket: function (ticket) {
      return ajax(`auth/check-ticket/${ticket}`, { method: 'GET' })
    },
    changePassword: function (data) {
      return ajax('auth/change-password', { method: 'PATCH', data })
    },
    checkAccountExists: function (data) {
      return ajax('auth/check', { method: 'POST', data })
    },
    verifyUser: function (data) {
      return ajax('auth/verify-user', { method: 'PATCH', data })
    },
    sendUpdateEmailLink: function (data) {
      return ajax('auth/send-update-email-link', { method: 'POST', data })
    },
    validateUpdateEmailToken: function (data) {
      return ajax('auth/validate-update-email-token', { method: 'POST', data })
    },
    checkUpdateEmailTokenValid: function (token) {
      return ajax(`auth/check-update-email-token/${token}`, { method: 'GET' })
    },
    updateTncData: function () {
      return ajax('auth/legal-info', { method: 'PUT' })
    },
    validateMagicLink: function ({ token, key }) {
      return ajax(
        `auth/signup-verify/${token}/${key}`,
        { method: 'GET' }
      ) as Promise<{
        success: boolean,
        action: string,
        data?: object
      }>
    },
    signup: function (data) {
      return ajax('auth/signup', {
        data,
        method: 'POST'
      })
    }
  },
  patient: {
    getAll: function () {
      return ajax('patient', { dispatch: 'patients' }) as Promise<PatientI[]>
    },
    getPatient: function (data): Promise<{ patient: PatientI, siteCode: string }> {
      return ajax('patient/getPatient', { method: 'POST', data })
    },
    checkSync: function () {
      return ajax('patient/sync', { method: 'GET' })
    },
    updatePatient: function (data) {
      return ajax('patient/profile/update', {
        data,
        method: 'PATCH',
        dispatch: 'patients',
        type: 'update'
      })
    },
    register: function (data) {
      return ajax('patient/register', { data, method: 'POST' })
    },
    registerToDifferentSite: function (data) {
      return ajax('patient/registerToDifferentSite', { data, method: 'POST' })
    },
    registerPatientToNewSite: function (data) {
      return ajax('patient/duplicate-patient', { data, method: 'POST' })
    },
    getProfileDetails: function (data) {
      return ajax('patient/profile/getProfileDetails', { data, method: 'POST' })
    },
    streetSearch: function (data) {
      return ajax('patient/smarty', {
        data,
        method: 'POST'
      })
    },
    addPatient: function (data) {
      return ajax('patient/addPatient', { data, method: 'POST' })
    },
    updateInsurance: function (data) {
      return ajax('patient/updateInsurance', { data, method: 'PATCH' })
    },
    validateUpdateRequestToken: function (tokenCache) {
      return ajax(`patient/validate-update-request-token/${tokenCache}`, {
        method: 'GET'
      })
    },
    validateTokenCache: function (data) {
      return ajax('patient/update-cache-token', { data, method: 'POST' })
    },
    sendLimsVerificationMail: function (data) {
      return ajax('patient/lims-email-verification', { data, method: 'POST' })
    },
    verifyLimsEmail: function (token) {
      return ajax(`patient/lims-email-verification/${token}`, {
        method: 'PATCH'
      })
    },
    getLimsEmailVerifiedDetails: function (token) {
      return ajax(`patient/lims-email-verification/${token}`, { method: 'GET' })
    },
    linkPatientsToAccount: function () {
      return ajax('patient/link', { method: 'GET' })
    },
    checkInLims: function () {
      return ajax('patient/check-in-lims', { method: 'GET' })
    }
  },
  kit: {
    getUserLatestKit: function () {
      return ajax('kit/get-user-latest-kit', {
        method: 'GET'
      })
    },
    getAll: function () {
      return ajax('kit/search', {
        method: 'POST',
        dispatch: 'kits',
        deleteFirst: true
      })
    },
    getKitsByPatient: function (patientId, queryString = ''): Promise<{ kits: PartialKitWithSample[] }> {
      return ajax(`kit/by-patient/${patientId}${queryString.length ? '?' + queryString : ''}`, { method: 'GET' })
    },
    check: function (
      code: string,
      mock: boolean = false,
      provider: boolean = false
    ): Promise<KitCheckResponse> {
      return ajax('kit/check', { data: { code, mock, provider } })
    },
    saveKitAction: function (code, pageInfo, additionalInfo = {}) {
      return ajax(`kit/save-kit-action/${code}`, {
        method: 'POST',
        data: { pageInfo, additionalInfo }
      })
    },
    register: function (code, { requestType = '', siteName = '' } = {}) {
      return ajax('kit/register', {
        method: 'POST',
        data: { code, requestType, siteName },
        dispatch: 'kits'
      })
    },
    update: function (id, data) {
      return ajax(`kit/${id}`, {
        data,
        method: 'PATCH',
        dispatch: 'kits'
      })
    },
    generateKitCodes: function (data) {
      return ajax('kit/a/add', {
        data,
        method: 'POST'
      })
    },
    getResultDetails: function (id, token): Promise<SampleI[]> {
      return ajax(`kit/result-details/${id}`, {
        data: { id, token },
        method: 'GET'
      })
    },
    getResultDetailsAuth: function (id: string): Promise<SampleI[]> {
      return ajax(`kit/get-result-auth/${id}`, {
        data: { id },
        method: 'GET'
      })
    },
    getResult: function (data) {
      return ajax('kit/get-result', {
        data,
        method: 'POST'
      })
    },
    downloadResult: function (id): Promise<DownloadResultResp> {
      return ajax(`kit/download-result/${id}`, {
        data: { id },
        method: 'GET'
      })
    },
    downloadResultReporting: function (id) {
      return ajax(`kit/download-result-reporting/${id}`, {
        data: { id },
        method: 'GET'
      })
    },
    deleteKit: function (data) {
      return ajax('kit/delete', {
        data,
        method: 'POST',
        dispatch: 'kits',
        type: 'remove'
      })
    },
    getInsurance: function (siteCode, kitTypeConfigId): Promise<{ insurance: boolean }> {
      return ajax(
        `kit/insurance/${siteCode?.toUpperCase()}/${kitTypeConfigId}`,
        {
          method: 'GET'
        }
      )
    },
    getKit: function (kitCode): Promise<{ kit: Kit }> {
      return ajax(`kit/${kitCode}`, { method: 'GET' })
    },
    shareResultWithProvider: function ({ id, ...data }: { id: string, name: string, email: string }) {
      return ajax(`kit/${id}/share-result-with-provider`, {
        method: 'POST',
        data
      })
    },
    sendRetestNotification: function (data) {
      return ajax('kit/send-retest-notification', { method: 'POST', data })
    },
    getDiabetesTrend: function (kitcode) {
      return ajax(`kit/diabetes-trend/${kitcode}`, { method: 'GET' })
    },
    isLatestKit: function (kitId) {
      return ajax(`kit/${kitId}/is-latest`, { method: 'GET' })
    },
    getSearchOrders: function (): Promise<ProgramKitI[]> {
      return ajax('kit/search-orders', { method: 'POST' })
    },
    getPatientDetailsFromKit: function (kitcode) {
      return ajax(`kit/reporting/${kitcode}`, {
        method: 'GET'
      })
    },
    isFaultyKit: function (sampleId) {
      return ajax(`kit/faulty/${sampleId}`, { method: 'GET' })
    },
    runReport: function (data) {
      return ajax('kit/run-report', { method: 'POST', data })
    },
    adminRegisterKit: function (data: {
      collected_at: string
      kitcode: string
      patient_id: string
      site_code: string
    }) {
      return ajax('kit/admin-register-kit', { method: 'POST', data })
    },
    autoCreateReq: function (kitcode) {
      return ajax(`kit/create-hp-req/${kitcode}`, {
        method: 'GET'
      })
    },
    generateSampleIDs: function (data) {
      return ajax('kit/generate-sample-ids', { method: 'POST', data })
    },
    uploadGeneratedSampleIDs: function (data) {
      return ajax('kit/upload-generated-ids', { method: 'POST', data })
    }
  },
  whitelistNumber: {
    getAllSmsLogs: function () {
      return ajax('whitelist-number/get-all-sms-logs', {
        method: 'GET'
      })
    },
    getAll: function () {
      return ajax('whitelist-number/get-all', {
        method: 'GET'
      })
    },
    create: function (data) {
      return ajax('whitelist-number/create', {
        data,
        method: 'POST'
      })
    },
    delete: function (id) {
      return ajax(`whitelist-number/delete/${id}`, {
        method: 'DELETE'
      })
    }
  },
  content: {
    getByKey: function (key) {
      return ajax(`content/${key}`, { method: 'GET' })
    },
    updateByKey: function (key, data) {
      return ajax(`content/${key}`, { method: 'PATCH', data })
    },
    createOrUpdateByKey: function (key, data) {
      return ajax(`content/${key}`, { method: 'PUT', data })
    },
    get: function () {
      return ajax('content', { dispatch: 'content' })
    },
    update: function (data) {
      return ajax('content', {
        data,
        method: 'PATCH'
      })
    },
    validateSite: function (code) {
      return ajax('content/validate-site', { data: { code } })
    },
    getUser: function () {
      return ajax('content/user', {
        dispatch: 'user'
      })
    }
  },
  ping: {
    checkConnection: function () {
      return ajax('ping', {})
    }
  },
  proxy: {
    getInsuranceProviders: function () {
      return ajax('proxy/insurance_providers', {})
    },
    hasAppointmentOccured: function (data) {
      return ajax('proxy/has_appointment_occured', { method: 'POST', data })
    },
    getSites: function (data) {
      return ajax('proxy/get_sites', { method: 'POST', data })
    }
  },
  consult: {
    checkEligibility: function (data) {
      return ajax('consult/check-eligibility', {
        method: 'POST',
        data
      })
    },
    createConsultData: function (data) {
      return ajax('consult/create-data', {
        method: 'POST',
        data
      })
    },
    updateConsultData: function (id, data) {
      return ajax(`consult/${id}`, {
        method: 'PATCH',
        data
      })
    },
    getConsultData: function (id) {
      return ajax(`consult/${id}`, {
        method: 'GET'
      })
    },
    createConsult: function (data) {
      return ajax('consult/create-consult/', {
        method: 'POST',
        data
      })
    },
    getConsults: function (
      page: string | number = '1',
      limit: string | number = '10',
      queryString: {
        patientId: string
        kitTypeConfigId: string
      } = {}
    ): Promise<ConsultsResponse> {
      const url = appendQueryString('consult', { page, limit, ...queryString })
      return ajax(url, { method: 'GET' })
    },
    getConsultsByKit: function (kitId) {
      return ajax(`consult/kit/${kitId}`, { method: 'GET' })
    },
    markConsultAsPickedUp: function (id) {
      return ajax(`consult/mark-picked/${id}`, { method: 'GET' })
    },
    getPharmacies: function (
      consultId: string,
      zipCode: string
    ): Promise<Pharmacy[]> {
      return ajax(`consult/${consultId}/pharmacies?zip=${zipCode}`, {
        method: 'GET'
      })
    },
    checkProviderResponse: function () {
      return ajax('consult/check-provider-response', { method: 'GET' })
    },
    checkProviderResponseForConsult: function (consultCode) {
      return ajax(`consult/check-provider-response/${consultCode}`, {
        method: 'GET'
      })
    },
    getConsultFromFeedbackToken: function (token) {
      return ajax(`consult/feedback/${token}`, { method: 'GET' })
    },
    getConsultFromId: function (id) {
      return ajax(`consult/id/${id}`, {
        method: 'GET'
      })
    },
    getAvailableConsultDays: function (data: AvailibilityDaysAPI) {
      return ajax('consult/get-available-days', {
        method: 'POST',
        data
      })
    },
    getAvailableConsultSlots: function (data: AvailibilitySlotsAPI) {
      return ajax('consult/get-available-slots', {
        method: 'POST',
        data
      })
    },
    completeConsultAppointmentCheckout: function (data: CompleteConsultCheckoutAPI) {
      return ajax('consult/complete-consult-appointment-checkout', {
        method: 'POST',
        data
      })
    },
    rescheduleConsultAppointmentCheckout: function (data: CompleteConsultCheckoutAPI) {
      return ajax(`consult/reschedule-consult-appointment/${data.mwl_consult_id}/${data.ol_appointment_id || 'any'}`, {
        method: 'PATCH',
        data
      })
    },
    rescheduleCareCoachingAppointmentCheckout: function (data: CompleteConsultCheckoutAPI) {
      return ajax(`consult/reschedule-care-appointment/${data.mwl_consult_id}/${data.ol_appointment_id}`, {
        method: 'PATCH',
        data
      })
    },
    completeCareCoachingAppointmentCheckout: function (data: CompleteCareCoachingCheckoutAPI) {
      return ajax('consult/complete-care-coaching-appointment-checkout', {
        method: 'POST',
        data
      })
    },
    sendConsultFormData: function (data: SendConsultFormAPI) {
      return ajax('consult/send-consult-form', {
        method: 'POST',
        data
      })
    },
    cancelConsultAppointment: function (data: CancelAppointmentAPI) {
      return ajax(`consult/cancel-consult-appointment/${data.mwl_consult_id}/${data.ol_appointment_id}`, {
        method: 'POST',
        data
      })
    },
    cancelCareAppointment: function (data: CancelAppointmentAPI) {
      return ajax(`consult/cancel-care-appointment/${data.mwl_consult_id}/${data.ol_appointment_id}`, {
        method: 'POST',
        data
      })
    }
  },
  square: {
    processPayment: function (data) {
      return ajax('square/payments', { method: 'POST', data })
    },
    getPayment: function (data) {
      return ajax('square/payment', { method: 'GET', data })
    }
  },
  manage: {
    getPatients: function (queryStringObject = {}) {
      const url = appendQueryString('manage/patients', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getPatient: function (patientId) {
      const url = `manage/patients/${patientId}`
      return ajax(url, { method: 'GET' })
    },
    getRequisitions: function (queryStringObject = {}) {
      const url = appendQueryString('manage/requisitions', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getSites: function () {
      return ajax('manage/sites', { method: 'GET' })
    },
    getOutreachConsults: function (queryStringObject = {}) {
      const url = appendQueryString('manage/outreach-visits', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getMessages: function () {
      return ajax('manage/messages', { method: 'GET' })
    },
    setMessagesResolved: function (data) {
      return ajax('manage/messages/resolve', { method: 'POST', data })
    },
    getPatientByPhone: function (phone) {
      return ajax(`manage/patients/check-phone/${phone}`, { method: 'GET' })
    }
  },
  feedback: {
    createFeedback: function (data) {
      return ajax('feedback', { method: 'POST', data })
    },
    createFeedbackLoggedOut: function (token, data) {
      return ajax(`feedback/nps/create/${token}`, { method: 'POST', data })
    },
    updateFeedback: function (data) {
      const { id, ...feedback } = data
      return ajax(`feedback/${id}`, { method: 'PATCH', data: feedback })
    },
    getAll: function (query) {
      const url = appendQueryString('feedback', query)
      return ajax(url, { method: 'GET' })
    },
    getCheckToken: function (token, query = {}) {
      const url = appendQueryString(`feedback/nps/check-token/${token}`, query)
      return ajax(url, { method: 'GET' })
    },
    getCheckHashToken: function (token, query = {}) {
      const url = appendQueryString(`feedback/check-hash/${token}`, query)
      return ajax(url, { method: 'GET' })
    },
    unsubscribeKitNotification: function (token) {
      return ajax(`feedback/unsubscribe-kit-remainder/${token}`, { method: 'GET' })
    },
    optOutFTP: function (token) {
      return ajax(`feedback/opt-out/${token}`, { method: 'GET' })
    },
    getProductLink: function (code) {
      return ajax(`feedback/review-link/${code}`, { method: 'GET' })
    },
    createAfterConsultFeedbacks: function (token, data) {
      return ajax(`feedback/after-consult/${token}`, { method: 'POST', data })
    }
  },
  site: {
    getSiteBrand: function (domain: string, translate = true): Promise<SiteI['white_label_config']> {
      return ajax(`site/brand/${domain}?translate=${translate}`, { method: 'GET' })
    },
    getSites: function (data) {
      return ajax('site/get-sites', { method: 'POST', data }) as SiteI[]
    },
    getSite: function (id) {
      return ajax(`site/get-site/${id}`, { method: 'GET' })
    },
    updateSite: function (
      siteId: string,
      data: Partian<SiteI>
    ): Promise<SiteI> {
      return ajax(`site/${siteId}`, { method: 'PATCH', data })
    },
    updateSitePromotion: function (
      siteId: string,
      data: number[]
    ) {
      return ajax(`site/update-site-promotion/${siteId}`, { method: 'PATCH', data })
    },
    getPromoWidgets: function () {
      return ajax('site/get-promotion-widgets', { method: 'GET' })
    },
    getDoctors: function (siteId) {
      return ajax(`site/${siteId}/doctors`, { method: 'GET' })
    },
    assignRole: function (data) {
      return ajax('site/assign-role', { method: 'POST', data })
    },
    getSiteStats: function () {
      return ajax('site/get-site-stats', { method: 'GET' })
    },
    toggleReceiveNotification: function (data) {
      return ajax('site/toggle-receive-positive-notifications', {
        method: 'POST',
        data
      })
    },
    updateCustomFieldConfig: function ({ id, ...data }) {
      return ajax(`site/${id}/custom-field`, { method: 'PATCH', data })
    },
    getSiteFromCode: function (code): Promise<{ data: SiteWithRetailI }> {
      return ajax(`site/code/${code}`, { method: 'GET' })
    },
    getSiteAftercare: function (siteId: string) {
      return ajax(`site/${siteId}/aftercare`, { method: 'GET' })
    }
  },
  lab: {
    getAllPlates: function () {
      return ajax('lab/getAllPlates', {
        method: 'GET'
      })
    },
    uploadPlates: function (data) {
      return ajax('lab/uploadPlates', {
        method: 'POST',
        data: {
          plates: data
        }
      })
    },
    exportResultHash: function (plateId) {
      return ajax(`lab/export/${plateId}`, {
        method: 'GET'
      })
    },
    deletePlate: function (plateId) {
      return ajax(`lab/delete/${plateId}`, {
        method: 'GET'
      })
    },
    exportResult: function (hash) {
      return ajax(`lab/getFile/${hash}/csv`, {
        method: 'GET'
      })
    },
    getAllTrackID: function () {
      return ajax('lab/getalltrackId', {
        method: 'GET'
      })
    },
    reprocessResults: function (data) {
      return ajax('lab/reprocess-results', {
        method: 'POST',
        data
      })
    }
  },
  order: {
    getAllOrders: function (paginationDetails = {}) {
      const url = appendQueryString('order/order-approvals', paginationDetails)
      return ajax(url, {
        method: 'GET'
      })
    },
    getAllOrdersWhitelabel: function (paginationDetails = {}) {
      const url = appendQueryString('order/order-approvals-whitelabel', paginationDetails)
      return ajax(url, {
        method: 'GET'
      })
    },
    getTrackingDetailsByKitId: function (queryStringObject = { kitId: '' }) {
      const url = appendQueryString('order/get-tracking-details-by-kitId', queryStringObject)
      return ajax(url, {
        method: 'GET'
      })
    },
    getAllOrdersMetrics: function () {
      return ajax('order/order-approvals-metrics', {
        method: 'GET'
      })
    },
    getAllOrdersQuery: function (queryStringObject = {}) {
      const url = appendQueryString('order/order-approvals', queryStringObject)
      return ajax(url, { method: 'GET' })
    },
    getSpecificOrder: function (id) {
      return ajax(`order/order-detail/${id.id}/${id.sample_id}`, {
        method: 'GET'
      })
    },
    getRequester: function (id) {
      return ajax(`order/order-requester/${id}`, {
        method: 'GET'
      })
    },
    getOrderForSite: function (id) {
      return ajax(`order/provider-orders/${id}`, {
        method: 'GET'
      })
    },
    getOrdersForSites: function (data) {
      return ajax('order/provider-orders', {
        method: 'POST',
        data
      })
    },
    uploadOrder: function (data) {
      return ajax('order/upload-order', {
        method: 'POST',
        data
      })
    },
    sendToGBF: function (data) {
      return ajax('order/send-gbf', {
        method: 'POST',
        data
      })
    },
    approveOrders: function (data) {
      return ajax('order/approve-orders', {
        method: 'POST',
        data
      })
    },
    createOrders: function (data) {
      return ajax('order/create-orders', {
        method: 'POST',
        data
      })
    },
    insertRejection: function (data) {
      return ajax('order/insert-rejection', {
        method: 'POST',
        data
      })
    },
    getOrderById: function (id) {
      return ajax(`order/specify-order/${id}`, {
        method: 'GET'
      })
    },
    editOrder: function (data) {
      return ajax('order/edit-order', {
        method: 'PATCH',
        data
      })
    },
    cancelOrder: function (data) {
      return ajax('order/cancel-order', {
        method: 'PATCH',
        data
      })
    },
    cancelKitterOrder: function (data) {
      return ajax('order/cancel-kitter-order', {
        method: 'PATCH',
        data
      })
    },
    getSKUsForSite: function (id) {
      return ajax(`order/site-skus/${id}`, {
        method: 'GET'
      })
    },
    uploadKitOrders: function (data) {
      return ajax('order/upload-kit-orders', { method: 'POST', data })
    },
    getDistinctCohorts: function (): Promise<CohortI[]> {
      return ajax('order/get-distinct-cohorts', { method: 'GET' })
    },
    editAddress: function (data) {
      return ajax('order/edit-address', { method: 'PATCH', data })
    },
    getFulfillmentData: function (startDate, endDate) {
      return ajax('order/fulfillment-data', {
        method: 'GET',
        data: {
          startDate,
          endDate
        }
      })
    },
    getAllExternalIds: function () {
      return ajax('order/get-all-ext-ids', { method: 'GET' })
    },
    getOrdersData: (data: GetOrdersDataAPI): Promise<GetOrdersDataResponse> => {
      return ajax('order/orders-data', { method: 'POST', data })
    }
  },
  analytics: {
    getHealthFeedbackAnalytics: function (queryString = '') {
      return ajax('analytics/health-feedback-analytics' + queryString, { method: 'GET' }) as HealthFeedbackAnalytics
    },
    getAllAnalytics: function () {
      return ajax('analytics/all-analytics', { method: 'GET' })
    },
    getZipCodeData: function (data) {
      return ajax('analytics/get-zipcode-data', { method: 'POST', data })
    },
    getReportData: function (data): Promise<AnalyticsReportResponse> {
      return ajax('analytics/report', { method: 'POST', data })
    },
    getRegisterKitFlowSatisfaction: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/register-kit-flow-satisfaction',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getDownloadOrShareResultUtilization: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/download-share-result-utilization',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getTelehealthUtilization: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/telehealth-utilization',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getTelehealthConsultsCount: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/telehealth-consults-count',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getResultOpened: function (queryString = {}) {
      const url = appendQueryString('analytics/results-opened', queryString)
      return ajax(url, { method: 'GET' })
    },
    getPrescriptionPickedUpRate: function (queryString = {}) {
      const url = appendQueryString(
        'analytics/prescription-picked-up-rate',
        queryString
      )
      return ajax(url, { method: 'GET' })
    },
    getUtilizationAnalytics: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanUtilizationAnalyticsI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/utilization?${params || ''}`, { method: 'GET' })
    },
    getMemberInfo: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanMembersAnalyticsI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/member-info?${params || ''}`, { method: 'GET' })
    },
    getPatientInfo: function ({
      queryKey
    }: UseQueryOptions): Promise<PatientAnalyticsI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/patient-info?${params || ''}`, { method: 'GET' })
    },
    getTestAnalytics: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanTestsAnalyticsI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/test-analytics?${params || ''}`, { method: 'GET' })
    },
    getProgramInsights: function ({
      queryKey
    }: UseQueryOptions): Promise<ProgramInsightsI> {
      const params = queryKey[1] || {}

      const searchParams = new URLSearchParams(params)
      if (!searchParams || !searchParams.has('site')) return

      return ajax(`analytics/program-insights?${params || ''}`, {
        method: 'GET'
      })
    },
    getPopulationHealth: function ({
      queryKey
    }: UseQueryOptions): Promise<PopulationHealthI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/population-health?${params || ''}`, {
        method: 'GET'
      })
    },
    getOperations: function ({
      queryKey
    }: UseQueryOptions): Promise<OperationsAnalyticsI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/operations?${params || ''}`, { method: 'GET' })
    },
    downloadProgramInsights: function ({
      queryKey
    }: UseQueryOptions): Promise<ProgramInsightsDownloadI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/download-insights?${params || ''}`, {
        method: 'GET'
      })
    },
    downloadPopulationHealth: function ({
      queryKey
    }: UseQueryOptions): Promise<PopulationHealthDownloadI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/download-population-health?${params || ''}`, {
        method: 'GET'
      })
    },
    downloadHealthPlanAnalyticsRawData: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanAnalyticsRawData> {
      const params = queryKey[1] || {}

      return ajax(`analytics/download-health-program?${params || ''}`, {
        method: 'GET'
      })
    },
    grafanaLogin: function () {
      return ajax('analytics/grafana-login', {
        method: 'GET'
      })
    }
  },
  notification: {
    createNotification: function (data) {
      return ajax('notification/create', {
        method: 'POST',
        data
      })
    },
    createMultipleNotifications: function (data) {
      return ajax('notification/multiple/create', {
        method: 'POST',
        data
      })
    },
    updateNotification: function (data) {
      return ajax('notification/update', {
        method: 'PATCH',
        data
      })
    },
    getNotificationsByAccount: function (): Promise<{
      ok: boolean,
      notifications: AppNotificationI[]
    }> {
      return ajax('notification/account', {
        method: 'GET'
      })
    },
    getNotificationsByPatient: function (patientId: string): Promise<{ ok: boolean, notifications: AppNotificationI[] }> {
      return ajax(`notification/patient/${patientId}`, {
        method: 'GET'
      })
    },
    getNotificationsBySites: function (): Promise<{ ok: boolean, notifications: AppNotificationI[] }> {
      return ajax('notification/site', {
        method: 'GET'
      })
    },
    getNotification: function (notificationId: string): Promise<{ ok: boolean, notification: AppNotificationI }> {
      return ajax(`notification/${notificationId}`, {
        method: 'GET'
      })
    },
    getListOfSitesAndPatients: function () {
      return ajax('notification/list/all', {
        method: 'GET'
      })
    }
  },
  sku: {
    getSkusForSite: function (id): Promise<CustomSiteSku[]> {
      return ajax(`sku/site-skus/${id}`, {
        method: 'GET'
      })
    },
    getSkusForSiteDefault: function (id): Promise<Sku[]> {
      return ajax(`sku/site-skus-default/${id}`, {
        method: 'GET'
      })
    },
    getAllSkus: function (): Promise<Sku[]> {
      return ajax('sku/skus', {
        method: 'GET'
      })
    },
    addSku: function (data) {
      return ajax('sku/add-sku', {
        method: 'POST',
        data
      })
    },
    editSiteSku: function (data) {
      return ajax('sku/edit-sku', {
        method: 'PATCH',
        data
      })
    },
    deleteSiteSku: function (data) {
      return ajax('sku/delete-sku', {
        method: 'PATCH',
        data
      })
    },
    enableSku: function (data) {
      return ajax('sku/enable-sku', {
        method: 'PATCH',
        data
      })
    },
    createSku: function (data) {
      return ajax('sku/create-sku', {
        method: 'POST',
        data
      })
    },
    editSku: function (data) {
      return ajax('sku/sku-config/edit', {
        method: 'PATCH',
        data
      })
    },
    deleteSku: function (data) {
      return ajax('sku/sku-config/delete', {
        method: 'POST',
        data
      })
    },
    editRequestSku: function (data) {
      return ajax('sku/edit-request-sku', {
        method: 'PATCH',
        data
      })
    }
  },
  inventory: {
    getSitesInventory: function () {
      return ajax('inventory/sites-inventory', {
        method: 'GET'
      })
    },
    getSitesInventoryOpt: function () {
      return ajax('inventory/sites-inventory-opt', {
        method: 'GET'
      })
    },
    getSpecificSiteData: function (name) {
      return ajax(`inventory/${name}`, {
        method: 'GET'
      })
    },
    getFulfilledKits: function () {
      return ajax('inventory/kits-fulfilled', {
        method: 'GET'
      })
    },
    getActiveKits: function () {
      return ajax('inventory/active-kits', {
        method: 'GET'
      })
    }
  },
  analyteConfig: {
    getAll: function () {
      return ajax('analyte-config', { method: 'GET' }) as Promise<AnalyteConfigI[]>
    },
    getById: function (id, translate = true): Promise<AnalyteConfigI> {
      return ajax(`analyte-config/${id}?translate=${translate}`, { method: 'GET' })
    },
    create: function (analyteConfig) {
      return ajax('analyte-config', { method: 'POST', data: analyteConfig })
    },
    updateById: function (id, analyteConfig) {
      return ajax(`analyte-config/${id}`, {
        method: 'PATCH',
        data: analyteConfig
      })
    },
    deleteById: function (id) {
      return ajax(`analyte-config/${id}`, { method: 'DELETE' })
    }
  },
  event: {
    create: function (data) {
      return ajax('event', { method: 'POST', data })
    }
  },
  registeredPrepProgram: {
    register: function (data: { formData: TPrepProgram; siteId: number }) {
      return ajax('registered-prep-program', {
        method: 'POST',
        data
      }) as Promise<{
        success: boolean
        registeredPrepProgram: TRegisteredPrepProgram
      }>
    },
    getById: function (id: number) {
      return ajax(`registered-prep-program/${id}`, {
        method: 'GET'
      }) as Promise<TRegisteredPrepProgram>
    },
    getAll: function () {
      return ajax('registered-prep-program', {
        method: 'GET'
      }) as Promise<TRegisteredPrepProgram>
    }
  },
  mwlProgram: {
    createConsult: function (data) {
      return ajax('simple-weight-plan/create', {
        method: 'POST',
        data
      })
    },
    setIneligibleConsult: function (slug) {
      return ajax(`simple-weight-plan/set-consult-ineligible/${slug}`, {
        method: 'PATCH'
      })
    },
    updateConsult: function (id, data) {
      return ajax(`simple-weight-plan/update-consult/${id}`, {
        method: 'PATCH',
        data
      })
    },
    updateCreateEligibility: function (data) {
      return ajax('simple-weight-plan/update-eligibility', {
        method: 'PATCH',
        data
      })
    },
    updateConsultPaymentStatus: function (id, data) {
      return ajax(`simple-weight-plan/update-consult-payment-completed/${id}`, {
        method: 'PATCH',
        data
      })
    },
    updateMoreInfo: function (id, data) {
      return ajax(`simple-weight-plan/update-more-info/${id}`, {
        method: 'PATCH',
        data
      })
    },
    updateFinalizeConsult: function (id) {
      return ajax(`simple-weight-plan/update-finalize-consult/${id}`, {
        method: 'PATCH'
      })
    },
    pauseSubscription: function (id, data) {
      return ajax(`simple-weight-plan/pause-subscription/${id}`, {
        method: 'PATCH',
        data
      })
    },
    resumeSubscription: function (id, resume_option: 'immediately' | 'specific_date', resume_date: number | null, cycle_passed: boolean = false) {
      return ajax(`simple-weight-plan/resume-subscription/${id}`, {
        method: 'PATCH',
        data: { resume_date, resume_option, cycle_passed }
      })
    },
    getPortalToken: function (id) {
      return ajax(`simple-weight-plan/get-portal-token/${id}`, {
        method: 'GET'
      })
    },
    getConsultById: function (id: string) {
      return ajax(`simple-weight-plan/get-by-id/${id}`, { method: 'GET' }) as Promise<{
        success: boolean
        data: any,
        content: any,
        slug: string
      }>
    },
    getSubscriptionsByUser: function () {
      return ajax('simple-weight-plan/get-subscription-by-user', { method: 'GET' }) as Promise<{
        success: boolean
        data: any,
      }>
    },
    getConsultNotesByType: function (refill_id: string, type: string) {
      return ajax(`simple-weight-plan/get-notes-by-type/${refill_id}/${type}`, { method: 'PATCH' }) as Promise<{
        success: boolean
        data: any
      }>
    },
    getConsultNoteDatesByType: function (id: string, type: string) {
      return ajax(`simple-weight-plan/get-note-dates-by-type/${id}/${type}`, { method: 'GET' }) as Promise<{
        success: boolean
        data: any
      }>
    },
    getAllNotesById: function (id: string) {
      return ajax(`simple-weight-plan/get-all-notes-by-id/${id}`, { method: 'GET' }) as Promise<{
        success: boolean
        data: any
      }>
    },
    getConsults: function () {
      return ajax('simple-weight-plan/get-all', { method: 'GET' }) as Promise<{
        success: boolean
        data: any
      }>
    }
  },
  program: {
    getPrepProgramConfig: function (slug: string) {
      return ajax(`program/prep/${slug}/config`, { method: 'GET' }) as Promise<{
        success: boolean
        site: TPrepProgramConfigSite
      }>
    },
    getPrepProgramPatients: function () {
      return ajax('program/prep/patients', { method: 'GET' }) as Promise<{
        success: boolean
        patients: PatientI[]
      }>
    },
    getProgramConfig: function (slug): Promise<ProgramConfigWithHeaderI> {
      return ajax(`program/${slug}`, { method: 'GET' })
    },
    getProgramByType: function (siteId, type): Promise<ProgramWithHeaderI> {
      return ajax(`program/${type}/${siteId}`, { method: 'GET' })
    },
    getProgramType: function (slug) {
      return ajax(`program/get-type/${slug}`, { method: 'GET' })
    },
    checkSKUEligibilityFromDob: function (slug, data) {
      return ajax(`program/${slug}/check-eligibility-from-dob`, {
        method: 'POST',
        data
      })
    },
    updateProgram: function (
      siteId: string,
      type: string,
      data: Partian<ProgramI>
    ): Promise<ProgramI> {
      return ajax(`program/${type}/${siteId}`, { method: 'PATCH', data })
    },
    confirmFreeOrder: function (data) {
      return ajax('program/confirm-free-order', { method: 'POST', data })
    },
    checkPatientEligibility: function (slug, data) {
      return ajax(`program/${slug}/check-eligibility`, { method: 'POST', data })
    },
    addNewNotifyee: function (data) {
      return ajax('program/add-new-notifyee', { method: 'POST', data })
    },
    checkProgramPauseStatus: function (slug) {
      return ajax(`program/${slug}/check-pause-status`, { method: 'GET' })
    }
  },
  healthPlan: {
    updateMember: function (id, data: MemberDetailsType) {
      return ajax(`health-plan/update-member/${id}`, { method: 'PATCH', data })
    },
    uploadMemberCSV: function (data: MemberUploadPayload) {
      return ajax('health-plan/upload', { method: 'POST', data })
    },
    downloadKitFulfillmentCSV: function (data: downloadFileRequestData) {
      return ajax('health-plan/kit-fulfillment/download', {
        method: 'POST',
        data
      }) as Promise<kitFulfillmentJSONData>
    },
    downloadMemberEngagementCSV: function (data: downloadFileRequestData) {
      return ajax('health-plan/member-engagement/download', {
        method: 'POST',
        data
      }) as Promise<kitFulfillmentJSONData>
    },
    getMembers: function ({ site_id, query = {} }: any) {
      let url
      if (site_id) {
        url = appendQueryString(`health-plan/members/${site_id}`, query)
      } else {
        url = appendQueryString('health-plan/members', query)
      }
      return ajax(url, { method: 'GET' }) as Promise<GetMembersResponse>
    },
    memberJourney: function (id) {
      return ajax(`health-plan/member-journey/${id}`, {
        method: 'GET'
      })
    },
    getMostRecentUpload: function () {
      return ajax('health-plan/member-uploads', { method: 'GET' })
    },
    undoLatestUploadOfASite: function (upload_id) {
      return ajax(`health-plan/upload/${upload_id}`, { method: 'DELETE' })
    },
    reportOfChanges: function () {
      return ajax('health-plan/report/changes', { method: 'GET' })
    },
    verifyMember: function (memberData: { memberCode: string; dob: string }) {
      return ajax(appendQueryString('health-plan/members/verify', memberData), {
        method: 'GET'
      })
    },
    assignAccount: function (auth0Id, key) {
      return ajax('health-plan/assign-account', {
        method: 'PATCH',
        data: { auth0Id, key }
      })
    },
    getBasicInfo: function (siteId) {
      return ajax(`health-plan/${siteId}/basic-info`, { method: 'GET' })
    },
    validateMagicLink: function ({ siteId, memberCode }) {
      return ajax(
        `health-plan/${siteId}/validate-magic-link?memberCode=${memberCode}`,
        { method: 'GET' }
      ) as Promise<{
        success: boolean
        healthPlanMember: { data: { Member_Language: string } }
      }>
    },
    getHedisData: function (data: { sites: string }) {
      return ajax('health-plan/hedis', {
        method: 'POST',
        data
      }) as Promise<HedisFileJSONData>
    },
    getHealthPlanOverviewData: function ({
      queryKey
    }: UseQueryOptions): Promise<HealthPlanOverviewAnalyticsI> {
      const params = queryKey[1] || {}
      return ajax(`analytics/overview?${params || ''}`, {
        method: 'GET'
      })
    },
    replaceHealthPlanKit: function (data) {
      return ajax('kit/kit-replace', { method: 'POST', data })
    },
    verifyHpUser: function (data) {
      return ajax('health-plan/check-hp-user', { method: 'POST', data })
    },
    confirmHpOrder: function (data) {
      return ajax('health-plan/confirm-order', { method: 'POST', data })
    },
    getAllEligibleSkusPerUser: function (memberId): Promise<{
      status: string,
      error: string,
      data: SkuResponse[],
      incentiveMapper: Record<string, number>
    }> {
      return ajax(`health-plan/get-all-available-skus/${memberId}`, {
        method: 'GET'
      })
    }
  },
  i18nextContent: {
    getAll: function (): Promise<TranslationI[]> {
      return ajax('i18next-content', { method: 'GET' })
    },
    create: function (data: Pick<TranslationI, 'identifier' | 'spanish' | 'english'>): Promise<TranslationI> {
      return ajax('i18next-content', { method: 'POST', data })
    },
    update: function (data: TranslationI): Promise<TranslationI> {
      return ajax(`i18next-content/${data.id}`, { method: 'PATCH', data })
    },
    delete: function (id: number): Promise<{ success: boolean }> {
      return ajax(`i18next-content/${id}`, { method: 'DELETE' })
    },
    getTranslation: function (idOrKey: string | number): Promise<TranslationI> {
      // The endpoint works with the id or the identifier (key) of the translation
      return ajax(`i18next-content/${idOrKey}`, { method: 'GET' })
    },
    getMultipleTranslations: function (data: { identifiers: string[] }): Promise<TranslationI[]> {
      return ajax('i18next-content/multiple', { method: 'POST', data })
    }
  },
  results: {
    uploadKitResults: function (data) {
      return ajax('results/upload', { method: 'POST', data })
    },
    getValidOptionsForFile: function (): Promise<ValidOptionsForResultFile> {
      return ajax('results/options', { method: 'GET' })
    }
  },
  healthPlanNotificationSettings: {
    getAll: function (queryString: string) {
      return ajax(`health-plan-notification-settings${queryString}`, { method: 'GET' }) as THealthPlanNotificationSettings[]
    },
    bulkUpdate: function (data: Partial<THealthPlanNotificationSettings>[]) {
      return ajax('health-plan-notification-settings', { method: 'PATCH', data })
    }
  },
  schedules: {
    getAll: function (query = {}) {
      const url = appendQueryString('schedules/get-all', query)
      return ajax(url, { method: 'GET' }) as ISchedules
    }
  },
  mailerSettings: {
    getMailerSettings: function (id: number): Promise<MailerSettingsI> {
      return ajax(`mailer/${id}`, { method: 'GET' })
    },
    getAllMailerSettings: function (): Promise<MailerSettingsI[]> {
      return ajax('mailer', { method: 'GET' })
    },
    createMailerSettings: function (data: Omit<MailerSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id', 'last_updated_by'>): Promise<MailerSettingsI> {
      return ajax('mailer', { method: 'POST', data })
    },
    updateMailerSettings: function (id: number, data: Omit<MailerSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id', 'last_updated_by'>): Promise<{ ok: boolean }> {
      return ajax(`mailer/${id}`, { method: 'PATCH', data })
    },
    deleteMailerSettings: function (id: number): Promise<{ ok: boolean }> {
      return ajax(`mailer/${id}`, { method: 'DELETE' })
    }
  },
  mailAndFax: {
    getMailsOnHold: function (): Promise<MailOnHoldI[]> {
      return ajax('mail-fax/mails-on-hold', { method: 'GET' })
    }
  },
  ssoSettings: {
    getSsoSettings: function (id: number): Promise<SsoSettingsI> {
      return ajax(`sso/${id}`, { method: 'GET' })
    },
    getAllSsoSettings: function (): Promise<SsoSettingsI[]> {
      return ajax('sso', { method: 'GET' })
    },
    createSsoSettings: function (data: Omit<SsoSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id', 'last_updated_by'>): Promise<SsoSettingsI> {
      return ajax('sso', { method: 'POST', data })
    },
    updateSsoSettings: function (id: number, data: Omit<SsoSettingsI, 'created_at' | 'updated_at' | 'deleted_at' | 'id', 'last_updated_by'>): Promise<{ ok: boolean }> {
      return ajax(`sso/${id}`, { method: 'PATCH', data })
    },
    deleteSsoSettings: function (id: number): Promise<{ ok: boolean }> {
      return ajax(`sso/${id}`, { method: 'DELETE' })
    }
  }
}

// Interfaces of the responses from the API

interface ValidOptionsForResultFile {
  analyteNames: string[]
  sampleInstruments: string[]
  samplePanel: string[]
  sampleTests: string[]
  sampleTypes: string[]
}
interface GetMembersResponse {
  ok: boolean
  message: string
  members: HealthPlanMemberI[]
  pagination: {
    count: number
    limit: number
    page: number
    total: number
  }
}

interface ResponseGetResultsDetails {
  token: string
  data: {
    testId: string
    data: ResultFromLimsI[]
  }[]
}

interface UpdateProfileData {
  notificationEmail?: string
  phone?: string
  language?: string
}

interface KitCheckResponse extends Partial<Kit> {
  kitTypeConfig?: KitTypeConfigI
  site?: SiteI
}

interface ConsultsResponse {
  consults: ConsultI[]
  count: number
}

export interface SiteWithRetailI extends SiteI {
  retail: boolean
}

export type PartialKitWithSample = Partial<Kit> & { samples: Pick<SampleI, 'result'>[] }

export interface ProgramWithHeaderI extends ProgramI {
  headers?: string
}

export interface ProgramConfigWithHeaderI extends ProgramConfig {
  headers?: string
  name?: string
  consent?: string
  address?: string
  allowedSiteSkus?: Array<{
    skuID: string
    skuName: string
    kitTypeConfig: Partial<KitTypeConfigI>
  }>
}

interface DownloadResultResp {
  lisEndPoint: string
  data: {
    patient: Partial<PatientI>
    site: Partial<SiteI>
    kit: Partial<Kit>
    samples: Partial<SampleI>[]
    kitTypeConfig: Partial<KitTypeConfigI>
    analytes: Partial<AnalyteConfigI>[]
  } | null
  buffer: string | null
}

export interface GetOrdersDataAPI {
  startDate: Date
  endDate: Date
  siteIds: number[]
  status: string[]
}

interface GetOrdersDataResponse {
  success: boolean
  data: any[]
}

export interface CustomSiteSku extends Pick<Sku, 'name' | 'setting' | 'enabled' | 'sku_id'> {
  site_id: number
  custom_price: string
  kitTypeConfig: Pick<KitTypeConfigI, 'id' | 'name' | 'allowedSexAssignedAtBirth'>
  analyteConfigs: Pick<AnalyteConfigI, 'id' | 'display_name' | 'reference_range' | 'result_states' | 'type'>[]
}

export default api
