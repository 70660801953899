import {
  TextField,
  Autocomplete,
  Stack,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  createFilterOptions,
  Box
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { api } from 'Core'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { InsuranceCompanyI, insuranceNames } from 'components/PatientComponents/data/insuranceNames'
import { insuranceValidationSchema, insuranceRelationshipOptions } from 'utils/validationSchemas'
import { PatientI } from '../../types/db/PatientI'
import { useFormik } from 'formik'
import Address from 'components/PatientComponents/MedicalData/Address'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gender } from 'components/PatientComponents/data/dropDownOptions'
import { getCurrentLanguage } from 'i18n'

const filterOptions = createFilterOptions<string>({
  matchFrom: 'start',
  stringify: (option) => option
})

const insuranceFilterOptions = createFilterOptions<InsuranceCompanyI>({
  matchFrom: 'start',
  stringify: option => option.label
})

const validationSchema = insuranceValidationSchema

const relations = insuranceRelationshipOptions

function InsuranceForm ({
  patient,
  submitHandler: SubmitHandler,
  onSubmit,
  siteId
}: InsuranceFormProps) {
  const [insurance, setInsurance] = useState(patient?.insurance)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setInsurance(patient?.insurance)
  }, [patient])

  const genderOptions = gender.map(data => data.name)
  const formik = useFormik<InsuranceFormData>({
    initialValues: {
      insuranceName: patient?.insurance?.insurance_name === 'UNINSURED' ? '' : (patient?.insurance?.insurance_name || null),
      policyNumber: patient?.insurance?.policy_number === 'UNINSURED' ? '' : (patient?.insurance?.policy_number || ''),
      groupNumber: patient?.insurance?.group_number || '',
      relationship: patient?.insurance?.relationship || null,
      insuredFirstName: patient?.insurance?.insured_first_name || '',
      insuredLastName: patient?.insurance?.insured_last_name || '',
      insuredDob: moment(patient?.insurance?.insured_dob, ['YYYY-MM-DD', 'MM-DD-YYYY']) || '',
      insuredGender: patient?.insurance?.insured_gender || null,
      warningCheckbox: false,
      primaryInsuredId: '',
      insuredAddress: {
        addressLine1: patient?.insurance?.insured_address?.delivery_line_1 || '',
        city: patient?.insurance?.insured_address?.city_name || '',
        state: patient?.insurance?.insured_address?.state_abbreviation || '',
        zipCode: patient?.insurance?.insured_address?.zipcode || '',
        plus4Code: patient?.insurance?.insured_address?.plus4_code || '',
        county: patient?.insurance?.insured_address?.county || '',
        country: 'US'
      }
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async formData => {
      try {
        const allPatients = await api.patient.getAll()
        const currentPatient = allPatients.find((p) => (
          p.first_name === patient.first_name &&
          p.last_name === patient.last_name &&
          p.dob === patient.dob
        ))
        const patientFound = currentPatient?.idMapper.find((p) => +p.site_id === siteId)

        await api.patient.updateInsurance({
          data: {
            ...formData,
            ...formData.insuredAddress,
            insuredDob: moment(formData.insuredDob).format('MM-DD-YYYY'),
            patientIds: currentPatient?.idMapper.map((mapper) => mapper.patient_id)
          }
        })

        const updatedPatient = await api.patient.getPatient({ id: patientFound?.patient_id || currentPatient?.id })

        if (updatedPatient?.patient && updatedPatient?.patient?.idMapper && currentPatient?.idMapper) {
          updatedPatient.patient.idMapper = currentPatient?.idMapper
        }

        onSubmit && onSubmit(updatedPatient)
      } catch (error) {
        console.error(error)
      }
    }
  })

  useEffect(() => {
    if (insuranceNames.length && insurance) {
      const insuranceData = insuranceNames.find((ins) => ins.label === insurance.insurance_name)
      formik.setFieldValue('primaryInsuredId', insuranceData?.id || '')
    }
  }, [insuranceNames])

  const relation = relations.find(item => (t(item, { lng: getCurrentLanguage(i18n) }) === formik.values.relationship || t(item, { lng: 'en-US' }) === formik.values.relationship))
  const genderValue = genderOptions.find(item => (t(item, { lng: getCurrentLanguage(i18n) }) === formik.values.insuredGender || t(item, { lng: 'en-US' }) === formik.values.insuredGender))

  return (
    <Stack
      component='form'
      sx={ { gap: 2 } }
      autoComplete='off'
      onSubmit={ formik.handleSubmit }
    >
      <Stack
        sx={ {
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          '& > *': {
            flex: '1 1 300px'
          }
        } }
      >
        <Autocomplete
          options={ insuranceNames }
          // @ts-ignore
          value={formik.values.insuranceName}
          isOptionEqualToValue={ (option, value) => option.label === value.label }
          filterOptions={ insuranceFilterOptions }
          onChange={ (_evt, insuranceName) => {
            formik.setFieldValue('insuranceName', insuranceName?.label)
            formik.setFieldValue('primaryInsuredId', insuranceName?.id)
            formik.setFieldValue('billTypeId', insuranceName?.bill_type_id)
          } }
          renderOption={ (props, data) => (
            <Box component='li' { ...props } key={ data?.id }>
              { data?.label }
            </Box>
          ) }
          renderInput={ props => (
            <TextField
              label={t('registerKit.insuranceForm.label.primaryInsurance')}
              error={ !!formik.errors.insuranceName }
              helperText={ <>{ t(formik.errors.insuranceName) }</> }
              { ...props }
            />
          ) }
        />
        <TextField
          name='policyNumber'
          value={ formik.values.policyNumber }
          onChange={ formik.handleChange }
          label={t('registerKit.insuranceForm.label.policyNumber')}
          error={ !!formik.errors.policyNumber }
          // @ts-ignore
          helperText={ t(formik.errors.policyNumber) }
        />
        <TextField
          label={t('registerKit.insuranceForm.label.groupNumber')}
          name='groupNumber'
          value={ formik.values.groupNumber }
          onChange={ formik.handleChange }
          error={ !!formik.errors.groupNumber }
          helperText={ <>{ t(formik.errors.groupNumber) }</> }
        />
        <Autocomplete
          options={ relations }
          filterOptions={ filterOptions }
          getOptionLabel={option => t(option)}
          value={t(relation)}
          onChange={ (_evt, relation) => {
            if (t(relation) === t('relation.self')) {
              formik.setValues({
                ...formik.values,
                insuredFirstName: patient.first_name,
                insuredLastName: patient.last_name,
                insuredDob: moment(patient.dob, 'YYYY-MM-DD'),
                insuredGender: patient.gender,
                insuredAddress: {
                  addressLine1: patient.address.delivery_line_1,
                  city: patient.address.city_name,
                  state: patient.address.state_abbreviation,
                  zipCode: patient.address.zipcode,
                  plus4Code: patient.address.plus4_code,
                  county: patient.address.county,
                  country: 'US'
                }
              })
            }
            formik.setFieldValue('relationship', t(relation))
          } }
          renderInput={ params => (
            <TextField
              label={t('registerKit.insuranceForm.label.patientRelationship')}
              error={ !!formik.errors.relationship }
              helperText={ <>{ t(formik.errors.relationship) }</> }
              { ...params }
            />
          ) }
        />
        <TextField
          label={t('registerKit.insuranceForm.label.insuredFirstName')}
          disabled={ formik.values.relationship === t('relation.self') && !formik?.errors?.insuredFirstName }
          name='insuredFirstName'
          value={ formik.values.insuredFirstName }
          onChange={ formik.handleChange }
          error={ !!formik.errors.insuredFirstName }
          helperText={ <>{ t(formik.errors.insuredFirstName) }</> }
        />
        <TextField
          label={t('registerKit.insuranceForm.label.insuredLastName')}
          name='insuredLastName'
          disabled={ formik.values.relationship === t('relation.self') && !formik?.errors?.insuredLastName }
          value={ formik.values.insuredLastName }
          onChange={ formik.handleChange }
          error={ !!formik.errors.insuredLastName }
          helperText={ <>{ t(formik.errors.insuredLastName) }</> }
        />
        <LocalizationProvider dateAdapter={ AdapterMoment }>
          <DatePicker
            disabled={ formik.values.relationship === t('relation.self') && !formik?.errors?.insuredDob }
            label={t('registerKit.insuranceForm.label.insuredDob')}
            value={ formik.values.insuredDob ? moment(formik.values.insuredDob) : null }
            onChange={ newValue => {
              formik.setFieldValue('insuredDob', moment(newValue).format('MM/DD/YYYY'))
            } }
            slotProps={ {
              textField: {
                error: !!formik.errors.insuredDob,
                helperText: <>{ t(formik.errors.insuredDob) }</>
              }
            } }
          />
        </LocalizationProvider>
        <Autocomplete
          filterOptions={ filterOptions }
          options={genderOptions}
          getOptionLabel={option => t(option)}
          disabled={ formik.values.relationship === t('relation.self') && !formik?.errors?.insuredGender }
          value={ t(genderValue) }
          onChange={ (_evt, gender) => {
            formik.setFieldValue('insuredGender', t(gender))
          } }
          renderInput={ params => (
            <TextField
              label={t('registerKit.insuranceForm.label.insuredGender')}
              error={ !!formik.errors.insuredGender }
              helperText={ <>{ t(formik.errors.insuredGender) }</> }
              { ...params }
            />
          ) }
        />
      </Stack>
      <Address
        formik={ formik }
        name='insuredAddress'
        disabled={ formik.values.relationship === t('relation.self') && !formik?.errors?.insuredAddress }
        copyAddress
      />
      <FormControl error={ Boolean(formik.errors.warningCheckbox) }>
        <FormControlLabel
          control={ <Checkbox /> }
          name='warningCheckbox'
          checked={ formik.values.warningCheckbox }
          onChange={ formik.handleChange }
          label={t('registerKit.insuranceForm.warningCheckbox')}
        />
        { formik.errors.warningCheckbox ? <FormHelperText>{ `${t(formik.errors.warningCheckbox)}` }</FormHelperText> : null }
      </FormControl>
      <SubmitHandler formik={ formik } />
    </Stack>
  )
}

// interfaces and types
export interface InsuranceFormData {
  insuranceName: string | null
  policyNumber: string
  groupNumber: string
  relationship: string | null
  insuredFirstName: string
  insuredLastName: string
  insuredDob: string | Moment
  insuredGender: string | null
  warningCheckbox: boolean
  primaryInsuredId: string
  insuredAddress: {
    addressLine1: string
    city: string
    state: string
    zipCode: string
    plus4Code: string
    county: string
    country: string
  }
}

interface InsuranceFormProps {
  siteId: number
  patient: PatientI
  siteCode: string
  submitHandler: ({ formik }: { formik: ReturnType<typeof useFormik<InsuranceFormData>> }) => JSX.Element
  onSubmit: (response: any) => void
}

export default InsuranceForm
