import React from 'react'
import { Alert, Button, Paper, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { t } from 'i18next'
import EditIcon from '@mui/icons-material/Edit'

interface PatientCardProps {
    patient: any,
    setShowEditPatientForm: React.Dispatch<React.SetStateAction<boolean>>
    setCurrentEditPatient: React.Dispatch<React.SetStateAction<any>>
    setCurrentPatient: React.Dispatch<React.SetStateAction<any>>
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>
    formik: any
    data: any
}

const PatientCard: React.FC<PatientCardProps> = ({ patient, setShowEditPatientForm, setCurrentEditPatient, formik, data }) => {
  const handleEditPatient = () => {
    setCurrentEditPatient(patient)
    setShowEditPatientForm(true)
    formik.setValues({
      ...formik.values,
      patient,
      shouldDuplicatePatient: 'no'
    })
  }

  return (
    <Stack>
        <Paper elevation={0} sx={{ backgroundColor: 'grey.100', padding: 2 }}>
            <Stack>
                <PatientDetail
                    title={t('registerPatient.medicalDetails.firstName')}
                    value={patient.first_name}
                />

                <PatientDetail
                    title={t('registerPatient.medicalDetails.lastName')}
                    value={patient.last_name}
                />

                <PatientDetail
                    title={t('registerKit.review.patientInformationDateOfBirth')}
                    value={moment(patient.dob).format('MM/DD/YYYY')}
                />

                <PatientDetail
                    title={t('registerPatient.medicalDetails.phone')}
                    value={patient.phone}
                />

                <PatientDetail
                    title={t('registerPatient.medicalDetails.email')}
                    value={patient.email}
                />
          {data.site
            ? <Stack>
            <Button
              onClick={ handleEditPatient }
              variant='text'
              startIcon={<EditIcon />}
              sx={{ textDecoration: 'underline', alignSelf: 'flex-start', pl: 0 }}
            >
              {t('profile.editPatient.title')}
            </Button>
          </Stack>
            : null}
            </Stack>
        </Paper>
        {
      !['male', 'female'].includes(patient?.sex_assigned_at_birth?.toLowerCase() || '')
        ? (<Alert
          severity='error'
          action={
            <Button
              onClick={ handleEditPatient }
              color='inherit'
              size='small'
              sx={ { textDecoration: 'underline' } }
            >
              { t('profile.editPatient.title') }
            </Button>
          }
          sx={ {
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            alignItems: 'center'
          } }
        >
          { t('registerPatient.medicalDetails.missingInformation') }
        </Alert>)
        : null
    }
        </Stack>
  )
}

const PatientDetail = ({ title, value }: { title: string, value: string }) => {
  return (
        <Stack direction='row' gap={1}>
            <Typography variant='body1' fontWeight='bold'>
                {title}:
            </Typography>

            <Typography variant='body1'>
                {value}
            </Typography>
        </Stack>
  )
}

export default PatientCard
