import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import MfaSetup from "./MfaSetup";
import { Container, ProtectedRoute } from "components";
import { Stack, useMediaQuery } from "@mui/material";
import theme from 'theme'
import MfaConfirm from "./MfaConfirm";
import jwtUtil from "Core/utils/jwtUtil";
import useAuth0 from "hooks/useAuth0";
import MfaHeader from "./MfaHeader";
import Footer from "components/Footer";

function MfaConfig () {
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useAuth0();
  const { state } = useLocation();
  const [redirectUrl, setRedirectUrl] = useState<string|null>(null);
  const userConfig = jwtUtil.getMfaData();
  const redirectCondition = Boolean(!userConfig.mfaConfig?.enabled || state?.action === 'update');

  const getRedirectUrl = () => {
    if(!redirectCondition){
      return '/'
    }
    const redirectPath = redirectUrl || window.sessionStorage.getItem('mfa-redirect') || '/';
    if(window.sessionStorage.getItem('mfa-redirect'))
      window.sessionStorage.removeItem('mfa-redirect')
    if(!redirectUrl)
      setRedirectUrl(redirectPath);
  
    return redirectPath
  }

  return(
    <>
      <MfaHeader/>
      <Container sx={{ paddingTop: '300px' }}>
        <Stack sx={{ gap: 3 }}>
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 5,
              justifyContent: mobile ? 'center' : 'flex-start',
              alignItems: 'flex-start'
            }}
            >
            <Stack
              sx={{
                width: '100%',
                maxWidth: 550,
                m: 'auto'
              }}
              
              >
                <Routes>
                  <Route element={<ProtectedRoute condition={Boolean(user)} redirectTo={'/'} />}>
                    <Route element={<ProtectedRoute condition={redirectCondition} redirectTo={getRedirectUrl()} />}>
                      <Route path='/setup' element={<MfaSetup />} />
                    </Route>
                    <Route path='/confirm/:token' element={<MfaConfirm />} />
                    <Route path="*" element={<Navigate to="../setup" replace={true} />} />
                  </Route>
                </Routes>
            </Stack>
          </Stack>
        </Stack>
        
      </Container>
      <Footer/>
    </>
  )
}

export default MfaConfig;