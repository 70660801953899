import { Box, Typography } from '@mui/material'
import React from 'react'

export const TextDataRow = ({ title, value }: Props) => {
  if (!value || value === '') return null

  return (
    <Box sx={{ display: 'inline' }}>
      <Typography component='span' fontWeight='bold' display='inline'>{title}&nbsp;</Typography>
      <Typography display='inline'>{value}</Typography>
    </Box>
  )
}

interface Props {
  title: string
  value: string
}
