import { Button, Link, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import DOMPurify from 'dompurify'
import { useWhiteLabelConfig } from '../../utils/white-label/WhiteLabelConfig'

const FeedbackCompleted = () => {
  const whitelabelConfig = useWhiteLabelConfig()

  return (
    <Stack gap={3}>
      <Typography variant="h4" component="h1" sx={{ textAlign: 'center' }}>
        {t('freeTestRemainder.feedback.title')}
      </Typography>
      <Stack
        component="img"
        src={'/assets/hifi_hands.svg'}
        alt={'2 doctors'}
        sx={{ m: 'auto' }}
      />
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t('freeTestRemainder.feedback.body'), {
            ADD_ATTR: ['target']
          })
        }}
      ></Typography>
      <Link href={`mailto:${whitelabelConfig?.emailForSupport || process.env.SUPPORT_EMAIL}`}>
        <Button variant="contained" fullWidth>
          {t('freeTestRemainder.feedback.emailBtn', {
            email: whitelabelConfig?.emailForSupport || process.env.SUPPORT_EMAIL
          })}
        </Button>
      </Link>
      <Link href="tel:+1(833)474-7745">
        <Button variant="outlined" fullWidth>
          {t('freeTestRemainder.feedback.callBtn')}
        </Button>
      </Link>
    </Stack>
  )
}

export default FeedbackCompleted
