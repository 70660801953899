import { createTheme, Palette, Theme, ThemeOptions } from '@mui/material/styles'
import { WhiteLabelTheme } from './types/db/SiteI'
import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/system' {
  interface DefaultTheme extends Theme {}
}

export const themeColors: Pick<ThemeOptions, 'palette'> = {
  palette: {
    mode: 'light',
    tonalOffset: 0.1,
    contrastThreshold: 3,
    primary: {
      main: '#333333',
      contrastText: '#FFFFFF'
    },
    background: {
      default: '#F7F7F7'
    },
    info: {
      main: '#395DB5'
    },
    error: {
      main: '#C62828'
    },
    success: {
      main: '#008453'
    },
    warning: {
      main: '#FF8F31'
    }
  }
}
const fontFamily = 'DM Sans'
export const themeTypographies: Pick<ThemeOptions, 'typography'> = {
  typography: {
    fontFamily,
    htmlFontSize: 16,
    h1: {
      fontFamily,
      fontWeight: 700,
      fontSize: 96,
      textTransform: 'capitalize'
    },
    h2: {
      fontFamily,
      fontWeight: 700,
      fontSize: 60,
      textTransform: 'capitalize'
    },
    h3: {
      fontFamily,
      fontWeight: 700,
      fontSize: 48,
      textTransform: 'capitalize'
    },
    h4: {
      fontFamily,
      fontSize: 34,
      fontWeight: 'bold',
      textTransform: 'capitalize'
    },
    h5: {
      fontFamily,
      fontWeight: 'bold',
      fontSize: 24,
      textTransform: 'capitalize'
    },
    h6: {
      fontFamily,
      fontWeight: 700,
      fontSize: 20,
      textTransform: 'capitalize'
    },
    subtitle1: {
      fontFamily,
      fontWeight: 400,
      fontSize: 16
    },
    subtitle2: {
      fontFamily,
      fontWeight: 500,
      fontSize: 14
    },
    body1: {
      fontFamily,
      fontWeight: 400,
      fontSize: 16
    },
    body2: {
      fontFamily,
      fontWeight: 400,
      fontSize: 14
    },
    button: {
      fontFamily,
      fontWeight: 500,
      fontSize: 15
    },
    caption: {
      fontFamily,
      fontWeight: 400,
      fontSize: 12
    },
    overline: {
      fontFamily,
      fontWeight: 400,
      fontSize: 12,
      textTransform: 'uppercase'
    }
  }
}

export const createCustomTheme = (
  themeOptions: CustomThemeOptions,
  customComponents?: WhiteLabelTheme['customComponents']): Theme => {
  return createTheme(themeOptions, {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: `${customComponents?.btn?.borderRadius || 32}px`,
            boxShadow: 'none',
            padding: '0.5rem 1.5rem',
            textTransform: 'none',
            width: 'max-content',
            '&, &:hover': {
              boxShadow: 'none'
            }
          },
          fullWidth: {
            width: '100%'
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none'
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: themeOptions.palette.success.main
            },
            '&.Mui-completed': {
              color: themeOptions.palette.success.main
            }
          }
        }
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: themeOptions.palette.success.main,
              fontWeight: 700
            },
            '&.Mui-completed': {
              color: themeOptions.palette.success.main,
              fontWeight: 700
            }
          }
        }
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            gap: 8
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            background: '#0000000A',
            borderRadius: 5,
            padding: 8,
            margin: 0
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            '&.MuiAlert-standardWarning': {
              color: '#5F2B01'
            }
          },
          icon: {
            '&.MuiAlert-standardWarning': {
              color: '#5F2B01'
            }
          }
        }
      }
    }
  })
}

const colorsAndTypographies = createTheme({
  ...themeColors,
  ...themeTypographies as TypographyOptions
})

const theme = createCustomTheme(colorsAndTypographies)

interface CustomThemeOptions extends ThemeOptions {
  palette: Palette & {
    success: {
      main: string
    }
  }
}

export default theme
