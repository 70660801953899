import { AnalyteConfigI, QuantitativeConfigI } from '../../types/db/AnalyteConfigI'
import { getResultForResultBox } from '../TestResult/utils'
import { Kit } from '../../types/db/Kit'
import { SampleI } from '../../types/db/SampleI'
import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import ResultBoxPDF from './ResultBoxPDF'

const QuantitativeResultsPDF = ({ samples, kit, analytes }: Props) => {
  return (<>
    <View
      style={ {
        display: 'flex',
        flexDirection: 'row',
        height: '30px',
        fontSize: '10px',
        alignItems: 'center',
        borderBottom: '1px solid #F0F0F0',
        gap: '4px'
      } }
    >
      <Text style={ { flex: 1, padding: '0px 8px' } }>Test</Text>
      <Text style={ { flex: 0.5, padding: '0px 8px' } }>Result</Text>
      <Text style={ { flex: 0.5, padding: '0px 8px' } }>Unit</Text>
      <Text style={ { flex: 1, padding: '0px 8px' } }>Flag</Text>
      <Text style={ { flex: 1, padding: '0px 8px' } }>Reference Range</Text>
      <Text style={ { flex: 0.5, padding: '0px 8px' } }>Unit</Text>
    </View>

    {
      samples.map((sample, idx) => {
        const analyte = analytes?.find(analyte => analyte?.id && sample?.analyte_id && (+analyte.id === +sample.analyte_id))

        return <QuantitativeResultRow
          key={ idx }
          sample={ sample }
          kit={ kit }
          analyte={ analyte }
        />
      })
    }
  </>)
}

const QuantitativeResultRow = ({ sample, kit, analyte }: RowProps) => {
  const test = sample?.analyte_scientific_name || sample.analyte_name || ''
  const numericResult = sample?.numeric_result || ''

  const resultBox = getResultForResultBox(sample as SampleI, kit as Kit, analyte as AnalyteConfigI)
  const measurementUnit = (analyte?.result_states as QuantitativeConfigI)?.measurementUnit || ''

  // Check if the numeric_result is a valid number
  const numberWithoutSpecialCharacters = sample?.numeric_result?.replace(/[^0-9.]/g, '') || ''
  const isValidNumber = !isNaN(+numberWithoutSpecialCharacters || NaN)

  return (<>
    <View
      wrap={ false }
      style={ {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '10px',
        alignItems: 'flex-start',
        borderBottom: '1px solid #F0F0F0',
        padding: '8px 0px',
        gap: '4px'
      } }
    >
      <Text style={ { flex: 1, padding: '8px', fontWeight: 700 } }>{ test }</Text>
      <Text style={ { flex: 0.5, padding: '8px' } }>{ numericResult }</Text>
      <Text style={ { flex: 0.5, padding: '8px' } }>{ measurementUnit }</Text>
      <ResultBoxPDF value={ isValidNumber ? resultBox?.text || '' : '' } color={ isValidNumber ? resultBox?.color || '' : '' } backgroundColor={ isValidNumber ? resultBox?.backgroundColor || '' : 'transparent' } />
      <Text style={ { flex: 1, padding: '8px' } }>{ resultBox?.referenceRange }</Text>
      <Text style={ { flex: 0.5, padding: '8px' } }>{ measurementUnit }</Text>
    </View>
  </>)
}

interface Props {
  samples: Partial<SampleI>[]
  kit: Partial<Kit> | undefined
  analytes: Partial<AnalyteConfigI>[] | undefined
}

interface RowProps {
  sample: Partial<SampleI>
  kit: Partial<Kit> | undefined
  analyte: Partial<AnalyteConfigI> | undefined
}

export default QuantitativeResultsPDF
