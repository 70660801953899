import { api } from 'Core'
import PreAuth from 'PreAuth'
import { PageNotFound } from 'components'
import Loader from 'components/Loader'
import useAuth0 from 'hooks/useAuth0'
import { getCurrentLanguage } from 'i18n'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router'

function Authenticate ({
  silent = false,
  authorize,
  preAuth = <PreAuth />
}: {
  silent?: boolean
  authorize?: () => boolean
  preAuth?: React.ReactElement
}) {
  const { isLoading, getAccessTokenSilently, user } = useAuth0()
  const [authLoading, setAuthLoading] = useState(!user)
  const { i18n } = useTranslation()

  const authFlow = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      if (accessToken) {
        window.sessionStorage.setItem('token', accessToken.accessToken)
        const user = await api.content.getUser()
        const language = getCurrentLanguage(i18n)
        if (user.user.language !== language) {
          api.auth.updateProfile({ language })
        }
        // @ts-ignore
        window.Beacon('identify', {
          email: user.user.email,
          signature: user.signature
        })
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setAuthLoading(false)
    }
  }

  useEffect(() => {
    if (user) return
    setAuthLoading(true)
    authFlow().finally(() => {
      setAuthLoading(false)
    })
  }, [])

  if (silent) return <Outlet />

  if (authLoading || isLoading) return <Loader />

  const isAuthorized = authorize ? authorize() : true

  if (user) {
    if (!isAuthorized) return <PageNotFound />

    return <Outlet />
  }

  return preAuth
}

function StoreRedirectPath ({
  pathKey
}: {
  pathKey: string
}) {
  
  const location = useLocation();

  useEffect(() => {
    const redirectPath = window.sessionStorage.getItem('mfa-redirect');
    if(redirectPath)
      return;
    const fullUrl = `${location.pathname}${location.search}${location.hash}`;
    window.sessionStorage.setItem(pathKey, fullUrl)
  }, [])

  return <Outlet />
}

export { Authenticate, StoreRedirectPath }
