import React from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import { Alert, Button, Link, Stack, Typography } from '@mui/material'
import RadioGroupComponent from './components/RadioGroupComponent'
import { useFormik } from 'formik'
import { TestingProgramNavigation, useTestingProgram } from '.'
import { useNavigate, useParams } from 'react-router'
import { api } from 'Core'
import { useQueryClient } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import { TTestProgram } from './schemas'
import { useTranslation } from 'react-i18next'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { FooterComponent } from '../../types/db/ProgramI'

function YourSituation () {
  const { jsonData, data, updateData } = useTestingProgram()
  const navigate = useNavigate()
  const { slug } = useParams()
  const queryClient = useQueryClient()

  const FooterComponent = ({ footerData }: { footerData: FooterComponent | undefined }) => {
    if (!footerData) return <></>

    return (
      <Stack gap={ 2 }>
        <Typography sx={ { mt: '30px' } } variant="h6" component="h3">
          { footerData.title }
        </Typography>
        { footerData.alert
          ? <Alert
            severity="info"
            sx={ {
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center'
              }
            } }
          >
            <Typography variant="body1" dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(footerData.alert.text1) } } />
            <Typography variant="body1" mt={ 1 } dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(footerData.alert.text2) } } />

          </Alert>
          : ''
        }

        <Typography variant="body1" mt={ 1 } dangerouslySetInnerHTML={ { __html: footerData.footerText } } />

        <Stack gap={ 2 }>
          { footerData.buttonsLinks?.map(buttonLink => (
            <Button
              fullWidth
              key={ buttonLink.text }
              variant='outlined'
              endIcon={ <OpenInNewIcon /> }
            >
              <Link href={ buttonLink.link } target='_blank' rel='noreferrer' underline='none'>{ buttonLink.text }</Link>
            </Button>
          )) }
        </Stack>

      </Stack>
    )
  }

  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      [jsonData.yourSituation.radioOptions.name]: data.yourSituation
    },
    onSubmit: async () => {
      const response = await api.program.checkProgramPauseStatus(slug)
      if (response.pauseStatus) {
        const programConfigData = queryClient.getQueryData([
          'program-content',
          slug
        ])
        queryClient.setQueryData(['program-content', slug], {
          // @ts-ignore
          ...programConfigData,
          isPaused: true
        })
        navigate('../program-paused')
        return
      }
      updateData((currentData: any) => ({
        // @ts-ignore
        ...currentData,
        yourSituation: formik.values[jsonData.yourSituation.radioOptions.name] as TTestProgram['yourSituation']
      }))
      navigate('../eligibility')
    }
  })

  const options = jsonData.yourSituation.radioOptions.options.map((option) => ({
    label: option.label,
    value: option.value,
    footerComponent: <FooterComponent footerData={ option.footerComponent } />
  }))

  const activateNextBtn = () => {
    const option = formik.values[jsonData.yourSituation.radioOptions.name]

    if (option === '') return false

    return jsonData.yourSituation.radioOptions.options.find(
      (o: any) => o.value === option
    )?.nextStepAllowed
  }

  const activateContinueBtn = () => {
    const option = formik.values[jsonData.yourSituation.radioOptions.name]

    if (option === '') return false

    return jsonData.yourSituation.radioOptions.options.find(
      (o: any) => o.value === option
    )?.continueNextStep
  }

  return (
    <Stack>
      <LayoutWisFlow headerText={ jsonData.yourSituation.title }>
        <Stack gap={ 2 } component="form" onSubmit={ formik.handleSubmit }>
          <RadioGroupComponent
            headerText={ jsonData.yourSituation.radioOptions.title }
            onChange={ formik.handleChange }
            name={ jsonData.yourSituation.radioOptions.name }
            options={ options }
            value={ formik.values[jsonData.yourSituation.radioOptions.name] || '' }
          />
          { options.find(
            (op: any) =>
              op.value ===
              formik.values[jsonData.yourSituation.radioOptions.name]
          )?.footerComponent
            ? options.find(
              (op: any) =>
                op.value ===
                formik.values[jsonData.yourSituation.radioOptions.name]
            )?.footerComponent
            : '' }
          { activateNextBtn()
            ? (
              <TestingProgramNavigation
                back={ <Button disabled>{ t('multiFormNavigation.back') }</Button> }
                next={
                  <Button type="submit" variant="contained">
                    { t('multiFormNavigation.next') }
                  </Button>
                }
              />
              )
            : null }
          { activateContinueBtn()
            ? (
              <Link sx={ { alignSelf: 'center' } } component={ Button } onClick={ () => formik.submitForm() }>{ t('multiFormNavigation.continue') }</Link>
              )
            : null }
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

YourSituation.propTypes = {}

export default YourSituation
