import { Stack, Typography } from '@mui/material'
import React from 'react'
import { PatientI } from '../types/db/PatientI'

function AddressSummary ({ address }: Props) {
  if (!address) return null
  return (
    <Stack component='span'>
      <Typography component='span'>{ address?.delivery_line_1 }</Typography>
      <Typography component='span'>
        { address?.city_name }, { address?.state_abbreviation }, { address?.zipcode },{ ' ' }
        { address?.plus4_code ? address?.plus4_code + ', ' : null } { address?.county }
      </Typography>
      <Typography component='span'>{ address?.country }</Typography>
    </Stack>
  )
}

interface Props {
  address: PatientI['address'] | null
}

export default AddressSummary
