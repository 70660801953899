import { AnalyteConfigI } from '../../types/db/AnalyteConfigI'
import { Document, Font } from '@react-pdf/renderer'
import { Kit } from '../../types/db/Kit'
import { KitTypeConfigI } from '../../types/db/KitTypeConfigI'
import { PatientI } from '../../types/db/PatientI'
import { SampleI } from '../../types/db/SampleI'
import { SiteI, WhiteLabelConfig } from '../../types/db/SiteI'
import CustomPagePDF from './CustomPagePDF'
import React from 'react'
import moment from 'moment'

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const ResultsReportPDF = ({ patient, site, kit, samples, kitTypeConfig, analytes, whiteLabelConfig }: Props) => {
  const groupedSamples = groupSamplesByType(samples)
  // Get the oldest created_at date from the samples
  const resultedAt = samples?.reduce((acc, sample) => {
    const createdAt = moment(sample.created_at).format('MM/DD/YYYY')
    if (!acc) {
      acc = createdAt
      return acc
    }

    return moment(createdAt).isBefore(acc) ? createdAt : acc
  }, '')

  const isCorrected = samples?.some(sample => (!!sample.reason_updated && sample.reason_updated?.trim().length > 0)) || false

  const displaySHKLabData = kitTypeConfig?.lisEndPoint === 'SHK USSL'

  return (
    <Document >
      {
        Object.keys(groupedSamples).map((sampleType, idx) => {
          const samplesByType = groupedSamples[sampleType]

          return (<CustomPagePDF
            key={ idx }
            patient={ patient }
            site={ site }
            kit={ kit }
            samplesByType={ samplesByType }
            sampleType={ sampleType }
            kitTypeConfig={ kitTypeConfig }
            analytes={ analytes }
            resultedAt={ resultedAt }
            whiteLabelConfig={ whiteLabelConfig }
            isCorrected={ isCorrected }
            displaySHKLabData={ displaySHKLabData }
          />)
        })
      }
    </Document>
  )
}

const groupSamplesByType = (samples: Partial<SampleI>[] | undefined) => {
  if (!samples) return {}

  return samples.reduce((acc, sample) => {
    const { sample_type_name = 'Unknown', analyte_type = 'qualitative' } = sample

    if (!acc[sample_type_name]) {
      acc[sample_type_name] = {
        [analyte_type]: [sample]
      }

      return acc
    }

    if (!acc[sample_type_name][analyte_type]) {
      acc[sample_type_name][analyte_type] = [sample]
    } else {
      // @ts-ignore
      acc[sample_type_name][analyte_type].push(sample)
    }

    return acc
  }, {} as GroupedSamplesI)
}

interface Props {
  patient: Partial<PatientI> | undefined
  site: Partial<SiteI> | undefined
  kit: Partial<Kit> | undefined
  samples: Partial<SampleI>[] | undefined
  kitTypeConfig: Partial<KitTypeConfigI> | undefined
  analytes: Partial<AnalyteConfigI>[] | undefined
  whiteLabelConfig: WhiteLabelConfig | undefined
}

interface GroupedSamplesI {
  [key: string]: GroupedSamplesByTypeI
}

export interface GroupedSamplesByTypeI {
  qualitative?: Partial<SampleI>[]
  quantitative?: Partial<SampleI>[]
}

export default ResultsReportPDF
