// Check if the string is a valid image url
export const isValidImageUrl = (string: string | undefined | null) => {
  if (!string) return false
  const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']
  // Check if the image is a local image
  const isLocalImage = string.startsWith('/assets/')

  if (isLocalImage) {
    const fileExtension = string.split('.').pop()?.toLowerCase() || ''
    return imageFormats.includes(fileExtension)
  } else {
    try {
      const urlObj = new URL(string)
      const fileExtension = urlObj?.pathname?.split('.')?.pop()?.toLowerCase() || ''
      return imageFormats.includes(fileExtension)
    } catch (_) {
      return false
    }
  }
}
