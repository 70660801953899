import { Box, Stack, Typography } from '@mui/material'
import AddressSummary from 'components/AddressSummary'

import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PatientI } from '../../../types/db/PatientI'
import { TextDataRow } from '../../../admin-app/pages/Tests/components/TextDataRow'

export const InsuranceDetails = ({ patient }: Props) => {
  const { t } = useTranslation()

  if (!patient || !patient.insurance) return null
  return (
    <>
      {
        patient?.insurance?.insurance_type === 'uninsured'
          ? (<Stack>
            <TextDataRow
              title={ t('registerPatient.unInsured.driverLicense') + ':' }
              value={ patient?.driver_license }
            />
            <TextDataRow
              title={ t('registerPatient.unInsured.ssn') + ':' }
              value={ patient?.insurance?.socialSecurityNo }
            />
          </Stack>)
          : (<Stack>
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.primaryInsurance') + ':' }
              value={ patient?.insurance?.insurance_name }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.policyNumber') + ':' }
              value={ patient?.insurance?.policy_number }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.groupNumber') + ':' }
              value={ patient?.insurance?.group_number }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.patientRelationship') + ':' }
              value={ patient?.insurance?.relationship }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.insuredFirstName') + ':' }
              value={ patient?.insurance?.insured_first_name }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.insuredLastName') + ':' }
              value={ patient?.insurance?.insured_last_name }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.insuredDob') + ':' }
              value={ moment(patient?.insurance?.insured_dob).isValid() ? moment(patient?.insurance?.insured_dob, ['YYYY-MM-DD', 'MM-DD-YYYY']).format('MM-DD-YYYY') : '' }
            />
            <TextDataRow
              title={ t('registerKit.insuranceForm.label.insuredGender') + ':' }
              value={ patient?.insurance?.insured_gender }
            />
            <Box sx={ { mt: 2 } }>
              <Typography component='span' fontWeight='bold' display='inline'>{ t('registerKit.insuranceForm.label.currentAddress') + ':' }&nbsp;</Typography>
              <AddressSummary address={ patient?.insurance?.insured_address } />
            </Box>
          </Stack>)
      }
    </>
  )
}

interface Props {
  patient: PatientI | null | undefined
}
