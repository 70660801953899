import { LoadingButton } from '@mui/lab'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import Loader from 'components/Loader'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { showSnackBar } from 'state/actions'
import { t } from 'i18next'

const options = [
  {
    value: () => t('freeTestRemainder.feedback.survey.option1'),
    key: 'I plan to use the test kit later'
  },
  {
    value: () => t('freeTestRemainder.feedback.survey.option2'),
    key: 'I forgot to use the test kit'
  },
  {
    value: () => t('freeTestRemainder.feedback.survey.option3'),
    key: 'I lost my kit'
  },
  {
    value: () => t('freeTestRemainder.feedback.survey.option4'),
    key: 'I found it hard to collect/return samples'
  },
  {
    value: () => t('freeTestRemainder.feedback.survey.option5'),
    key: 'I have visited a clinic or doctor instead'
  },
  {
    value: () => t('freeTestRemainder.feedback.survey.option6'),
    key: 'The kit arrived damaged/did not arrive'
  },
  {
    value: () => t('freeTestRemainder.feedback.survey.option7'),
    key: 'Other'
  }
]

const FeedbackForm = () => {
  const [formSubmit, setFormSubmit] = useState(false)
  const [desc, setDesc] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token, id } = useParams()
  const [currentOption, setCurrentOption] = useState(id && Number(id) ? options[Number(id) - 1]?.key : 'Other')

  const key = id ? 'healthkitNotSentToLabSurvey1EMAIL' : 'healthkitNotSentToLabSurvey1LINK'

  const { isLoading, data } = useQuery(['health-kit-survey', token], () =>
    api.feedback.getCheckToken(token, { key })
  )

  if (isLoading) {
    return <Loader />
  }

  const FormSubmit = async (selectedOption = '', setLoading = true) => {
    try {
      if (setLoading) setFormSubmit(true)
      const createFeedback = await api.feedback.createFeedbackLoggedOut(token, {
        rating: 0,
        description: selectedOption,
        cacheKey: key,
        feedbackKey: key
      })
      if (createFeedback) {
        navigate('../feedback-completed')
        return dispatch(
          showSnackBar({
            severity: 'success',
            message: 'Feedback sent successfully',
            show: true
          })
        )
      } else throw Error('Could not create feedback')
    } catch (error) {
      dispatch(
        showSnackBar({
          show: true,
          severity: 'error',
          message: 'Something went wrong'
        })
      )
      if (setLoading) setFormSubmit(false)
    }
  }

  if (!data) {
    navigate('/')
    dispatch(
      showSnackBar({
        show: true,
        severity: 'error',
        message: 'Invalid token, token might be revoked or expired'
      })
    )
    return <Loader />
  }

  if (data && data?.acted) {
    navigate('../feedback-completed')
    return <Loader />
  }

  return (
    <Stack gap={3}>
      <Typography variant="h4" component="h1" sx={{ textAlign: 'center' }}>
        {t('freeTestRemainder.feedback.survey.title', { lng: 'es-US' })}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {t('freeTestRemainder.feedback.survey.body')}
      </Typography>
      <RadioGroup
        name="feedback"
        onChange={(evt, value) => {
          setCurrentOption(value)
        }}
        sx={{ gap: 2 }}
        value={currentOption}
      >
        {options.map((option, key) => (
          <FormControlLabel
            key={key}
            control={<Radio />}
            label={option.value()}
            value={option.key}
          />
        ))}
      </RadioGroup>
      {currentOption === 'Other'
        ? (
        <TextField
          sx={{ mr: 1, backgroundColor: 'white', width: '100%' }}
          label={t('freeTestRemainder.feedback.survey.other')}
          fullWidth
          onChange={(e) => setDesc(e.target.value)}
          name="Other"
          value={desc}
        />
          )
        : null}
      <LoadingButton
        type="button"
        onClick={() =>
          FormSubmit(currentOption === 'Other' ? desc : currentOption)
        }
        variant="contained"
        fullWidth={true}
        loading={formSubmit}
      >
        {t('registerPatient.button.submit') }
      </LoadingButton>
    </Stack>
  )
}

export default FeedbackForm
