import {
  Alert,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWhiteLabelConfig } from '../../../../utils/white-label/WhiteLabelConfig'

const toUpperOnChange = evt => {
  evt.target.value = evt.target.value.toUpperCase()
}

function Site ({ formik }) {
  const { t } = useTranslation()
  const whitelabelConfig = useWhiteLabelConfig()

  return (
    <Stack sx={{ gap: 3 }}>
      <Typography component='h2' variant='h5'>
        {t('registerPatient.site.tellUsMore')}
      </Typography>
      <Stack sx={{ gap: 1 }}>
        <Typography>{t('registerPatient.site.organizationTestingQuestion')}</Typography>
        <RadioGroup
          id='isPartOfOrganization'
          aria-labelledby='is-part-of-organization'
          name='isPartOfOrganization'
          value={formik.values.isPartOfOrganization || null}
          onChange={(_evt, value) => formik.setValues({
            ...formik.values,
            isPartOfOrganization: value,
            locationCode: '',
            signBase64: '',
            signName: '',
            tnc: false,
            disclaimerTnc: false,
            consentTnc: false,
            consentSignBase64: '',
            consentSignName: ''
          })}
        >
          <FormControlLabel value='Yes' control={<Radio />} label={t('registerPatient.site.yes')} />
          <FormControlLabel value='No' control={<Radio />} label={t('registerPatient.site.no')} />
        </RadioGroup>
        {formik.isError('isPartOfOrganization')
          ? (
            <FormHelperText error={formik.isError('isPartOfOrganization')}>
              {t(formik.getError('isPartOfOrganization'))}
            </FormHelperText>
            )
          : null}
      </Stack>

      {formik.values.isPartOfOrganization === 'Yes'
        ? (
          <Stack sx={{ gap: 2 }}>
            <Typography>
              {t('registerPatient.site.organizationSiteCodeInstructions', {
                email: whitelabelConfig?.emailForSupport || process.env.REACT_APP_SUPPORT_EMAIL
              })} {' '}
              <Typography component='span' sx={{ textDecoration: 'underline' }}>
                {whitelabelConfig?.emailForSupport || process.env.REACT_APP_SUPPORT_EMAIL}
              </Typography>{' '}
              *
            </Typography>
            <FormControl sx={{ gap: 1 }} error={formik.isError('locationCode')}>
              <TextField
                id='site-code'
                name='locationCode'
                label={t('registerPatient.site.siteCodeLabel')}
                value={formik.values.locationCode}
                onChange={evt => {
                  toUpperOnChange(evt)
                  formik.handleChange(evt)
                }}
              />
            </FormControl>
            {formik.isError('locationCode')
              ? (
                <Alert severity='error'>
                  {t('registerPatient.site.siteCodeErrorInstructions', {
                    email: whitelabelConfig?.emailForSupport || process.env.REACT_APP_SUPPORT_EMAIL
                  })}
                </Alert>
                )
              : null}
          </Stack>
          )
        : null}
    </Stack>
  )
}

export default Site
