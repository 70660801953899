import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Dialog, Divider, IconButton, Link, RadioGroup, Stack, TextField, Typography, useRadioGroup } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import Loader from 'components/Loader'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { showSnackBar } from 'state/actions'
import CloseIcon from '@mui/icons-material/Close'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'

const FeedbackForm = ({ setFeedback, cacheKey = 'npsSatisfactionSurvey', feedbackKey = 'nps-form' }) => {
  const { t } = useTranslation()
  const [formSubmit, setFormSubmit] = useState(false)
  const [desc, setDesc] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token, option } = useParams()
  const [currentOption, setCurrentOption] = useState(Number(option) || 0)

  const { isLoading, data } = useQuery(
    ['nps-review', token],
    () => api.feedback.getCheckToken(token, { key: cacheKey })
  )

  if (isLoading) {
    return (
      <Loader/>
    )
  }

  const FormSubmit = async () => {
    try {
      setFormSubmit(true)
      const createFeedback = await api.feedback.createFeedbackLoggedOut(token, {
        rating: currentOption,
        description: desc,
        cacheKey,
        feedbackKey
      })
      if (createFeedback) {
        setFeedback(createFeedback)
        navigate('../feedback-completed')
        return dispatch(
          showSnackBar({
            severity: 'success',
            message: 'Feedback sent successfully',
            show: true
          })
        )
      } else throw Error('Could not create feedback')
    } catch (error) {
      dispatch(
        showSnackBar({
          show: true,
          severity: 'error',
          message: 'Something went wrong'
        })
      )
      setFormSubmit(false)
    }
  }

  if (!data) {
    navigate('/')
    dispatch(
      showSnackBar({
        show: true,
        severity: 'error',
        message: 'Invalid token, token might be revoked or expired'
      })
    )
    return (
      <Loader/>
    )
  }

  if (data && data?.acted) {
    navigate('../feedback-completed')
    return (
        <Loader/>
    )
  }

  return (
    <Stack gap={3}>
      <Stack>
          <Stack
              component="img"
              src={'/assets/2-doctors-2.svg'}
              alt={'2 doctors'}
              sx={{ m: 'auto' }}
          />
          <Alert
              severity="success"
              sx={{
                '& .MuiAlert-icon': {
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
          >
              <Typography variant="body1">
              {t('npsFeedback.survey.title1')} <strong>{t('npsFeedback.survey.title2')}</strong>
              </Typography>
          </Alert>
      </Stack>
      <Stack direction="row" gap={1}
        sx={{

          alignItems: 'center',
          justifyContent: 'left'
        }} >
        <Typography variant="body1">
          {t('npsFeedback.survey.body1')}
        </Typography>
        <Stack
          sx={{
            backgroundColor: getColor(currentOption),
            color: '#ffffff',
            borderRadius: '50%',
            height: 32,
            width: 32,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          {currentOption}
        </Stack>
      </Stack>
      <Link
        onClick={() => setShowDrawer(true)}
        color="primary"
        rel="noreferrer"
        sx={{ cursor: 'pointer' }}
      >
        {t('npsFeedback.survey.body2')}
      </Link>
      {/* {
        currentOption >= 9 && data?.productUrl
          ? <ProductLink overrideLink={data?.productUrl} />
          : <Typography variant="body1">
          <strong>If you have another 10 seconds...</strong>
        </Typography>
      } */}
      <Typography variant="body1">
        <strong>{t('npsFeedback.survey.body3')}...</strong>
      </Typography>
      <Stack gap={1}>
          <Typography variant="body1">
            {t('npsFeedback.survey.body4')}
          </Typography>
          <TextField
              label=''
              aria-label='experience'
              fullWidth
              multiline
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              placeholder='Share your thoughts'
              rows={3}
              name='description'
          />
      </Stack>
      <ChangeScoreDialog
        defaultValue={currentOption}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        onChange={(value) => setCurrentOption(value)} />

      <LoadingButton
              type="button"
              onClick={() => FormSubmit()}
              variant="contained"
              fullWidth={true}
              loading={formSubmit}
          >
          {t('registerPatient.button.submit')}
      </LoadingButton>
    </Stack>
  )
}

export default FeedbackForm

function ChangeScoreDialog ({ open, onClose, defaultValue, onChange }) {
  const { t } = useTranslation()
  return (
    <Dialog maxWidth='xl' open={open} onClose={onClose}>
      <Box p={2} sx={{ textAlign: 'center' }}>
        <Typography variant='h4' textAlign={'left'}>{t('npsFeedback.survey.body2')}</Typography>
      </Box>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: '8px', top: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <Box p={2}>
        <Typography variant='subtitle1' textAlign={'center'}>
          <strong>{t('npsFeedback.survey.question')}</strong>
        </Typography>
        <Box p={1}>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '4rem 1fr 4rem', sm: '6rem 1fr 6rem' },
            alignItems: 'center',
            gap: { xs: '.5rem', sm: '1rem' }
          }}
          >
            <Typography
              variant='body2'
              sx={{
                fontWeight: 700,
                color: '#00000099',
                gridRow: { xs: '2/3', sm: '1/2' },
                gridColumn: { xs: '1/2' },
                textAlign: { xs: 'left', sm: 'right' }
              }}
            >
              0 - {t('npsFeedback.survey.option1')}
            </Typography>
            <RadioGroup defaultValue={defaultValue} name='rating' sx={{ flexDirection: 'column', gridColumn: { xs: '1/4', sm: '2/3' } }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {Array(11).fill(null).map((val, i) => (
                  <RatingButton onChange={onChange} key={i} value={i}>{i}</RatingButton>
                ))}
              </div>
            </RadioGroup>
            <Typography
              variant='body2'
              sx={{
                fontWeight: 700,
                color: '#00000099',
                gridRow: { xs: '2/3', sm: '1/2' },
                gridColumn: { xs: '3/4' },
                textAlign: { xs: 'right', sm: 'left' }
              }}
            >
              10 - {t('npsFeedback.survey.option2')}
            </Typography>
          </Box>

        </Box>
        <Button
          type="button"
          onClick={onClose}
          variant="contained"
          fullWidth={true}
        >
          {t('registerPatient.button.submit')}
        </Button>
      </Box>

    </Dialog>
  )
}

const getColor = (value) => {
  if (value > 8) { return 'success.main' } else if (value > 4) { return 'warning.dark' } else { return 'error.main' }
}

function RatingButton ({ children, value, onChange }) {
  const radioGroup = useRadioGroup()
  const checked =
    !!radioGroup.value && String(radioGroup.value) === String(value)
  return (
    <Stack
      component='label'
      sx={{
        backgroundColor: checked ? getColor(value) : '#00000099',
        color: '#ffffff',
        borderRadius: '50%',
        height: 32,
        width: 32,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        mx: '4px'
      }}
    >
      <Box
        name={radioGroup.name}
        component='input'
        type='radio'
        checked={checked}
        onChange={evt => {
          radioGroup.onChange(evt, value)
          onChange(value)
        }}
        value={value}
        sx={visuallyHidden}
      />
      {children}
    </Stack>
  )
}
