import React from 'react'
import { Typography, Stack } from '@mui/material'
// @ts-ignore
import DOMPurify from 'dompurify'

interface SectionRenderProps {
  title: string | undefined,
  body: string | undefined
}

const SectionRender : React.FC<SectionRenderProps> = ({ title, body }) => {
  
  return (
    <Stack gap={2}>
      { title ? 
        <Typography variant="h6" component="h3" textAlign="left">
          {title}
        </Typography>
        : ''
      }
      {
        body ? 
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body, { ADD_ATTR: ['target'] }) }} />
        : ''
      }
    </Stack>
  )
}

export default SectionRender
