import React, { MouseEventHandler, useState } from 'react'
import { Person } from '@mui/icons-material'
import { checkVisibilityMatch } from '../utils'
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import LogoutDialog from 'components/Header/components/LogoutDialog'
import useAuth0 from 'hooks/useAuth0'
import { useNavigate } from 'react-router'
import { checkWalgreens, checkWalmart } from 'utils/affiliateUtilFunctions'
import { useTranslation } from 'react-i18next'
import { AccountAction, useAccountSettings } from './useAccountSettings'
import { UserPermissionsI } from '..'
import { useWhiteLabelConfig } from '../../../utils/white-label/WhiteLabelConfig'
import { getSubdomain } from '../../../utils/utilFuntions'

export const AccountSettings = ({ userPermissions }: { userPermissions: UserPermissionsI }) => {
  const whilteLabelConfig = useWhiteLabelConfig()
  const subdomain = getSubdomain()
  const shouldUseWhiteLabel = ['app'].includes(subdomain)

  const accountActions = useAccountSettings()
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] = useState(false)
  const { user } = useAuth0()
  const [anchorEl2, setAnchorEl2] = useState<EventTarget & HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl2)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl2(event.currentTarget)
  }

  const handleClose = () => { setAnchorEl2(null) }

  const isWalmart = checkWalmart()
  const isWalgreens = checkWalgreens()

  const handleItemClick = (action: AccountAction) => {
    handleClose()
    if (action?.title === t('header.account.logout')) {
      setIsLogoutConfirmationOpen(true)
      return
    }

    if (action?.onClick) {
      action.onClick()
      return
    }

    navigate(action?.navigateTo || '/')
  }

  if (!user) return <></>
  return (
    <>
      <LogoutDialog
        id='user-logout'
        keepMounted
        isLogoutConfirmationOpen={ isLogoutConfirmationOpen }
        setIsLogoutConfirmationOpen={ setIsLogoutConfirmationOpen }
      />
      <Tooltip title={ t('header.link.account.tooltip') } arrow>
        <IconButton
          onClick={ handleClick }
          size='small'
          aria-controls={ open ? 'account-menu' : undefined }
          aria-haspopup='true'
          aria-expanded={ open ? 'true' : undefined }
          tabIndex={ 0 }
        >
          <Person sx={ { color: isWalmart || isWalgreens ? 'white' : shouldUseWhiteLabel ? whilteLabelConfig?.theme?.customComponents?.header?.textColor : '#333' } } />
        </IconButton>
      </Tooltip>
      {
        open
          ? (
            <Menu
              anchorEl={ anchorEl2 }
              id='account-menu'
              open={ open }
              onClose={ handleClose }
              MenuListProps={ {
                'aria-labelledby': 'account-menu'
              } }
              slotProps={ {
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }
              } }
              transformOrigin={ { horizontal: 'right', vertical: 'top' } }
              anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
            >
              {
                open && accountActions.map(
                  (action) => {
                    if (!checkVisibilityMatch({
                      userPermissions,
                      componentRestrictions: action.visibleOn
                    })) return null

                    return (
                      <MenuItem
                        divider
                        key={ action?.title }
                        onClick={ () => { handleItemClick(action) } }
                      >
                        <ListItemIcon>
                          { action.icon }
                        </ListItemIcon>
                        { action.title }
                      </MenuItem>
                    )
                  }
                )
              }
            </Menu>
            )
          : null
      }
    </>
  )
}
