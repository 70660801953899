import { Stack, Typography } from '@mui/material'
import { api } from 'Core'
import ReviewModalComponent from 'components/ProductReviewComponent'
import React, { useEffect, useState } from 'react'

const FeedbackCompleted = ({ feedback = { id: 0, rating: 0, meta: { kitCode: null } } }) => {
  const [reviewModal, setOpenReviewModal] = useState(false)
  const [productLink, setProductLink] = useState('')
  const [meta, setMeta] = useState({})
  

  useEffect(() => {
    if (feedback?.rating >= 9 && feedback?.meta?.kitCode) {
      const getLink = async () => {
        const linkData = await api.feedback.getProductLink(feedback?.meta?.kitCode)
        if (linkData?.productUrl) {
          setProductLink(linkData?.productUrl)
          setMeta({
            ...linkData,
            page: 'NPS Feedback',
            kitCode: feedback.meta.kitCode
          })
          setOpenReviewModal(true)
        }
      }
      getLink()
    }
  }, [])

  return (
            <Stack gap={3}>
                <Typography variant='h4' component='h1' sx={{ textAlign: 'center' }}>
                    Thanks again for your feedback
                </Typography>
                <Stack
                    component="img"
                    src={'/assets/hifi_hands.svg'}
                    alt={'2 doctors'}
                    sx={{ m: 'auto' }}
                />
                <Typography variant="body1">
                    Thank you for your feedback! Your insights are valuable and will help us improve.
                </Typography>
                <ReviewModalComponent reviewModal={reviewModal} setOpenReviewModal={setOpenReviewModal} productLink={productLink} meta={meta}/>

            </Stack>
  )
}

export default FeedbackCompleted
