import { Badge, Box, IconButton, Popover } from '@mui/material'
import ListOfNotifications from './ListOfNotifications'
import NotificationsIcon from '@mui/icons-material/Notifications'
import React, { MouseEventHandler, useState } from 'react'
import useNotifications from 'hooks/useNotifications'
import { checkWalgreens, checkWalmart } from 'utils/affiliateUtilFunctions'
import { useWhiteLabelConfig } from '../../../utils/white-label/WhiteLabelConfig'
import { AppNotificationI } from '../../../types/db/AppNotificationI'

const PatientInbox = () => {
  const whilteLabelConfig = useWhiteLabelConfig()
  const { unreadMessagesNumber, notifications } = useNotifications()
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null)
  const isInboxOpen = Boolean(anchorEl)
  const id = isInboxOpen ? 'inbox-popover' : undefined
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isWalmart = checkWalmart()
  const isWalgreens = checkWalgreens()

  return (
    <>
      <IconButton
        onClick={ handleClick }
        aria-describedby={ id }
        aria-controls={ isInboxOpen ? 'inbox-popover' : undefined }
        aria-haspopup='true'
        aria-expanded={ isInboxOpen ? 'true' : undefined }
        size='small'
      >
        <Badge badgeContent={ unreadMessagesNumber } color="error">
          <NotificationsIcon sx={ { color: isWalmart || isWalgreens ? 'white' : whilteLabelConfig?.theme?.customComponents?.header?.textColor || '#333333' } } />
        </Badge>
      </IconButton>

      <Popover
        id={ id }
        open={ isInboxOpen }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        transformOrigin={ { horizontal: 'right', vertical: 'top' } }
        anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
        slotProps={ {
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        } }
      >
        <Box sx={ { bgcolor: 'background.paper', borderRadius: '.25rem', width: '100%' } }>
          <ListOfNotifications notifications={ notifications as AppNotificationI[] } />
        </Box>
      </Popover>
    </>
  )
}

export default PatientInbox
