import { Box, styled } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'

import './Loader.css'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,0.9)'
}))

const Loader = ({ hide }) => {
  if (hide) {
    return null
  }
  return (
    <Root className='loading-background'>
      <div className='loading-bar' role='alert' aria-busy='true'>
        <div className='loading-circle-1' />
        <div className='loading-circle-2' />
      </div>
    </Root>
  )
}

Loader.propTypes = {
  hide: PropTypes.bool
}

PropTypes.defaultProps = {
  hide: false
}

export default Loader
