import React from 'react'
import { Box, Button, Grid, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function ClientSideError () {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        height: '100vh',
        margin: '-8px',
        background: '#eee',
        px: 2
      }}
    >
      <Grid rowSpacing={2} container>
        <Grid item xs={12}>
          <Typography variant='h2'>Unexpected error!</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>
            Sorry, we had some technical problems loading this page. It is
            likely an issue at our end.
          </Typography>
          <Typography variant='h5'>
            <Link href={window.location.pathname}>Reload page</Link> or{' '}
            <Link
              href='mailto:help@simplehealthkit.com'
              target='_blank'
              rel='noreferrer'
            >
              contact us
            </Link>{' '}
            if the problem persists.
          </Typography>
          <Typography variant='h5'>
            <Button variant='text' onClick={() => navigate('../')}>Go back</Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientSideError
