import moment from 'moment'
import * as yup from 'yup'

function validateDob( value = '' ) {
  const momentFormat = `${value.substring( 6 )}-${value.substring(
    0,
    2
  )}-${value.substring( 3, 5 )}`
  return moment( momentFormat, true ).isValid()
}

export const home = yup.object( {
  disclaimer: yup
    .boolean()
    .oneOf( [ true ], 'Please accept conditions' )
    .default( false )
} )

export const about = yup.object( {
  whatDescribesBest: yup
    .string()
    .oneOf( [ 'none' ], 'Please select none of the above' )
    .required( 'Please select an option' )
    .nullable()
    .default( null )
} )

export const patientSelection = yup.object( {
  patientId: yup
    .number()
    .required( 'Please select a patient' )
    .nullable()
    .default( null ),
  myselfTck: yup
    .boolean()
    .required()
    .oneOf( [ true ], 'Prep program cannot be availed for others' )
    .default( false )
} )

export const lifeStyleInfo = yup.object( {
  situationsCurrentlyApply: yup
    .array()
    .of( yup.string() )
    .min( 1, 'Please select at least one option' )
    .required()
    .default( [] ),
  currentlyUsingDailyPrep: yup
    .string()
    .required( 'Please select an option' )
    .nullable()
    .default( null ),
  situationsApply: yup
    .array()
    .of( yup.string() )
    .min( 1, 'Please select at least one option' )
    .default( [] )
} )

export const stiInfo = yup.object( {
  diagnosedWithSTIs: yup
    .array()
    .of( yup.string() )
    .min( 1, 'Please select at least one option' )
    .required()
    .default( [] ),
  diagnosedWithSTIsOther: yup
    .string()
    .default( '' )
    .when( 'diagnosedWithSTIs', {
      is: ( value: string[] | undefined ) => value && value.includes( 'other' ),
      then: yup.string().required( 'Please provide other STIs' ).default( '' )
    } ),
  completedPreviousTreatment: yup
    .string()
    .nullable()
    .default( null )
    .when( 'diagnosedWithSTIs', {
      is: ( value: string[] | undefined ) =>
        value && !value.includes( 'None of the above' ),
      then: yup.string().required( 'Please select an option' ).nullable().default( null )
    } ),
  partnerPositive: yup
    .string()
    .required( 'Please select an option' )
    .nullable()
    .default( null ),
  partnerBeenOnTreatment: yup
    .string()
    .nullable()
    .default( null )
    .when( 'partnerPositive', {
      is: 'Yes',
      then: yup.string().required( 'Please select an option' ).nullable().default( null )
    } ),
  partnerViralLoad: yup
    .string()
    .nullable()
    .default( null )
    .when( 'partnerPositive', {
      is: 'Yes',
      then: yup.string().required( 'Please select an option' ).nullable().default( null )
    } )
} )

export const additionalQuestions = yup.object( {
  diagnosedWithConditions: yup
    .array()
    .min( 1, 'Select at least one' )
    .default( [] ),
  medicalConditions: yup
    .string()
    .required( 'This field is required' )
    .default( '' ),
  currentPrescriptionMeds: yup
    .string()
    .required( 'This field is required' )
    .default( '' ),
  medAllergy: yup.string().required( 'This field is required' ).default( '' ),
  extraInfo: yup.string().required( 'This field is required' ).default( '' )
} )

const insuranceValidationSchema = yup.object( {
  primaryInsuranceName: yup
    .string()
    .nullable()
    .required( 'Required Field' )
    .default( '' ),
  policyNumber: yup.string().nullable().required( 'Required Field' ).default( '' ),
  groupNumber: yup.string().nullable().required( 'Required Field' ).default( '' ),
  patientRelationshipToInsured: yup
    .string()
    .nullable()
    .required( 'Required Field' )
    .default( '' ),
  insuredFirstName: yup
    .string()
    .nullable()
    .required( 'Required Field' )
    .default( '' ),
  insuredLastName: yup
    .string()
    .nullable()
    .required( 'Required Field' )
    .default( '' ),
  insuredGender: yup.string().nullable().required( 'Required Field' ).default( '' ),
  sexAssignedAtBirth: yup
    .string()
    .nullable()
    .required( 'Required Field' )
    .default( '' ),
  insuranceDisclaimer: yup
    .boolean()
    .oneOf( [ true ], 'Please accept conditions' )
    .default( false ),
  dob: yup
    .string()
    .required( 'Please enter your date of birth' )
    .test(
      'DOB',
      'The Date Of Birth must be valid and in the format MM/DD/YYYY',
      validateDob
    ).default( '' ),
  addressLine1: yup.string().required( 'This field is required' ).default( '' ),
  city: yup.string().required( 'This field is required' ).default( '' ),
  state: yup.string().required( 'This field is required' ).nullable().default( null ),
  zipCode: yup
    .string()
    .min( 5, 'Please enter a valid zipcode' )
    .required( 'This field is required' ).default( '' )
} )

export const insuranceInfo = yup.object( {
  paymentType: yup
    .string()
    .required( 'Please select payment type' )
    .oneOf( [ 'withInsurance', 'withoutInsurance' ] )
    .nullable()
    .default( null ),
  insurance: yup.object().nullable().default( null ).when( 'paymentType', {
    is: 'withInsurance',
    then: insuranceValidationSchema
  } )
} )

export const prepPharmacySchema = yup.object( {
  id: yup.string().required( 'Please select a pharmacy' ).default( '' ),
  name: yup.string().required( 'Please select a pharmacy' ).default( '' ),
  address: yup.string().required( 'Please select a pharmacy' ).default( '' ),
  phone: yup.string().default( '' ),
  zip: yup.string().required( 'Please select a pharmacy' ).default( '' ),
  coordinates: yup.object( {
    lat: yup.number().required( 'Please select a pharmacy' ).default( NaN ),
    lng: yup.number().required( 'Please select a pharmacy' ).default( NaN ),
  } ).required( 'Please select a pharmacy' ),
} )

export const prepProgram = yup.object( {
  home,
  about,
  patientSelection,
  lifeStyleInfo,
  stiInfo,
  additionalQuestions,
  insuranceInfo,
  pharmacy: prepPharmacySchema,
  id : yup.number().nullable()
} )

export type TPrepProgram = yup.TypeOf<typeof prepProgram>
