import { analyticEventTracker, analyticsReady } from '../utils/analytics'
import { ReduxState } from '../types/ReduxState'
import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router'
import moment from 'moment'
import useAuth0 from './useAuth0'

// Hook for analytics
export const useAnalytics = () => {
  const location = useLocation()
  const ui = useSelector((state: ReduxState) => state.ui)
  const dispatch = useDispatch()
  const [startTime, setStartTime] = useState(moment())
  const [lastLocation, setLastLocation] = useState('')
  const { user } = useAuth0() 

  useLayoutEffect(() => {
    analyticsReady(() => console.log('Analytics are ready!'))
    if(user?.email){
      window.sessionStorage.setItem('track_email_analytics', user?.email);
    }
  }, [user?.email])

  // Send Analytics info about in which step the user left the new kit form
  useLayoutEffect(() => {
    if (!location.pathname.includes('register') && ui['newKitForm/step'] !== undefined && ui['newKitForm/step'] !== null) {
      analyticEventTracker(`(F) Quit kit - ${ui['newKitForm/step']}`, { category: 'Register kit' })
      dispatch({ type: 'ui/newKitForm/step', payload: null })
    }
  }, [location])

  // Trigger when user closes the tab or the browser
  useLayoutEffect(() => {
    window.addEventListener('beforeunload', () => {
      const pageCloseTime = moment()
      const totalTimeSpentOnPage = intervals(pageCloseTime.diff(startTime, 'minutes'))
      const pageName = currentPageName(location.pathname)
      analyticEventTracker(`(P) ${pageName} (${totalTimeSpentOnPage})`, { category: 'Pages', page: location.pathname })
      setStartTime(moment())
    })
    return () => {
      window.removeEventListener('beforeunload', () => {})
    }
  }, [])
  // Trigger when user changes page
  useLayoutEffect(() => {
    if (!lastLocation) {
      setLastLocation(location.pathname)
    } else {
      const pageCloseTime = moment()
      const totalTimeSpentOnPage = intervals(pageCloseTime.diff(startTime, 'minutes'))
      const pageName = currentPageName(lastLocation)
      analyticEventTracker(`(P) ${pageName} (${totalTimeSpentOnPage})`, { category: 'Pages', page: location.pathname })
      setLastLocation(location.pathname)
      setStartTime(moment())
    }
  }, [location.pathname])
}

// UTILS
export const intervals = (time = NaN) => {
  if (time <= 1) return '0-1 min'
  if (time <= 5) return '1-5 min'
  if (time <= 10) return '5-10 min'
  if (time > 10) return '> 10 min'
  return 'invalid'
}

export const currentPageName = (pathname = '') => {
  if (pathname === '/') return 'Home'
  if (pathname === '/tests') return 'Tests'
  if (pathname === '/account') return 'Account'
  if (pathname === '/email-verified') return 'Email verified'
  if (pathname === '/patients') return 'Patients'
  if (pathname === '/signup') return 'Signup'
  if (pathname === '/square') return 'Square'
  if (pathname === '/add-patient') return 'Add patient'

  if (pathname.startsWith('/callback')) return 'User Redirected'

  if (pathname.startsWith('/visits')) return 'Visits'
  if (pathname.includes('/view-result')) return 'External test results'
  if (pathname.startsWith('/tests/')) return 'Test Results'
  if (pathname.includes('/update-patient-field')) return 'Update patient'
  if (pathname.includes('/order-prescription')) return 'Prescription'
  if (pathname.includes('test-details')) return 'Test details'

  if (pathname.startsWith('/register')) {
    if (pathname.includes('patient-info')) return 'New kit - Patient info'
    if (pathname.includes('screening')) return 'New kit - Screening'
    if (pathname.includes('insurance')) return 'New kit - Insurance'
    if (pathname.includes('sample-collection')) return 'New kit - Collection'
    if (pathname.includes('review')) return 'New kit - Review'
    if (pathname.includes('success')) return 'New kit - Success'
    return 'New kit - Kit details'
  }

  if (pathname.startsWith('/after-care')) {
    if (pathname.includes('instruction')) return 'Aftercare - Instructions'
    if (pathname.includes('treatment')) return 'Aftercare - Treatment'
    if (pathname.includes('history')) return 'Aftercare - History'
    if (pathname.includes('medical-eligibility')) return 'Aftercare - Medical eligibility'
    if (pathname.includes('eligibility')) return 'Aftercare - Eligibility'
    if (pathname.includes('contacts')) return 'Aftercare - Contacts'
    if (pathname.includes('upload')) return 'Aftercare - Upload'
    if (pathname.includes('review')) return 'Aftercare - Review'
    if (pathname.includes('success')) return 'Aftercare - Success'
  }

  if (pathname.startsWith('/program')) {
    if (pathname.includes('bmi-calculator')) return 'Programs - MWL - BMI calculator'
    if (pathname.includes('ineligible')) return 'Programs - MWL - Ineligible'
    if (pathname.includes('progression-graph')) return 'Programs - MWL - Progression graph'
    if (pathname.includes('mwl-eligible')) return 'Programs - MWL - Is Eligible'
    if (pathname.includes('following-apply')) return 'Programs - MWL - Following apply'
    if (pathname.includes('medical-condition')) return 'Programs - MWL - Medical condition'
    if (pathname.includes('additional-details')) return 'Programs - MWL - Additional details'
    if (pathname.includes('you-are-eligible')) return 'Programs - MWL - You are eligible'
    if (pathname.includes('authenticate')) return 'Programs - MWL - Authenticate'
  }

  if (pathname.startsWith('/simple-weight-plan')) {
    if (pathname.includes('consult-details')) return 'Simple weight plan - Consult details'

    if (pathname.includes('medical-notes')) {
      if (pathname.endsWith('medical-notes')) return 'Simple weight plan - Medical notes - Summary'
      if (pathname.endsWith('medical')) return 'Simple weight plan - Medical notes - Medical'
      if (pathname.endsWith('management')) return 'Simple weight plan - Medical notes - Management'
      if (pathname.endsWith('care-coaching')) return 'Simple weight plan - Medical notes - Care coaching'
    }

    if (pathname.includes('refill-intake')) {
      if (pathname.includes('step')) return `Simple weight plan - Refill intake - Step ${pathname.split('step')[1]}`
      if (pathname.includes('ineligible')) return 'Simple weight plan - Refill intake - Ineligible'
      if (pathname.includes('support-details')) return 'Simple weight plan - Refill intake - Support details'
      if (pathname.includes('shipping-details')) return 'Simple weight plan - Refill intake - Shipping details'
    }

    if (pathname.includes('more-info')) {
      if (pathname.includes('step')) return `Simple weight plan - Intake Form - Step ${pathname.split('step')[1]}`
      if (pathname.includes('payment-success')) return 'Simple weight plan - Intake Form - Payment success'
      if (pathname.includes('terms-conditions')) return 'Simple weight plan - Intake Form - Terms and conditions'
    }

    if (pathname.includes('connect')) {
      if (pathname.includes('/async')) {
        if (pathname.endsWith('async')) return 'Simple weight plan - Connect - Async - Landing'
        if (pathname.includes('/what-is-next')) return 'Simple weight plan - Connect - Async - What is next'

        if (pathname.includes('/care-coaching')) {
          if (pathname.endsWith('care-coaching')) return 'Simple weight plan - Connect - Async - Intro'

          if (pathname.includes('book-care-coaching')) {
            if (pathname.includes('billing-info')) return 'Simple weight plan - Connect - Async - Book care coaching - Billing info'
            if (pathname.includes('payment-preview')) return 'Simple weight plan - Connect - Async - Book care coaching - Payment preview'
            if (pathname.includes('appointment')) {
              if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Async - Book care coaching - Choose slot'
              if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Async - Book care coaching - Confirm slot'
            }
          }
        }
      }

      if (pathname.includes('/sync')) {
        if (pathname.endsWith('sync')) return 'Simple weight plan - Connect - Sync - Landing'

        if (pathname.includes('/care-coaching')) {
          if (pathname.endsWith('care-coaching')) return 'Simple weight plan - Connect - Sync - Intro'

          if (pathname.includes('/book-care-coaching')) {
            if (pathname.includes('billing-info')) return 'Simple weight plan - Connect - Sync - Book care coaching - Billing info'
            if (pathname.includes('payment-preview')) return 'Simple weight plan - Connect - Sync - Book care coaching - Payment preview'
            if (pathname.includes('what-is-next')) return 'Simple weight plan - Connect - Sync - Book care coaching - What is next'

            if (pathname.includes('/appointment')) {
              if (pathname.endsWith('appointment')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment'
              if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Choose slot'
              if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Confirm slot'
            }
          }

          if (pathname.includes('/appointment')) {
            if (pathname.endsWith('appointment')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment'
            if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Choose slot'
            if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Confirm slot'
          }
        }

        if (pathname.includes('/appointment')) {
          if (pathname.endsWith('appointment')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment'
          if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Sync - Book Appointment - Choose slot'
          if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Sync - Book Appointment - Confirm slot'
        }

        if (pathname.includes('/reschedule')) {
          if (pathname.includes('/co/')) {
            if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Sync - Reschedule - Consult - Choose slot'
            if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Sync - Reschedule - Consult - Confirm slot'
          }

          if (pathname.includes('/cc/')) {
            if (pathname.includes('/book-care-coaching')) {
              if (pathname.includes('billing-info')) return 'Simple weight plan - Connect - Sync - Book care coaching - Billing info'
              if (pathname.includes('payment-preview')) return 'Simple weight plan - Connect - Sync - Book care coaching - Payment preview'
              if (pathname.includes('what-is-next')) return 'Simple weight plan - Connect - Sync - Book care coaching - What is next'

              if (pathname.includes('/appointment')) {
                if (pathname.endsWith('appointment')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment'
                if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Choose slot'
                if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Confirm slot'
              }
            }

            if (pathname.includes('/appointment')) {
              if (pathname.endsWith('appointment')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment'
              if (pathname.includes('choose-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Choose slot'
              if (pathname.includes('confirm-slot')) return 'Simple weight plan - Connect - Sync - Book care coaching - Book Appointment - Confirm slot'
            }
          }
        }

        if (pathname.includes('/what-is-next')) return 'Simple weight plan - Connect - Sync - What is next'
      }
    }

    if (pathname.includes('pause-program')) {
      if (pathname.includes('reason')) return 'Simple weight plan - Pause program - Reason'
    }

    if (pathname.includes('personalised-plan')) return 'Simple weight plan - Consult - Personalised plan'
    if (pathname.includes('choose-medication')) return 'Simple weight plan - Consult - Choose medication'
    if (pathname.includes('shipping-info')) return 'Simple weight plan - Consult - Shipping info'
    if (pathname.includes('review')) return 'Simple weight plan - Consult - Review'
  }

  // Default return if no matches found
  return pathname
}
