import * as yup from 'yup'

export const siteValidationSchema = yup.object().shape({
  siteCode: yup.string().required('Please enter the site code')
})

export const validationSchema = yup.object().shape({
  siteCode: yup.string().required('Please enter the site code'),
  correctOrganization: yup
    .string().nullable()
    .required('Please confirm your organization'),
  disclaimerConsentAgree: yup
    .boolean()
    .oneOf([true], 'Please agree to the disclaimer and consent'),
  fullName: yup.string(),
  signBase64: yup.string().when('fullName', fullName => {
    if (!fullName) return yup.string().required('Please sign above or write your name below')
  })
})
