import { api } from 'Core'
import { Alert, Button, CircularProgress, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { FormikProps, useFormik } from 'formik'
import { GeoCodeResponse } from 'types/GeoCodeResponse'
import { Heading } from '.'
import { LoadingButton } from '@mui/lab'
import { MultiStepFormNavigation, useMultiStepForm } from 'components/MultiStepForm'
import { PrEPPharmacy, PrEPProgramConfig, TPrepProgramConfigSite } from 'types/db/SiteI'
import { showSnackBar } from 'state/actions'
import { TPrepProgram, prepPharmacySchema } from './schemas'
import { TRegisteredPrepProgram } from 'types/db/RegisteredPrepProgram'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import DOMPurify from 'dompurify'
import GeoCode from 'react-geocode'
import haversine from 'haversine'
import React, { useEffect, useState } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import PharmaciesMap from './components-prep/PharmaciesMap'

const PharmacySelection = ( { prepProgramConfig, site }: Props ) => {
  const { data, update } = useMultiStepForm<TPrepProgram>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const pharmacySelectionContent = prepProgramConfig?.content?.pharmacySelection || {}

  const { mutate, isLoading } = useMutation(
    api.registeredPrepProgram.register,
    {
      onSuccess: ( data ) => {
        update( { ...data.registeredPrepProgram.formData, id: data.registeredPrepProgram?.id } )
        window.sessionStorage.removeItem( 'prep-program' )
        navigate( '../enroll-complete' )
      },
      onError: ( error ) => {
        console.log( { error } )
        dispatch(
          showSnackBar( {
            show: true,
            message: 'Something went wrong. Please try again.',
            severity: 'error'
          } )
        )
      }
    }
  )

  const formik: FormikProps<PrEPPharmacy> = useFormik( {
    initialValues: data.pharmacy,
    validationSchema: prepPharmacySchema,
    onSubmit: ( formData ) => {
      update( { ...data, pharmacy: formData } )

      const updatedData: TRegisteredPrepProgram = {
        siteId: site?.id,
        patientId: data?.patientSelection?.patientId || NaN,
        formData: {
          ...data,
          pharmacy: formData
        }
      }
      mutate( updatedData )
    }
  } )

  return (
    <Stack gap={2} component='form' onSubmit={formik.handleSubmit}>
      <Heading>{pharmacySelectionContent.title || ''}</Heading>
      <Stack
        component="img"
        src={`/assets/${pharmacySelectionContent?.img?.src || ''}`}
        alt={pharmacySelectionContent?.img?.alt || ''}
        sx={{ m: 'auto', mb: '17px' }}
      />
      <Stack>
        <Typography variant="h6" component="h6">
          {pharmacySelectionContent?.whatNext?.title || ''}
        </Typography>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( pharmacySelectionContent?.whatNext?.body || '', { ADD_ATTR: [ 'target' ] } ) }} ></Typography>
      </Stack>

      <PrEPPharmacySelectionForm formik={formik} prepProgramConfig={prepProgramConfig} />

      <MultiStepFormNavigation
        next={
          <LoadingButton
            disabled={isLoading || !formik.isValid || ( Object.keys( formik.errors ).length > 0 ) || ( Object.keys( formik.values ).some( ( field ) => !formik.values[ field as keyof PrEPPharmacy ] ) )}
            variant="contained"
            loading={isLoading}
            type='submit'
          >
            Submit
          </LoadingButton>
        }
      />
    </Stack>
  )
}

const PrEPPharmacySelectionForm = ( { formik, prepProgramConfig }: FormProps ) => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || ''
  const pharmacies = prepProgramConfig?.pharmacies || []

  const [ isGeolocationEnabled, setIsGeolocationEnabled ] = useState<boolean>( true )
  const [ isLoading, setIsLoading ] = useState<boolean>( false )
  const [ numberOfDisplayedPharmacies, setNumberOfDisplayedPharmacies ] = useState<number>( 5 )
  const [ currentPosition, setCurrentPosition ] = useState<{ lat: number, lng: number } | null>( null )
  const [ sortedPharmacies, setSortedPharmacies ] = useState<PrEPPharmacy[]>( pharmacies )
  const [ zip, setZip ] = useState( formik.values.zip || '' )

  useEffect( () => {
    if ( navigator?.geolocation && !zip ) {
      navigator.geolocation.getCurrentPosition(
        ( position ) => {
          const cPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          // Sort pharmacies by distance from current location
          const sortedPharmacies = sortAndFilterPharmacies( pharmacies, cPosition )

          setCurrentPosition( cPosition )
          setSortedPharmacies( sortedPharmacies )
        }
      )
    }
  }, [] )

  useEffect( () => {
    GeoCode.setApiKey( googleApiKey )
    GeoCode.setLanguage( 'en' )
    GeoCode.setRegion( 'us' )

    if ( navigator?.permissions ) {
      navigator.permissions.query( { name: 'geolocation' } ).then( ( result ) => {
        if ( result.state !== 'granted' ) {
          setIsGeolocationEnabled( false )
        }
      } )
    }

  }, [] )

  useEffect( () => {
    if ( zip.length < 3 || !zip ) setSortedPharmacies( pharmacies )

    if ( zip.length === 5 ) {
      ( async () => {
        try {
          setIsLoading( true )
          const response: GeoCodeResponse = await GeoCode.fromAddress( zip )
          if ( !( response?.results?.length > 0 ) ) {
            return
          }

          const { lat, lng } = response.results[ 0 ].geometry.location
          const sortedPharmacies = sortAndFilterPharmacies( pharmacies, { lat, lng } )

          setSortedPharmacies( sortedPharmacies )
        } catch ( error ) {
          console.log( { error } )
          setSortedPharmacies( [] )
        } finally {
          setIsLoading( false )
        }
      } )()
    }
  }, [ zip ] )

  const onSelectionChange = ( value: string ) => {
    const selectedPharmacy = pharmacies.find( ( pharmacy ) => +pharmacy.zip === +value )
    if ( selectedPharmacy ) {
      formik.setValues( selectedPharmacy )
      setCurrentPosition( selectedPharmacy.coordinates )
    }
  }

  const onSelectionChangeFromMap = ( value: string ) => {
    const selectedPharmacy = pharmacies.find( ( pharmacy ) => +pharmacy.zip === +value )
    if ( selectedPharmacy ) {
      formik.setValues( selectedPharmacy )
      // When a pharmacy is selected from the map, move it to the top of the list
      setSortedPharmacies( [ selectedPharmacy, ...sortedPharmacies.filter( ( pharmacy ) => pharmacy.zip !== selectedPharmacy.zip ) ] )

    }
  }

  const moveMapTo = () => {
    if ( navigator?.geolocation ) {
      navigator.geolocation.getCurrentPosition(
        ( position ) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          // Sort pharmacies by distance from current location
          const sortedPharmacies = sortAndFilterPharmacies( pharmacies, currentPosition )

          setCurrentPosition( currentPosition )
          setSortedPharmacies( sortedPharmacies )
          setZip( '' )
        }
      )
    }
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="h6">Get Tested At A Nearby Location</Typography>

      <TextField
        id='user-zip'
        placeholder='Enter your zip code'
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          maxLength: 5,

        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLoading ? <CircularProgress size='1rem' /> : <SearchRoundedIcon />}
            </InputAdornment>
          )
        }}
        name='user-zip'
        label='ZIP Code'
        variant='outlined'
        value={zip}
        onChange={( e ) => {
          const onlyNums = e.target.value.replace( /[^0-9]/g, '' )
          setZip( onlyNums )
        }}
      />

      {
        isGeolocationEnabled
          ? ( <Typography variant='body1' sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={moveMapTo}>Locate Me</Typography> )
          : <Alert severity="warning">To improve our suggestions, please enable location services in your browser settings.</Alert>
      }

      {
        Object.keys( formik.errors ).length > 0
          ? <Alert severity="error">Please select a pharmacy.</Alert>
          : null
      }

      {
        sortedPharmacies.length > 0
          ? ( <>
            <PharmaciesMap
              coordinates={currentPosition}
              pharmacies={sortedPharmacies}
              formik={formik}
              onSelectionChange={onSelectionChangeFromMap}
            />

            <FormControl >
              <RadioGroup
                name='pharmacy'
                value={formik.values.zip || ''}
                onChange={( e, value ) => onSelectionChange( value )}
              >
                {
                  sortedPharmacies.slice( 0, numberOfDisplayedPharmacies ).map( ( pharmacy: PrEPPharmacy, idx: number ) => (
                    <FormControlLabel
                      key={`${pharmacy.zip}-${idx}`}
                      value={pharmacy.zip}
                      control={<Radio />}
                      label={
                        <Stack direction="column">
                          <Typography variant="body1" component="span" fontWeight='bold'>{pharmacy.name}</Typography>
                          <Typography variant="body2" component="span">{pharmacy.address}</Typography>
                        </Stack>
                      }
                    />
                  ) )
                }
              </RadioGroup>
            </FormControl>
          </> )
          : ( <Typography variant="body1" component="p">No locations were found near this zipcode or location.</Typography> )
      }



      <Button
        variant='text'
        onClick={() => setNumberOfDisplayedPharmacies( prev => ( prev + 10 ) )}
        disabled={( sortedPharmacies.length <= numberOfDisplayedPharmacies ) || ( sortedPharmacies.length === 0 ) || ( numberOfDisplayedPharmacies === 0 )}
        sx={{ alignSelf: 'center' }}
      >
        Load more options
      </Button>
    </Stack>
  )
}

// Utils
const sortAndFilterPharmacies = ( pharmacies: PrEPPharmacy[] = [], position: { lat: number, lng: number } ) => {
  const sortedPharmacies = pharmacies
    .sort( ( a, b ) => {
      // Sort pharmacies by distance from current location
      const distanceA = haversine( position, { lat: a.coordinates.lat, lng: a.coordinates.lng }, { format: '{lat,lng}' } )
      const distanceB = haversine( position, { lat: b.coordinates.lat, lng: b.coordinates.lng }, { format: '{lat,lng}' } )

      return distanceA - distanceB
    } )
    .filter( ( pharmacy ) => {
      // Filter out pharmacies that are more than X miles away
      const distance = haversine( position, { lat: pharmacy.coordinates.lat, lng: pharmacy.coordinates.lng }, { format: '{lat,lng}', unit: 'mile' } )

      return distance <= 100
    } )

  return sortedPharmacies
}

interface FormProps {
  formik: FormikProps<PrEPPharmacy>
  prepProgramConfig: PrEPProgramConfig
}

interface Props {
  prepProgramConfig: PrEPProgramConfig,
  site: TPrepProgramConfigSite
}

export default PharmacySelection