import React, { useEffect } from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import { Alert, Button, Stack, Typography } from '@mui/material'
import { useTestingProgram } from '.'
import { useMutation } from '@tanstack/react-query'
import { api } from 'Core'
import useAuth0 from 'hooks/useAuth0'
import { getAffiliate } from 'utils/affiliateUtilFunctions'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader'

function OrderComplete () {
  const { jsonData } = useTestingProgram()
  const navigate = useNavigate()
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation(async () => {
    const response = await api.auth.sendVerificationMailLink({ user, affiliate: getAffiliate() })
    if (response.success) {
      window.sessionStorage.setItem('emailVerified', 'onProgress')
      dispatch(
        showSnackBar({
          show: true,
          message: t('dashboard.verifyBlock.snackbar.success'),
          severity: 'success'
        })
      )
    }
  }
  )

  const handleSendVerificationMail = () => {
    mutate()
  }

  useEffect(() => {
    if (!user) {
      const timer = setTimeout(() => {
        if (!user) {
          navigate('../your-situation')
        }
      }, 5000)

      return () => {
        clearTimeout(timer)
      }
    }
    // Initialize Beacon
    // @ts-ignore
    window.Beacon('show-message', jsonData.orderComplete.feedback_id, { delay: 1000 })
  }, [user])

  if (!user) {
    return <Loader/>
  }

  return (
    <Stack>
      <LayoutWisFlow headerText={''}>
        <Stack gap={3}>
          <Stack>
            <Stack
              component="img"
              src={`/assets/${jsonData.orderComplete.img.src}`}
              alt={jsonData.orderComplete.img.alt}
              sx={{ m: 'auto' }}
            />
            <Alert
              severity="success"
              sx={{
                '& .MuiAlert-icon': {
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
            >
              <Typography variant="body1">
                {jsonData.orderComplete.alert.textBody}
              </Typography>
            </Alert>
          </Stack>

          <Typography variant="body1">
            {jsonData.orderComplete.textBody}
          </Typography>

          {user?.email_verified
            ? <Button fullWidth variant='outlined' onClick={() => navigate('/')}>{jsonData.orderComplete.btnTextHome}</Button>
            : <>
              <Typography variant="h6" component="h6">
                {jsonData.orderComplete.whatNext.title}
              </Typography>
              <Alert
                severity="info"
                sx={{
                  '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center'
                  }
                }}
              >
                <Typography variant="body1">
                  {jsonData.orderComplete.whatNext.alertBody1}
                  <u>{user?.email}</u>.{' '}{jsonData.orderComplete.whatNext.alertBody2}
                </Typography>
              </Alert>
              <LoadingButton
                onClick={handleSendVerificationMail}
                loading={isLoading}
                variant="outlined"
                fullWidth
              >
                {jsonData.orderComplete.btnText}
              </LoadingButton></>}
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

OrderComplete.propTypes = {}

export default OrderComplete
