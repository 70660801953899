import { checkVisibilityMatch, LinkButton, visibilityStates } from '../utils'
import { useNavLinks } from './useNavLinks'
import React from 'react'
import useAuth0 from 'hooks/useAuth0'
import { UserPermissionsI } from '..'

export const NavLinks = ({ userPermissions, isMobile }: Props) => {
  const { user } = useAuth0()
  const navLinksActions = useNavLinks()

  if (userPermissions.page?.some(page => page === visibilityStates.page.accountPage)) return <></>
  if (isMobile) return <></>
  if (!user) return null

  // Inbox page is displayed only in mobile view
  const filteredNavLinkActions = navLinksActions.filter(nav => nav.navigateTo !== '/inbox')

  return (
    <>
      {
        filteredNavLinkActions.map(action => {
          if (!checkVisibilityMatch({
            userPermissions,
            componentRestrictions: action.visibleOn
          })) return null

          return (
            <LinkButton to={action.navigateTo} key={action?.title} onClick={action?.onClick ? action.onClick : null} target={action.target}>
              {action.title}
            </LinkButton>
          )
        })
      }
    </>
  )
}

// interfaces
export interface Props {
  userPermissions: UserPermissionsI
  isMobile: boolean
}
