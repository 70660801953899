import React, { useCallback, useState } from 'react'
import { Stack } from '@mui/system'
import {
  IconButton,
  Typography,
  TextField,
  Dialog,
  FormControl,
  Alert,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  CircularProgress,
  Box,
  Divider,
  Radio
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useFormik from 'hooks/useFormik'
import { LoadingButton } from '@mui/lab'
import { Paper, RadioGroup, TextButton } from 'components'
import { getPatientIdFromMapper, toUpperOnChange } from 'utils/utilFuntions'
import { api } from 'Core'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// import patientPortalApi from 'Core/patientPortalApi'
import SignatureBox from 'components/SignatureBox'
import _ from 'lodash'
import { validationSchema } from './utils'
import RichTextEditor, { createEditorStateFromRaw } from 'components/RichTextEditor'
import DOMPurify from 'dompurify'
import { Trans, useTranslation } from 'react-i18next'
import { useWhiteLabelConfig } from '../../utils/white-label/WhiteLabelConfig'

export const AddSiteAffiliationDialog = ({ open, closeDialog, patient }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={closeDialog}>
      <Paper>
        <Stack flexDirection='column' gap={2}>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Typography variant='h4' fontWeight='bold'>
              {t('registerPatient.addSiteAffiliationDialog.title')}
            </Typography>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <AddSiteAffiliation patient={patient} closeDialog={closeDialog} />
        </Stack>
      </Paper>
    </Dialog>
  )
}

const AddSiteAffiliation = ({ patient, closeDialog = null }) => {
  const whitelabelConfig = useWhiteLabelConfig()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isAlreadyExist, setIsAlreadyExist] = useState(false)

  const validateSiteCodeMutation = useMutation(api.site.getSiteFromCode, {
    onMutate: () => setIsAlreadyExist(false),
    onSuccess: response => {
      const site = response.data
      if (!site) return

      const idMapper = getPatientIdFromMapper(patient.idMapper, site)

      setIsAlreadyExist(!!idMapper)
    }
  })

  const site = validateSiteCodeMutation.data?.data

  const validateSiteCode = useCallback(
    _.debounce(validateSiteCodeMutation.mutate, 300),
    []
  )

  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: {
      siteCode: '',
      correctOrganization: null,
      disclaimerConsentAgree: false,
      fullName: '',
      signBase64: ''
    },
    validationSchema,
    onSubmit: async () => {
      if (!site) return

      const result = await api.patient.registerPatientToNewSite({
        newSite: site.id,
        patient_id: patient.id
      })

      await queryClient.invalidateQueries(['patients'])

      if (result) {
        dispatch(
          showSnackBar({
            show: true,
            message: t('registerPatient.addSiteAffiliation.snackbar.success'),
            severity: 'success'
          })
        )
      } else {
        dispatch(
          showSnackBar({
            show: true,
            message: `${t('registerPatient.addSiteAffiliation.snackbar.error')} ${result?.error}`,
            severity: 'error'
          })
        )
      }
      if (closeDialog) closeDialog()
    }
  })

  const handleSiteCodeChange = evt => {
    toUpperOnChange(evt)
    formik.handleChange(evt)

    if (evt.target.value) {
      validateSiteCode(evt.target.value)
    }

    formik.setValues({
      ...formik.values,
      siteCode: evt.target.value,
      correctOrganization: null
    })
  }

  const showDisclaimer = formik.values.correctOrganization === 'Yes'

  return (
    <Stack
      component='form'
      autoComplete='off'
      onSubmit={formik.handleSubmit}
      gap={2}
    >
      <Typography variant='body1'>
        {t('registerPatient.addSiteAffiliation.enterSiteCode')}{' '}
      </Typography>
      <TextField
        id='site-code'
        name='siteCode'
        label='Enter site code'
        value={formik.values.siteCode}
        onChange={handleSiteCodeChange}
        helperText={formik.getError('siteCode')}
        error={formik.getError('siteCode')}
        InputProps={{
          endAdornment: validateSiteCodeMutation.isLoading
            ? (
              <CircularProgress size={16} />
              )
            : null
        }}
      />

      {formik.values.siteCode && !validateSiteCodeMutation.isLoading
        ? (
            site
              ? (
                  isAlreadyExist
                    ? (
                      <Alert severity='error'>
                        {t('registerPatient.addSiteAffiliation.patientAlreadyExist', { sitecode: formik.values.siteCode, email: whitelabelConfig.emailForSupport || process.env.REACT_APP_SUPPORT_EMAIL })}
                      </Alert>
                      )
                    : (
                      <RadioGroup
                        aria-labelledby='correct-organization'
                        name='correctOrganization'
                        value={formik.values.correctOrganization}
                        onChange={(_evt, option) => {
                          formik.setFieldValue('correctOrganization', option)
                        }}
                      >
                        <FormControl
                          sx={{ gap: 1 }}
                          error={formik.isError('correctOrganization')}
                        >
                          <Typography component='label' htmlFor='site-code' dangerouslySetInnerHTML={{
                            __html: t('registerPatient.addSiteAffiliation.yourOrg', { sitename: site.name, sitecode: site.code })
                          }}>
                           {/* {t('registerPatient.addSiteAffiliation.is')}{' '}
                           <Typography
                              component='span'
                              sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                            >
                              {site.name} ({site.code})
                            </Typography>{' '} */}
                          </Typography>
                          <FormControlLabel control={<Radio />} label={t('account.resetPassword.yes')} value='Yes' />
                          <FormControlLabel control={<Radio />} label={t('account.resetPassword.no')} value='No' />
                        </FormControl>
                      </RadioGroup>
                      )
                )
              : (
                <Alert severity='error'>
                 {t('registerPatient.addSiteAffiliation.notIdentifySiteCode', {
                   email: whitelabelConfig?.emailForSupport || process.env.REACT_APP_SUPPORT_EMAIL
                 })}
                </Alert>
                )
          )
        : null}

      {formik.values.correctOrganization === 'No'
        ? (
          <Alert severity='error'>
            {t('registerPatient.addSiteAffiliation.notAffiliate', {
              email: whitelabelConfig?.emailForSupport || process.env.REACT_APP_SUPPORT_EMAIL
            })}
          </Alert>
          )
        : null}

      {showDisclaimer
        ? (
          <DisclaimerAndConsent formik={formik} site={site} />
          )
        : null}

      <LoadingButton
        type='submit'
        loading={formik.isSubmitting}
        fullWidth
        variant='contained'
        disabled={!showDisclaimer}
      >
        {t('registerPatient.button.submit')}
      </LoadingButton>
    </Stack>
  )
}

const DisclaimerAndConsent = ({ formik, site }) => {
  const [openDisclaimer, setOpenDisclaimer] = useState(false)
  const [openConsent, setOpenConsent] = useState(false)
  const { t } = useTranslation()
  return (
    <Stack gap={2}>
      <Typography variant='h5'>{t('registerPatient.disclaimerAndConsent')}</Typography>
      <FormControl error={formik.isError('disclaimerConsentAgree')}>
        <FormControlLabel
          control={
            <Checkbox
              name='disclaimerConsentAgree'
              id='disclaimerConsentAgree'
              checked={formik.values.disclaimerConsentAgree}
              onChange={formik.handleChange}
              sx={{
                color: formik.isError('disclaimerConsentAgree')
                  ? '#EB0017'
                  : '#212121'
              }}
            />
          }
          sx={{ textAlign: 'start' }}
          label={
            <Typography>
             <Trans i18nKey='registerPatient.disclaimer.readAndAgreeTo'>
                I have read and agree to the <TextButton onClick={() => setOpenDisclaimer(true)}>disclaimer</TextButton> and <TextButton onClick={() => setOpenConsent(true)}>consent</TextButton>
              </Trans>
            </Typography>
          }
        />
        {formik.isError('disclaimerConsentAgree')
          ? (
            <FormHelperText>
              {t(formik.getError('disclaimerConsentAgree'))}
            </FormHelperText>
            )
          : null}
      </FormControl>
      <SignatureBox
        label={t('registerPatient.disclaimer.signHere')}
        onSave={signBase64 => formik.setFieldValue('signBase64', signBase64)}
        onClear={() => formik.setFieldValue('signBase64', '')}
        error={formik.getError('signBase64')}
      />
      <FormControl sx={{ gap: 1 }}>
        <Typography component='label' htmlFor='site-code'>
          {t('registerPatient.disclaimer.signatureInstructions')}
        </Typography>
        <TextField
          id='site-code'
          name='fullName'
          label={t('registerPatient.disclaimer.signFullname')}
          value={formik.values.fullName}
          onChange={evt => {
            formik.handleChange(evt)
          }}
        />
      </FormControl>

      <DisclaimerDialog
        open={openDisclaimer}
        onClose={() => setOpenDisclaimer(false)}
        site={site}
      />
      <ConsentDialog
        open={openConsent}
        onClose={() => setOpenConsent(false)}
        site={site}
      />
    </Stack>
  )
}

function DisclaimerDialog ({ open, onClose, site }) {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={1} sx={{ textAlign: 'center' }}>
        <Typography variant='h6'>{site.name}</Typography>
        {site.address}
      </Box>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: '8px', top: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <Box p={1}>
        <Typography variant='h5' sx={{ fontWeight: 700, textAlign: 'center' }}>
          <Box component='span' sx={{ fontWeight: 'normal' }}>
            {t('registerPatient.disclaimer.dialog.shk')}{' '}
          </Box>
          {t('registerPatient.disclaimer.dialog.text')}
        </Typography>
        {
          site?.disclaimer
            ? (
                <RichTextEditor editorState={createEditorStateFromRaw(site?.disclaimer)} readOnly />
              )
            : (
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(site?.patient_disclaimer) }} />
              )
        }
      </Box>
    </Dialog>
  )
}

export function ConsentDialog ({ open, onClose, site }) {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={1} sx={{ textAlign: 'center' }}>
        <Typography variant='h6'>{site?.name}</Typography>
        {site?.address}
      </Box>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: '8px', top: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <Box p={1}>
        <Typography variant='h5' sx={{ fontWeight: 700, textAlign: 'center' }}>
          <Box component='span' sx={{ fontWeight: 'normal' }}>
          {t('registerPatient.disclaimer.dialog.shk')}{' '}
          </Box>
          {t('registerPatient.disclaimer.dialog.consent')}
        </Typography>

        {
          site?.consent
            ? (
                <RichTextEditor editorState={createEditorStateFromRaw(site?.consent)} readOnly />
              )
            : (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(site?.patient_registration_consent)
                }}
              />
              )
        }
      </Box>
    </Dialog>
  )
}
