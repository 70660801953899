import moment from 'moment'
import * as yup from 'yup'

export function removeSubQuestions (question) {
  const { sub, ...questionWithoutSub } = question

  return questionWithoutSub
}

export function flatQuestions (question) {
  let allSubQuestions = [removeSubQuestions(question)]

  if (!question.sub) return allSubQuestions

  const subQuestions = Object.values(question.sub.when).map(
    when => when.questions
  )

  for (const questions of subQuestions) {
    allSubQuestions = [
      ...allSubQuestions,
      ...questions.map(flatQuestions).flat(Infinity)
    ]
  }

  return allSubQuestions
}

export function resetNestedFields (question, formik) {
  const subQuestions = flatQuestions(question)
  for (const question of subQuestions) {
    formik.setFieldValue(question.id, undefined)
  }
}

export function getInitialValues (questions, answers = {}) {
  return questions.reduce((initialValues, question) => {
    const subQuestions = flatQuestions(question)
    let values = { ...initialValues }
    for (const question of subQuestions) {
      const answer = answers[question.id]
      values = {
        ...values,
        [question.id]: answer || (question.config?.value || question?.isArray ? [] : '')
      }
    }
    return values
  }, {})
}

export function getValidationSchema (question, when) {
  /** @type { import('yup').BaseSchema } */
  let schema = question?.isArray ? yup.array().min(1, 'Please select at least 1') : yup.string().nullable()
  const validation = question.config?.validation

  if (!validation) return schema

  if (when) {
    schema = schema.when(when.id, {
      is: givenAnswer => {
        if (when.value === 'anythingChecked') {
          return (givenAnswer || []).length > 0 && !(givenAnswer || []).includes('None of the above')
        }
        return when.value === givenAnswer
      },
      then: getValidationSchema(question)
    })

    return schema
  }

  if (validation.required) {
    schema = schema.required(validation.required)
  }

  if (question.type === 'date') {
    schema = schema.test('is-valid-date', 'Invalid date', function (value) {
      return moment(value).isValid()
    })

    if (validation?.uptoDays !== undefined) {
      schema = schema.test('is-valid-max-date', 'Invalid date', function (value) {
        return moment(value).isBefore(moment().add(validation.uptoDays, 'days'))
      })
    }
  }

  return schema
}

export function buildValidationSchema (questions = [], when) {
  const schema = questions.reduce((schema, question) => {
    let updatedSchema = {
      ...schema,
      [question.id]: getValidationSchema(question, when)
    }
    const whens = question.sub?.when
    if (whens) {
      for (const when of Object.keys(whens)) {
        const schema = buildValidationSchema(whens[when].questions, {
          id: question.id,
          value: when
        })
        updatedSchema = { ...updatedSchema, ...schema }
      }
    }
    return updatedSchema
  }, {})

  return schema
}

export function checkInsuranceEligibility (questions = [], answers) {
  let isEligible = true
  const why = {}

  for (const question of questions) {
    const { insurance, sub } = question
    const answer = answers[question.id]

    if (insurance && insurance.when !== answer) {
      isEligible = false
      Object.assign(why, { [question.id]: insurance.why })
    }

    if (sub) {
      const subQuestion = sub.when[answer]
      if (subQuestion) {
        const result = checkInsuranceEligibility(subQuestion.questions, answers)
        Object.assign(why, result.why)
      }
    }
  }

  return { isEligible, why }
}
