export const cachedQuery = async (item, api) => {
  const data = window.sessionStorage.getItem(item)
  if (data) {
    const expiryToken = window.sessionStorage.getItem(`${item}ExpiryToken`)
    if (Number.parseInt(expiryToken) < (new Date().getTime() - 15 * 60000)) {
      const response = await api()
      window.sessionStorage.setItem(item, JSON.stringify(response))
      window.sessionStorage.setItem(`${item}ExpiryToken`, new Date().getTime().toString())
      return response
    } else {
      const response = JSON.parse(window.sessionStorage.getItem(item))
      return response
    }
  } else {
    const response = await api()
    window.sessionStorage.setItem(item, JSON.stringify(response))
    window.sessionStorage.setItem(`${item}ExpiryToken`, new Date().getTime().toString())
    return response
  }
}
