import React from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import {
  Alert,
  Link as MuiLink,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/material'
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import StoreIcon from '@mui/icons-material/Store'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useTestingProgram } from '.'
import { Link } from 'react-router-dom'
import useAuth0 from 'hooks/useAuth0'
import { isEmpty } from 'lodash'
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded'
import EmailIcon from '@mui/icons-material/Email'

function EligibleFalse () {
  const { jsonData, frequency, age } = useTestingProgram()
  const { user } = useAuth0()

  return (
    <Stack>
      <LayoutWisFlow headerText={jsonData.eligibleFalse.title}>
        <Stack gap={1}>
          <Stack
            component="img"
            src={`/assets/${jsonData.eligibleFalse.img.src}`}
            alt={jsonData.eligibleFalse.img.alt}
            sx={{ m: 'auto' }}
          />
          <Typography variant="body1">
            {jsonData.eligibleFalse.textBody1.split('xxxx')[0]}
            {frequency.orderFreq}
            {jsonData.eligibleFalse.textBody1.split('xxxx')[1]}
            {age}
            {jsonData.eligibleFalse.textBody1.split('xxxx')[2]}
          </Typography>

          {user
            ? (
            <Stack sx={{ fontWeight: 500, my: 2 }} spacing={1}>
              <Typography variant="body1">
               {jsonData.eligibleFalse.notEligibleText3}
              </Typography>
              <MuiLink component={Link} to="../update-patient-info">
                {jsonData.eligibleFalse.updatePatientInfoBtn}
              </MuiLink>
            </Stack>
              )
            : null}
            <Typography variant="h5">{jsonData.eligibleFalse.title2}</Typography>
            {jsonData.eligibleFalse.lowCostSection
              ? (
            <Stack gap={2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
              <Typography variant="h6">
                {jsonData.eligibleFalse.lowCostSection.title}
              </Typography>
              <Typography variant="body1">
                {jsonData.eligibleFalse.lowCostSection.body}
              </Typography>
              <MuiLink
                target="_blank"
                rel="noreferrer"
                href={jsonData.eligibleFalse.lowCostSection.link}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <LocationCityRoundedIcon />
                {jsonData.eligibleFalse.lowCostSection.linkText}
                <LaunchOutlinedIcon
                  fontSize="small"
                  sx={{ fontSize: '17px', mb: '-5px', mx: '5px' }}
                />
              </MuiLink>
            </Stack>
                )
              : null}
          <Typography variant="body1">
            {jsonData.eligibleFalse.resources.title}
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 3 }}>
            {jsonData.eligibleFalse.resources.sources.map((source) => (
              <ListItem key={source.linkText} sx={{ display: 'list-item' }}>
                <MuiLink href={source.url} target="_blank">
                  {source.linkText}
                  <LaunchOutlinedIcon
                    fontSize="small"
                    sx={{
                      fontSize: '17px',
                      mb: '-5px',
                      mx: '5px'
                    }}
                  />
                </MuiLink>
                {source.linkDesc}
              </ListItem>
            ))}
          </List>
          {
            isEmpty(jsonData.eligibleFalse.testing.urlText.trim())
              ? (
              <MuiLink href={jsonData.eligibleFalse.testing.url} target="_blank">
                <ApartmentIcon
                  fontSize="small"
                  sx={{
                    mr: '5px',
                    mb: '-5px'
                  }}
                />
                {jsonData.eligibleFalse.testing.urlText}
              </MuiLink>
                )
              : null
          }
          <Typography variant="body1">
            {jsonData.eligibleFalse.store.title}
          </Typography>
          { isEmpty(jsonData.eligibleFalse.store.url)
            ? ''
            : (
              <MuiLink href={jsonData.eligibleFalse.store.url} target="_blank">
                <StoreIcon
                  fontSize="small"
                  sx={{
                    mr: '5px',
                    mb: '-5px'
                  }}
                />
                {jsonData.eligibleFalse.store.urlText}
                <LaunchOutlinedIcon
                  fontSize="small"
                  sx={{ fontSize: '17px', mb: '-5px', mx: '5px' }}
                />
              </MuiLink>
              )
          }
          <Alert
            severity="info"
            sx={{
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center'
              }
            }}
          >
            <Typography variant="body1">
              {jsonData.eligibleFalse.alert.textBody}
            </Typography>
          </Alert>
          {jsonData.programPause.needHelpSection
            ? (
            <Stack
              sx={{ my: '20px' }}
              component="form"
              // onSubmit={formik.handleSubmit}
              gap={2}
            >
              <Typography variant="h6" component="h6">
                {jsonData.programPause.needHelpSection.title}
              </Typography>
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: jsonData.programPause.needHelpSection.body
                  }}
                />
              </Typography>
              <MuiLink
                href={`mailto:${jsonData.programPause.needHelpSection.email}`}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <EmailIcon />
                {jsonData.programPause.needHelpSection.email}
              </MuiLink>
            </Stack>
              )
            : null}
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

EligibleFalse.propTypes = {}

export default EligibleFalse
