import { LoadingButton } from '@mui/lab'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import Loader from 'components/Loader'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { showSnackBar } from 'state/actions'
import { t } from 'i18next'

const options = [
  'I plan to use the test kit later',
  'I forgot to use the test kit',
  'I lost my kit',
  'I found it hard to collect/return samples',
  'I have visited a clinic or doctor instead',
  'The kit arrived damaged/did not arrive',
  'Other'
]

const FeedbackForm = () => {
  const [formSubmit, setFormSubmit] = useState(false)
  const [desc, setDesc] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token, option } = useParams()
  const [currentOption, setCurrentOption] = useState('Other')

  const { isLoading, data } = useQuery(['free-test-review', token], () =>
    api.feedback.getCheckToken(token, { key: 'freeTestingRemainder' })
  )

  if (isLoading) {
    return <Loader />
  }

  const FormSubmit = async (selectedOption = '', setLoading = true) => {
    try {
      if (setLoading) setFormSubmit(true)
      const createFeedback = await api.feedback.createFeedbackLoggedOut(token, {
        rating: 0,
        description: selectedOption,
        cacheKey: 'freeTestingRemainder',
        feedbackKey: 'freeTestingRemainder'
      })
      if (createFeedback) {
        navigate('../feedback-completed')
        return dispatch(
          showSnackBar({
            severity: 'success',
            message: 'Feedback sent successfully',
            show: true
          })
        )
      } else throw Error('Could not create feedback')
    } catch (error) {
      dispatch(
        showSnackBar({
          show: true,
          severity: 'error',
          message: 'Something went wrong'
        })
      )
      if (setLoading) setFormSubmit(false)
    }
  }

  if (!data) {
    navigate('/')
    dispatch(
      showSnackBar({
        show: true,
        severity: 'error',
        message: 'Invalid token, token might be revoked or expired'
      })
    )
    return <Loader />
  }

  if (option !== '7') {
    FormSubmit(options[Number(option) - 1], false)
    return <Loader />
  }

  if (data && data?.acted) {
    navigate('../feedback-completed')
    return <Loader />
  }

  return (
    <Stack gap={3}>
      <Typography variant="h4" component="h1" sx={{ textAlign: 'center' }}>
        {t('freeTestRemainder.feedback.survey.title')}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {t('freeTestRemainder.feedback.survey.body')}
      </Typography>
      <RadioGroup
        name="feedback"
        onChange={(evt, value) => {
          setCurrentOption(value)
        }}
        sx={{ gap: 2 }}
        value={currentOption}
      >
        {options.map((option, key) => (
          <FormControlLabel
            key={key}
            control={<Radio />}
            label={option}
            value={option}
          />
        ))}
      </RadioGroup>
      {currentOption === 'Other'
        ? (
        <TextField
          sx={{ mr: 1, backgroundColor: 'white', width: '100%' }}
          label="Please describe the reason"
          fullWidth
          onChange={(e) => setDesc(e.target.value)}
          name="Other"
          value={desc}
        />
          )
        : null}
      <LoadingButton
        type="button"
        onClick={() =>
          FormSubmit(currentOption === 'Other' ? desc : currentOption)
        }
        variant="contained"
        fullWidth={true}
        loading={formSubmit}
      >
        Submit
      </LoadingButton>
    </Stack>
  )
}

export default FeedbackForm
