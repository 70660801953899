import { Stack, useMediaQuery } from '@mui/material'
import { Container } from 'components'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import theme from 'theme'
import UnsubscribeCompleted from './UnsubscribeCompleted'
import UnsubscribeReturnKitRemainder from './UnsubscribeReturnKitRemainder'
import NpsHeader from 'pages/NpsReview/NpsHeader'

function UnsubscribeView () {
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <NpsHeader />
      <Container>
        <Stack sx={{ gap: 3 }}>
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 5,
              justifyContent: mobile ? 'center' : 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <Stack
              sx={{
                width: {
                  xs: '100%',
                  sm: 560
                },
                m: 'auto'
              }}
            >
              <Routes>
                <Route
                  path='/unsubscribed'
                  element={<UnsubscribeCompleted />}
                />
                <Route
                  path='/return-kit/:token'
                  element={<UnsubscribeReturnKitRemainder />}
                />
                <Route path='/*' element={<Navigate to='/return-kit' />} />
              </Routes>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export default UnsubscribeView
