import { Alert, Snackbar, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HIDE_SNACKBAR } from 'state/actions'

const SnackBar = () => {
  const { show, severity, message } = useSelector(state => state.snackbar)
  const dispatch = useDispatch()
  const theme = useTheme()
  const tablet = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => dispatch({ type: HIDE_SNACKBAR })
  return (
    <Snackbar open={show} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: tablet ? 'center' : 'right' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar
