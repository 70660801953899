import { Menu } from '@mui/material'
import jwtUtil from 'Core/utils/jwtUtil'
import React from 'react'
import { MenuItems } from '../utils'

export const NavigationOptions = ({ open, anchorEl, handleClose }: Props) => {
  const roles = jwtUtil.getRoles()
  return (
    roles.length && (
      <Menu
        id='navigator-menu'
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItems roles={roles} />
      </Menu>
    )
  )
}

interface Props {
  open: boolean
  anchorEl: EventTarget & HTMLButtonElement | null
  handleClose: () => void
}
