import React, { useState } from 'react';
import LayoutWisFlow from './components/LayoutWisconsonFlow';
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  ListItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { useFormik } from 'formik';
import HelpIcon from '@mui/icons-material/Help';
import Address from 'components/PatientComponents/MedicalData/Address';
import { TestingProgramNavigation, useTestingProgram } from '.';
import { useLocation, useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { api } from 'Core';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import useAuth0 from 'hooks/useAuth0';
import moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { limsFormatDate } from 'utils/utilFuntions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Loader from 'components/Loader';
import {
  gender,
  race,
  ethnicity,
  GenderDialog,
  sexAssignedAtBirth,
} from 'components/PatientComponents/data/dropDownOptions';
import { useTranslation } from 'react-i18next';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.label,
});

function YourInfo() {
  const { isLoading } = useAuth0();

  if (isLoading) return <Loader />;

  return <YourInfoContent />;
}

function YourInfoContent() {
  const { t } = useTranslation();
  const { jsonData, updateData, data } = useTestingProgram();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const isPatientUpdatePath = location.pathname.includes('update-patient-info');

  const genderOptions = gender?.map((data, i) => ({
    label: t(data.name),
    value: data.value,
    info: data.info,
    key: data.key,
    id: i,
  }));

  const raceOptions = race?.map((data, i) => ({
    label: t(data.name),
    value: data.value,
    key: data.key,
    id: i,
  }));

  const ethnicityOptions = ethnicity.map((ethnicity) => ({
    ...ethnicity,
    name: t(ethnicity.name),
  }));

  const sexAssignedAtBirthOptions = sexAssignedAtBirth.map((data, i) => ({
    label: t(data.name),
    value: data.value,
    key: data.key,
    id: i,
  }));

  const [inputValue1, setInputValue1] = useState(
    data.yourInfo?.gender &&
      genderOptions.find((gen) => gen.value === data.yourInfo?.gender)
      ? genderOptions.find((gen) => gen.value === data.yourInfo?.gender)
      : { key: '', value: '', label: '' }
  );
  const [inputValue2, setInputValue2] = useState(
    data.yourInfo?.race &&
      raceOptions.find((rac) => rac.value === data.yourInfo?.race)
      ? raceOptions.find((rac) => rac.value === data.yourInfo?.race)
      : { key: '', value: '', label: '' }
  );
  const [inputValue3, setInputValue3] = useState(
    data.yourInfo?.ethnicity &&
      ethnicityOptions.find((rac) => rac.value === data.yourInfo?.ethnicity)
      ? ethnicityOptions.find((rac) => rac.value === data.yourInfo?.ethnicity)
      : { name: '', value: '', key: '' }
  );
  const [inputValue4, setInputValue4] = useState(
    data.yourInfo?.sexAssignedAtBirth &&
      sexAssignedAtBirthOptions.find(
        (sex) => sex.value === data.yourInfo?.sexAssignedAtBirth
      )
      ? sexAssignedAtBirthOptions.find(
          (sex) => sex.value === data.yourInfo?.sexAssignedAtBirth
        )
      : { name: '', value: '', key: '' }
  );
  const [openGenderDialog, setOpenGenderDialog] = useState(false);

  const [loadPage, setLoadPage] = useState(false);
  const [showPatientForm, setShowPatientForm] = useState(
    user ? data.yourInfo?.selectedPatient || isPatientUpdatePath : true
  );

  const { data: patients, isLoading } = useQuery(
    ['patients-program-testing'],
    async () => {
      const load = window.sessionStorage.getItem('yourInfoLoad');
      if (load === 'true') {
        setLoadPage(true);
      }

      const response = await api.patient.getAll();
      if (load === 'true') {
        if (response.length === 0) {
          formik.setFieldValue('selectedPatient', 'newPatient');
          setShowPatientForm(true);
          setLoadPage(false);
        }
        if (response.length === 1) {
          handleLoggedSetPatient(response[0]);
          setTimeout(() => {
            formik.handleSubmit();
          }, 1000);
        }
        window.sessionStorage.removeItem('yourInfoLoad');
      }

      response.push({
        id: 'newPatient',
        value: (
          <Stack flexDirection="row" gap={3}>
            <PersonAddIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                opacity: 0.54,
              }}
            />
            <Typography>
              {t(
                'registerKit.patientDetails.patientForm.patient.addNewPatient'
              )}
            </Typography>
          </Stack>
        ),
      });
      setLoadPage(false);
      return response;
    },
    {
      enabled: !!user,
    }
  );

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(t('registerPatient.validation.requiredField')),
    lastName: yup
      .string()
      .trim()
      .required(t('registerPatient.validation.requiredField')),
    dob: yup
      .string()
      .required(t('afterCare.eligibility.validation.dob'))
      .test('DOB', t('afterCare.eligibility.validation.dob'), (value) => {
        if (value) {
          const momentFormat = `${value.substring(6)}-${value.substring(
            0,
            2
          )}-${value.substring(3, 5)}`;
          const isDateValid = moment(momentFormat, true).isValid();
          if (isDateValid) {
            return true;
          } else {
            return false;
          }
        }
      }),
    gender: yup
      .string()
      .required(t('registerPatient.validation.requiredField')),
    sexAssignedAtBirth: yup
      .string()
      .test(
        'sexAssignedAtBirth',
        t('registerPatient.validation.requiredField'),
        (value, { parent }) => {
          if (parent.selectedPatient === 'newPatient') {
            if (!value || value.trim() === '') {
              return false;
            }
          }

          return true;
        }
      ),
    race: yup.string().required(t('registerPatient.validation.requiredField')),
    ethnicity: yup
      .string()
      .required(t('registerPatient.validation.requiredField')),
    shippingFname: yup
      .string()
      .trim()
      .required(t('registerPatient.validation.requiredField')),
    shippingLname: yup
      .string()
      .trim()
      .required(t('registerPatient.validation.requiredField')),
    phone: yup
      .string()
      .min(
        10,
        t(
          'afterCare.eligibility.moreDetailForm.validation.phoneValidationErrorMessage'
        )
      )
      .required(t('registerPatient.validation.requiredField')),
    testProgram: yup
      .object({
        addressLine1: yup
          .string()
          .required(t('registerPatient.validation.requiredField')),
        city: yup
          .string()
          .required(t('registerPatient.validation.requiredField')),
        state: yup
          .string()
          .required(t('registerPatient.validation.requiredField')),
        zipCode: yup
          .string()
          .min(5, t('ftp.invalidZipCode'))
          .required(t('registerPatient.validation.requiredField')),
      })
      .required(),
  });

  const validationSchemaStep1 = yup.object({
    selectedPatient: yup
      .object()
      .required(t('registerPatient.validation.requiredField')),
    myselfTck: yup.bool().required().oneOf([true]),
  });

  const initialValue = {
    myselfTck: false,
    selectedPatient: user ? data.yourInfo?.selectedPatient : 'newPatient',
    firstName: data.yourInfo?.firstName || '',
    lastName: data.yourInfo?.lastName || '',
    dob: data.yourInfo?.dob ? data.yourInfo?.dob : data.eligibility?.dob,
    dobPrimary: data.yourInfo?.dob
      ? moment(data.yourInfo?.dob, 'MM-DD-YYYY')
      : moment(data.eligibility?.dob, 'MM-DD-YYYY'),
    gender: data.yourInfo?.gender || '',
    sexAssignedAtBirth: data.yourInfo?.sexAssignedAtBirth || '',
    race: data.yourInfo?.race || '',
    ethnicity: data.yourInfo?.ethnicity || '',
    shippingFname: data.yourInfo?.shippingFname || '',
    shippingLname: data.yourInfo?.shippingLname || '',
    phone: data.yourInfo?.phone || '',
    testProgram: {
      addressLine1: data.yourInfo?.addressLine1 || '',
      addressLine2: data.yourInfo?.addressLine2 || '',
      city: data.yourInfo?.city || '',
      state: data.yourInfo?.state || '',
      zipCode: data.yourInfo?.zipCode
        ? data.yourInfo?.zipCode
        : data.eligibility?.zipcode,
      plus4Code: data.yourInfo?.plus4Code || '',
    },
  };

  const formik = useFormik({
    initialValues: {
      ...initialValue,
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!showPatientForm) {
        setShowPatientForm(true);
        return;
      }
      const response = await api.program.checkProgramPauseStatus(slug);
      if (response.pauseStatus) {
        const programConfigData = queryClient.getQueryData([
          'program-content',
          slug,
        ]);
        queryClient.setQueryData(['program-content', slug], {
          // @ts-ignore
          ...programConfigData,
          isPaused: true,
        });
        navigate('../program-paused');
        return;
      }
      const pattern = new RegExp(jsonData.zipCodeRegex);
      let eligible = true;
      const age = moment().diff(values.dob, 'years');
      if (
        age < jsonData.checkEligibility.age ||
        !pattern.test(values.testProgram.zipCode.toString())
      ) {
        eligible = false;
      }

      if (user && eligible) {
        const dataToSend = {
          firstName: values.firstName,
          lastName: values.lastName,
          dob: limsFormatDate(values.dob),
          gender: values.gender,
          sexAssignedAtBirth: values.sexAssignedAtBirth,
          ethnicity: values.ethnicity,
          race: values.race,
          zipcode: values.testProgram.zipCode,
          state: values.testProgram.state,
          addressLine1: values.testProgram.addressLine1,
          plus4Code: values.testProgram.plus4Code,
          city: values.testProgram.city,
          phonenumber: values.phone,
          address: `${values.testProgram.addressLine1}, ${values.testProgram.city}, ${values.testProgram.state} ${values.testProgram.zipCode}`,
        };

        const isPatientEligible = await api.program.checkPatientEligibility(
          slug,
          dataToSend
        );
        if (!isPatientEligible?.eligible) {
          navigate('../not-eligible');
          return;
        } else if (isPatientEligible?.msg === 'PATIENT_SKU_INELIGIBLE') {
          const programConfigData = queryClient.getQueryData([
            'program-content',
            slug,
          ]);

          const newAllowedSkus = jsonData?.allowedSiteSkus.filter(
            (sku) => !isPatientEligible.ineligibleSkus.includes(sku.skuID)
          );
          queryClient.setQueryData(['program-content', slug], {
            // @ts-ignore
            ...programConfigData,
            allowedSiteSkus: newAllowedSkus,
          });

          updateData((currData) => ({
            ...currData,
            err: 'PATIENT_SKU_INELIGIBLE',
            eligibility: {
              dob: values.dob,
              zipcode: values.testProgram.zipCode,
            },
            yourInfo: {
              selectedPatient: values.selectedPatient,
              addressLine1: values.testProgram.addressLine1,
              addressLine2: values.testProgram.addressLine2,
              city: values.testProgram.city,
              state: values.testProgram.state,
              zipCode: values.testProgram.zipCode,
              plus4Code: values.testProgram.plus4Code,
              firstName: values.firstName,
              lastName: values.lastName,
              dob: values.dob,
              gender: values.gender,
              sexAssignedAtBirth: values.sexAssignedAtBirth,
              race: values.race,
              ethnicity: values.ethnicity,
              shippingFname: values.shippingFname,
              shippingLname: values.shippingLname,
              phone: values.phone,
            },
          }));

          if (!newAllowedSkus.length) {
            return navigate('../not-eligible');
          }

          if (
            !data?.eligibleTrue?.selectedSKU ||
            isPatientEligible.ineligibleSkus.includes(
              data?.eligibleTrue?.selectedSKU
            )
          ) {
            navigate('../select-kit');
            return;
          }
        }
      }

      updateData((currentData) => ({
        ...currentData,
        eligibility: {
          dob: values.dob,
          zipcode: values.testProgram.zipCode,
        },
        yourInfo: {
          selectedPatient: values.selectedPatient,
          addressLine1: values.testProgram.addressLine1,
          addressLine2: values.testProgram.addressLine2,
          city: values.testProgram.city,
          state: values.testProgram.state,
          zipCode: values.testProgram.zipCode,
          plus4Code: values.testProgram.plus4Code,
          firstName: values.firstName,
          lastName: values.lastName,
          dob: values.dob,
          gender: values.gender,
          sexAssignedAtBirth: values.sexAssignedAtBirth,
          race: values.race,
          ethnicity: values.ethnicity,
          shippingFname: values.shippingFname,
          shippingLname: values.shippingLname,
          phone: values.phone,
        },
      }));

      if (eligible) {
        if (!data.eligibleTrue) {
          navigate('../select-kit');
          return;
        }
        if (isPatientUpdatePath) {
          navigate('../your-info');
          return;
        }
        navigate('../review');
        return;
      }
      navigate('../not-eligible');
    },
    validationSchema: showPatientForm
      ? validationSchema
      : validationSchemaStep1,
  });

  const handleLoggedSetPatient = (newValue) => {
    const date = moment(newValue.dob, 'YYYY-MM-DD');
    formik.setValues({
      ...formik.values,
      firstName: newValue.first_name || '',
      lastName: newValue.last_name || '',
      dob: date.format('MM/DD/YYYY') || '',
      dobPrimary: date,
      gender: newValue.gender || '',
      sexAssignedAtBirth: newValue.sex_assigned_at_birth || '',
      race: newValue.race || '',
      ethnicity: newValue.ethnicity || '',
      shippingFname: newValue.first_name || '',
      shippingLname: newValue.last_name || '',
      phone: newValue.phone || '',
      testProgram: {
        addressLine1: newValue.address?.delivery_line_1 || '',
        addressLine2: '',
        state: newValue.address?.state_abbreviation || '',
        city: newValue.address?.city_name || '',
        zipCode: newValue.address?.zipcode || '',
        plus4Code: newValue.address?.plus4Code || '',
      },
      selectedPatient: newValue,
    });
  };

  const PatientDropdown = () => {
    return (
      <Autocomplete
        id="patientInfo"
        disabled={isLoading}
        getOptionLabel={(patient) =>
          patient && patient?.id !== 'newPatient'
            ? `${patient.first_name} ${patient.last_name}`
            : ''
        }
        options={patients || []}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_event, newValue) => {
          if (newValue) {
            if (newValue.id !== 'newPatient') {
              handleLoggedSetPatient(newValue);

              return;
            }
            formik.setValues({
              ...formik.values,
              firstName: '',
              lastName: '',
              dob: data.eligibility?.dob ? data.eligibility?.dob : '',
              gender: '',
              sexAssignedAtBirth: '',
              race: '',
              ethnicity: '',
              shippingFname: '',
              shippingLname: '',
              phone: '',
              testProgram: {
                addressLine1: '',
                addressLine2: '',
                plus4Code: '',
                state: '',
                city: '',
                zipCode: data.eligibility?.zipcode
                  ? data.eligibility?.zipcode
                  : '',
              },
              selectedPatient: 'newPatient',
            });
            setShowPatientForm(true);
          } else {
            formik.setValues({
              ...formik.values,
              selectedPatient: null,
            });
          }
        }}
        value={
          formik.values.selectedPatient === 'newPatient'
            ? null
            : formik.values.selectedPatient || null
        }
        renderInput={(props) => (
          <TextField
            {...props}
            name="patients"
            label={t('registerKit.patientDetails.patientForm.patient.label')}
            error={!!formik.errors.selectedPatient}
            helperText={formik.errors.selectedPatient}
          />
        )}
        renderOption={(props, option) => (
          <ListItem id={option.id} key={option.id} {...props}>
            {option.id === 'newPatient'
              ? option.value
              : `${option.first_name} ${option.last_name}`}
          </ListItem>
        )}
      />
    );
  };

  if (loadPage) {
    return <Loader />;
  }

  return (
    <Stack>
      <LayoutWisFlow
        headerText={
          isPatientUpdatePath
            ? jsonData.updatePatientInfo.title
            : jsonData.yourInfo.title
        }
      >
        <Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
          {jsonData.yourInfo.alertText ? (
            <Alert
              severity="info"
              sx={{
                '& .MuiAlert-icon': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Typography variant="body1">
                {jsonData.yourInfo.alertText}
              </Typography>
            </Alert>
          ) : null}
          {user && !showPatientForm && !isPatientUpdatePath ? (
            <>
              <Typography variant="body1">
                {t('ftp.selectRightPatient')}
              </Typography>
              <PatientDropdown />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.myselfTck}
                    name="myselfTck"
                    onChange={formik.handleChange}
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {jsonData.myselfDisclaimer.labelText}
                  </Typography>
                }
              />
              {formik.errors.myselfTck ? (
                <Alert
                  severity="error"
                  sx={{
                    '& .MuiAlert-icon': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography variant="body1">
                    {jsonData.myselfDisclaimer.errorText}
                  </Typography>
                </Alert>
              ) : (
                ''
              )}
              <TestingProgramNavigation
                back={
                  <Button onClick={() => navigate('../select-kit')}>
                    {t('multiFormNavigation.back')}
                  </Button>
                }
                next={
                  <Button type="submit" variant="contained">
                    {t('multiFormNavigation.next')}
                  </Button>
                }
              />
            </>
          ) : (
            <>
              <Stack gap={2}>
                <Typography variant="h6" component="h6">
                  {jsonData.yourInfo.personalInfo.title}
                  <Tooltip
                    arrow
                    placement="top"
                    title={jsonData.yourInfo.personalInfo.tooltip}
                  >
                    <HelpIcon
                      sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        ml: '10px',
                        mb: '-5px',
                      }}
                    />
                  </Tooltip>
                </Typography>
                {user &&
                showPatientForm &&
                formik.values.selectedPatient !== 'newPatient' ? (
                  <PatientDropdown />
                ) : (
                  <>
                    <TextField
                      label={
                        t('registerPatient.medicalDetails.firstName') + '*'
                      }
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={(e) => {
                        formik.setFieldValue('firstName', e.target.value);
                        formik.setFieldValue('shippingFname', e.target.value);
                      }}
                      error={
                        formik.touched?.firstName && !!formik.errors.firstName
                      }
                      helperText={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                          ? formik.errors.firstName
                          : null
                      }
                      FormHelperTextProps={{
                        sx: { color: 'primary.red' },
                      }}
                    />
                    <TextField
                      label={t('registerPatient.medicalDetails.lastName') + '*'}
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={(e) => {
                        formik.setFieldValue('lastName', e.target.value);
                        formik.setFieldValue('shippingLname', e.target.value);
                      }}
                      error={
                        formik.touched?.lastName && !!formik.errors.lastName
                      }
                      helperText={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                          ? formik.errors.lastName
                          : null
                      }
                      FormHelperTextProps={{
                        sx: { color: 'primary.red' },
                      }}
                    />
                  </>
                )}
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={t('afterCare.eligibility.dob') + '*'}
                        maxDate={moment().valueOf()}
                        value={
                          formik.values.dobPrimary
                            ? moment(formik.values.dobPrimary).toDate()
                            : null
                        }
                        onChange={(newValue) => {
                          const dob = moment(newValue).format('MM-DD-YYYY');
                          formik.setFieldValue('dobPrimary', newValue || '');
                          formik.setFieldValue('dob', dob || '');
                        }}
                        slotProps={{
                          textField: {
                            error: formik.touched?.dob && !!formik.errors.dob,
                            helperText:
                              formik.touched.dob && Boolean(formik.errors.dob)
                                ? formik.errors.dob
                                : null,
                            fullWidth: true,
                            FormHelperTextProps: {
                              sx: { color: 'primary.red' },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {user &&
                  showPatientForm &&
                  formik.values.selectedPatient !== 'newPatient' ? null : (
                    <>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          id="sexAssignedAtBirth"
                          autoComplete={false}
                          filterOptions={filterOptions}
                          options={sexAssignedAtBirthOptions}
                          getOptionLabel={(op) => op.label || ''}
                          value={inputValue4 || null}
                          onChange={(e, newValue) => {
                            if (newValue?.key) {
                              formik.setFieldValue(
                                'sexAssignedAtBirth',
                                newValue?.value
                              );
                              setInputValue4(newValue);
                            } else {
                              formik.setFieldValue('sexAssignedAtBirth', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                t(
                                  'registerPatient.medicalDetails.sexAssigned'
                                ) + '*'
                              }
                              autoComplete="off"
                              onFocus={(event) =>
                                event.target.setAttribute('autocomplete', 'off')
                              }
                              name="sexAssignedAtBirth"
                              error={
                                formik.touched.sexAssignedAtBirth &&
                                Boolean(formik.errors.sexAssignedAtBirth)
                              }
                              helperText={
                                formik.touched.sexAssignedAtBirth &&
                                Boolean(formik.errors.sexAssignedAtBirth)
                                  ? formik.errors.sexAssignedAtBirth
                                  : null
                              }
                              FormHelperTextProps={{
                                sx: { color: 'primary.red' },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Stack>
                          <Autocomplete
                            id="gender"
                            autoComplete={false}
                            filterOptions={filterOptions}
                            isOptionEqualToValue={(option, value) =>
                              option.key === value
                            }
                            getOptionLabel={(option) => option.label}
                            options={genderOptions}
                            value={inputValue1 || null}
                            // inputValue={inputValue1}
                            // onInputChange={(event, newInputValue) => {
                            //   setInputValue1(newInputValue)
                            // }}
                            renderOption={(props, option) => (
                              <Stack sx={{ p: '5px', px: '10px' }} {...props}>
                                <Typography sx={{ width: '100%' }}>
                                  {option.label}
                                </Typography>
                                <Typography
                                  sx={{ width: '100%', color: '#00000099' }}
                                  variant={'caption'}
                                >
                                  {option.info}
                                </Typography>
                              </Stack>
                            )}
                            onChange={(e, newValue) => {
                              if (newValue?.key) {
                                formik.setFieldValue('gender', newValue.value);
                                setInputValue1(newValue);
                              } else {
                                formik.setFieldValue('gender', '');
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  t('registerPatient.medicalDetails.gender') +
                                  '*'
                                }
                                autoComplete="off"
                                onFocus={(event) =>
                                  event.target.setAttribute(
                                    'autocomplete',
                                    'off'
                                  )
                                }
                                name="gender"
                                error={
                                  formik.touched.gender &&
                                  Boolean(formik.errors.gender)
                                }
                                helperText={
                                  formik.touched.gender &&
                                  Boolean(formik.errors.gender)
                                    ? formik.errors.gender
                                    : null
                                }
                                FormHelperTextProps={{
                                  sx: { color: 'primary.red' },
                                }}
                              />
                            )}
                          />
                          <Link
                            onClick={() => setOpenGenderDialog(true)}
                            color="primary"
                            variant="caption"
                            sx={{ pl: '10px' }}
                          >
                            {t(
                              'registerPatient.medicalDetails.learnMoreGender'
                            )}
                          </Link>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          id="race"
                          autoComplete={false}
                          filterOptions={filterOptions}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value
                          }
                          getOptionLabel={(option) => option.label}
                          options={raceOptions}
                          value={inputValue2 || null}
                          // inputValue={inputValue2}
                          // onInputChange={(_event, newInputValue) => {
                          //   setInputValue2(newInputValue)
                          // }}
                          fullWidth
                          onChange={(e, newValue) => {
                            if (newValue?.key) {
                              formik.setFieldValue('race', newValue.value);
                              setInputValue2(newValue);
                            } else {
                              formik.setFieldValue('race', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                t('registerPatient.medicalDetails.race') + '*'
                              }
                              autoComplete="off"
                              onFocus={(event) =>
                                event.target.setAttribute('autocomplete', 'off')
                              }
                              name="race"
                              error={
                                formik.touched.race &&
                                Boolean(formik.errors.race)
                              }
                              helperText={
                                formik.touched.race &&
                                Boolean(formik.errors.race)
                                  ? formik.errors.race
                                  : null
                              }
                              FormHelperTextProps={{
                                sx: { color: 'primary.red' },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          id="ethnicity"
                          autoComplete={false}
                          options={ethnicityOptions}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          // filterOptions={filterOptions}
                          getOptionLabel={(option) => option.name}
                          value={inputValue3 || null}
                          onChange={(_event, newInputValue) => {
                            if (newInputValue) {
                              formik.setFieldValue(
                                'ethnicity',
                                newInputValue.value
                              );
                              setInputValue3(newInputValue);
                            } else {
                              formik.setFieldValue('ethnicity', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                t('registerPatient.medicalDetails.ethnicity') +
                                '*'
                              }
                              name="ethnicity"
                              autoComplete="off"
                              onFocus={(event) =>
                                event.target.setAttribute('autocomplete', 'off')
                              }
                              error={
                                formik.touched.ethnicity &&
                                Boolean(formik.errors.ethnicity)
                              }
                              helperText={
                                formik.touched.ethnicity &&
                                Boolean(formik.errors.ethnicity)
                                  ? formik.errors.ethnicity
                                  : null
                              }
                              FormHelperTextProps={{
                                sx: { color: 'primary.red' },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
              <Stack gap={2}>
                <Typography variant="h6" component="h6">
                  {jsonData.yourInfo.shippingInfo.title}
                </Typography>
                <Typography variant="body1">
                  {jsonData.yourInfo.shippingInfo.tooltip}
                </Typography>

                <TextField
                  label={t('registerPatient.medicalDetails.firstName') + '*'}
                  name="shippingFname"
                  value={formik.values.shippingFname || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.shippingFname &&
                    Boolean(formik.errors.shippingFname)
                  }
                  helperText={
                    formik.touched.shippingFname &&
                    Boolean(formik.errors.shippingFname)
                      ? formik.errors.shippingFname
                      : null
                  }
                  FormHelperTextProps={{
                    sx: { color: 'primary.red' },
                  }}
                />
                <TextField
                  label={t('registerPatient.medicalDetails.lastName') + '*'}
                  name="shippingLname"
                  value={formik.values.shippingLname || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.shippingLname &&
                    Boolean(formik.errors.shippingLname)
                  }
                  helperText={
                    formik.touched.shippingLname &&
                    Boolean(formik.errors.shippingLname)
                      ? formik.errors.shippingLname
                      : null
                  }
                  FormHelperTextProps={{
                    sx: { color: 'primary.red' },
                  }}
                />
                <InputMask
                  mask="9999999999"
                  maskChar=""
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                >
                  {/* @ts-ignore */}
                  {() => (
                    <TextField
                      label={t('registerPatient.medicalDetails.phone') + '*'}
                      name="phone"
                      value={formik.values.phone || ''}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={
                        formik.touched.phone && Boolean(formik.errors.phone)
                          ? formik.errors.phone
                          : null
                      }
                      FormHelperTextProps={{
                        sx: { color: 'primary.red' },
                      }}
                    />
                  )}
                </InputMask>
                <Address
                  formik={formik}
                  copyAddress={false}
                  disabled={false}
                  name="testProgram"
                  defaultCountry="USA"
                  testProgramSetting={true}
                />
              </Stack>
              {isPatientUpdatePath ? (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  {t('common.save')}
                </LoadingButton>
              ) : (
                <TestingProgramNavigation
                  back={
                    <Button onClick={() => navigate('../select-kit')}>
                      {t('multiFormNavigation.back')}
                    </Button>
                  }
                  next={
                    <LoadingButton
                      loading={formik.isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      {t('multiFormNavigation.next')}
                    </LoadingButton>
                  }
                />
              )}
            </>
          )}
        </Stack>
      </LayoutWisFlow>
      <GenderDialog
        open={openGenderDialog}
        onClose={() => setOpenGenderDialog(false)}
      />
    </Stack>
  );
}

YourInfo.propTypes = {};

export default YourInfo;
