import React from 'react'
import SignUp from './SignUp'
import SignIn from './SignIn'
import useAuth0 from 'hooks/useAuth0'
import { CircularProgress } from '@mui/material'

const AuthForm = ({
  formik,
  authComponent,
  setAuthComponent,
  showFirstName,
  showLastName,
  type,
  disableEmail = false,
  title = null,
  mt = 2
}) => {
  const { isLoading, user } = useAuth0()

  if (isLoading) return <CircularProgress size={16} sx={{ margin: '0 auto' }} />
  if (user) return null

  return (
    <>
      {
        authComponent === 'sign-up' && (
          <SignUp
            formik={formik}
            disableEmail={disableEmail}
            setAuthComponent={setAuthComponent}
            showFirstName={showFirstName}
            showLastName={showLastName}
            type={type}
            title={title}
            mt={mt}
          />
        )
      }
      {
        authComponent === 'sign-in' && (
          <SignIn
            formik={formik}
            disableEmail={disableEmail}
            setAuthComponent={setAuthComponent}
            type={type}
            mt={mt}
          />
        )
      }
    </>
  )
}

export default AuthForm
