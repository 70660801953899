import React, { useState } from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import {
  Alert,
  Link,
  List,
  ListItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import StoreIcon from '@mui/icons-material/Store'
import { useTestingProgram } from '.'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { api } from 'Core'
import { useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'
import { isEmpty } from 'lodash'
import EmailIcon from '@mui/icons-material/Email'
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded'

function ProgramPause () {
  const { jsonData } = useTestingProgram()
  const dispatch = useDispatch()
  const { slug } = useParams()

  const [notificationSuccess, showNotificationSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validateOnChange: false,
    validationSchema: yup.object({
      name: yup.string().trim().required('Please enter your full name'),
      email: yup
        .string()
        .trim()
        .email('Invalid email')
        .required('Please enter your email')
    }),
    onSubmit: async (values) => {
      try {
        const dataToSend = {
          slug,
          email: values.email,
          name: values.name
        }
        const response = await api.program.addNewNotifyee(dataToSend)
        if (response.success) {
          dispatch(
            showSnackBar({
              show: true,
              message: jsonData.programPause.snackBar.success,
              severity: 'success'
            })
          )
          showNotificationSuccess(true)
          formik.resetForm()
        }
      } catch (e) {
        console.log(e)
      }
    }
  })

  if (notificationSuccess) {
    return (
      <Stack>
        <LayoutWisFlow
          headerText={jsonData.programPause.notificationSuccess.title}
        >
          <Stack gap={1}>
            <Stack>
              <Stack
                component="img"
                src={`/assets/${jsonData.programPause.notificationSuccess.img.src}`}
                alt={jsonData.programPause.notificationSuccess.img.alt}
                sx={{ m: 'auto' }}
              />
              <Alert
                severity="success"
                sx={{
                  '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center'
                  }
                }}
              >
                <Typography variant="body1">
                  {jsonData.programPause.notificationSuccess.alert.textBody}
                </Typography>
              </Alert>
            </Stack>
            <Typography variant="body1">
              {jsonData.programPause.notificationSuccess.body}
            </Typography>
          </Stack>
        </LayoutWisFlow>
      </Stack>
    )
  }

  return (
    <Stack>
      <LayoutWisFlow headerText={jsonData.programPause.title}>
        <Stack gap={1}>
          <Typography variant="body1">
            {jsonData.programPause.textBody1}
          </Typography>
          <Stack
            component="img"
            src={`/assets/${jsonData.programPause.img.src}`}
            alt={jsonData.programPause.img.alt}
            sx={{ m: 'auto' }}
          />
          <Typography variant="body1">
            {jsonData.programPause.textBody2}
          </Typography>
          {jsonData.programPause.lowCostSection
            ? (
            <Stack gap={2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
              <Typography variant="h6">
                {jsonData.programPause.lowCostSection.title}
              </Typography>
              <Typography variant="body1">
                {jsonData.programPause.lowCostSection.body}
              </Typography>
              <Link
                target="_blank"
                rel="noreferrer"
                href={jsonData.programPause.needHelpSection.email}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <LocationCityRoundedIcon />
                {jsonData.programPause.lowCostSection.linkText}
                <LaunchOutlinedIcon
                  fontSize="small"
                  sx={{ fontSize: '17px', mb: '-5px', mx: '5px' }}
                />
              </Link>
            </Stack>
              )
            : null}
          <Typography variant="body1">
            {jsonData.programPause.resources.title}
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 3 }}>
            {jsonData.programPause.resources.sources.map((source) => (
              <ListItem key={source.linkText} sx={{ display: 'list-item' }}>
                <Link href={source.url} target="_blank">
                  {source.linkText}
                  <LaunchOutlinedIcon
                    fontSize="small"
                    sx={{
                      fontSize: '17px',
                      mb: '-5px',
                      mx: '5px'
                    }}
                  />
                </Link>
                {source.linkDesc}
              </ListItem>
            ))}
          </List>
          <Typography variant="body1">
            {jsonData.programPause.store.title}
          </Typography>
          {isEmpty(jsonData.programPause.store.url)
            ? (
                ''
              )
            : (
            <Link href={jsonData.programPause.store.url} target="_blank">
              <StoreIcon
                fontSize="small"
                sx={{
                  mr: '5px',
                  mb: '-5px'
                }}
              />
              {jsonData.programPause.store.urlText}
              <LaunchOutlinedIcon
                fontSize="small"
                sx={{ fontSize: '17px', mb: '-5px', mx: '5px' }}
              />
            </Link>
              )}
          <Alert
            severity="info"
            sx={{
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center'
              }
            }}
          >
            <Typography variant="body1">
              {jsonData.programPause.alert.textBody}
            </Typography>
          </Alert>
          {jsonData.programPause.notification
            ? (
            <Stack
              sx={{ my: '20px' }}
              component="form"
              onSubmit={formik.handleSubmit}
              gap={2}
            >
              <Typography variant="h6" component="h6">
                {jsonData.programPause.notification.title}
              </Typography>
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: jsonData.programPause.notification.body
                  }}
                />
              </Typography>
              <TextField
                label="Your Full Name *"
                name="name"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched?.name && !!formik.errors.name}
                helperText={
                  formik.touched.name && Boolean(formik.errors.name)
                    ? formik.errors.name
                    : null
                }
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
              />
              <TextField
                label="Your Email Address *"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched?.email && !!formik.errors.email}
                helperText={
                  formik.touched.email && Boolean(formik.errors.email)
                    ? formik.errors.email
                    : null
                }
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
              />
              <LoadingButton
                fullWidth
                loading={formik.isSubmitting}
                type="submit"
                variant="contained"
              >
                {jsonData.programPause.notification.btnText}
              </LoadingButton>
            </Stack>
              )
            : null}
          {jsonData.programPause.needHelpSection
            ? (
            <Stack
              sx={{ my: '20px' }}
              component="form"
              onSubmit={formik.handleSubmit}
              gap={2}
            >
              <Typography variant="h6" component="h6">
                {jsonData.programPause.needHelpSection.title}
              </Typography>
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: jsonData.programPause.needHelpSection.body
                  }}
                />
              </Typography>
              <Link
                href={`mailto:${jsonData.programPause.needHelpSection.email}`}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <EmailIcon />
                {jsonData.programPause.needHelpSection.email}
              </Link>
            </Stack>
              )
            : null}

          {/* <Stack sx={{ my: "20px" }} gap={2}>
            <ExpandGroupComponent
              headerText={jsonData.programPause.expandOptions.title}
              datas={jsonData.programPause.expandOptions.data}
            />
            <Typography variant="body1">
              <div
                dangerouslySetInnerHTML={{
                  __html: jsonData.programPause.expandOptions.footerText
                }}
              />
            </Typography>
          </Stack>
          <Stack sx={{ my: "20px" }} gap={2}>
            <Typography variant="h6" component="h6">
              {jsonData.programPause.partner.title}
            </Typography>
            <Stack
              component="img"
              src={`/assets/${jsonData.programPause.partner.img.src}`}
              alt={jsonData.programPause.partner.img.alt}
              sx={{ m: "auto" }}
            />
            <Typography variant="body1">
              <div
                dangerouslySetInnerHTML={{
                  __html: jsonData.programPause.partner.body
                }}
              />
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handlePartnerWithUs()}
            >
              {jsonData.programPause.partner.btnText}
            </Button>
          </Stack> */}
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

ProgramPause.propTypes = {}

export default ProgramPause
