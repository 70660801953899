export const accountLogin = (acc) => {
  return (dispatch) => {
    dispatch({
      type: 'login',
      payload: acc
    })
  }
}

export const patientSelect = (patient) => {
  return (dispatch) => {
    dispatch({
      type: 'selectPatient',
      payload: patient
    })
  }
}

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  }
}

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
})

/* -------------------------- Patient Registration Stepper ------------------- */

export const SAVE_PATIENT_DETAILS = 'SAVE_PATIENT_DETAILS'
export const SAVE_SITE_DETAILS = 'SAVE_SITE_DETAILS'
export const CLEAR_SITE_DATA = 'CLEAR_SITE_DATA'

/* -------------------------- Initial Loader ------------------- */

export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

/* -------------------------- SnackBar ------------------- */

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

export const showSnackBar = (payload) => ({
  type: SHOW_SNACKBAR,
  payload
})

export const hideSnackBar = () => ({
  type: HIDE_SNACKBAR
})

/* -------------------------- Auth ------------------- */
export const GET_TOKEN_SILENTLY_INIT = 'GET_TOKEN_SILENTLY_INIT'
export const GET_TOKEN_SILENTLY_SUCCESS = 'GET_TOKEN_SILENTLY_SUCCESS'
export const GET_TOKEN_SILENTLY_FAILURE = 'GET_TOKEN_SILENTLY_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const FIND_USER_INIT = 'FIND_USER_INIT'
export const FIND_USER_SUCCESS = 'FIND_USER_SUCCESS'
export const FIND_USER_FAILURE = 'FIND_USER_FAILURE'
export const TOGGLE_MOCK_USER = 'TOGGLE_MOCK_USER'

export const getTokenSilentlyInit = () => ({ type: GET_TOKEN_SILENTLY_INIT })
export const getTokenSilentlySuccess = (payload) => ({
  type: GET_TOKEN_SILENTLY_SUCCESS,
  payload
})
export const getTokenSilentlyFailure = (payload) => ({
  type: GET_TOKEN_SILENTLY_FAILURE,
  payload
})

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload
})

export const findUserInit = () => ({ type: FIND_USER_INIT })
export const findUserSuccess = (payload) => ({
  type: FIND_USER_SUCCESS,
  payload
})
export const findUserFailure = () => ({ type: FIND_USER_FAILURE })

export const toggleMockUser = () => ({ type: TOGGLE_MOCK_USER })
