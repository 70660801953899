import { PatientI } from '../../types/db/PatientI'
import { SectionTitlePDF, TextContentContainerPDF, TextContentPDF } from './utils'
import { View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

const PatientInformationPDF = ({ patient, doctor }: Props) => {
  const { first_name = '', last_name = '', dob = '', sex_assigned_at_birth = '' } = patient || {}

  return (<>
    <View
      style={ {
        display: 'flex',
        flexDirection: 'column'
      } }
    >
      <SectionTitlePDF title="Patient Information" />

      <TextContentContainerPDF>
        <>
          <TextContentPDF title="First Name:" content={ first_name } />
          <TextContentPDF title="Middle Initial:" content='' />
        </>
      </TextContentContainerPDF>

      <TextContentContainerPDF>
        <>
          <TextContentPDF title="Last Name:" content={ last_name } />
          <TextContentPDF title="Date of Birth:" content={ moment(dob).format('MM/DD/YYYY') } />
        </>
      </TextContentContainerPDF>

      <TextContentContainerPDF>
        <>
          <TextContentPDF title="Gender:" content={ sex_assigned_at_birth || '' } />
          <TextContentPDF title="Ordering Doctor:" content={ doctor } />
        </>
      </TextContentContainerPDF>
    </View>
  </>)
}

interface Props {
  patient: Partial<PatientI> | undefined,
  doctor: string
}

export default PatientInformationPDF
