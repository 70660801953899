import moment from 'moment'
import * as yup from 'yup'

export const insuranceRelationshipOptions = [
  'relation.self',
  'relation.child',
  'relation.parent',
  'relation.spouse'
]

export const insuranceValidationSchema = yup.object().shape({
  insuranceName: yup
    .string()
    .required('registerKit.insuranceForm.validation.selectInsuranceName')
    .nullable(),
  groupNumber: yup
    .string(),
  policyNumber: yup
    .string()
    .trim('registerKit.insuranceForm.validation.removeWhiteSpaces')
    .required('registerKit.insuranceForm.validation.enterInsuranceNumber'),
  relationship: yup
    .string()
    .required('registerKit.insuranceForm.validation.selectRelationship')
    .nullable(),
  insuredFirstName: yup
    .string()
    .trim('registerKit.insuranceForm.validation.removeWhiteSpaces')
    .required('registerKit.insuranceForm.validation.insuredFirstname'),
  insuredLastName: yup
    .string()
    .trim('registerKit.insuranceForm.validation.removeWhiteSpaces')
    .required('registerKit.insuranceForm.validation.insuredLastname'),
  insuredDob: yup
    .string()
    .min(10, 'registerKit.insuranceForm.validation.invalidDate')
    .required('registerKit.insuranceForm.validation.dobRequired')
    .test(
      'DOB',
      'registerKit.insuranceForm.validation.dobDescription',
      (value) => {
        if (!value) return false

        // Checkting for CompleteDate Object
        const isDateValid = moment(value).isValid()
        if (isDateValid) {
          const maxAge = moment().diff(moment('1900-01-01'), 'years')
          return (
            moment().diff(moment(value), 'years') >= 0 &&
            moment().diff(moment(value), 'years') < maxAge
          )
        } else {
          // Checking for MM-DD-YYYY/YYYY-MM-DD format
          const isDateValid = moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD']).isValid()
          if (isDateValid) {
            const maxAge = moment().diff(moment('1900-01-01'), 'years')
            return (
              moment().diff(moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD']), 'years') >= 0 &&
              moment().diff(moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD']), 'years') < maxAge
            )
          }

          return false
        }
      }
    )
    .nullable(),
  insuredGender: yup
    .string()
    .required('registerKit.insuranceForm.validation.insuredGender')
    .nullable(),
  warningCheckbox: yup.boolean().oneOf([true], 'registerKit.insuranceForm.validation.agreeToCheckbox'),
  insuredAddress: yup.object({
    addressLine1: yup
      .string()
      .trim('registerKit.insuranceForm.validation.removeWhiteSpaces')
      .required('registerKit.insuranceForm.validation.addressLine1'),
    city: yup
      .string()
      .trim('registerKit.insuranceForm.validation.removeWhiteSpaces')
      .required('registerKit.insuranceForm.validation.cityRequired'),
    state: yup.string().required('registerKit.insuranceForm.validation.stateRequired'),
    zipCode: yup
      .string()
      .trim('registerKit.insuranceForm.validation.removeWhiteSpaces')
      .required('registerKit.insuranceForm.validation.zipcodeRequired'),
    county: yup
      .string(),
    country: yup.string().required('registerKit.insuranceForm.validation.countryRequired')
  })
})
