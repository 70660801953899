import { Alert } from '@mui/material'
import { KitTypeConfigI } from '../types/db/KitTypeConfigI'
import { PatientI } from '../types/db/PatientI'
import { useTranslation } from 'react-i18next'
import React from 'react'

const NotAllowedDueToSexAssignedAtBirthAlert = ({ kitTypeConfig, patient }: NotAllowedDueToSexAlertProps) => {
  const { t } = useTranslation()

  // The kit type config can be configured to allow only a specific sex assigned at birth
  const isAllowedDueToTheSexAssignedAtBirth = checkIfAllowedDueToSexAssignedAtBirth(kitTypeConfig, patient)

  if (isAllowedDueToTheSexAssignedAtBirth) return null

  return <Alert severity='error' sx={ { alignItems: 'center' } }>
    { t('registerKit.patientDetails.notAllowedDueToSexAssignedAtBirth') }
  </Alert>
}

export const checkIfAllowedDueToSexAssignedAtBirth = (kitTypeConfig: KitTypeConfigI | undefined, patient: PatientI | undefined) => {
  if (!kitTypeConfig || !kitTypeConfig.allowedSexAssignedAtBirth || !patient) return true

  if (!patient.sex_assigned_at_birth) return false

  // The kit type config can be configured to allow only a specific
  const allowedSexAssignedAtBirth = kitTypeConfig?.allowedSexAssignedAtBirth
  const isAllowedDueToTheSexAssignedAtBirth = (allowedSexAssignedAtBirth === 'both') || (patient && allowedSexAssignedAtBirth && allowedSexAssignedAtBirth?.toLowerCase() === patient?.sex_assigned_at_birth?.toLowerCase())

  return isAllowedDueToTheSexAssignedAtBirth
}

interface NotAllowedDueToSexAlertProps {
  kitTypeConfig: KitTypeConfigI | undefined,
  patient: PatientI | undefined,
}

export default NotAllowedDueToSexAssignedAtBirthAlert
