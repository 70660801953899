import React from 'react'
import {
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText
} from '@mui/material'
import PropTypes from 'prop-types'

const RadioGroupComponent = ({
  onChange,
  name,
  value,
  options,
  error = false,
  helperText = '',
  headerText
}) => {
  return (
    <Stack gap={1}>
      <Typography>{headerText}</Typography>
      <RadioGroup name={name} onChange={onChange} sx={{ gap: 2 }} value={value}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={<Radio />}
            label={option.label}
            value={option.value}
          />
        ))}
        {error
          ? (
          <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>
            )
          : (
              ''
            )}
      </RadioGroup>
    </Stack>
  )
}

RadioGroupComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string
    })
  )
}

export default RadioGroupComponent
