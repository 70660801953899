import {
  Stack,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Container, Paper, Stepper } from 'components'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { createContext, useContext, useState, useEffect } from 'react'
import CheckEligibility from './CheckEligibility'
import EligibleTrue from './EligibleTrue'
import EligibleFalse from './EligibleFalse'
import YourSituation from './YourSituation'
import OrderSuccess from './OrderSuccess'
import YourInfo from './YourInfo'
import OrderReview from './OrderReview'
import { Route, Routes, useNavigate, useParams, Navigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import Loader from 'components/Loader'
import ProgramPause from './ProgramPause'
import OrderComplete from './OrderComplete'
import { TTestProgram } from './schemas'
import { useTranslation } from 'react-i18next'
import HeaderFreeTestsProgram from 'components/HeaderFreeTestsProgram'
import { FreeTestingProgramLandingPage } from './LandingPage'
import { isShkDomain } from 'utils/utilFuntions'
import { ContentJSON, ProgramConfig } from '../../types/db/ProgramI'
import { KitTypeConfigI } from '../../types/db/KitTypeConfigI'

type TMultiStepFormContext = {
  data: TTestProgram
  updateData: React.Dispatch<React.SetStateAction<TTestProgram>>
  step: {
    active: number
    total: number
  }
  jsonData: ContentJSON & {
    site: {
      consent: string
      address: string
    }
    allowedSiteSkus: Array<{
      skuID: string
      skuName: string
      kitTypeConfig: Partial<KitTypeConfigI>
    }> | undefined
    zipCodeRegex: ProgramConfig['zipCodeRegex']
  }
  frequency: ProgramConfig['frequency']
  age: number
}

const TestingProgramContext = createContext<TMultiStepFormContext | null>(null)

TestingProgramContext.displayName = 'TestingProgramContext'

export function useTestingProgram () {
  const context = useContext(TestingProgramContext)

  if (!context) {
    throw Error(
      'useTestingProgram must be in scope with <TestingProgramProvider /> '
    )
  }

  const { data, updateData, step, jsonData, frequency, age } = context
  console.log({ jsonData })
  return {
    data,
    updateData,
    step,
    jsonData,
    frequency,
    age
  }
}

function getActiveStep () {
  let activeStep = -1
  // let pageName = ''
  const pathname = location.pathname
  if (pathname.includes('your-situation')) {
    activeStep = 0
    // pageName = 'Get Started'
  }
  if (pathname.includes('eligibility')) {
    activeStep = 1
    // pageName = 'Check Eligibility'
  }
  if (pathname.includes('select-kit')) {
    activeStep = 2
    // pageName = 'Kit Details'
  }
  if (pathname.includes('your-info')) {
    activeStep = 3
    // pageName = 'Patient and Shipping Details'
  }
  if (pathname.includes('review')) {
    activeStep = 4
    // pageName = 'Review'
  }

  return activeStep
}

export function TestingProgramProvider ({
  children
}: {
  children: React.ReactNode
}) {
  const persistedData = JSON.parse(
    window.sessionStorage.getItem('testingProgram') || '{}'
  ) as TTestProgram

  const [data, updateData] = useState({
    ...persistedData,
    err: ''
  })

  const { slug } = useParams()

  const navigate = useNavigate()
  const {
    data: programContent,
    isLoading,
    isError
  } = useQuery(
    ['program-content', slug],
    () => api.program.getProgramConfig(slug),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    window.sessionStorage.setItem('testingProgram', JSON.stringify(data))
  }, [data])

  if (isLoading) return <Loader />

  if (
    programContent?.isPaused &&
    !window.location.pathname.includes('program-paused')
  ) {
    navigate(`/program/${slug}/program-paused`)
    return <Loader />
  } else if (
    !programContent?.isPaused &&
    window.location.pathname.includes('program-paused')
  ) {
    navigate(`/program/${slug}/your-situation`)
    return <Loader />
  } else if (!programContent?.isActive || (!isLoading && isError)) {
    navigate('/')
    return <Loader />
  }

  return (
    <TestingProgramContext.Provider
      value={ {
        data,
        updateData,
        frequency: programContent.frequency,
        age: programContent.age || 18,
        jsonData: {
          ...programContent.contentJson as ContentJSON,
          site: {
            consent: programContent.consent || '',
            address: programContent.address || ''
          },
          allowedSiteSkus: programContent.allowedSiteSkus,
          zipCodeRegex: programContent.zipCodeRegex
        },
        step: {
          active: getActiveStep(),
          total: 5
        }
      } }
    >
      { children }
    </TestingProgramContext.Provider>
  )
}

function TestingProgram () {
  const { t } = useTranslation()
  useDocumentTitle(t('ftp.docTitle'))

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [stepperActive, setStepperActive] = useState(true)

  return (
    <TestingProgramProvider>
      <Routes>
        <Route path='/' element={ <FreeTestingProgramLandingPage /> } />
        <Route
          path='*'
          element={
            <>
              <HeaderFreeTestsProgram />

              <Container>
                <Stack sx={ { gap: 3 } }>
                  <Stack sx={ { gap: 1.5, mx: 'auto' } }>
                    <Typography
                      variant='h4'
                      component='h1'
                      sx={ { textAlign: 'center' } }
                    >
                      { t('ftp.stdTitle') }
                    </Typography>
                    { isShkDomain()
                      ? (
                        <Typography
                          variant='h4'
                          component='h1'
                          sx={ { textAlign: 'center' } }
                        >
                          <Stack direction={ 'row' } justifyContent={ 'center' }>
                            <Stack
                              component='img'
                              src={ '/assets/poweredByText.svg' }
                              alt={ t('ftp.shkLogoAlt') }
                              sx={ { m: '5px' } }
                            />
                            <Stack
                              component='img'
                              src={ '/assets/logoPoweredBy.svg' }
                              alt={ t('ftp.shkLogoAlt') }
                              sx={ { m: '5px' } }
                            />
                          </Stack>
                        </Typography>
                        )
                      : (
                          ''
                        )
                    }
                  </Stack>
                  <Stack
                    sx={ {
                      flexDirection: 'row',
                      gap: 5,
                      justifyContent: mobile ? 'center' : 'flex-start',
                      alignItems: 'flex-start'
                    } }
                  >
                    { !mobile
                      ? (
                        <TestingProgramStepper
                          setStepperActive={ setStepperActive }
                        />
                        )
                      : null }
                    <Paper
                      sx={ {
                        width: {
                          xs: '100%',
                          sm: 560
                        },
                        m: stepperActive ? '0px' : 'auto'
                      } }
                    >
                      <Routes>
                        <Route
                          path='/eligibility'
                          element={ <CheckEligibility /> }
                        />
                        <Route
                          path='/not-eligible'
                          element={ <EligibleFalse /> }
                        />
                        <Route path='/select-kit' element={ <EligibleTrue /> } />
                        <Route
                          path='/your-situation'
                          element={ <YourSituation /> }
                        />
                        <Route
                          path='/order-success'
                          element={ <OrderSuccess /> }
                        />
                        <Route path='/your-info' element={ <YourInfo /> } />
                        <Route
                          path='/update-patient-info'
                          element={ <YourInfo /> }
                        />
                        <Route
                          path='/program-paused'
                          element={ <ProgramPause /> }
                        />
                        <Route path='/review' element={ <OrderReview /> } />
                        <Route
                          path='/order-complete'
                          element={ <OrderComplete /> }
                        />
                        {/* {user
                          ? (
                            )
                          : null} */}
                        <Route
                          path='/*'
                          element={ <Navigate to='your-situation' /> }
                        />
                      </Routes>
                    </Paper>
                  </Stack>
                </Stack>
              </Container>
            </>
          }
        />
      </Routes>
    </TestingProgramProvider>
  )
}

export default TestingProgram

export function TestingProgramNavigation ({
  back,
  next
}: {
  back?: React.ReactChild | null
  next?: React.ReactChild | null
}) {
  const { t } = useTranslation()
  const { step } = useTestingProgram()
  return (
    <Stack
      sx={ {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      } }
    >
      { back }
      <Typography
        variant='subtitle2'
        component='h3'
        sx={ theme => ({
          [theme.breakpoints.down('sm')]: {
            marginLeft: '-18px'
          }
        }) }
      >
        { t('multiStepNavigation.steps', {
          currentStep: step.active + 1,
          totalSteps: step.total
        }) }
      </Typography>
      { next }
    </Stack>
  )
}

function TestingProgramStepper ({
  setStepperActive
}: {
  setStepperActive: (data: boolean) => void
}) {
  const { step } = useTestingProgram()
  const { t } = useTranslation()
  const labels = [
    t('ftp.stepper.getStarted'),
    t('afterCare.eligibility.button.checkEligibility'),
    t('ftp.stepper.selectTest'),
    t('ftp.stepper.orderDetails'),
    t('afterCare.afterCareNavigation.button.review')
  ]

  setStepperActive(step.active !== -1)

  return (
    <>
      { step.active === -1
        ? (
            ''
          )
        : (
          <Paper
            sx={ {
              width: 260,
              position: 'sticky',
              top: 24,
              display: { xs: 'none', sm: 'flex' }
            } }
          >
            <Stepper activeStep={ step.active } orientation='vertical'>
              { labels.map(label => (
                <Step key={ label }>
                  <StepLabel>{ label }</StepLabel>
                </Step>
              )) }
            </Stepper>
          </Paper>
          ) }
    </>
  )
}
