import { api } from 'Core'
import Loader from 'components/Loader'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { showSnackBar } from 'state/actions'

const UnsubscribeReturnKitRemainder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useParams()

  useEffect(() => {
    const FormSubmit = async () => {
      try {
        const cache = await api.feedback.getCheckHashToken(token, { key: 'kitNotSentToLab' })

        if (cache) {
          const unsubscribeKitNotification = await api.feedback.unsubscribeKitNotification(token)

          if (unsubscribeKitNotification) {
            navigate('../unsubscribed')
            dispatch(
              showSnackBar({
                severity: 'success',
                message: 'Unsubscribed from notifications successfully',
                show: true
              })
            )
          } else throw Error('Could not Unsubscribe')
        }
      } catch (error) {
        console.log(error, 'error')
        dispatch(
          showSnackBar({
            show: true,
            severity: 'error',
            message: 'Something went wrong'
          })
        )
        navigate('/')
      }
    }

    FormSubmit()
  }, [])

  return (
    <Loader />
  )
}

export default UnsubscribeReturnKitRemainder
