import React, {} from 'react'
import {
  Box,
  Typography,
  Dialog,
  IconButton,
  Stack
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'

export const ethnicity: IdentityCharacteristicI[] = [
  {
    name: 'ethnicity.hispanicOrLatino',
    value: 'Hispanic/Latino',
    key: 'Hispanic/Latino'
  },
  {
    name: 'ethnicity.notHispanicOrLatino',
    value: 'Not Hispanic or Latino',
    key: 'Not Hispanic or Latino'
  },
  {
    name: 'ethnicity.unknown',
    value: 'Unknown',
    key: 'Prefer not to answer'
  }
]

export const gender: IdentityCharacteristicI[] = [
  {
    name: 'gender.male',
    value: 'Male',
    info: '',
    key: 'Male'
  },
  {
    name: 'gender.female',
    value: 'Female',
    info: '',
    key: 'Female'
  },
  {
    name: 'gender.cisMale',
    value: 'CisMale',
    info: '',
    key: 'Cisgender Male'
  },
  {
    name: 'gender.cisFemale',
    value: 'CisFemale',
    info: '',
    key: 'Cisgender Female'
  },
  {
    name: 'gender.transMale',
    value: 'TransMale',
    info: '',
    key: 'Transgender Male'
  },
  {
    name: 'gender.transFemale',
    value: 'TransFemale',
    info: '',
    key: 'Transgender Female'
  },
  {
    name: 'gender.nonBinary',
    value: 'NonBinary',
    info: '',
    key: 'Non-binary'
  },
  {
    name: 'gender.unknown',
    value: 'Unknown',
    info: '',
    key: 'Prefer not to answer'
  }
]

export const race: IdentityCharacteristicI[] = [
  {
    name: 'race.americanIndianOrAlaskaNative',
    value: 'American Indian/Alaska Native',
    key: 'American Indian/Alaska Native'
  },
  {
    name: 'race.asian',
    value: 'Asian',
    key: 'Asian'
  },
  {
    name: 'race.black',
    value: 'Black',
    key: 'Black'
  },
  {
    name: 'race.nativeHawaiian',
    value: 'Native Hawaiian/Pacific Isles',
    key: 'Native Hawaiian/Pacific Isles'
  },
  {
    name: 'race.unknown',
    value: 'Unknown',
    key: 'Prefer not to answer'
  },
  {
    name: 'race.white',
    value: 'White',
    key: 'White'
  },
  {
    name: 'race.other',
    value: 'Other',
    key: 'Other'
  }
]

export const sexAssignedAtBirth: IdentityCharacteristicI[] = [
  {
    name: 'gender.female',
    value: 'Female',
    key: 'Female'
  },
  {
    name: 'gender.male',
    value: 'Male',
    key: 'Male'
  }
  // {
  //   name: 'gender.unknown',
  //   value: 'Prefer not to answer',
  //   key: 'Prefer not to answer'
  // }
]

export const preferredLanguages = [
  {
    key: 'English',
    value: 'en-US',
  },
  {
    key: 'Spanish',
    value: 'es-US',
  }
]

export function GenderDialog ({ open, onClose }: GenderDialogProps) {
  const { t } = useTranslation()
  return (
    <Dialog open={ open } onClose={ onClose }>
      <Box p={ 3 } sx={ { textAlign: 'left' } }>
        <Typography variant='h3'>{ t('registerPatient.medicalDetails.genderDialog.genderIdentity') }</Typography>
      </Box>
      <IconButton
        onClick={ onClose }
        sx={ { position: 'absolute', right: '10px', top: '10px' } }
      >
        <CloseIcon />
      </IconButton>
      <Box p='0rem 1.5rem 1.5rem 1.5rem'>
        <Typography>{ t('registerPatient.medicalDetails.genderDialog.genderIdentityDescription') }</Typography>
        <Typography>
          <ul className="circle">
            <li dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(t('registerPatient.medicalDetails.genderDialog.transgenderDescription')) } } />
            <li dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(t('registerPatient.medicalDetails.genderDialog.nonBinaryDescription')) } } />
            <li dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(t('registerPatient.medicalDetails.genderDialog.cisGenderDescription')) } } />
          </ul>
          { t('registerPatient.medicalDetails.genderDialog.genderDiversityImportance') }
        </Typography>
      </Box>
    </Dialog>
  )
}

export const SexAssignedAtBirthDialog = ({ open, onClose }: SexAssignedAtBirthDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog open={ open } onClose={ onClose }>
      <Stack gap={ 1 } p={ 3 }>
        <Typography variant='h3'>{ t('registerPatient.medicalDetails.sexAssigned') }</Typography>
        <IconButton
          onClick={ onClose }
          sx={ { position: 'absolute', right: '10px', top: '10px' } }
        >
          <CloseIcon />
        </IconButton>
        <Stack gap={ 1 }>
          <Typography>{ t('registerPatient.medicalDetails.sexAssignedAtBirthDialog.p1') }</Typography>
          <Typography>{ t('registerPatient.medicalDetails.sexAssignedAtBirthDialog.p2') }</Typography>
        </Stack>
      </Stack>
    </Dialog>
  )
}

interface GenderDialogProps {
  open: boolean
  onClose: () => void
}

interface SexAssignedAtBirthDialogProps {
  open: boolean
  onClose: () => void
}

export interface IdentityCharacteristicI {
  name: string
  value: string
  key: string
  info?: string
}
