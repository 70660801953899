import _ from 'lodash'
import { api } from 'Core'
import { Alert, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { checkAffiliate } from 'utils/affiliateUtilFunctions'
import { LoadingButton } from '@mui/lab'
import { RegisterKitDataI, RegisterKitNavigation, useRegisterKit } from '.'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import AvoidShippingDelaysDialog from './AvoidShippingDelaysDialog'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import { TFunction } from 'i18next'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { isShkDomain } from 'utils/utilFuntions'
import { analyticEventTracker } from 'utils/analytics'

function KitCodeForm () {
  const navigate = useNavigate()
  const whitelabelConfig = useWhiteLabelConfig()
  const isSHK = isShkDomain()
  const { kitId } = useParams()
  const { data, updateData, saveKitAction } = useRegisterKit()
  const [isCheckingKit, setIsCheckingKit] = useState(false)
  const [openShippingReminder, setOpenShippingReminder] = useState(false)
  const { t } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()
  const [showCustomAlert, setShowCustomAlert] = useState(false)
  const badKits = ['SHKPTESTDEC6', 'SS-58948-TEST', 'SHKPTESTDEC7', 'SHKPTESTDEC8', 'SHKP50019907', 'SHKP50019917', 'SHKP50019930', 'SHKP50019942', 'SHKP50019953', 'SHKP50019961', 'SHKP50019971', 'SHKP50019983', 'SHKP50019992', 'SHKP50020001', 'SHKP50020011', 'SHKP50020019', 'SHKP50021068', 'SHKP50021077', 'SHKP50021086', 'SHKP50021095', 'SHKP50021105', 'SHKP50021115', 'SHKP50021123', 'SHKP50021130', 'SHKP50021140', 'SHKP50021151', 'SHKP50021160', 'SHKP50021170']
  const formik = useFormik({
    initialValues: data.kitCodeForm,
    validationSchema: getValidationSchema(t as unknown as TFunction<'frontend'>),
    onSubmit: async formData => {
      try {
        const response = await api.kit.check(formData.kitcode, false)

        if (response && badKits.includes(response?.code || '')) {
          setShowCustomAlert(true)
          return
        }
        setShowCustomAlert(false)
        if (!response) {
          formik.setFieldError(
            'kitcode',
            'Incorrect Code. Please try again.'
          )
          return
        }
        // if response.site.name exists that means we returned a fulfilled kit from the check api
        // if the response does not contain this attribute, the kit was not fulfilled and manually entered.
        if (response.site?.name) {
          if ((response.site?.name !== whitelabelConfig.siteName) && !isSHK) {
            formik.setFieldError(
              'kitcode',
              'Incorrect site attached to kit. Please try again.'
            )
            return
          }
        }

        const updatedData = {
          kit: { ...response },
          // payment,
          kitCodeForm: formData,
          patientInfoForm: {
            isKitFulfilled: response.is_fulfilled
          },
          site: response.site
        }

        updateData((state) => ({
          ...state,
          ...updatedData
        }) as unknown as RegisterKitDataI)
        saveKitAction('KitCodeSelection')

        const validExpirationDate = moment(response.expiration_date).isValid()
        if (validExpirationDate) {
          const currentDate = new Date()
          const expiry = new Date(response.expiration_date || '')
          expiry.setDate(expiry.getDate() - 7)
          const isExpired = currentDate >= expiry
          if (isExpired) {
            navigate(`/register/${response.id}/expired`)
            return
          }
        }
        if (!kitId || response.id !== +kitId) {
          navigate(`/register/${response.id}/patient-info`)
          return
        }
        navigate('patient-info')
      } catch (error) {
        formik.setFieldError('kitcode', t('registerKit.kitDetails.errors.incorrectKitCode'))
        analyticEventTracker(`(E) User entered incorrect kit code - ${formData.kitcode}`, {
          category: 'Register kit'
        })
        console.error(error)
      }
    }
  })

  const checkKitFulfillment = async (kitcode: string) => {
    try {
      const response = await api.kit.check(kitcode, true)
      // console.log('resp ', response)
      if (response) {
        formik.setFieldValue('isKitFulfilled', response.is_fulfilled)
        return
      }
      formik.setFieldValue('isKitFulfilled', false)
    } catch (error) {
      formik.setFieldValue('isKitFulfilled', false)
    } finally {
      setIsCheckingKit(false)
    }
  }

  const debouncedCheckKitFulfillment = useCallback(
    _.debounce(checkKitFulfillment, 500),
    []
  )

  const handleSampleIdChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('isKitFulfilled', true)
    if (!evt.target.value) {
      formik.handleChange(evt)
      return
    }
    setIsCheckingKit(true)
    toUpperOnChange(evt)
    debouncedCheckKitFulfillment(evt.target.value)
    formik.handleChange(evt)
  }

  useEffect(() => {
    if (formik.values.kitcode) {
      setIsCheckingKit(true)
      checkKitFulfillment(formik.values.kitcode)
    }
  }, [])

  const handleNextBtn = () => {
    // The reminder dialog should only be shown on Friday and Saturday
    analyticEventTracker(`(E) Next Button Clicked on Kit Code Selection`, {
      category: 'Register kit'
    })
    const today = moment().day()
    if (today === 5 || today === 6) {
      setOpenShippingReminder(whiteLabelConfig?.kitter_integrations?.weekend_disclaimer?.enabled ?? true)
    } else {
      formik.handleSubmit()
    }
  }

  return (
    <Stack
      sx={ { gap: 3 } }
    >
      <Typography variant='h5' component='h2'>{ t('registerKit.kitDetails.title') }</Typography>
      <Typography variant='subtitle1' component='h3' my={ 0.5 } dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(t('registerKit.kitDetails.description')) } } />
      <Stack direction='row'>
        <Stack
          component='img'
          src='/assets/kitdetails1.svg'
          alt='kitdetails-img-1'
          sx={ { m: 'auto' } }
        />

        <Stack
          component='img'
          src='/assets/testTube.svg'
          alt='kitdetails-img-2'
          sx={ { m: 'auto' } }
        />
        <Stack
          component='img'
          src='/assets/kitdetails2.svg'
          alt='kitdetails-img-3'
          sx={ { m: 'auto' } }
        />
      </Stack>
      {
        checkAffiliate()
          ? <Stack spacing={ 0.5 }>
            <TextField
              name='kitcode'
              label={ t('registerKit.kitDetails.affiliatedKitCodeField.label') }
              value={ formik.values.kitcode }
              onChange={ handleSampleIdChange }
              InputProps={ {
                endAdornment: isCheckingKit ? <CircularProgress size={ 20 } /> : null
              } }
              helperText={ formik.touched.kitcode ? t(formik.errors.kitcode) : null }
              error={ formik.touched.kitcode && !!formik.errors.kitcode }
            />
            <Typography sx={ { fontSize: 12, color: '#808080' } }>{ t('registerKit.kitDetails.affiliatedKitCodeField.instruction') }</Typography>
          </Stack>
          : <TextField
            name='kitcode'
            label={ t('registerKit.kitDetails.kitCodeField.label') }
            value={ formik.values.kitcode }
            onChange={ handleSampleIdChange }
            InputProps={ {
              endAdornment: isCheckingKit ? <CircularProgress size={ 20 } /> : null
            } }
            helperText={ formik.touched.kitcode ? t(formik.errors.kitcode) : null }
            error={ formik.touched.kitcode && !!formik.errors.kitcode }
          />
      }
      {showCustomAlert && <Alert severity='error'> {t('faultyKitPage.alert')} </Alert>}
      <AvoidShippingDelaysDialog open={ openShippingReminder } formik={ formik } handleClose={ () => setOpenShippingReminder(false) } />

      <RegisterKitNavigation
        back={ <Button disabled>{ t('multiFormNavigation.back') }</Button> }
        next={
          <LoadingButton
            loading={ formik.isSubmitting }
            onClick={ handleNextBtn }
            variant='contained'
            disabled={ isCheckingKit }
          >
            { t('multiFormNavigation.next') }
          </LoadingButton>
        }
      />
    </Stack>
  )
}

// Utils
const toUpperOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
  evt.target.value = evt.target.value.toUpperCase()
}

function getValidationSchema (t: TFunction<'frontend' | 'backend', undefined>) {
  return yup.object().shape({
    kitcode: yup
      .string()
      .trim(t('registerKit.kitDetails.kitCodeField.errors.trim'))
      .required('registerKit.kitDetails.kitCodeField.errors.required')
  })
}

export default KitCodeForm
