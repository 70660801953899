import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import useFormik from 'hooks/useFormik'
import { Heading } from '.'
import {
  MultiStepFormNavigation,
  useMultiStepForm
} from 'components/MultiStepForm'
import { TPrepProgram, stiInfo } from './schemas'
import type { PrEPProgramConfig } from 'types/db/SiteI'

function StiInfo( {
  prepProgramConfig
}: {
  prepProgramConfig: PrEPProgramConfig
} ) {
  const { data, update } = useMultiStepForm<TPrepProgram>()

  const stiInfoContent = prepProgramConfig.content.stiInfo

  const formik = useFormik( {
    initialValues: data.stiInfo,
    validationSchema: stiInfo,
    onSubmit: formData => {
      update( { ...data, stiInfo: formData as TPrepProgram[ 'stiInfo' ] } )
    }
  } )

  return (
    <>
      <Typography variant='h6' component='h6' align='center'>
        {stiInfoContent.img.alt}
      </Typography>
      <Stack
        component='img'
        src={`/assets/${stiInfoContent.img.src}`}
        alt={stiInfoContent.img.alt}
        sx={{ m: 'auto', mb: '17px' }}
      />
      <Heading>{stiInfoContent.title}</Heading>
      <Stack
        sx={{ my: '17px' }}
        gap={2}
        component='form'
        onSubmit={formik.handleSubmit}
      >
        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {stiInfoContent.questions[ 'diagnosedWithSTIs' ].title}
        </Typography>
        <FormControl error={formik.isError( 'diagnosedWithSTIs' )}>
          <FormGroup>
            {stiInfoContent.questions[ 'diagnosedWithSTIs' ].options.map(
              option => (
                <>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<Typography sx={{ textTransform: 'capitalize' }}> {option} </Typography>}
                    key={option}
                    {...formik.registerCheckbox( 'diagnosedWithSTIs', option )}
                    onChange={( event ) => {
                      formik.registerCheckbox( 'diagnosedWithSTIs', option ).onChange( event );
                      if ( formik.values[ 'diagnosedWithSTIs' ].includes( 'None of the above' ) ) {
                        formik.setFieldValue( 'completedPreviousTreatment', null )
                      }
                      if ( !formik.values[ 'diagnosedWithSTIs' ].includes( 'other' ) ) {
                        formik.setFieldValue( 'diagnosedWithSTIsOther', '' )
                      }
                    }}
                  />
                  {option === 'other' &&
                    formik.values[ 'diagnosedWithSTIs' ].includes( 'other' ) ? (
                    <Stack sx={{ gap: 0.6 }}>
                      {/* <Typography>
                        {
                          stiInfoContent.questions['diagnosedWithSTIsOther']
                            .title
                        }
                      </Typography> */}
                      <TextField
                        id='diagnosedWithSTIsOther'
                        name='diagnosedWithSTIsOther'
                        label={
                          stiInfoContent.questions[ 'diagnosedWithSTIsOther' ]
                            .helperText
                        }
                        value={formik.values[ 'diagnosedWithSTIsOther' ]}
                        onChange={formik.handleChange}
                        error={formik.isError( 'diagnosedWithSTIsOther' )}
                        helperText={formik.getError( 'diagnosedWithSTIsOther' )}
                      />
                    </Stack>
                  ) : (
                    ''
                  )}
                </>
              )
            )}
            {formik.isError( 'diagnosedWithSTIs' ) ? (
              <FormHelperText error>
                {`${formik.errors[ 'diagnosedWithSTIs' ] || ''}`}
              </FormHelperText>
            ) : null}
          </FormGroup>
        </FormControl>
        {formik.values[ 'diagnosedWithSTIs' ].length > 0 &&
          !formik.values[ 'diagnosedWithSTIs' ].includes( 'None of the above' ) ? (
          <>
            <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
              {stiInfoContent.questions[ 'completedPreviousTreatment' ].title}
            </Typography>
            <FormControl error={formik.isError( 'completedPreviousTreatment' )}>
              <RadioGroup
                name={'completedPreviousTreatment'}
                value={formik.values[ 'completedPreviousTreatment' ]}
                onChange={formik.handleChange}
              >
                {stiInfoContent.questions[
                  'completedPreviousTreatment'
                ].options.map( option => (
                  <FormControlLabel
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ) )}
              </RadioGroup>
              {formik.isError( 'completedPreviousTreatment' ) ? (
                <FormHelperText>
                  {`${formik.errors[ 'completedPreviousTreatment' ] || ''}`}
                </FormHelperText>
              ) : null}
            </FormControl>
          </>
        ) : (
          ''
        )}
        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {stiInfoContent.questions[ 'partnerPositive' ].title}
        </Typography>
        <FormControl error={formik.isError( 'partnerPositive' )}>
          <RadioGroup
            name={'partnerPositive'}
            value={formik.values[ 'partnerPositive' ]}
            onChange={( e ) => {
              formik.handleChange( e )
              if ( formik.values[ 'partnerPositive' ] === 'No' ) {
                formik.setFieldValue( 'partnerBeenOnTreatment', null )
                formik.setFieldValue( 'partnerViralLoad', null )
              }
            }}
          >
            {stiInfoContent.questions[ 'partnerPositive' ].options.map( option => (
              <FormControlLabel
                value={option}
                control={<Radio />}
                label={option}
              />
            ) )}
          </RadioGroup>
          {formik.isError( 'partnerPositive' ) ? (
            <FormHelperText>{`${formik.errors[ 'partnerPositive' ] || ''}`}</FormHelperText>
          ) : null}
        </FormControl>
        {formik.values[ 'partnerPositive' ] === 'Yes' ? (
          <>
            <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
              {stiInfoContent.questions[ 'partnerBeenOnTreatment' ].title}
            </Typography>
            <FormControl error={formik.isError( 'partnerBeenOnTreatment' )}>
              <RadioGroup
                name={'partnerBeenOnTreatment'}
                value={formik.values[ 'partnerBeenOnTreatment' ]}
                onChange={formik.handleChange}
              >
                {stiInfoContent.questions[ 'partnerBeenOnTreatment' ].options.map(
                  ( option: any ) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  )
                )}
              </RadioGroup>
              {formik.isError( 'partnerBeenOnTreatment' ) ? (
                <FormHelperText>
                  {`${formik.errors[ 'partnerBeenOnTreatment' ] || ''}`}
                </FormHelperText>
              ) : null}
            </FormControl>
            <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
              {stiInfoContent.questions[ 'partnerViralLoad' ].title}
            </Typography>
            <FormControl error={formik.isError( 'partnerViralLoad' )}>
              <RadioGroup
                name={'partnerViralLoad'}
                value={formik.values[ 'partnerViralLoad' ]}
                onChange={formik.handleChange}
              >
                {stiInfoContent.questions[ 'partnerViralLoad' ].options.map(
                  ( option: any ) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  )
                )}
              </RadioGroup>
              {formik.isError( 'partnerViralLoad' ) ? (
                <FormHelperText>
                  {`${formik.errors[ 'partnerViralLoad' ] || ''}`}
                </FormHelperText>
              ) : null}
            </FormControl>
          </>
        ) : (
          ''
        )}
        <MultiStepFormNavigation />
      </Stack>
    </>
  )
}

export default StiInfo
