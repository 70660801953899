import { Home } from '@mui/icons-material'
import {
  Breadcrumbs,
  Container,
  Divider,
  Link,
  Paper,
  styled,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

const StyledContainer = styled(Paper)({
  // textTransform: 'capitalize'
})

interface PageLayoutProps {
  title?: string
  hideTitleSection?: boolean
  breadcrumbs?: string
  noBox?: boolean
  fullWidth?: boolean
  sx?: SxProps
  children?: JSX.Element | JSX.Element[] | false[] | null
}

const PageLayout = (props: PageLayoutProps) => {
  const { title, hideTitleSection, sx, breadcrumbs, noBox, fullWidth, children } = props

  const navigate = useNavigate()

  const theme = useTheme()

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    document.title = `${_.startCase(_.toLower(title))} | SHK`
    return () => {
      //   cleanup
    }
  }, [title])

  if (noBox) {
    return (
      <Container
        maxWidth={ !fullWidth ? 'lg' : false }
        sx={ { backgroundColor: '#fff', padding: '0 16px' } }
      >
        <main id='main'>

          { !hideTitleSection && title && title.length > 0 && (
            <>
              <Typography variant='h5' component='h2' sx={ { pb: 1 } }>
                { title }
              </Typography>
              <Divider sx={ { mb: 2 } } />
            </>
          ) }
          { children }
        </main>

      </Container>
    )
  }

  return (
    <Container maxWidth={ !fullWidth ? 'lg' : false } sx={ { padding: '0 16px' } }>
      <main id='main'>
        <StyledContainer
          elevation={ 0 }
          sx={ {
            my: 1,
            p: isMdUp ? 2 : 0.5,
            mx: 'auto',
            backgroundColor: '#fff',
            ...sx
          } }
        >
          { breadcrumbs === 'map' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link
                aria-label='home'
                underline='hover'
                color='inherit'
                href='#'
                onClick={ (_) => {
                  navigate('/')
                } }
              >
                <Home />
              </Link>
              <Link underline='hover' color='text.primary' aria-current='page'>
                Clinics
              </Link>
            </Breadcrumbs>
          ) }
          { breadcrumbs === 'client-admin' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link
                aria-label='home'
                underline='hover'
                color='inherit'
                href='#'
                onClick={ (_) => {
                  navigate('/')
                } }
              >
                <Home />
              </Link>
              <Link underline='hover' color='text.primary' aria-current='page'>
                Client Admin
              </Link>
            </Breadcrumbs>
          ) }
          { breadcrumbs === 'link-site' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link
                aria-label='home'
                underline='hover'
                color='inherit'
                href='#'
                onClick={ (_) => {
                  navigate('/')
                } }
              >
                <Home />
              </Link>
              <Link underline='hover' color='text.primary' aria-current='page'>
                Link New Client Site Code
              </Link>
            </Breadcrumbs>
          ) }
          { breadcrumbs === 'register-patient' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link
                aria-label='home'
                underline='hover'
                color='inherit'
                href='#'
                onClick={ (_) => {
                  navigate('/')
                } }
              >
                <Home />
              </Link>
              <Link underline='hover' color='text.primary' aria-current='page'>
                Register Patient
              </Link>
            </Breadcrumbs>
          ) }
          { breadcrumbs === 'register' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link
                aria-label='home'
                underline='hover'
                color='inherit'
                href='#'
                onClick={ (_) => {
                  navigate('/')
                } }
              >
                <Home />
              </Link>
              <Link underline='hover' color='text.primary' aria-current='page'>
                Register Kit
              </Link>
            </Breadcrumbs>
          ) }
          { breadcrumbs === 'settings' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link
                aria-label='home'
                underline='hover'
                color='inherit'
                href='#'
                onClick={ (_) => {
                  navigate('/')
                } }
              >
                <Home />
              </Link>
              <Link underline='hover' color='text.primary' aria-current='page'>
                Settings
              </Link>
            </Breadcrumbs>
          ) }
          { breadcrumbs === 'home1' && (
            <Breadcrumbs aria-label='breadcrumb' sx={ { mb: 1 } }>
              <Link aria-label='home' underline='hover' color='inherit' href='/'>
                <Home /> Dashboard
              </Link>
            </Breadcrumbs>
          ) }
          { !hideTitleSection && title && title.length > 0 && (
            <>
              <Typography variant='h5' component='h2' sx={ { pb: 1 } }>
                { title }
              </Typography>
              <Divider sx={ { mb: 2 } } />
            </>
          ) }
          { children }
        </StyledContainer>
      </main>

    </Container>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string,
  hideTitleSection: PropTypes.bool,
  breadcrumbs: PropTypes.string,
  noBox: PropTypes.bool,
  fullWidth: PropTypes.bool
}

PageLayout.defaultProps = {
  title: '',
  hideTitleSection: false,
  breadcrumbs: null,
  noBox: false,
  fullWidth: false
}

export default PageLayout
