import { AnalyteConfigI } from '../../types/db/AnalyteConfigI'
import { GroupedSamplesByTypeI } from '.'
import { Kit } from '../../types/db/Kit'
import { SectionTitlePDF } from './utils'
import { View } from '@react-pdf/renderer'
import QualitativeResultsPDF from './QualitativeResultsPDF'
import QuantitativeResultsPDF from './QuantitativeResultsPDF'
import React from 'react'

const TestResultsPDF = ({ samplesByType, analytes, kit }: Props) => {
  const { qualitative = [], quantitative = [] } = samplesByType

  return (<>
    <View>
      <SectionTitlePDF title="Test Results" />

      {
        quantitative.length > 0
          ? <QuantitativeResultsPDF
            samples={ quantitative }
            kit={ kit }
            analytes={ analytes }
          />
          : null
      }

      {
        qualitative.length > 0
          ? <QualitativeResultsPDF
            samples={ qualitative }
            kit={ kit }
            analytes={ analytes }
          />
          : null
      }

    </View>
  </>)
}

interface Props {
  samplesByType: GroupedSamplesByTypeI
  analytes: Partial<AnalyteConfigI>[] | undefined
  kit: Partial<Kit> | undefined
}

export default TestResultsPDF
