import { IconButton, Modal, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Login from 'pages/Auth/Login'
import React, { Dispatch, SetStateAction, useState } from 'react'
import Signup from 'pages/Auth/Signup'

const AuthenticationModal = ({ open, handleClose }: Props) => {
  const [displayedComponent, setDisplayedComponent] = useState('login')

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="authentication-modal"
      aria-describedby="modal-for-authentication"
      sx={ {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      } }
    >
      <Stack
        spacing={ 2 }
        sx={ {
          bgcolor: 'background.paper',
          borderRadius: '.25rem',
          padding: '1rem 0rem',
          height: '100%',
          maxHeight: {
            xs: '100%',
            sm: '90%'
          },
          overflowY: 'auto',
          width: '100%',
          maxWidth: '40rem'
        } }
      >
        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
          <IconButton
            aria-label='close'
            onClick={ handleClose }
            sx={ { marginLeft: 'auto', marginRight: '1rem' } }
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        { chooseDisplayedComponent(displayedComponent, setDisplayedComponent) }
      </Stack>

    </Modal>
  )
}

const chooseDisplayedComponent = (displayedComponent: string, setDisplayedComponent: Dispatch<SetStateAction<string>>) => {
  switch (displayedComponent) {
    case 'login':
      return <Login displayedComponent={ displayedComponent } setDisplayedComponent={ setDisplayedComponent } />
    case 'signup':
      return <Signup displayedComponent={ displayedComponent } setDisplayedComponent={ setDisplayedComponent } />
    default:
      return <Login setDisplayedComponent={ setDisplayedComponent } />
  }
}

interface Props {
  open: boolean
  handleClose: () => void
}

export default AuthenticationModal
