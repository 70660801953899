import React, { useCallback } from 'react'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import DOMPurify from 'dompurify'
import FeedbackForm from 'components/FeedbackForm'
import { useMutation } from '@tanstack/react-query'
import { api } from 'Core'
import { PrEPProgramConfig } from 'types/db/SiteI'
import { useNavigate } from 'react-router'
import { useMultiStepForm } from 'components/MultiStepForm'
import { TPrepProgram } from './schemas'

function EnrollComplete ({
  prepProgramConfig
} : {
  prepProgramConfig: PrEPProgramConfig
}) {
  const enrollCompleteContent = prepProgramConfig.content.enrollComplete
  const { data } = useMultiStepForm<TPrepProgram>()

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      disclaimer: false
    },
    onSubmit: async () => {
      navigate(`/programs/prep-details/${prepProgramConfig.slug}/${data.id}`)
    }
  })

  const createFeedbackMutation = useMutation(api.feedback.createFeedback)
  const updateFeedbackMutation = useMutation(api.feedback.updateFeedback)

  const handleFeedbackChange = useCallback(
    // TODO: Add the proper Type
    (data: any) => {
      const feedbackData = {
        ...data,
        feature: 'register-prep-program',
        meta: { patientId: 'patient-id-test' }
      }
      if (createFeedbackMutation.data) {
        updateFeedbackMutation.mutate({
          id: createFeedbackMutation.data.id,
          data: feedbackData
        })
        return
      }
      createFeedbackMutation.mutate(feedbackData)
    },
    [createFeedbackMutation.data]
  )

  return (
    <Stack>
      <Stack gap={2} component='form' onSubmit={formik.handleSubmit}>
        <Stack>
          <Stack
            component='img'
            src={`/assets/${enrollCompleteContent.img.src}`}
            alt={enrollCompleteContent.img.alt}
            sx={{ m: 'auto' }}
          />
          <Alert
            severity='success'
            sx={{
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center'
              }
            }}
          >
            <Typography variant='body1'>
              {enrollCompleteContent.alert.textBody}
            </Typography>
          </Alert>
        </Stack>

        <Typography variant='h6' component='h6'>
          {enrollCompleteContent.whatNext.title}
        </Typography>
        <Typography
          variant='body1'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(enrollCompleteContent.whatNext.body, {
              ADD_ATTR: ['target']
            })
          }}
        ></Typography>
        <Stack>
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              p: '12px'
            }}
          >
            <Stack>
              <Typography variant="subtitle1" fontWeight={700}>
                {enrollCompleteContent.whatNext.pharmacyEmoji} {data.pharmacy.name}
              </Typography>
              <Typography variant="body1" color="#00000099">
                {enrollCompleteContent.whatNext.addressEmoji} {data.pharmacy.address}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        {/* <Typography variant='body1' color='#00000099'>
          <u>{enrollCompleteContent.reportIssueText}</u>
        </Typography> */}
        <FeedbackForm
          title={enrollCompleteContent.feedback.title}
          totalPoints={5}
          lowestLabel={enrollCompleteContent.feedback.lowestLabel}
          highestLabel={enrollCompleteContent.feedback.highestLabel}
          onChange={handleFeedbackChange}
        />
        <Button fullWidth variant='contained' type='submit'>
          {enrollCompleteContent.btnText}
        </Button>
      </Stack>
    </Stack>
  )
}

export default EnrollComplete
