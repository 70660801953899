import { api } from '../Core'
import { queryKeys } from '../Core/utils/queryKeys'
import { useQuery } from '@tanstack/react-query'

const useGetServerTranslation = (idOrKey: string | undefined) => {
  // The endpoint works with the id number or the identifier (key) of the translation
  const { isLoading, isError, data: translation } = useQuery({
    queryKey: [queryKeys.i18nextContent.ONE, idOrKey],
    queryFn: () => api.i18nextContent.getTranslation(idOrKey || ''),
    enabled: !!idOrKey,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  return {
    isLoading: idOrKey ? isLoading : false,
    isError,
    translation
  }
}

export default useGetServerTranslation
