import { AnalyteConfigI } from '../../types/db/AnalyteConfigI'
import { getResultForResultBox } from '../TestResult/utils'
import { Kit } from '../../types/db/Kit'
import { SampleI } from '../../types/db/SampleI'
import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import ResultBoxPDF from './ResultBoxPDF'

const QualitativeResultsPDF = ({ samples, kit, analytes }: Props) => {
  return (<>
    <View
      style={ {
        display: 'flex',
        flexDirection: 'row',
        height: '30px',
        fontSize: '10px',
        alignItems: 'center',
        borderBottom: '1px solid #F0F0F0',
        gap: '4px'
      } }
    >
      <Text style={ { flex: 1, padding: '0px 8px' } }>Test</Text>
      <Text style={ { flex: 1, padding: '0px 8px' } }>Result</Text>
      <Text style={ { flex: 1, padding: '0px 8px' } }>Reference Range</Text>
      <Text style={ { flex: 2, padding: '0px 8px' } }>Comments</Text>
    </View>

    {
      samples.map((sample, idx) => {
        const analyte = analytes?.find(analyte => analyte?.id && sample?.analyte_id && (+analyte.id === +sample.analyte_id))

        return <QualitativeResultRow
          key={ idx }
          sample={ sample }
          kit={ kit }
          analyte={ analyte }
        />
      })
    }
  </>)
}

const QualitativeResultRow = ({ sample, kit, analyte }: RowProps) => {
  const test = sample?.analyte_scientific_name || sample.analyte_name || ''
  const comments = sample?.reason_rejection || sample?.comment || ''
  const resultBox = getResultForResultBox(sample as SampleI, kit as Kit, analyte as AnalyteConfigI)

  return (<>
    <View
      wrap={ false }
      style={ {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '10px',
        alignItems: 'flex-start',
        borderBottom: '1px solid #F0F0F0',
        padding: '8px 0px',
        gap: '4px'
      } }
    >
      <Text style={ { flex: 1, padding: '8px 8px 0px 8px', fontWeight: 700 } }>{ test }</Text>
      <ResultBoxPDF value={ resultBox?.text || '' } color={ resultBox?.color || '' } backgroundColor={ resultBox?.backgroundColor || '' } />
      <Text style={ { flex: 1, padding: '8px 8px 0px 8px' } }>{ resultBox?.referenceRange }</Text>
      <Text style={ { flex: 2, padding: '8px 8px 0px 8px' } }>{ comments }</Text>
    </View>
  </>)
}

interface Props {
  samples: Partial<SampleI>[]
  kit: Partial<Kit> | undefined
  analytes: Partial<AnalyteConfigI>[] | undefined
}

interface RowProps {
  sample: Partial<SampleI>
  kit: Partial<Kit> | undefined
  analyte: Partial<AnalyteConfigI> | undefined
}

export default QualitativeResultsPDF
