import { BottomNavigation, BottomNavigationAction, Box, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import StoreIcon from '@mui/icons-material/Store'
import MenuIcon from '@mui/icons-material/Menu'
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings'
import React, { MouseEventHandler, useState } from 'react'
import useNotifications from 'hooks/useNotifications'
import { useTranslation } from 'react-i18next'
import BiotechIcon from '@mui/icons-material/Biotech';
import SpaIcon from '@mui/icons-material/Spa';
import { isShkDomain } from 'utils/utilFuntions'

const MobileMenuPatient = ({ setIsLogoutConfirmationOpen, hasConsults }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isShk = isShkDomain()
  const { pathname } = useLocation()
  const { unreadMessagesNumber } = useNotifications()
  const [currentPathname, setCurrentPathname] = useState(pathname)
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null)
  const isMenuOpen = Boolean(anchorEl)

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (event) => { setAnchorEl(event.currentTarget) }
  const handleCloseMenu = () => { setAnchorEl(null) }

  return (
    <Box sx={ {
      bgcolor: '#fff',
      borderTop: '1px solid #d3d3d3',
      bottom: 0,
      left: 0,
      padding: '.5rem 0rem',
      position: 'fixed',
      width: '100%',
      zIndex: 1000
    } }
    >
      <BottomNavigation
        showLabels
        value={ currentPathname }
        onChange={ (event, value) => {
          if (value !== 'menu') {
            navigate(value)
            setCurrentPathname(value)
          }
        } }
      >
        <BottomNavigationAction value='/' label={t('mobileMenu.home')} icon={ <HomeIcon /> } />
        
        <BottomNavigationAction value='/tests' label={t('mobileMenu.tests')} icon={
          <BiotechIcon />
        } />

        {isShk && <BottomNavigationAction value='/view-programs' label={t('mobileMenu.programs')} icon={
          <SpaIcon/>         
        } />}

        {
          hasConsults
            ? <BottomNavigationAction value='/visits' label={t('mobileMenu.visits')} icon={ <ContentPasteIcon /> } />
            : null
        }
        <BottomNavigationAction
          value='menu'
          label={t('mobileMenu.menu.button')}
          icon={ <MenuIcon /> }
          id="menu-button"
          aria-controls={ isMenuOpen ? 'menu' : undefined }
          aria-haspopup="true"
          aria-expanded={ isMenuOpen ? 'true' : undefined }
          onClick={ handleOpenMenu }
        />
      </BottomNavigation>
      <MenuComponent
        anchorEl={ anchorEl }
        handleCloseMenu={ handleCloseMenu }
        isMenuOpen={ isMenuOpen }
        setIsLogoutConfirmationOpen={ setIsLogoutConfirmationOpen }
      />
    </Box>
  )
}

const MenuComponent = ({ anchorEl, handleCloseMenu, isMenuOpen, setIsLogoutConfirmationOpen }: MenuComponentProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSettingsButton = () => {
    navigate('/account')
    handleCloseMenu()
  }

  const handlePortalSwitchButton = () => {
    navigate('/choose-portal')
    handleCloseMenu()
  }

  const handleShopButton = () => {
    window.open('https://www.simplehealthkit.com/where-to-buy/')
    handleCloseMenu()
  }

  const handleLogout = () => {
    setIsLogoutConfirmationOpen(true)
  }

  return (
    <Menu
      id="menu"
      anchorEl={ anchorEl }
      open={ isMenuOpen }
      onClose={ handleCloseMenu }
      MenuListProps={ {
        'aria-labelledby': 'menu-button'
      } }
      transformOrigin={ { horizontal: 'right', vertical: 'bottom' } }
      anchorOrigin={ { horizontal: 'right', vertical: 'top' } }
      slotProps={{
        paper: {
          style: {
            width: '12rem'
          }
        }
      }}
    >
      <MenuItem onClick={ handleShopButton }>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        {t('mobileMenu.menu.shop')}
      </MenuItem>
      <MenuItem onClick={ handleSettingsButton }>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        {t('mobileMenu.menu.settings')}
      </MenuItem>
      <MenuItem onClick={ handlePortalSwitchButton }>
        <ListItemIcon>
          <CachedIcon />
        </ListItemIcon>
        {t('homepage.switchPortal')}
      </MenuItem>
      <MenuItem onClick={ handleLogout }>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        {t('mobileMenu.menu.logout')}
      </MenuItem>
    </Menu>
  )
}

interface Props {
  setIsLogoutConfirmationOpen: (value: boolean) => void
  hasConsults: boolean
}

interface MenuComponentProps {
  anchorEl: EventTarget & HTMLButtonElement | null
  handleCloseMenu: () => void
  isMenuOpen: boolean
  setIsLogoutConfirmationOpen: (value: boolean) => void
}

export default MobileMenuPatient
