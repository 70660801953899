import { api } from 'Core'
import { calculateNewNotificationsNumber } from 'components/Header/PatientInbox/utils'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AppNotificationI } from '../types/db/AppNotificationI'

const useNotifications = () => {
  const { data: notifications, isLoading } = useQuery({
    queryKey: ['patient-inbox'],
    queryFn: api.notification.getNotificationsByAccount,
    select: (data) => data.ok ? data.notifications : []
  })

  const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(0)
  useEffect(() => {
    if (notifications || !isLoading) {
      const newNumber = calculateNewNotificationsNumber(notifications as AppNotificationI[])
      setUnreadMessagesNumber(newNumber)
    }
  }, [notifications])

  return {
    unreadMessagesNumber,
    notifications
  }
}

export default useNotifications
