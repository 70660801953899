import { Button, CircularProgress } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import useAuth0 from 'hooks/useAuth0'
import { useTranslation } from 'react-i18next'

const AuthenticationBtn = ({ handleOpen }: Props) => {
  const { isLoading, user } = useAuth0()
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipText, setTooltipText] = useState('')
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    // Show the tooltip if the user is on the check eligibility page
    if (location.pathname.includes('/eligibility') && !isLoading && !user) {
      setTooltipText(`👋 ${t('ftp.loginBtnTooltip')}`)
      setShowTooltip(true)

      // Hide the tooltip after a specific time
      const tooltipTimeout = setTimeout(() => {
        setShowTooltip(false)
      }, 5000)

      // Clear the timeout when the component unmounts or the user navigates away from the page
      return () => {
        clearTimeout(tooltipTimeout)
      }
    } else {
      setTooltipText('')
    }
  }, [location.pathname, isLoading, user])

  return (
    <>
      {
        isLoading
          ? <CircularProgress size={ 16 } />
          : (
            <Tooltip
              title={ user ? '' : tooltipText }
              placement='bottom-end'
              arrow
              open={ showTooltip }
              componentsProps={ {
                tooltip: {
                  sx: {
                    fontSize: 14,
                    bgcolor: 'common.white',
                    color: 'common.black',
                    border: '1px solid #0000001A',
                    '& .MuiTooltip-arrow': {
                      color: 'common.white',
                      '&:before': {
                        border: '1px solid #0000001A'
                      }
                    }
                  }
                }
              } }
            >
              <Button
                variant='text'
                onClick={ handleOpen }
                sx={ {
                  textDecoration: 'underline !important',
                  color: '#000',
                  padding: '.25rem'
                } }
              >
                { `${user ? t('header.logoutDialog.button.logout') : t('login.title')}` }
              </Button>
            </Tooltip>)
      }
    </>
  )
}

interface Props {
  handleOpen: () => void
}

export default AuthenticationBtn
