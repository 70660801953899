import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  FormHelperText
} from '@mui/material'
import Insured from './Insured'
import UnInsured from './UnInsured'
import { paymentTypes, PaymentTypesI } from 'utils/insurance'
import ForgottenInsurance from './ForgottenInsurance'
import { useTranslation } from 'react-i18next'

const PaymentInfo = ({
  formik,
  paymentType,
  setPaymentType,
  copyAddress,
  setCopyAddress,
  disableAllFields,
  setValidationSchema2,
  hideCheckBox,
  isAdmin
}: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (paymentType !== formik.values.isInsured) {
      formik.setFieldValue('isInsured', paymentType)
    }
  }, [paymentType, formik.values.isInsured])

  return (
    <Stack>
      <FormControl
        sx={ { py: 3 } }
        error={
          Boolean(formik.errors?.isInsured)
        }
      >
        <Typography component='label' id='is-patient-insured'>
          { isAdmin ? `${t('registerPatient.paymentInfo.patientHaveInsurance')} *` : `${t('registerPatient.paymentInfo.haveInsurance')} *` }
        </Typography>
        <RadioGroup
          sx={ { mt: 1 } }
          aria-labelledby='is-patient-insured'
          name='isPatientInsured'
          value={ paymentType || formik.values.isInsured }
          onChange={ (_evt, newValue) => {
            setPaymentType(newValue as keyof PaymentTypesI)
            formik.setFieldValue('isInsured', newValue)
          } }
        >
          <FormControlLabel value='insured' control={ <Radio autoFocus /> } label={ t('registerPatient.paymentInfo.label.yes') } />
          <FormControlLabel value='uninsured' control={ <Radio /> } label={ t('registerPatient.paymentInfo.label.no') } />
          {
            isAdmin
              ? <FormControlLabel value='forgottenInsurance' control={ <Radio /> } label={ t('registerPatient.paymentInfo.patientForgotInsuranceInfo') } />
              : null
          }
          {
            isAdmin
              ? <FormControlLabel value='prepaid' control={ <Radio /> } label={ 'Prepaid' } />
              : null
          }
        </RadioGroup>
        <FormHelperText>
          { formik.errors.isInsured ? t(`${formik.errors.isInsured}`) : '' }
        </FormHelperText>
      </FormControl>

      { paymentType === paymentTypes.insured && (
        <Insured
          formik={ formik }
          setCopyAddress={ setCopyAddress }
          copyAddress={ copyAddress }
          disableAllFields={ disableAllFields }
          isAdmin={ isAdmin }
          hideCheckBox={ hideCheckBox }
        />
      ) }
      { paymentType === paymentTypes.forgottenInsurance && isAdmin && (
        <ForgottenInsurance
          formik={ formik }
        />
      ) }
      {
        paymentType === paymentTypes.uninsured && (
          isAdmin
            ? (
                isAdmin && (
                <UnInsured
                  formik={ formik }
                  setValidationSchema2={ setValidationSchema2 }
                  disableAllFields={ disableAllFields }
                />
                )
              )
            : null
        )
      }
    </Stack>
  )
}

interface Props {
  formik: any
  paymentType: keyof PaymentTypesI
  setPaymentType: Dispatch<SetStateAction<keyof PaymentTypesI>>
  copyAddress: boolean
  setCopyAddress: Dispatch<SetStateAction<boolean>>
  disableAllFields: boolean
  setValidationSchema2: Dispatch<SetStateAction<'dl' | 'ssn' | 'insured' | null>>
  hideCheckBox: boolean
  isAdmin: boolean
}

export default PaymentInfo
