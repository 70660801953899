import { QuestionMark } from '@mui/icons-material'
import { Box, Button, Fab, IconButton, Link, Modal, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { t } from 'i18next'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { isEmpty } from 'lodash'
import theme from 'theme'

function BeaconNonSHK ({ isBeaconVisible } : Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  

  useEffect(() => {
    if (!mobile && isBeaconVisible) {
      window.addEventListener('isWhiteLabelHelpOpen', () => {
        setIsModalOpen(true)
      })
    }
    return () => {
      if (!mobile && isBeaconVisible) {
        window.removeEventListener('isWhiteLabelHelpOpen', () => {});
      }
    }
  }, [mobile])

  if (isBeaconVisible && !mobile) {
    return (
      <>
        <Fab color="primary" sx={{
          right: 20,
          bottom: 20,
          position: 'fixed'
        }} onClick={() => setIsModalOpen(true)}>
          <QuestionMark />
        </Fab>
        <ModalForBeacon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
      </>
    )
  }
  return (
    <></>
  )
}

export const ModalForBeacon = ({ isModalOpen, setIsModalOpen } : ModalProps) => {
  const whiteLabelConfig = useWhiteLabelConfig()

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-subject"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '.25rem',
          display: 'flex',
          flexFlow: 'column',
          gap: '1rem',
          maxHeight: '90%',
          overflowY: 'auto',
          maxWidth: '40rem',
          padding: '2rem',
          position: 'relative',
          width: '100%'
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5' sx={{ fontSize: '1.8rem' }}>{t('beacon.modal.main')}</Typography>
          <IconButton onClick={() => setIsModalOpen(false)} >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          component='form'
          autoComplete='off'
          sx={{ gap: '1rem', width: '100%' }}
        >
          <Typography variant='caption'>
            {t('beacon.modal.body')}
          </Typography>
        </Stack>
        { isEmpty(whiteLabelConfig?.supportEmail)
          ? ''
          : (
          <Link href={`mailto:${whiteLabelConfig?.supportEmail}`}>
            <Button variant="contained" fullWidth>
              {t('freeTestRemainder.feedback.emailBtn', { email: whiteLabelConfig?.supportEmail })}
            </Button>
          </Link>
            )}
        { isEmpty(whiteLabelConfig?.supportNumber)
          ? ''
          : (
          <Link href={`tel:${whiteLabelConfig?.supportNumber}`}>
            <Button variant="outlined" fullWidth>
              {t('beacon.modal.callBtn', { phone: whiteLabelConfig?.supportNumber })}
            </Button>
          </Link>
            )}

        { isEmpty(whiteLabelConfig?.workTimings || [])
          ? ''
          : (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>{t('beacon.modal.operationHours')}</Typography>
            <ul>
            {
              (whiteLabelConfig?.workTimings || [])?.map((data, key) => (
                <li key={key}>
                  <Stack direction={'row'} sx={ { gap: 0.5 }}>
                    <Typography>{data.key} :</Typography>
                    <Typography>{data.value}</Typography>
                  </Stack>
                </li>
              ))
            }
            </ul>

          </>
            )}

      </Box>
    </Modal>
  )
}

interface Props {
  isBeaconVisible: boolean
}

interface ModalProps {
  isModalOpen: boolean
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void
}

export default BeaconNonSHK
