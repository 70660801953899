import { FormikProps } from 'formik'
import { PrEPPharmacy } from 'types/db/SiteI'
import { Stack, Tooltip, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React from 'react'

const PharmacyMarker = ( { pharmacy, formik, onSelectionChange }: Props ) => {
  const isSelected = formik.values.zip === pharmacy.zip

  return (
    <Tooltip
      arrow
      title={(
        <Stack spacing={1}>
          <Typography variant="subtitle1" fontWeight="bold">{pharmacy.name}</Typography>
          <Typography variant="body2">{pharmacy.address}</Typography>
        </Stack>
      )}
    >
      <LocationOnIcon 
        sx={{ 
          color: isSelected ? '#0079FF' : '#E14D2A', 
          fontSize: isSelected ? '2.5rem' : '1.5rem',
          transition: 'all 0.2s ease-in-out',
          zIndex: isSelected ? 10 : 1,
          cursor: 'pointer',
          transform: 'translateZ(0) translate(-50%, -100%)',
          backfaceVisibility: 'hidden'
        }}
        onClick={() => onSelectionChange( pharmacy.zip )}
      />
    </Tooltip>

  )
}

interface Props {
  pharmacy: PrEPPharmacy
  formik: FormikProps<PrEPPharmacy>
  onSelectionChange: ( value: string ) => void
  lat: number
  lng: number
}

export default PharmacyMarker