import { SectionTitlePDF, TextContentContainerPDF, TextContentPDF } from './utils'
import { Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'
import { GroupedSamplesByTypeI } from '.'
import { SampleI } from '../../types/db/SampleI'

const SampleInformationPDF = ({ testOrdered = '', code = '', sampleType = '', collectionDate = '', samplesByType }: Props) => {
  const correctedSamples: Partial<SampleI>[] = []

  if (samplesByType) {
    const { qualitative = [], quantitative = [] } = samplesByType
    const samples = [...qualitative, ...quantitative]

    if (samples?.length > 0) {
      samples?.forEach(sample => {
        if (sample?.reason_updated && sample?.reason_updated.length > 0) correctedSamples.push(sample)
      })
    }
  }

  return (<>
    <View
      style={ {
        display: 'flex',
        flexDirection: 'column'
      } }
    >
      <SectionTitlePDF title="Sample Information" />

      <TextContentContainerPDF>
        <>
          <TextContentPDF title="Test Ordered:" content={ testOrdered } />
          <TextContentPDF title="Sample ID:" content={ code } />
        </>
      </TextContentContainerPDF>

      <TextContentContainerPDF>
        <>
          <TextContentPDF title="Sample Type:" content={ sampleType } />
          <TextContentPDF title="Collection Date:" content={ moment(collectionDate).format('MM/DD/YYYY') } />
        </>
      </TextContentContainerPDF>
    </View>

    {
      correctedSamples.length > 0
        ? (<View
          style={ {
            display: 'flex',
            flexDirection: 'column'
          } }
        >
          <Text>The lab has corrected the following result(s) in this report:</Text>

          <View
            style={ {
              display: 'flex',
              flexDirection: 'row',
              height: '25px',
              fontSize: '10px',
              alignItems: 'center',
              borderBottom: '1px solid #F0F0F0',
              borderTop: '1px solid #F0F0F0',
              gap: '4px'
            } }
          >
            <Text style={ { flex: 1, padding: '0px 8px' } }>Test</Text>
            <Text style={ { flex: 1, padding: '0px 8px' } }>Comments</Text>
          </View>

          {
            correctedSamples.map((sample, idx) => {
              const test = sample?.analyte_scientific_name || sample.analyte_name || ''
              const comments = sample?.reason_updated || ''

              return (<View
                key={ idx }
                wrap={ false }
                style={ {
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                  alignItems: 'flex-start',
                  borderBottom: '1px solid #F0F0F0',
                  gap: '4px'
                } }
              >
                <Text style={ { flex: 1, padding: '8px', fontWeight: 700 } }>{ test }</Text>
                <Text style={ { flex: 1, padding: '8px' } }>{ comments }</Text>
              </View>)
            })
          }
        </View>)
        : null
    }
  </>)
}

interface Props {
  testOrdered: string,
  code: string,
  sampleType: string,
  collectionDate: string
  samplesByType: GroupedSamplesByTypeI | undefined
}

export default SampleInformationPDF
