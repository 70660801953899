import { analyticEventTracker } from 'utils/analytics'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import cookieUtils from 'Core/utils/cookieUtils'
import React, { useState } from 'react'
import useAuth0 from 'hooks/useAuth0'
import { useTranslation } from 'react-i18next'

const LogoutDialog = ({ setIsLogoutConfirmationOpen, isLogoutConfirmationOpen, ...other }) => {
  const { logout } = useAuth0()
  const radioGroupRef = React.useRef(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleCloseDialog = () => {
    setIsLogoutConfirmationOpen(false)
  }

  const handleLogout = async () => {
    try {
      // This is used for analythics
      cookieUtils.deleteCookie('newUser')
      // This is used for analythics
      analyticEventTracker('Log out')
      setLoading(true)
      const pageInfo = window.document.title || 'N/A';
      await logout(pageInfo, { actionBy: 'User Action', reason: `User clicked on logout` })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 435 } }}
      maxWidth='xs'
      TransitionProps={{ onEntering: handleEntering }}
      open={isLogoutConfirmationOpen}
      onClose={handleCloseDialog}
      {...other}
    >
      <DialogTitle>{t('header.logoutDialog.title')}</DialogTitle>
      <DialogActions>
        <Button onClick={handleCloseDialog}>{t('header.logoutDialog.button.cancel')}</Button>
        <LoadingButton
          autoFocus
          loading={loading}
          onClick={handleLogout}
          variant='contained'
        >
          {t('header.logoutDialog.button.logout')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default LogoutDialog
