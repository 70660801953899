import { api } from '../../../Core'
import { Button, FormControl, FormControlLabel, FormLabel, IconButton, Modal, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { showSnackBar } from '../../../state/actions'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import * as yup from 'yup'
import LanguageIcon from '@mui/icons-material/Language'
import React, { useEffect, useState } from 'react'
import useAuth0 from '../../../hooks/useAuth0'
import { changeLanguage, getCurrentLanguage, LANGUAGES, T_LANGUAGE_TAGS } from 'i18n'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

const LanguageSelection = () => {
  const { i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Paper
        elevation={ 0 }
        sx={ {
          p: 2
        } }
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>{t('profile.languageSelection.language')}</Typography>
          <Button
            variant='text'
            startIcon={ <LanguageIcon /> }
            onClick={ () => setOpen(true) }
          >
            { LANGUAGES[getCurrentLanguage(i18n)] }
          </Button>
        </Stack>
      </Paper>
      <LanguageSelectionModal open={ open } onClose={ () => setOpen(false) } />
    </>
  )
}

const LanguageSelectionModal = ({ open, onClose, isNotAuthenticated = false }: ModalProps) => {
  const queryClient = useQueryClient()
  const params = useParams()
  const slug = params.slug
  const { getAccessTokenSilently } = useAuth0()
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedLang, setSelectedLang] = useState('')
  const handleClose = () => { onClose() }

  useEffect(() => {
    i18n.loadLanguages('es-US')
  }, [])

  const languageMutation = useMutation({
    mutationFn: (data: LanguageData) => api.auth.updateProfile(data),
    onSuccess: async (data) => {
      const accessToken = await getAccessTokenSilently(false).then(
        response => response
      )
      window.sessionStorage.setItem('token', accessToken.accessToken)
      changeLanguage(data?.user_metadata?.language || 'en-US')
      dispatch(
        showSnackBar({
          message: t('profile.languageSelection.languageSelectionModal.snackbar.success', { lng: selectedLang }),
          severity: 'success',
          show: true
        })
      )
    },
    onError: () => {
      dispatch(
        showSnackBar({
          message: t('profile.languageSelection.languageSelectionModal.snackbar.error'),
          severity: 'error',
          show: true
        })
      )
      formik.setFieldValue('language', i18n.language || 'en-US')
    }
  })

  const formik = useFormik({
    initialValues: {
      language: getCurrentLanguage(i18n)
    },
    validationSchema: yup.object({
      language: yup.string().required('profile.languageSelection.languageSelectionModal.validation.selectLang')
    }),
    onSubmit: formData => {
      if (isNotAuthenticated) {
        i18n.changeLanguage(formData.language)
      } else {
        languageMutation.mutate(formData as LanguageData)
      }

      // Invalidate the cache to refetch the data with the new language
      queryClient.invalidateQueries(['program-content', slug])
      handleClose()
    }
  })

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const locale = event.target.value
      formik.setFieldValue('language', locale)
      setSelectedLang(locale)
      await formik.handleSubmit()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={ {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      } }
    >
      <Stack
        gap={ 2 }
        sx={ {
          padding: 'clamp(1rem, 5vw, 2rem)',
          bgcolor: '#fff',
          borderRadius: '.25rem',
          width: '100%',
          maxWidth: '30rem',
          height: '100%',
          maxHeight: {
            xs: '100%',
            sm: '80vh'
          }
        } }
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' gap={ 2 } justifyContent='flex-start' alignItems='center'>
            <LanguageIcon />
            <Typography variant='h5' fontWeight='bold'>{t('profile.languageSelection.languageSelectionModal.preferLang')}</Typography>
          </Stack>
          <IconButton onClick={ handleClose }>
            <Close />
          </IconButton>
        </Stack>

        <Stack>
          <Stack gap={ 1 }>
            <FormControl disabled={ languageMutation.isLoading }>
              <FormLabel
                id="language-selection-radio-button"
                sx={ { mb: 1 } }
              >
                {t('profile.languageSelection.languageSelectionModal.selectLangPreference')}
              </FormLabel>
              <RadioGroup
                aria-labelledby="language-selection-radio-button"
                name="language-radio-buttons-group"
                value={ formik.values.language }
                onChange={ handleChange }
              >
                {
                  Object.keys(LANGUAGES).map((lan, index) => (
                    <FormControlLabel
                      key={ index }
                      value={ lan }
                      control={ <Radio sx={ { py: 0 } } /> }
                      label={ LANGUAGES[lan as T_LANGUAGE_TAGS] }
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  )
}

export { LanguageSelectionModal }

// interfaces
interface ModalProps {
  open: boolean
  onClose: () => void
  isNotAuthenticated?: boolean
}

interface LanguageData {
  language: string
}

export default LanguageSelection
