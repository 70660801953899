import React from 'react'
import { Typography, Stack, Stepper, Step, StepLabel } from '@mui/material'
// @ts-ignore
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface ProgramStepperProps {
    nextCycle : string
}

const ProgramStepper : React.FC<ProgramStepperProps> = ({ nextCycle }) => {

    const activeSteps = {
        Questionnaire_Complete: 0,
        Get_Tested: 1,
        Get_Presciption: 2
    }

    return (
        <Stepper
            orientation={'vertical'}
            activeStep={1}
            sx={{
            width: { sm: 416 },
            '& .MuiStepIcon-root.Mui-active': {
                color: 'success.main'
            },
            '& .MuiStepLabel-label.Mui-completed': {
                color: 'success.main'
            },
            '& .MuiStepLabel-label.Mui-active': {
                color: 'success.main',
                fontWeight: 'bold'
            },
            '& .MuiStepConnector-line': {
                color: 'success.main'
            }
            }}
        >
            {['Complete screening questionnaire', 'Get tested', 'Get prescription & Take PrEP regularly', `Next Cycle: ${nextCycle}`].map(label => {
            return (
                <Step key={label}>
                {label === 'Received' && false
                    ? <StepLabel sx={{ cursor: 'pointer' }} onClick={() => {}}>
                    <Stack spacing={1} direction='column'>
                        {label}
                        <Typography sx={{ fontSize: '12px', textDecoration: 'underline' }}>Track your kit! <OpenInNewIcon fontSize='inherit' /></Typography>
                    </Stack>
                    </StepLabel>
                    : <StepLabel>{label}</StepLabel>
                }
                </Step>
            )
            })}
        </Stepper>
    )
}

export default ProgramStepper
