import React from 'react'
import { Typography, Stack, Box, Grid } from '@mui/material'

interface CardRenderProps {
  title: string | undefined,
  bgColor: string | undefined,
  color: string | undefined,
  src: string | undefined
}

const CardRender : React.FC<CardRenderProps> = ({ title, bgColor, src, color }) => {
  
  return (
    <Grid container sx={{ background : bgColor, color : {color},  p : '20px', borderRadius : '5px' }}>
      <Grid item xs={6}> 
        <Typography variant="h6" component="h3" textAlign="left">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Stack
          component="img"
          src={`/assets/${src}`}
          alt={title}
          sx={{ ml: 'auto' }}
        />
      </Grid>
    </Grid>
  )
}

export default CardRender
