import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import FeedbackForm from 'components/FeedbackForm'
import { api } from 'Core'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { VideoTutorial, useRegisterKit } from '.'
import { useTranslation } from 'react-i18next'
import { isShkDomain } from 'utils/utilFuntions'
import { analyticEventTracker } from 'utils/analytics'

function RegisterConfirm () {
  const { t } = useTranslation()
  const { data: registerKitData, saveKitAction } = useRegisterKit()
  const isShk = isShkDomain()

  const createFeedbackMutation = useMutation(api.feedback.createFeedback)
  const updateFeedbackMutation = useMutation(api.feedback.updateFeedback)

  const [rating, setRating] = useState(0)

  const handleFeedbackChange = useCallback(
    (data: any) => {
      setRating(data?.rating)
      const feedbackData = {
        ...data,
        feature: 'register-kit',
        meta: { kitCode: registerKitData?.kit?.code || '' }
      }
      saveKitAction('Feedback', { info: 'Patient submited review' });
      analyticEventTracker(`(E) Updated Feedback Score on Registration Confirmation Page`, {
        category: 'Register kit'
      })
      
      if (createFeedbackMutation.data) {
        updateFeedbackMutation.mutate({
          id: createFeedbackMutation.data.id,
          data: feedbackData
        })
        return
      }
      createFeedbackMutation.mutate(feedbackData)
    },
    [createFeedbackMutation.data]
  )

  return (
    <Stack sx={{ gap: 3 }}>
      <Stack
        sx={{
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          width: '100%'
        }}
      >
        { isShk
          ? (
          <Box
            component='img'
            src='/assets/registration_completed.svg'
            alt='Completed Registration'
            sx={{
              width: { xs: '100%', sm: 264 },
              objectFit: 'cover'
            }}
          />
            )
          : null}

        <Alert severity='success' sx={{ alignItems: 'center' }}>
          {t('registerKit.success.alert')}
        </Alert>

      </Stack>

      <Typography variant='h5' component='h2'>{t('registerKit.success.whatNextTitle')}</Typography>
      <Typography variant='body1'>
        {t('registerKit.success.whatNextDescription')}
      </Typography>

      <VideoTutorial autoOpen={false} stepOverride={true} btnText={t('registerKit.success.watchTutorialBtn')}/>
      <FeedbackForm
        title={t('registerKit.success.feedbackFormTitle')}
        onChange={handleFeedbackChange}
      />

      <Stack 
        spacing={2} 
        textAlign='center'
        onClick={() => {
          analyticEventTracker(`(E) Back to Dashboard Button Clicked on Registration Confirmation Page`, {
            category: 'Register kit'
          })
        }}
      >
        <Button
          component={Link}
          to={rating && rating >= 4 ? `/?reviewId=${registerKitData?.kit?.code}` : '/'}
          variant='contained'
          fullWidth
        >
          {t('registerKit.success.gotoDashboardBtn')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default RegisterConfirm
