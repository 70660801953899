import React, { useState } from 'react'
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  createFilterOptions
} from '@mui/material'
import useFormik from 'hooks/useFormik'
import { Heading } from '.'
import {
  MultiStepFormNavigation,
  useMultiStepForm
} from 'components/MultiStepForm'
import { insuranceNames } from 'components/PatientComponents/data/insuranceNames'
import Address from 'components/PatientComponents/MedicalData/Address'
import {
  gender,
  sexAssignedAtBirth
} from 'components/PatientComponents/data/dropDownOptions'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from 'moment'
import { PrEPProgramConfig } from 'types/db/SiteI'
import { TPrepProgram, insuranceInfo } from './schemas'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import { PatientI } from 'types/db/PatientI'

interface RenderLabelProps {
  title: string | undefined
  body: string | undefined
}

const RenderLabel: React.FC<RenderLabelProps> = ({ title, body }) => {
  return (
    <Stack>
      <Typography variant='subtitle1' fontWeight={700} sx={{ mb: '5px' }}>
        {title}
      </Typography>
      <Typography variant='body1' component='h6'>
        {body}
      </Typography>
    </Stack>
  )
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: any) => option.name
})

const relation = ['Self', 'Child', 'Parent', 'Spouse']

const genderOptions = gender?.map((data, i) => ({
  name: data.name,
  value: data.value,
  info: data.info,
  id: i
}))

const relationOptions = relation?.map((data, i) => ({
  name: data,
  id: i
}))

const sexAssignedAtBirthOptions = sexAssignedAtBirth.map((data, i) => ({
  label: data.name,
  value: data.value,
  id: i
}))

function InsuranceInfo ({
  prepProgramConfig
}: {
  prepProgramConfig: PrEPProgramConfig
}) {
  const { data, update } = useMultiStepForm<TPrepProgram>()

  const patientsQuery = useQuery(['patients'], api.patient.getAll)

  if (patientsQuery.isLoading) return <Typography>Loading patients!</Typography>
  if (patientsQuery.isError) return <Typography>Error loading patients!</Typography>

  const patients = patientsQuery.data

  const selectedPatient = patients.find(patient => patient.id === data.patientSelection.patientId as number) as PatientI

  const insuranceInfoContent = prepProgramConfig.content.insuranceInfo

  const formik = useFormik({
    initialValues: {
      ...data.insuranceInfo,
      insurance: {
        primaryInsuranceName: data.insuranceInfo.insurance?.primaryInsuranceName || selectedPatient.insurance?.insurance_name,
        policyNumber: data.insuranceInfo.insurance?.policyNumber || selectedPatient.insurance?.policy_number,
        groupNumber: data.insuranceInfo.insurance?.groupNumber || selectedPatient.insurance?.group_number,
        patientRelationshipToInsured: data.insuranceInfo.insurance?.patientRelationshipToInsured || selectedPatient.insurance?.relationship,
        insuredFirstName: data.insuranceInfo.insurance?.insuredFirstName || selectedPatient.insurance?.insured_first_name,
        insuredLastName: data.insuranceInfo.insurance?.insuredLastName || selectedPatient.insurance?.insured_last_name,
        insuredGender: data.insuranceInfo.insurance?.insuredGender || selectedPatient.insurance?.insured_gender,
        insuranceDisclaimer: null,
        sexAssignedAtBirth: data.insuranceInfo.insurance?.sexAssignedAtBirth,
        dob:
          data.insuranceInfo.insurance?.dob
            ? moment(
              data.insuranceInfo.insurance?.dob,
              'MM-DD-YYYY'
            ).format('MM-DD-YYYY')
            : moment(
              selectedPatient.insurance?.insured_dob,
              'YYYY-MM-DD'
            ).format('MM-DD-YYYY'),
        insuredDOB:
          data.insuranceInfo.insurance?.dob
            ? moment(
              data.insuranceInfo.insurance?.dob,
              'MM-DD-YYYY'
            ) || null
            : moment(
              selectedPatient.insurance?.insured_dob,
              'YYYY-MM-DD'
            ) || null,
        addressLine1: data.insuranceInfo.insurance?.addressLine1 || selectedPatient.insurance?.insured_address?.delivery_line_1,
        city: data.insuranceInfo.insurance?.city || selectedPatient.insurance?.insured_address?.city_name,
        state: data.insuranceInfo.insurance?.state || selectedPatient.insurance?.insured_address?.state_abbreviation,
        zipCode: data.insuranceInfo.insurance?.zipCode || selectedPatient.insurance?.insured_address?.zipcode
      }
    },
    validationSchema: insuranceInfo,
    onSubmit: formData => {
      const updatedData = {
        ...data,
        insuranceInfo: {
          ...formData,
          insurance: formData.paymentType === 'withInsurance' ? { ...formData.insurance } : null
        } as TPrepProgram[ 'insuranceInfo' ]
      }
      update(updatedData)
    }
  })

  const [inputValue, setInputValue] = useState(
    formik.values.insurance?.primaryInsuranceName &&
      insuranceNames.find(
        gen => gen.label === formik.values.insurance?.primaryInsuranceName
      )
      ? insuranceNames.find(
        gen => gen.label === formik.values.insurance?.primaryInsuranceName
      )
      : {
          id: '',
          label: '',
          bill_type_id: ''
        }
  )
  const [inputValue2, setInputValue2] = useState(
    formik.values.insurance?.insuredGender &&
      genderOptions.find(
        gen => gen.value === formik.values.insurance?.insuredGender
      )
      ? genderOptions.find(
        gen => gen.value === formik.values.insurance?.insuredGender
      )
      : {
          name: '',
          value: '',
          info: '',
          id: ''
        }
  )
  const [inputValue3, setInputValue3] = useState(
    formik.values.insurance?.patientRelationshipToInsured &&
      relationOptions.find(
        gen =>
          gen.name === formik.values.insurance?.patientRelationshipToInsured
      )
      ? relationOptions.find(
        gen =>
          gen.name === formik.values.insurance?.patientRelationshipToInsured
      )
      : {
          name: '',
          id: ''
        }
  )

  return (
    <>
      <Heading>{insuranceInfoContent.title}</Heading>
      <Typography variant='body1' component='h6' sx={{ my: '18px' }}>
        {insuranceInfoContent.questions.paymentType.title}
      </Typography>
      <Stack
        sx={{ my: '17px' }}
        gap={2}
        component='form'
        onSubmit={formik.handleSubmit}
      >
        <FormControl error={formik.isError('paymentType')}>
          <RadioGroup
            name={'paymentType'}
            value={formik.values.paymentType}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value={'withInsurance'}
              control={<Radio />}
              label={
                <RenderLabel
                  title={
                    insuranceInfoContent.questions.paymentType.options
                      .withInsurance.title
                  }
                  body={
                    insuranceInfoContent.questions.paymentType.options
                      .withInsurance.helperText
                  }
                />
              }
            />
            <FormControlLabel
              value={'withoutInsurance'}
              control={<Radio />}
              label={
                <RenderLabel
                  title={
                    insuranceInfoContent.questions.paymentType.options
                      .withoutInsurance.title
                  }
                  body={
                    insuranceInfoContent.questions.paymentType.options
                      .withoutInsurance.helperText
                  }
                />
              }
            />
          </RadioGroup>
          {formik.isError('paymentType')
            ? (
            <FormHelperText>{`${formik.errors.paymentType || ''}`}</FormHelperText>
              )
            : null}
        </FormControl>
        {formik.values.paymentType
          ? (
          <Alert
            severity='info'
            sx={{
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center'
              }
            }}
          >
            <Typography variant='body1'>
              {
                insuranceInfoContent.questions.paymentType.options[
                  formik.values.paymentType as
                  | 'withInsurance'
                  | 'withoutInsurance'
                ].alertText
              }
            </Typography>
          </Alert>
            )
          : (
              ''
            )}
        {formik.values.paymentType === 'withInsurance'
          ? (
          <>
            <Heading>{insuranceInfoContent.insuranceForm.title}</Heading>
            <Grid item container spacing={2}>
              <Grid item md={6} xs={12}>
                {insuranceNames && (
                  <Autocomplete
                    id='insurance.primaryInsuranceName'
                    options={insuranceNames}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    getOptionLabel={option => option.label}
                    renderOption={(props, data) => (
                      <Box component='li' {...props} key={data?.id}>
                        {data?.label}
                      </Box>
                    )}
                    filterOptions={filterOptions}
                    value={inputValue}
                    onChange={(e, newValue) => {
                      if (newValue?.label) {
                        setInputValue(newValue)
                        formik.setFieldValue(
                          'insurance.primaryInsuranceName',
                          newValue.label
                        )
                        formik.setFieldValue(
                          'insurance.primary_insurance_id',
                          newValue.id
                        )
                      } else {
                        formik.setFieldValue('insurance.primaryInsuranceName', '')
                        formik.setFieldValue('insurance.primary_insurance_id', null)
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Primary Insurance Name *'
                        onFocus={event =>
                          event.target.setAttribute('autocomplete', 'off')
                        }
                        name='insurance.primaryInsuranceName'
                        error={
                          Boolean(formik.isError('insurance.primaryInsuranceName'))
                        }
                        helperText={
                          formik.isError('insurance.primaryInsuranceName')
                            ? 'Required field'
                            : null
                        }
                        FormHelperTextProps={{
                          sx: { color: 'primary.red' }
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label='Policy Number *'
                  variant='outlined'
                  type='text'
                  name='insurance.policyNumber'
                  autoComplete='off'
                  onFocus={event =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  id='policyNumber'
                  value={
                    formik.values.insurance?.policyNumber === 'CLIENT BILL'
                      ? ''
                      : formik.values.insurance?.policyNumber
                  }
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.isError('insurance.policyNumber')
                  }
                  helperText={
                    formik.isError('insurance.policyNumber')
                      ? 'Required field'
                      : null
                  }
                  FormHelperTextProps={{
                    sx: { color: 'primary.red' }
                  }}
                />
              </Grid>

              <Grid xs={12} item md={6}>
                <TextField
                  label='Group Number'
                  variant='outlined'
                  type='text'
                  name='insurance.groupNumber'
                  id='groupNumber'
                  autoComplete='off'
                  onFocus={event =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  fullWidth
                  value={formik.values.insurance?.groupNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.isError('insurance.groupNumber')
                  }
                  helperText={
                    formik.isError('insurance.groupNumber')
                      ? 'Required field'
                      : null
                  }
                  FormHelperTextProps={{
                    sx: { color: 'primary.red' }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id='patientRelationshipToInsured'
                  renderOption={(props, data) => (
                    <Box component='li' {...props} key={data?.id}>
                      {data?.name}
                    </Box>
                  )}
                  autoComplete={false}
                  options={relationOptions}
                  filterOptions={filterOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value
                  }
                  getOptionLabel={option => option.name}
                  value={inputValue3 || null}
                  onChange={(e, newValue) => {
                    if (newValue?.name) {
                      setInputValue3(newValue)
                      if (newValue.name === 'Self') {
                        formik.setFieldValue('insurance', {
                          ...formik.values.insurance,
                          patientRelationshipToInsured:
                            newValue.name,
                          addressLine1:
                            selectedPatient.address
                              ?.delivery_line_1,
                          city: selectedPatient.address
                            ?.city_name,
                          state:
                            selectedPatient.address
                              ?.state_abbreviation,
                          zipCode:
                            selectedPatient.address?.zipcode,
                          sexAssignedAtBirth: selectedPatient.sex_assigned_at_birth,
                          insuredGender: selectedPatient.gender,
                          dob: moment(
                            selectedPatient.dob,
                            'YYYY-MM-DD'
                          ).format('MM-DD-YYYY') || '',
                          insuredDOB:
                            moment(
                              selectedPatient.dob,
                              'YYYY-MM-DD'
                            ),
                          insuredLastName:
                            selectedPatient.last_name,
                          insuredFirstName:
                            selectedPatient.first_name
                        })

                        setInputValue2(
                          genderOptions.find(
                            gen =>
                              gen.value ===
                              selectedPatient.gender
                          )
                        )
                      } else {
                        formik.setFieldValue('insurance.insuredFirstName', '')
                        formik.setFieldValue('insurance.insuredLastName', '')
                        formik.setFieldValue('insurance.insuredGender', '')
                        formik.setFieldValue('insurance.insuredAddress', '')
                        formik.setFieldValue('insurance.sexAssignedAtBirth', '')
                        formik.setFieldValue('insurance.dob', '')
                        formik.setFieldValue('insurance.addressLine1', '')
                        formik.setFieldValue('insurance.city', '')
                        formik.setFieldValue('insurance.state', '')
                        formik.setFieldValue('insurance.zipCode', '')
                        formik.setFieldValue('insurance.insuredDOB', null)
                        setInputValue2({
                          name: '',
                          value: '',
                          info: '',
                          id: ''
                        })
                      }
                    } else {
                      formik.setFieldValue('insurance.patientRelationshipToInsured', '')
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Patient Relationship to Insured *'
                      autoComplete='off'
                      onFocus={event =>
                        event.target.setAttribute('autocomplete', 'off')
                      }
                      sx={{
                        '& .Mui-disabled': {
                          color: '#666666a6'
                        },
                        '& .MuiInputBase-input': {
                          cursor: 'inherit',
                          WebkitTextFillColor: '#212121'
                        }
                      }}
                      name='insurance.patientRelationshipToInsured'
                      error={
                        formik.isError('insurance.patientRelationshipToInsured')
                      }
                      helperText={
                        formik.isError('insurance.patientRelationshipToInsured')
                          ? 'Required field'
                          : null
                      }
                      FormHelperTextProps={{
                        sx: { color: 'primary.red' }
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label='Insured First Name *'
                  variant='outlined'
                  type='text'
                  autoComplete='off'
                  onFocus={event =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  name='insurance.insuredFirstName'
                  id='insuredFirstName'
                  fullWidth
                  value={formik.values.insurance?.insuredFirstName || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.isError('insurance.insuredFirstName')
                  }
                  helperText={
                    formik.isError('insurance.insuredFirstName')
                      ? 'Required field'
                      : null
                  }
                  FormHelperTextProps={{
                    sx: { color: 'primary.red' }
                  }}
                  sx={{
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: 'default',
                      WebkitTextFillColor: '#212121'
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label='Insured Last Name *'
                  variant='outlined'
                  autoComplete='off'
                  onFocus={event =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  type='text'
                  name='insurance.insuredLastName'
                  id='insuredLastName'
                  fullWidth
                  value={formik.values.insurance?.insuredLastName || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.isError('insurance.insuredLastName')
                  }
                  helperText={
                    formik.isError('insurance.insuredLastName')
                      ? 'Required field'
                      : null
                  }
                  FormHelperTextProps={{
                    sx: { color: 'primary.red' }
                  }}
                  sx={{
                    '& .Mui-disabled': {
                      color: '#666666a6'
                    },
                    '& .MuiInputBase-input': {
                      cursor: 'default',
                      WebkitTextFillColor: '#212121'
                    }
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Insured Date of Birth *'
                    maxDate={moment().toDate()}
                    value={formik.values.insurance?.insuredDOB ? moment(formik.values.insurance?.insuredDOB).toDate() : null}
                    onChange={newValue => {
                      const dob = moment(newValue).format('MM-DD-YYYY')
                      formik.setFieldValue('insurance.insuredDOB', newValue || '')
                      formik.setFieldValue('insurance.dob', dob || '')
                    }}
                    slotProps={{
                      textField: {
                        error: formik.isError('insurance.dob'),
                        helperText:
                          formik.isError('insurance.dob')
                            ? 'Required field'
                            : null,
                        fullWidth: true,
                        FormHelperTextProps: {
                          sx: { color: 'primary.red' }
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id='sexAssignedAtBirth'
                  autoComplete={false}
                  filterOptions={filterOptions}
                  options={sexAssignedAtBirthOptions}
                  value={formik.values.insurance?.sexAssignedAtBirth || ''}
                  onChange={(e, newValue) => {
                    if (newValue?.label) {
                      formik.setFieldValue(
                        'insurance.sexAssignedAtBirth',
                        newValue?.value
                      )
                    } else {
                      formik.setFieldValue('insurance.sexAssignedAtBirth', '')
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Sex Assigned at Birth *'
                      autoComplete='off'
                      onFocus={event =>
                        event.target.setAttribute('autocomplete', 'off')
                      }
                      name='insurance.sexAssignedAtBirth'
                      error={
                        formik.isError('insurance.sexAssignedAtBirth')
                      }
                      helperText={
                        formik.isError('insurance.sexAssignedAtBirth')
                          ? 'Required field'
                          : null
                      }
                      FormHelperTextProps={{
                        sx: { color: 'primary.red' }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id='gender'
                  filterOptions={filterOptions}
                  autoComplete={false}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  getOptionLabel={option => option.name}
                  onFocus={event =>
                    event.target.setAttribute('autocomplete', 'off')
                  }
                  options={genderOptions}
                  value={inputValue2 || null}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack sx={{ p: '5px', px: '10px' }}>
                        <Typography sx={{ width: '100%' }}>
                          {option.name}
                        </Typography>
                        <Typography
                          sx={{ width: '100%', color: '#00000099' }}
                          variant={'caption'}
                        >
                          {option.info}
                        </Typography>
                      </Stack>
                    </li>
                  )}
                  onChange={(e, newValue) => {
                    if (newValue?.name) {
                      formik.setFieldValue('insurance.insuredGender', newValue.value)
                      setInputValue2(newValue)
                    } else {
                      formik.setFieldValue('insurance.insuredGender', '')
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Gender *'
                      autoComplete='off'
                      onFocus={event =>
                        event.target.setAttribute('autocomplete', 'off')
                      }
                      sx={{
                        '& .Mui-disabled': {
                          color: '#666666a6'
                        },
                        '& .MuiInputBase-input': {
                          cursor: 'inherit',
                          WebkitTextFillColor: '#212121'
                        }
                      }}
                      name='insurance.insuredGender'
                      error={
                        formik.isError('insurance.insuredGender')
                      }
                      helperText={
                        formik.isError('insurance.insuredGender')
                          ? 'Required field'
                          : null
                      }
                      FormHelperTextProps={{
                        sx: { color: 'primary.red' }
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Address
                  formik={formik}
                  copyAddress={false}
                  disabled={false}
                  name='insurance'
                  defaultCountry='US'
                  testProgramSetting={false}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <FormControl
                    error={
                      formik.isError('insurance.insuranceDisclaimer')
                    }
                  >
                    <FormControlLabel
                      sx={{ p: 1 }}
                      control={
                        <Checkbox
                          color='primary'
                          name='insurance.insuranceDisclaimer'
                          checked={formik.values.insurance?.insuranceDisclaimer}
                          onChange={(e) => {
                            formik.setFieldValue('insurance.insuranceDisclaimer', [e.target.checked])
                          }}
                        />
                      }
                      label={insuranceInfoContent.insuranceForm.disclaimer}
                    />
                    {
                      formik.isError('insurance.insuranceDisclaimer')
                        ? <FormHelperText error>
                          Required field
                        </FormHelperText>
                        : null
                    }
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </>
            )
          : (
              ''
            )}
        <MultiStepFormNavigation />
      </Stack>
    </>
  )
}

export default InsuranceInfo
