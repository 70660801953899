import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert
} from '@mui/material'
import { CardPaymentDetails, TextButton } from 'components'
import moment from 'moment-timezone'
import CloseIcon from '@mui/icons-material/Close'
import { RegisterKitNavigation, useRegisterKit } from '.'
import { formatDate, isShkDomain } from 'utils/utilFuntions'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { Navigate, useNavigate, useParams } from 'react-router'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import useFormik from 'hooks/useFormik'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import { Prompt } from 'components/Prompt'
import useStateCallback from 'hooks/useStateCallback'
import { analyticEventTracker } from 'utils/analytics'
// import { gender } from 'components/PatientComponents/data/dropDownOptions'
import { Trans, useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import RichTextEditor, { createEditorStateFromRaw } from 'components/RichTextEditor'
import DOMPurify from 'dompurify'
function getValidationSchema (t: TFunction<'frontend' | 'backend', undefined>, firstName: string, lastName: string) {
  const fullName = `${firstName.trim()} ${lastName.trim()}`

  return yup.object().shape({
    reviewTnCDisclaimer: yup
      .boolean()
      .oneOf([true], t('registerKit.review.reviewTnCDisclaimerRequiredError')),
    reviewTnCTwo: yup
      .boolean()
      .oneOf([true], t('registerKit.review.reviewTnCTwoRequiredError')),
    fullName: yup.string()
      .required('Full name is required')
      .test('fullName-match', 'Signature does not match. Please ensure it is the same as your patient name.', function (value) {
        return (value ?? '').trim().toLowerCase() === fullName.trim().toLowerCase()
      })
  })
}

const ReviewForm = () => {
  const { t } = useTranslation()
  const { data, saveKitAction } = useRegisterKit()
  const { kitId } = useParams()
  const dispatch = useDispatch()
  const [showPromptOnExit, setShowPromptOnExit] = useStateCallback(true)
  const isSHK = isShkDomain()
  const navigate = useNavigate()
  const whitelabelConfig = useWhiteLabelConfig()

  const formik = useFormik({
    initialValues: data.reviewForm,
    validationSchema: getValidationSchema(t as unknown as TFunction<'frontend'>, data?.patientInfoForm?.patient?.first_name || '', data?.patientInfoForm?.patient?.last_name || ''),
    onSubmit: async () => {
      setShowPromptOnExit(false)
      try {
        const collectedDate = new Date(data.sampleCollectionInfoForm.collectedDate)
        const collectedTime = new Date(data.sampleCollectionInfoForm.collectedTime)

        const finalDate = new Date(
          collectedDate.getFullYear(),
          collectedDate.getMonth(),
          collectedDate.getDate(),
          collectedTime.getHours(),
          collectedTime.getMinutes(),
          collectedTime.getSeconds(),
          collectedTime.getMilliseconds()
        )

        const collectedAt = moment.tz(finalDate, 'America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
        await api.kit.update(kitId, {
          // add payment information here
          collected_at: collectedAt,
          pageInfo: 'Review'
        })
        saveKitAction('Review')
        await api.kit.getAll()
        analyticEventTracker('New kit registered', { category: 'Register kit' })
        dispatch({ type: 'ui/newKitForm/step', payload: null })
        navigate('../success')
      } catch (error) {
        console.error(error)
        dispatch(
          showSnackBar({
            show: true,
            message: 'Something went wrong',
            severity: 'error'
          })
        )
      }
    }
  })

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showPromptOnExit
  )

  const [openDisclaimer, setOpenDisclaimer] = useState(false)
  const [openConsent, setOpenConsent] = useState(false)
  const [openReminder, setOpenReminder] = useState(false)

  if (!data.sampleCollectionInfoForm.collectedTime) return <Navigate replace to='../sample-collection' />

  return (
    <Stack sx={ { gap: 3 } } autoComplete='off' component='form' onSubmit={ formik.handleSubmit }>
      <Prompt
        open={ showPrompt && showPromptOnExit }
        onClose={ cancelNavigation }
        confirmNavigation={ confirmNavigation }
      />
      <Typography variant='h5' component='h2'>
        { t('registerKit.review.title') }
      </Typography>
      <Typography variant='subtitle1' component='h3'>
        { t('registerKit.review.subtitle') }
      </Typography>

      <Box sx={ { textAlign: 'left' } }>
        <Typography my={ 1 } variant='h6' component='h3'>
          { t('registerKit.review.kitInformationSectionTitle') }
        </Typography>
        <Box pb={ 1 } sx={ { display: 'flex', justifyContent: 'space-between' } }>
          <Box width={ 160 }>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>{ t('registerKit.review.kitInformationTestName') }</Typography>
              <Typography>
                { data?.kit?.kitTypeConfig?.name }
              </Typography>
            </Box>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>{ t('registerKit.review.kitInformationSampleID') }</Typography>
              <Typography>{ data?.kit?.code }</Typography>
            </Box>
          </Box>
          <Box width={ 160 }>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>
                { t('registerKit.review.kitInformationCollectionDate') }
              </Typography>
              <Typography>
                { formatDate(data.sampleCollectionInfoForm.collectedDate) }
              </Typography>
            </Box>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>
                { t('registerKit.review.kitInformationCollectionTime') }
              </Typography>
              <Typography>
                { moment(data.sampleCollectionInfoForm.collectedTime).format('hh:mm A') }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Typography my={ 1 } variant='h6' component='h3'>
          { t('registerKit.review.patientInformationSectionTitle') }
        </Typography>
        <Box pb={ 1 } sx={ { display: 'flex', justifyContent: 'space-between' } }>
          <Box width={ 160 }>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>{ t('registerKit.review.patientInformationFirstName') }</Typography>
              <Typography>{ data?.patientInfoForm?.patient?.first_name }</Typography>
            </Box>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>{ t('registerKit.review.patientInformationDateOfBirth') }</Typography>
              <Typography>
                { formatDate(data?.patientInfoForm?.patient?.dob || '') }
              </Typography>
            </Box>
          </Box>
          <Box width={ 160 }>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>{ t('registerKit.review.patientInformationLastName') }</Typography>
              <Typography>{ data?.patientInfoForm?.patient?.last_name }</Typography>
            </Box>
            <Box my={ 0.5 }>
              <Typography sx={ { fontWeight: 'bold' } }>{ t('registerKit.review.patientInformationSexAssignedAtBirth') }</Typography>
              <Typography>{ data?.patientInfoForm?.patient?.sex_assigned_at_birth || 'Other' }</Typography>
            </Box>
          </Box>
        </Box>
        { data?.kit?.squarePaymentToken
          ? <Stack>
            <Divider />
            <Typography my={ 1 } variant='h6' component='h3'>
              { t('registerKit.review.paymentInformationSectionTitle') }
            </Typography>
            <CardPaymentDetails squarePaymentToken={ data?.kit?.squarePaymentToken } />
          </Stack>
          : null }
      </Box>
      <Stack spacing={ 1.5 } sx={ { gap: 1 } }>
        <FormControl error={ formik.isError('reviewTnCTwo') }>
          <FormControlLabel
            onChange={ formik.handleChange }
            control={
              <Checkbox
                checked={ !!formik.values.reviewTnCTwo }
                disabled={ formik.isSubmitting }
                name='reviewTnCTwo'
                onChange={ formik.handleChange }
                sx={ {
                  color: formik.isError('reviewTnCTwo')
                    ? '#EB0017'
                    : '#212121'
                } }
              />
            }
            label={
              <span style={ { display: 'flex', textAlign: 'start' } }>
                <Typography>
                  <Trans i18nKey='registerKit.review.reviewTnCTwoLabel'>
                    I understand that if I fail to <strong>collect all the required samples according to the instructions</strong>, the lab will only process the available samples and I may not be eligible for a replacement kit.
                  </Trans>
                </Typography>
              </span>
            }
          />
          { formik.isError('reviewTnCTwo')
            ? (
              <FormHelperText>{ formik.getError('reviewTnCTwo') }</FormHelperText>
              )
            : null }
        </FormControl>
        <FormControl error={ formik.isError('reviewTnCDisclaimer') }>
          <FormControlLabel
            onChange={ formik.handleChange }
            control={
              <Checkbox
                checked={ !!formik.values.reviewTnCDisclaimer }
                disabled={ formik.isSubmitting }
                name='reviewTnCDisclaimer'
                onChange={ formik.handleChange }
                sx={ {
                  color: formik.isError('reviewTnCDisclaimer')
                    ? '#EB0017'
                    : '#212121'
                } }
              />
            }
            label={
              <Typography>
                <Trans i18nKey='registerPatient.disclaimer.readAndAgreeTo'>
                  I have read and agree to the 
                  <TextButton onClick={ () => 
                    {
                      analyticEventTracker(`(E) Opened Disclaimer Modal on Registration Review Page`, {
                        category: 'Register kit'
                      })
                      setOpenDisclaimer(true) 
                    }}
                  >disclaimer</TextButton> and <TextButton onClick={ () => {
                    analyticEventTracker(`(E) Opened Consent Modal on Registration Review Page`, {
                      category: 'Register kit'
                    })
                    setOpenConsent(true) 
                  }}>consent</TextButton>
                </Trans>
              </Typography>
            }
          />
          { formik.isError('reviewTnCDisclaimer')
            ? (
              <FormHelperText>{ formik.getError('reviewTnCDisclaimer') }</FormHelperText>
              )
            : null }
        </FormControl>
        <Typography component='label' htmlFor='site-code'>
          { t('registerKit.patientDetails.disclaimer.sign.title') }
        </Typography>
        <FormControl sx={ { gap: 1 } } error={ formik.isError('fullName') }>
          <TextField
            id='site-code'
            name='fullName'
            label={ t(
              'registerKit.patientDetails.disclaimer.sign.fullNameSign.label'
            ) }
            value={ formik.values.fullName }
            onChange={ evt => {
              formik.handleChange(evt)
            } }
            helperText={ formik.getError('fullName') }
            error={ formik.getError('fullName') }
          />
        </FormControl>
      </Stack>

      <RegisterKitNavigation
        back={
          <Button
            onClick={ () => {
              analyticEventTracker(`(E) Back Button Clicked on Registration Review Page`, {
                category: 'Register kit'
              })
              setShowPromptOnExit(false, () => navigate('../sample-collection'))
            }}
          >
            { t('multiFormNavigation.back') }
          </Button>
        }
        next={
          <LoadingButton
            onClick={ async () => {
              analyticEventTracker(`(E) Submit Button Clicked and Open Reminder Modal on Registration Review Page`, {
                category: 'Register kit'
              })
              setOpenReminder(true)
            }}
            loading={ formik.isSubmitting }
            type='button'
            variant='contained'
          >
            { t('multiFormNavigation.submit') }
          </LoadingButton>
        }
      />

      <DisclaimerDialog
        open={ openDisclaimer }
        onClose={ () => setOpenDisclaimer(false) }
        isSHK={ isSHK }
        whiteLabelConfig={ whitelabelConfig }
      />
      <ConsentDialog
        whiteLabelConfig={ whitelabelConfig }
        isSHK={ isSHK }
        open={ openConsent }
        onClose={ () => setOpenConsent(false) }
      />
      <Dialog open={ openReminder } PaperProps={ {
        sx: {
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }
      } }>
        <DialogTitle
          sx={ {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: '1.5rem',
            p: 0
          } }
        >
          { t('registerKit.review.reminderDialogTitle') }
        </DialogTitle>
        <DialogContent sx={ { p: 0 } }>
          <Stack gap={ 2 }>
            <Typography variant='body1'>
              { t('registerKit.review.reminderDialogDescription') }
            </Typography>

            <Alert severity='info' sx={ { alignItems: 'center' } }>
              <Trans i18nKey='registerKit.review.shippingDelayAlert'>
                To avoid shipping delays, please drop your sample(s) off at the <strong>designated courier office within 24 hours!</strong>
              </Trans>
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={ theme => ({
            p: 0,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              justifyContent: 'center',
              paddingX: `${theme.spacing(2)}`,
              gap: 2
            }
          }) }
        >
          <Button
            onClick={ () => {
              analyticEventTracker(`(E) Close Reminder Modal on Registration Review Page`, {
                category: 'Register kit'
              })
              setOpenReminder(false)
            } }
            sx={ theme => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }) }
          >
            { t('registerKit.review.reminderDialogGoBack') }
          </Button>
          <Button
            variant='contained'
            onClick={ () => {
              analyticEventTracker(`(E) Clicked on Confirmed on Reminder Modal on Registration Review Page`, {
                category: 'Register kit'
              })
              formik.submitForm()
              setOpenReminder(false)
            } }
            sx={ theme => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }) }
          >
            { t('registerKit.review.reminderDialogIveAddedTheDetails') }
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default ReviewForm

function DisclaimerDialog ({ open, onClose, isSHK, whiteLabelConfig }: { open: boolean, onClose: () => void, isSHK: boolean, whiteLabelConfig: any }) {
  const { t } = useTranslation()
  const { data } = useRegisterKit()
  if (!data) return null
  const site = data.site
  if (!site) return null

  return (
    <Dialog open={ open } onClose={ onClose }>
      <Box p={ 1 } sx={ { textAlign: 'center' } }>
        {
          site.is_hidden
            ? null
            : (
              <>
                <Typography variant='h6'>{ site.name }</Typography>
                { site.address }
              </>
              )
        }
      </Box>
      <IconButton
        onClick={ onClose }
        sx={ { position: 'absolute', right: '8px', top: '8px' } }
      >
        <CloseIcon />
      </IconButton>
      { !site.is_hidden && <Divider /> }
      <Box p={ 1 }>
        <Typography variant='h5' sx={ { fontWeight: 700, textAlign: 'center' } }>
          <Box component='span' sx={ { fontWeight: 'normal' } }>
            { isSHK ? t('registerPatient.disclaimer.dialog.shk') : whiteLabelConfig?.siteName }{ ' ' }
          </Box>
          { t('registerPatient.disclaimer.dialog.text') }
        </Typography>
        {
          site?.disclaimer
            ? (
              <RichTextEditor editorState={ createEditorStateFromRaw(site?.disclaimer) } readOnly />
              )
            : (
              <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(site?.patient_disclaimer || '') } } />
              )
        }
      </Box>
    </Dialog>
  )
}

function ConsentDialog ({ open, onClose, isSHK, whiteLabelConfig }: { open: boolean, onClose: () => void, isSHK: boolean, whiteLabelConfig: any }) {
  const { t } = useTranslation()
  const { data } = useRegisterKit()
  if (!data) return null
  const site = data.site
  if (!site) return null

  return (
    <Dialog open={ open } onClose={ onClose }>
      <Box p={ 1 } sx={ { textAlign: 'center' } }>
        {
          site.is_hidden
            ? null
            : (
              <>
                <Typography variant='h6'>{ site.name }</Typography>
                { site.address }
              </>
              )
        }
      </Box>
      <IconButton
        onClick={ onClose }
        sx={ { position: 'absolute', right: '8px', top: '8px' } }
      >
        <CloseIcon />
      </IconButton>
      { !site.is_hidden && <Divider /> }
      <Box p={ 1 }>
        <Typography variant='h5' sx={ { fontWeight: 700, textAlign: 'center' } }>
          <Box component='span' sx={ { fontWeight: 'normal' } }>
            { isSHK ? t('registerPatient.disclaimer.dialog.shk') : whiteLabelConfig?.siteName }{ ' ' }
          </Box>
          { t('registerPatient.disclaimer.dialog.consent') }
        </Typography>

        {
          site?.consent
            ? (
              <RichTextEditor editorState={ createEditorStateFromRaw(site?.consent) } readOnly />
              )
            : (
              <div
                dangerouslySetInnerHTML={ {
                  __html: DOMPurify.sanitize(site?.patient_registration_consent ?? '')
                } }
              />
              )
        }
      </Box>
    </Dialog>
  )
}
