import React from 'react'
import { Box } from '@mui/system'

function SkipToMainContent () {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 10,
        left: '-100%',
        zIndex: 99,
        transition: '0.3s',
        '&:focus': { left: 10 }
      }}
      component='a'
      href='#main'
      tabIndex={0}
    >
      Skip to main content
    </Box>
  )
}

export default SkipToMainContent
