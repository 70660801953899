import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { CardPaymentDetails, TextButton } from 'components'
import SquarePaymentForm, { tokenize } from 'components/SquarePaymentForm'
import { api } from 'Core'
import useFormik from 'hooks/useFormik'
import useStateCallback from 'hooks/useStateCallback'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { RegisterKitNavigation, useRegisterKit } from '.'
import InsuranceDetailsForm from './InsuranceDetailsForm'

export const PatientPaymentMethods = () => {
  const { data } = useRegisterKit()

  if (data.payment === 'cash') {
    return <CashPaymentForm />
  }

  return <InsuranceDetailsForm />
}

function CashPaymentForm () {
  const {
    data,
    updateData
  } = useRegisterKit()

  const { kit } = data

  const navigate = useNavigate()
  const [, setShowPromptOnExit] = useStateCallback(true)

  const isTokenAssociated = !!kit.squarePaymentToken

  const [cardTokenized, setCardTokenized] = useState(isTokenAssociated)

  const [card, setCard] = useState(null)

  const { mutate, isLoading } = useMutation(
    async () => {
      if (!card) return
      const tokenResult = await tokenize(card)
      if (!tokenResult?.token) throw new Error('Invalid card data')
      const kit = await api.kit.update(data.kit.id, {
        pageInfo: 'Payment',
        squarePaymentToken: tokenResult
      })
      if (!kit.squarePaymentToken) {
        throw new Error('Something went wrong')
      }
      return kit
    },
    {
      onSuccess: kit => {
        updateData(current => ({ ...current, kit }))
        navigate('../sample-collection')
      }
    }
  )

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (cardTokenized) {
        return navigate('../sample-collection')
      }
      mutate()
    }
  })

  return (
    <Stack sx={{ gap: 3 }} component='form' onSubmit={formik.handleSubmit}>
      <Typography variant='h5'>Payment Info</Typography>
      {cardTokenized
        ? (
        <Stack sx={{ gap: 2 }}>
          <CardPaymentDetails squarePaymentToken={kit.squarePaymentToken} />
          <TextButton onClick={() => setCardTokenized(false)}>
            Change payment details
          </TextButton>
        </Stack>
          )
        : (
        <Stack>
          <SquarePaymentForm onInitialize={setCard} />
          {isTokenAssociated
            ? (
            <TextButton onClick={() => setCardTokenized(true)}>
              Cancel
            </TextButton>
              )
            : null}
        </Stack>
          )}
      <RegisterKitNavigation
        back={
          <Button
            onClick={() =>
              setShowPromptOnExit(false, () => navigate('../screening'))
            }
          >
            Back
          </Button>
        }
        next={
          <LoadingButton
            type='submit'
            variant='contained'
            loading={isLoading}
            disabled={!cardTokenized && !card}
          >
            Next
          </LoadingButton>
        }
      />
    </Stack>
  )
}
