import React from 'react'
import { Alert, FormLabel, Stack, Typography } from '@mui/material'
import RadioGroupComponent from './components-prep/RadioGroupComponent'
import { useFormik } from 'formik'
import DOMPurify from 'dompurify'
import { Heading } from '.'
import {
  MultiStepFormNavigation,
  useMultiStepForm
} from 'components/MultiStepForm'
import type { TPrepProgram } from './schemas'
import * as schemas from './schemas'
import type { PrEPProgramConfig } from 'types/db/SiteI'

function About ({
  prepProgramConfig
}: {
  prepProgramConfig: PrEPProgramConfig
}) {
  const { data, update } = useMultiStepForm<TPrepProgram>()

  const aboutContent = prepProgramConfig.content.about
  const optionsContent = aboutContent.questions.whatDescribesBest.options

  type TOptionKeys = keyof typeof optionsContent

  const formik = useFormik({
    initialValues: data.about,
    onSubmit: async values => {
      update({ ...data, about: values })
    },
    validationSchema: schemas.about,
    validateOnChange: true
  })

  const options: { label: string; value: string }[] = [
    {
      value: 'diagnosedHIVBefore',
      label: optionsContent['diagnosedHIVBefore'].title
    },
    {
      value: 'exposedHIVInLast30Days',
      label: optionsContent['exposedHIVInLast30Days'].title
    },
    {
      value: 'exposedHepatitisBefore',
      label: optionsContent['exposedHepatitisBefore'].title
    },
    { value: 'none', label: optionsContent['none'].title }
  ]

  const showFooter = !!formik.values.whatDescribesBest && !formik.isValid

  const footerContent = showFooter
    ? optionsContent[
        formik.values.whatDescribesBest as Exclude<TOptionKeys, 'none'>
      ].footer
    : null

  return (
    <Stack sx={{ gap: 2 }} component='form' onSubmit={formik.handleSubmit}>
      <Heading>{aboutContent.title}</Heading>
      <RadioGroupComponent
        headerText={aboutContent.questions.whatDescribesBest.title}
        onChange={formik.handleChange}
        name='whatDescribesBest'
        options={options}
        value={formik.values.whatDescribesBest}
      />
      {footerContent ? (
        <FooterComponent footerData={footerContent} />
      ) : (
        <>
          <FormLabel error>{formik.errors.whatDescribesBest}</FormLabel>
          <MultiStepFormNavigation />
        </>
      )}
    </Stack>
  )
}

interface FooterComponentProps {
  footerData?: {
    title: string
    titleText: string
    alert: {
      text1: string
      text2?: string
    }
    footerText?: string
  }
}

const FooterComponent: React.FC<FooterComponentProps> = ({ footerData }) => {
  if (!footerData) return <></>

  return (
    <Stack gap={2}>
      <Typography sx={{ mt: '30px' }} variant='h6' component='h3'>
        {footerData.title}
      </Typography>
      <Typography
        variant='body1'
        mt={1}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(footerData.titleText, {
            ADD_ATTR: ['target']
          })
        }}
      />
      {footerData.alert ? (
        <Alert
          severity='info'
          sx={{
            '& .MuiAlert-icon': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          <Typography
            variant='body1'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(footerData.alert.text1, {
                ADD_ATTR: ['target']
              })
            }}
          />
          {footerData.alert.text2 ? (
            <Typography
              variant='body1'
              mt={1}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(footerData.alert.text2, {
                  ADD_ATTR: ['target']
                })
              }}
            />
          ) : null}
        </Alert>
      ) : (
        ''
      )}

      {footerData.footerText ? (
        <Typography
          variant='body1'
          mt={1}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(footerData.footerText, {
              ADD_ATTR: ['target']
            })
          }}
        />
      ) : null}
    </Stack>
  )
}

export default About
