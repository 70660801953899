import { Stack, useMediaQuery } from '@mui/material'
import { Container } from 'components'
import React, { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import theme from 'theme'
import FeedbackCompleted from './FeedbackCompleted'
import FeedbackForm from './FeedbackForm'
import NpsHeader from './NpsHeader'

function NpsReview () {
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [feedback, setFeedback] = useState({})

  return (
    <>
      <NpsHeader />
      <Container>
        <Stack sx={{ gap: 3 }}>
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 5,
              justifyContent: mobile ? 'center' : 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <Stack
              sx={{
                width: {
                  xs: '100%',
                  sm: 560
                },
                m: 'auto'
              }}

            >
              <Routes>
                <Route path="/feedback-completed" element={<FeedbackCompleted feedback={feedback} />} />
                <Route path="/submit-feedback/:token/:option" element={<FeedbackForm setFeedback = {setFeedback}/>} />
                <Route path="/reminder/:token/:option" element={<FeedbackForm setFeedback={setFeedback}/>} />
                <Route path="/weight-plan-feedback/:token/:option" element={<FeedbackForm feedbackKey={'weight-loss-program-nps'} cacheKey={'npsMwlSatisfactionSurvey'} setFeedback={setFeedback}/>} />
                <Route path="/*" element={<Navigate to="/submit-feedback" />} />
              </Routes>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export default NpsReview
