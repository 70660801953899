import { ReduxActionI } from '.'
import { HIDE_SNACKBAR, SHOW_SNACKBAR } from '../actions/index'

const defaultState = {
  show: false,
  message: '',
  severity: 'info'
} as Snackbar

export default (state = defaultState, { payload, type }: ReduxActionI): Snackbar => {
  switch (type) {
    case HIDE_SNACKBAR: {
      return {
        ...defaultState
      }
    }
    case SHOW_SNACKBAR:
      return {
        ...state,
        show: payload.show,
        message: payload.message,
        severity: payload.severity
      }
    default:
      return state
  }
}

export interface Snackbar {
  show: boolean
  message: string
  severity: string
}
