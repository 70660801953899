import { Box, useMediaQuery } from '@mui/material'
import { FormikProps } from 'formik'
import { PrEPPharmacy } from 'types/db/SiteI'
import GoogleMapReact from 'google-map-react'
import PharmacyMarker from './PharmacyMarker'
import React from 'react'
import theme from 'theme'

const PharmaciesMap = ( { pharmacies, formik, onSelectionChange, coordinates }: PharmaciesMapProps) => {
  const mobile = useMediaQuery( theme.breakpoints.down( 'sm' ) )

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || ''
  const mapSettings = {
    center: {
      lat: coordinates?.lat || 37.34043,
      lng: coordinates?.lng || -121.88664
    },
    zoom: 13
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: mobile ? '250px' : '350px',
        borderRadius: '.25rem',
        overflow: 'hidden'
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleApiKey,
          libraries: [ 'places' ]
        }}
        center={mapSettings.center}
        defaultZoom={mapSettings.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        {
          pharmacies.map( ( pharmacy, index ) => (
            <PharmacyMarker
              key={`${pharmacy.zip}-${index}`}
              lat={pharmacy.coordinates.lat}
              lng={pharmacy.coordinates.lng}
              pharmacy={pharmacy}
              formik={formik}
              onSelectionChange={onSelectionChange}
            />
          ) )
        }
      </GoogleMapReact>
    </Box>
  )
}

interface PharmaciesMapProps {
  pharmacies: PrEPPharmacy[]
  formik: FormikProps<PrEPPharmacy>
  onSelectionChange: ( value: string ) => void
  coordinates: {
    lat: number
    lng: number
  } | null
}

export default PharmaciesMap