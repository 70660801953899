import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { personalInfoValidator } from 'pages/Auth/Signup'
import { useTranslation } from 'react-i18next'

function PasswordPolicy ({ sx = {}, context, type = '', policy = '', ...props }) {
  const { password } = context
  const { t } = useTranslation()
  const [errors, setErrors] = useState([])
  const passwordSchemaBase = yup
    .string()
    .min(8, 'min')
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,}$/, 'alpha-numeric')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'alpha-numeric'
    )
    .matches(/[*@!#%&()^~{}]+/, 'special-chars')

  const passwordPolicySchema = (type === 'signup' || type === 'invite')
    ? passwordSchemaBase.test('checkPasswordAgainstUserInfo', 'personal-data', () =>
      personalInfoValidator(password, { parent: context })
    )
    : passwordSchemaBase

  useEffect(() => {
    passwordPolicySchema
      .validate(password, { abortEarly: false })
      .then(() => {
        setErrors([])
      })
      .catch((error) => {
        setErrors(error.errors)
      })
  }, [password, context])

  return (
    <Stack sx={{ gap: 1, ...sx }} {...props}>
      <Typography variant="body2">{t('signup.passwordPolicy.heading')}</Typography>
      <Match checked={!errors.includes('special-chars')}>
        {t('signup.passwordPolicy.specialChar')} (!@#$%^&*)
      </Match>
      <Match checked={!errors.includes('alpha-numeric')}>
        {t('signup.passwordPolicy.alphanumericCharacters')}
      </Match>
      <Match checked={!errors.includes('min')}>
        {t('signup.passwordPolicy.passwordLength')}
      </Match>
      <Match checked={!policy.includes('personal-data') && (!errors.includes('personal-data') && password.length > 0)}>
        {t('signup.passwordPolicy.avoidPersonalDataInPassword')}
      </Match>
    </Stack>
  )
}

export default PasswordPolicy

function Match ({ checked = false, children }) {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '1em',
        alignItems: 'center',
        fontSize: 14
      }}
    >
      {checked
        ? (
        <CheckCircleIcon
          data-testid="CheckCircleIcon"
          sx={{ color: 'success.main', fontSize: 18 }}
        />
          )
        : (
        <CancelIcon
          data-testid="CancelIcon"
          sx={{ color: 'error.main', fontSize: 18 }}
        />
          )}
      <Typography sx={{ fontSize: 'inherit' }}>{children}</Typography>
    </Stack>
  )
}
