import { api } from 'Core'
import { Badge, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Grid from '@mui/material/Unstable_Grid2'
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { useState, useEffect, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { AppNotificationI } from '../../../types/db/AppNotificationI'
import { useSearchParams } from 'react-router-dom'

const NotificationCard = ({ notification }: { notification: AppNotificationI }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const { id, type, subject, message, read_at } = notification
  const chat = notification?.chat && typeof notification.chat === 'string' ? JSON.parse(notification.chat) : null

  const handleDisplayNotification = () => {
    setSearchParams({ ...searchParams, notificationId: notification.id.toString() })
    queryClient.invalidateQueries(['patient-inbox'])
  }

  return (
    <CardContainer >
      <StatusDot read_at={ read_at } />
      <Grid xs={ 11 }>
        <Grid container alignItems='center' wrap='nowrap' spacing={ 1 } onClick={ handleDisplayNotification }>
          <Grid xs={ 2 } display='flex' alignItems='center' justifyContent='center'>
            <NotificationIcon type={ type } chat={ chat } />
          </Grid>
          <Grid xs={ 10 } flex='1'>
            <NotificationTitle subject={ subject } />
            <NotificationMessage message={ message } />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={ 1 }>
        <NotificationOptions id={ id } />
      </Grid>
    </CardContainer>
  )
}

// Styled components
const CardContainer = ({ children, ...props }: CardContainerProps) => {
  return (
    <Grid { ...props } container sx={ {
      backgroundColor: '#F5F5F5',
      borderRadius: '.25rem',
      padding: '1rem 0.5rem',
      position: 'relative',
      cursor: 'pointer'
    } }
    >
      { children }
    </Grid>
  )
}

const StatusDot = ({ read_at }: { read_at: Date | null }) => {
  return (
    <Box sx={ {
      backgroundColor: read_at ? 'success.main' : 'error.main',
      borderRadius: '50%',
      height: '.5rem',
      left: '.5rem',
      position: 'absolute',
      top: '.5rem',
      width: '.5rem'
    } } />
  )
}

const NotificationIcon = ({ type, chat }: { type: string, chat: AppNotificationI['chat'] }) => {
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0)
  useEffect(() => {
    if (chat && Array.isArray(chat)) {
      const total = chat.reduce((acc, cur) => {
        if ((window?.location?.hostname?.split('.')[0] !== cur.origin) && !cur.readAt) {
          return acc + 1
        }
        return acc
      }, 0)

      setTotalUnreadMessages(total)
    }
  }, [chat])
  return (
    <Badge badgeContent={ totalUnreadMessages } color='error' anchorOrigin={ { vertical: 'top', horizontal: 'left' } }>
      <Box
        component='img'
        alt='Message Icon'
        src={ `/assets/messageIcons/${type}.png` }
        sx={ { width: '100%', maxWidth: '2.5rem' } }
      />
    </Badge>
  )
}

const NotificationTitle = ({ subject }: { subject: string }) => {
  return (
    <Typography
      variant='body1'
      sx={ {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'
      } }>
      { subject }
    </Typography>
  )
}

const NotificationMessage = ({ message }: { message: string }) => {
  return (
    <Typography
      variant='caption'
      sx={ {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block'
      } }>
      { message }
    </Typography>
  )
}

const NotificationOptions = ({ id }: { id: number }) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    // @ts-ignore
    mutationFn: (data) => api.notification.updateNotification({ id, ...data }),
    onSuccess: () => queryClient.invalidateQueries(['patient-inbox'])
  })

  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const isSubMenuOpen = Boolean(anchorEl)
  const ariaId = isSubMenuOpen ? 'inbox-popover' : undefined
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSnoozed = () => {
    const tomorrow = moment().add(24, 'hours')
    // @ts-ignore
    mutation.mutate({ snoozed_until: tomorrow })
  }

  const handleArchive = () => {
    // @ts-ignore
    mutation.mutate({ archived: true })
  }

  return (
    <>
      <IconButton
        size='small'
        onClick={ handleClick }
        aria-describedby={ ariaId }
        aria-controls={ isSubMenuOpen ? 'inbox-popover' : undefined }
        aria-haspopup='true'
        aria-expanded={ isSubMenuOpen ? 'true' : undefined }
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        tabIndex={ 0 }
        anchorEl={ anchorEl }
        id='account-menu'
        open={ isSubMenuOpen }
        onClose={ handleClose }
        onClick={ handleClose }
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        }}
        transformOrigin={ { horizontal: 'right', vertical: 'top' } }
        anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
      >
        <MenuItem onClick={ handleSnoozed }>
          { t('header.link.notification.notificationCard.snooze') }
        </MenuItem>
        <MenuItem onClick={ handleArchive }>
          { t('header.link.notification.notificationCard.archive') }
        </MenuItem>
      </Menu>

    </>
  )
}

interface CardContainerProps {
  children: React.ReactNode
}

export default NotificationCard
