import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'

// siteId: String
export const useGetSite = (siteId: number | undefined) => {
  const query = useQuery(
    ['sites', siteId],
    () => api.site.getSites({ ids: [siteId] }),
    {
      select: responseData => responseData.find(site => {
        if (!siteId) return false
        return +site?.id === +siteId
      }),
      enabled: !!siteId
    }
  )

  return {
    ...query,
    // When the query is disabled due the siteIds being empty, the status of the isLoading is always true and the page is stuck in a loading state. This is a workaround to fix that.
    isLoading: query.isLoading && query.fetchStatus !== 'idle'
  }
}
