import { ReduxActionI } from '.'
import { SHOW_LOADER, HIDE_LOADER } from '../actions/index'

const defaultState = {
  loader: false
}

export default (state = defaultState, action: ReduxActionI) => {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case HIDE_LOADER:
      return {
        ...state,
        loader: false
      }

    default:
      return state
  }
}
