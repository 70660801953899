import { GroupedSamplesByTypeI } from '.'
import { AnalyteConfigI } from '../../types/db/AnalyteConfigI'
import { KitTypeConfigI } from '../../types/db/KitTypeConfigI'
import { Text, View } from '@react-pdf/renderer'
import React from 'react'

const OtherInformationPDF = ({ kitTypeConfig, samplesByType, displaySHKLabData }: Props) => {
  const analytesInPage = [] as string[]

  Object.keys(samplesByType).forEach((type) => {
    const samples = samplesByType[type as keyof GroupedSamplesByTypeI] || []

    samples.forEach(sample => {
      const analyteId = sample?.analyte_id

      if (analyteId && !analytesInPage.includes(analyteId)) {
        analytesInPage.push(String(analyteId))
      }
    })
  })

  const comments = kitTypeConfig?.analytes?.reduce((acc, analyte) => {
    if (!analytesInPage.includes(String((analyte?.analyteConfig as AnalyteConfigI)?.id)) && !analytesInPage.includes(String(analyte?.analyteConfig))) {
      return acc
    }

    analyte?.comment?.blocks?.forEach(block => {
      if (block?.text && block.text.trim().length > 0) {
        acc.push(block.text)
      }
    })

    return acc
  }, [] as string[])

  if (!comments || comments.length === 0) return null
  return (<>
    <View
      style={ {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        fontSize: '10px'
      } }
    >
      <Text style={ { fontWeight: 700 } }>Other information:</Text>

      {
        comments?.map((comment, idx) => (
          <Text key={ idx }>{ comment }</Text>
        ))
      }

      {/* {
        displaySHKLabData
          ? (<Text>The test has been performed at US Specialty Labs LP CLIA #05D2130115.</Text>)
          : null
      } */}

    </View>
  </>)
}

export interface Props {
  kitTypeConfig: Partial<KitTypeConfigI> | undefined
  samplesByType: GroupedSamplesByTypeI
  displaySHKLabData: boolean
}

export default OtherInformationPDF
