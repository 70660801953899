import i18next, { i18n } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import LocizeBackend from 'i18next-locize-backend'
import { getSubdomain } from 'utils/utilFuntions'
import { WhiteLabelConfig } from '../types/db/SiteI'

const {
  REACT_APP_LOCIZE_PROJECT_ID,
  REACT_APP_LOCIZE_API_KEY,
  NODE_ENV,
  REACT_APP_LOCIZE_VERSION
} = process.env

const i18nApplicableSubdomains = ['app', 'account'] as Array<string>
const isSubdomainI18nApplicable = i18nApplicableSubdomains.includes(
  getSubdomain()
)

export const isI18nEnabled = (whiteLabelConfig?: WhiteLabelConfig | undefined) => {
  const isI18nEnabled = process.env.REACT_APP_IS_I18N_ENABLED === 'true'
  const isLanguageSupportAvailable = !!whiteLabelConfig?.languageSupport

  if (!whiteLabelConfig) return isI18nEnabled && isSubdomainI18nApplicable

  return isI18nEnabled && isLanguageSupportAvailable && isSubdomainI18nApplicable
}

export const LANGUAGE_TAGS = ['en-US', 'es-US'] as const
export type T_LANGUAGE_TAGS = typeof LANGUAGE_TAGS[number]

export const LANGUAGES: Record<typeof LANGUAGE_TAGS[number], string> = {
  'en-US': 'English',
  'es-US': 'Español'
}
export const DEFAULT_LANGUAGE = i18next.language ?? 'en-US'

export default i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(LocizeBackend)
  .init({
    supportedLngs: isI18nEnabled() ? Object.keys(LANGUAGES) : ['en-US'],
    load: 'currentOnly',
    fallbackLng: 'en-US',
    debug: NODE_ENV === 'development',
    ns: 'frontend',
    backend: {
      projectId: REACT_APP_LOCIZE_PROJECT_ID,
      apiKey: REACT_APP_LOCIZE_API_KEY,
      version: REACT_APP_LOCIZE_VERSION
    }
  })

export function changeLanguage (locale: string) {
  const validLocale = Object.keys(LANGUAGES).includes(locale)
    ? locale
    : undefined
  return i18next.changeLanguage(validLocale)
}

export function getCurrentLanguage (i18n: i18n) {
  if (LANGUAGES[i18n.language as T_LANGUAGE_TAGS]) return i18n.language as T_LANGUAGE_TAGS
  if (i18n.resolvedLanguage && LANGUAGES[i18n.resolvedLanguage as T_LANGUAGE_TAGS]) {
    return i18n.resolvedLanguage as T_LANGUAGE_TAGS
  }
  return 'en-US'
}
