import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { pdf } from '@react-pdf/renderer'
import { Paper } from 'components'
import { api } from 'Core'
import useFormik, { textFieldPropsGetter } from 'hooks/useFormik'
import React from 'react'
import { useParams } from 'react-router'
import * as yup from 'yup'
import ResultsReportPDF from '../../../components/ResultsReportPDF'
import { useWhiteLabelConfig } from '../../../utils/white-label/WhiteLabelConfig'
import { showSnackBar } from '../../../state/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isShkDomain } from 'utils/utilFuntions'
import moment from 'moment'

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Please enter patient's first name").trim(),
  lastName: yup.string().required("Please enter patient's last name").trim(),
  dob: yup.string().required("Please enter patient's date of birth").nullable().trim(),
  id: yup.string().required('Test ID is required').trim()
})

function TestResultExternalProvider () {
  const { id } = useParams()
  const whiteLabelConfig = useWhiteLabelConfig()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isShk = isShkDomain()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dob: null,
      id
    },
    validationSchema,
    onSubmit: async (values) => {
      // Cast values to trigger the transformations in the schema (trim, etc)
      const castValues = validationSchema.cast(values)

      try {
        const payload = {
          ...castValues,
          dob: moment(castValues.dob).format('YYYY-MM-DD')
        }
        const response = await api.admin.downloadResultExternal(payload)

        // Ensure response and lisEndPoint are valid
        if (!response || !response.lisEndPoint) throw new Error('Type not found in response')

        let pdfBlob

        // Handle response based on the lisEndPoint
        if (response.lisEndPoint === 'SHK LIMSabc') {
          if (!response.buffer) throw new Error('No buffer found in response')

          // Convert base64 string back to a Blob
          const byteCharacters = atob(response.buffer)
          const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0))
          const byteArray = new Uint8Array(byteNumbers)
          pdfBlob = new Blob([byteArray], { type: 'application/pdf' })
        } else {
          if (!response.data) throw new Error('No data found in response')

          const { kit, analytes, kitTypeConfig, patient, samples, site } = response.data

          // Generate PDF using your ResultsReportPDF component
          const asPdf = pdf(
            <ResultsReportPDF
              kit={ kit }
              analytes={ analytes }
              kitTypeConfig={ kitTypeConfig }
              patient={ patient }
              samples={ samples }
              site={ site }
              whiteLabelConfig={ whiteLabelConfig }
            />
          )

          pdfBlob = await asPdf.toBlob()
        }

        // Create a URL for the Blob and open it in a new tab
        const url = window.URL.createObjectURL(pdfBlob)
        window.open(url, '_blank')
      } catch (error) {
        console.log(error)

        dispatch(showSnackBar({
          show: true,
          message: t('account.alert.error'),
          severity: 'error'
        }))
      }
    }
  })

  const getTextFieldProps = textFieldPropsGetter(formik)

  return (
    <Paper sx={ { width: 'min(600px, 90%)', mx: 'auto', my: 6 } }>
      <Stack sx={ { gap: 3 } } component='form' onSubmit={ formik.handleSubmit }>
        <Typography variant='h4' sx={ { textAlign: 'center' } }>
          {isShk ? 'Provider' : 'Customer Admin'} Download Result
        </Typography>
        <Stack sx={ { gap: 2 } }>
          <TextField
            { ...getTextFieldProps({
              name: 'firstName',
              label: 'Patient first name'
            }) }
          />
          <TextField
            { ...getTextFieldProps({
              name: 'lastName',
              label: 'Patient last name'
            }) }
          />
          <LocalizationProvider dateAdapter={ AdapterMoment }>
            <DatePicker
              label='Patient date of birth'
              value={ formik.values.dob }
              onChange={ value => formik.setFieldValue('dob', value) }
              slotProps={ {
                textField: getTextFieldProps({
                  name: 'dob',
                  onChange: value => formik.setFieldValue('dob', value)
                })
              } }
            />
          </LocalizationProvider>

          <LoadingButton
            type='submit'
            variant='contained'
            fullWidth
            loading={ formik.isSubmitting }
          >
            Download Result
          </LoadingButton>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default TestResultExternalProvider
