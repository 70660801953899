import { Text, View } from '@react-pdf/renderer'
import React from 'react'

const ResultBoxPDF = ({ value, color, backgroundColor }: { value: string, color: string, backgroundColor: string }) => {
  switch (backgroundColor) {
    case 'success.main':
      backgroundColor = '#008453'
      break
    case 'error.main':
      backgroundColor = '#C62828'
      break
    case 'warning.main':
    case 'warning.dark':
      backgroundColor = '#FF8F31'
      break
    case 'info.main':
      backgroundColor = '#395DB5'
      break
    case 'transparent':
      backgroundColor = 'transparent'
      break
    default:
      backgroundColor = '#8f8f8f'
      break
  }

  return (<View
    style={ {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor,
      color,
      padding: '8px 4px',
      borderRadius: '16px'
    } }
  >
    <Text style={ { fontWeight: 700, textTransform: 'capitalize' } }>{ value }</Text>
  </View>)
}

export default ResultBoxPDF
