import { api } from 'Core'
import patientPortalApi from 'Core/patientPortalApi'
import { cachedQuery } from 'Core/utils/cachingUtils'
import { getSiteIds } from 'utils/utilFuntions'
import { useGetSites } from './query/useGetSites'
import useAuth0 from './useAuth0'
import { PatientI } from '../types/db/PatientI'

export default function useSite (props?: Props) {
  const { user } = useAuth0()
  const sitesData = useGetSites({ siteIds: props?.siteIds || [] })

  async function getSites (patients: PatientI[] = []) {
    const siteIds = getSiteIds(patients)
    return cachedQuery(`${user?.sub}-sites`, () =>
      api.proxy.getSites({ ids: siteIds })
    )
  }

  async function validateSiteCode (siteCode: string) {
    try {
      // const res = await cachedQuery(siteCode, () =>
      //   patientPortalApi.validateSite(siteCode)
      // )
      const res = await api.site.getSiteFromCode(siteCode)
      if (!res.data) {
        window.sessionStorage.removeItem(siteCode)
        window.sessionStorage.removeItem(`${siteCode}ExpiryToken`)
        return
      }

      return res
    } catch (error) {
      console.log(error)
    }
  }

  async function getSiteContents (siteCode: string) {
    try {
      const [
        insuranceList,
        raceList,
        genders,
        ethnicityList
      ] = await Promise.all([
        cachedQuery('insurance', () => api.proxy.getInsuranceProviders()),
        cachedQuery(`${siteCode}-race`, () =>
          patientPortalApi.getRace(siteCode)
        ),
        cachedQuery(`${siteCode}-gender`, () =>
          patientPortalApi.getGender(siteCode)
        ),
        cachedQuery(`${siteCode}-ethnicity`, () =>
          patientPortalApi.getEthnicity(siteCode)
        )
      ])

      return {
        insuranceList: insuranceList as { data: { name: string, id: string }[] },
        raceList: raceList as { data: { name: string }[] },
        genders: genders as { data: { name: string }[] },
        ethnicityList: ethnicityList as { data: { name: string }[] }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return { getSites, validateSiteCode, getSiteContents, sitesData }
}

// interfaces
interface Props {
  siteIds?: string[]
}
