import { Badge, Box, Button, Divider, IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import LogoutDialog from 'components/Header/components/LogoutDialog'
import { checkVisibilityMatch } from '../utils'
import { useNavigate } from 'react-router'
import useNotifications from 'hooks/useNotifications'
import { useNavLinks } from './useNavLinks'
import { useAccountSettings } from './useAccountSettings'
import { UserPermissionsI } from '..'

export const DrawerMenu = ({ userPermissions }: { userPermissions: UserPermissionsI }) => {
  const [open, setOpen] = useState(false)
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] = useState(false)
  const navigate = useNavigate()
  const navLinksActions = useNavLinks()
  const accountActions = useAccountSettings()

  function LinkButtonMobile ({ to, icon, children, onClick }: LinkButtonMobileI) {
    const { unreadMessagesNumber } = useNotifications()

    return (
      <Button
        sx={ { px: 0 } }
        onClick={ onClick || (() => {
          navigate(to)
          setOpen(false)
        }) }
        startIcon={
          children === 'Inbox'
            ? <Badge badgeContent={ unreadMessagesNumber } color="error">{ icon }</Badge>
            : icon
        }
      >

        { children }
      </Button>
    )
  }

  // if (userPermissions?.page?.some(page => page === visibilityStates.page.accountPage)) return <></>

  return (
    <Box sx={ { display: { md: 'none' } } }>
      <IconButton onClick={ () => setOpen(true) } color='primary'>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        open={ open }
        onClose={ () => setOpen(false) }
        onOpen={ () => {} }
        anchor='right'
      >
        <Stack sx={ { width: 275, maxWidth: '100%' } }>
          <Stack sx={ { gap: 2, p: 2 } }>
            <Stack
              sx={ {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              } }
            >
              <Typography variant='body2'>Menu</Typography>
              <IconButton color='primary' onClick={ () => setOpen(false) }>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack sx={ { gap: 2 } }>
              {
                navLinksActions.filter(navItem => navItem?.title !== 'Ask for help').map(action => {
                  if (!checkVisibilityMatch({
                    userPermissions,
                    // @ts-ignore
                    componentRestrictions: action.visibleOn
                  })) return null

                  return (
                    <LinkButtonMobile key={ action?.title } to={ action?.navigateTo } icon={ action?.icon || <></> } onClick={ action?.onClick ? action.onClick : undefined }>
                      { action?.title }
                    </LinkButtonMobile>
                  )
                })
              }
            </Stack>
          </Stack>
          <Divider />
          <Stack sx={ { gap: 2, p: 2 } }>
            {
              accountActions.map(action => {
                if (!checkVisibilityMatch({
                  userPermissions,
                  componentRestrictions: action.visibleOn
                })) return null

                return (
                  <LinkButtonMobile
                    to={ action?.navigateTo }
                    key={ action?.title }
                    icon={ action?.mobileIcon }
                    onClick={ action?.title === 'Logout' ? () => setIsLogoutConfirmationOpen(true) : action.onClick ? action.onClick : undefined }
                  >
                    { action?.mobileTitle }
                  </LinkButtonMobile>
                )
              })
            }
          </Stack>
        </Stack>
      </SwipeableDrawer>

      <LogoutDialog
        id='user-logout'
        keepMounted
        isLogoutConfirmationOpen={ isLogoutConfirmationOpen }
        setIsLogoutConfirmationOpen={ setIsLogoutConfirmationOpen }
      />
    </Box>
  )
}

interface LinkButtonMobileI {
  to: string
  icon: JSX.Element
  children: string
  onClick?: () => void
}
