export const gender = [
  {
    id: '6',
    name: 'TransMale',
    warning: true,
    class_name: 'bar-abc-grey-dropdown'
  },
  {
    id: '7',
    name: 'TransFemale',
    warning: true,
    class_name: 'bar-abc-grey-dropdown'
  },
  {
    id: '8',
    name: 'NonBinary',
    warning: true,
    class_name: 'bar-abc-grey-dropdown'
  },
  {
    id: '2',
    name: 'Female',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '3',
    name: 'Male',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '10',
    name: 'CisFemale',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '11',
    name: 'CisMale',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '9',
    name: 'Unknown',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  }
]

export const ethnicity = [
  {
    id: '7',
    name: 'African American',
    warning: true,
    class_name: 'bar-abc-grey-dropdown'
  },
  {
    id: '6',
    name: 'Caucasian',
    warning: true,
    class_name: 'bar-abc-grey-dropdown'
  },
  {
    id: '3',
    name: 'Hispanic/Latino',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '5',
    name: 'Not Hispanic or Latino',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '1',
    name: 'Unknown',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  }
]

export const race = [
  {
    id: '5',
    name: 'American Indian/Alaska Native',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '3',
    name: 'Asian',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '2',
    name: 'Black',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '6',
    name: 'Native Hawaiian/Pacific Isles',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '7',
    name: 'Other',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '4',
    name: 'Unknown',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  },
  {
    id: '1',
    name: 'White',
    warning: false,
    class_name: 'bar-abc-green2-dropdown'
  }
]

export const bill = [
  {
    id: '9',
    name: 'Insured'
  },
  {
    id: '10',
    name: 'Uninsured'
  }
]

export const relation = [
  { id: '1', name: 'Self' },
  { id: '2', name: 'Spouse' },
  { id: '3', name: 'Child' },
  { id: '6', name: 'Parent' }
]
