import React, { Dispatch, SetStateAction, useState } from 'react'
import InputMask from 'react-input-mask'
import {
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Typography,
  Stack,
  FormControl,
  FormHelperText
} from '@mui/material'
import Address from '../MedicalData/Address'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { insuranceNames } from '../data/insuranceNames'
import { gender } from 'components/PatientComponents/data/dropDownOptions'
import { formatDate } from 'utils/utilFuntions'
import { useTranslation } from 'react-i18next'

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: {
    value?: string;
    bill_type_id?: string;
    label: string;
    id: number | string;
  }) => option.label
})

const relation = [
  { name: 'relation.self', value: 'Self' },
  { name: 'relation.spouse', value: 'Spouse' },
  { name: 'relation.child', value: 'Child' },
  { name: 'relation.parent', value: 'Parent' }
]

const Insured = ({
  formik,
  copyAddress,
  setCopyAddress,
  disableAllFields,
  hideCheckBox = false,
  isAdmin = false
}: Props) => {
  const [inputValue, setInputValue] = useState('')

  const { t } = useTranslation()

  const genderOptions = gender?.map((data, i) => ({
    value: data.value,
    label: t(data.name),
    id: i
  }))

  const relationOptions = relation?.map((data, i) => ({
    value: data.value,
    label: t(data.name),
    id: i
  }))

  return (
    <Stack sx={ { gap: 3, mb: 2 } }>
      <Typography variant='h6'>{ t('registerPatient.paymentInfo.insured.title') }</Typography>
      <Grid spacing={ 2 } container>
        <Grid item xs={ 12 }>
          { insuranceNames && (
            <Autocomplete
              id='primaryInsuranceName'
              options={ insuranceNames }
              isOptionEqualToValue={ (option, value) => option.label === value.label }
              renderOption={ (props, data) => (
                <Box component='li' { ...props } key={ data?.id }>
                  { data?.label }
                </Box>
              ) }
              disabled={ disableAllFields }
              filterOptions={ filterOptions }
              value={ insuranceNames.find(insuranceName => formik.values.insured?.primaryInsuranceName === insuranceName.label) || null }
              inputValue={ inputValue }
              onInputChange={ (event, newInputValue) => {
                setInputValue(newInputValue)
              } }
              onChange={ (e, newValue) => {
                if (newValue?.label) {
                  formik.setFieldValue(
                    'insured.primaryInsuranceName',
                    newValue.label
                  )
                  formik.setFieldValue(
                    'insured.primary_insurance_id',
                    newValue.id
                  )
                } else {
                  formik.setFieldValue('insured.primaryInsuranceName', '')
                  formik.setFieldValue('insured.primary_insurance_id', null)
                }
              } }
              renderInput={ (params) => (
                <TextField
                  { ...params }
                  label={ t('registerPatient.paymentInfo.insured.primaryInsuranceName') }
                  error={ formik.isError('insured.primaryInsuranceName') }
                  helperText={ t(formik.getError('insured.primaryInsuranceName') as string) || '' }
                />
              ) }
            />
          ) }
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <TextField
            label={ t('registerPatient.paymentInfo.insured.policyNumber') }
            variant='outlined'
            type='text'
            name='insured.policyNumber'
            id='policyNumber'
            value={
              (!formik.values.insured?.policyNumber || formik.values.insured?.policyNumber === 'CLIENT BILL')
                ? ''
                : formik.values.insured?.policyNumber
            }
            disabled={ disableAllFields }
            onChange={ formik.handleChange }
            fullWidth
            error={
              formik.touched.insured?.policyNumber &&
              Boolean(formik.errors?.insured?.policyNumber)
            }
            helperText={
              formik.touched.insured?.policyNumber &&
                Boolean(formik.errors.insured?.policyNumber)
                ? t(formik.errors.insured?.policyNumber) as string
                : ''
            }
          />
        </Grid>
        <Grid xs={ 12 } item md={ 6 }>
          <TextField
            label={ t('registerPatient.paymentInfo.insured.groupNumber') }
            variant='outlined'
            type='text'
            name='insured.groupNumber'
            id='groupNumber'
            disabled={ disableAllFields }
            fullWidth
            value={ formik.values.insured.groupNumber || '' }
            onChange={ formik.handleChange }
            error={
              formik.touched.insured?.groupNumber &&
              Boolean(formik.errors?.insured?.groupNumber)
            }
            helperText={
              formik.touched.insured?.groupNumber &&
              t(formik.errors?.insured?.groupNumber)
            }
            FormHelperTextProps={ {
              sx: { color: 'primary.red' }
            } }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <Autocomplete
            id='patientRelationshipToInsured'
            autoComplete={ false }
            options={ relationOptions }
            isOptionEqualToValue={ (option, value) => option.value === value?.value }
            filterOptions={ filterOptions }
            disabled={ disableAllFields }
            value={ relationOptions.find(option => option.value === formik.values.insured.patientRelationshipToInsured) || null }
            onChange={ (e, newValue) => {
              if (!newValue?.value) {
                formik.setFieldValue('insured.patientRelationshipToInsured', '')
                return
              }

              formik.setFieldValue('insured.patientRelationshipToInsured', newValue.value)
              if (newValue.value === 'Self') {
                setCopyAddress(true)
                formik.setFieldValue(
                  'insured.insuredFirstName',
                  formik.values.firstName
                )
                formik.setFieldValue(
                  'insured.insuredLastName',
                  formik.values.lastName
                )
                formik.setFieldValue(
                  'insured.insuredGender',
                  formik.values.gender
                )
                formik.setFieldValue('insured.insuredDOB',
                  formatDate(formik.values.dob)
                )
                formik.setFieldValue(
                  'insuredAddress',
                  formik.values.medicalAddress
                )
              } else {
                setCopyAddress(false)
                formik.setFieldValue('insured.insuredFirstName', '')
                formik.setFieldValue('insured.insuredLastName', '')
                formik.setFieldValue('insured.insuredGender', '')
                formik.setFieldValue('insured.insuredDOB', '')
              }
            } }
            renderInput={ (params) => (
              <TextField
                { ...params }
                label={ t('registerPatient.paymentInfo.insured.patientRelationshipToInsured') }
                autoComplete='off'
                sx={ {
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disableAllFields ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor: disableAllFields
                      ? '#666666a6'
                      : '#212121'
                  }
                } }
                name='insured.patientRelationshipToInsured'
                disabled={ disableAllFields }
                error={
                  formik.touched.insured?.patientRelationshipToInsured &&
                  Boolean(formik.errors?.insured?.patientRelationshipToInsured)
                }
                helperText={
                  formik.touched.insured?.patientRelationshipToInsured &&
                    Boolean(formik.errors?.insured?.patientRelationshipToInsured)
                    ? t(formik.errors?.insured?.patientRelationshipToInsured) as string
                    : ''
                }
              />
            ) }
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <TextField
            label={ t('registerPatient.paymentInfo.insured.insuredFirstName') }
            variant='outlined'
            type='text'
            name='insured.insuredFirstName'
            id='insuredFirstName'
            fullWidth
            disabled={
              (formik.values.insured.patientRelationshipToInsured === 'Self' ||
                disableAllFields) &&
              !formik?.errors?.insured?.insuredFirstName
            }
            value={ formik.values.insured.insuredFirstName || '' }
            onChange={ formik.handleChange }
            error={
              formik.touched.insured?.insuredFirstName &&
              Boolean(formik.errors?.insured?.insuredFirstName)
            }
            helperText={
              formik.touched.insured?.insuredFirstName &&
                formik.errors?.insured?.insuredFirstName
                ? t(formik.errors?.insured?.insuredFirstName) as string
                : ''
            }
            sx={ {
              '& .Mui-disabled': {
                color: '#666666a6'
              },
              '& .MuiInputBase-input': {
                cursor:
                  formik.values.insured.patientRelationshipToInsured === 'Self'
                    ? 'not-allowed'
                    : 'default',
                WebkitTextFillColor:
                  formik.values.insured.patientRelationshipToInsured === 'Self'
                    ? '#666666a6'
                    : '#212121'
              }
            } }
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <TextField
            label={ t('registerPatient.paymentInfo.insured.insuredLastName') }
            variant='outlined'
            type='text'
            name='insured.insuredLastName'
            id='insuredLastName'
            fullWidth
            disabled={
              (formik.values.insured.patientRelationshipToInsured === 'Self' ||
                disableAllFields) &&
              !formik?.errors?.insured?.insuredLastName
            }
            value={ formik.values.insured.insuredLastName || '' }
            onChange={ formik.handleChange }
            error={
              formik.touched.insured?.insuredLastName &&
              Boolean(formik.errors?.insured?.insuredLastName)
            }
            helperText={
              formik.touched.insured?.insuredLastName &&
                formik.errors?.insured?.insuredLastName
                ? t(formik.errors?.insured?.insuredLastName) as string
                : ''
            }
            sx={ {
              '& .Mui-disabled': {
                color: '#666666a6'
              },
              '& .MuiInputBase-input': {
                cursor:
                  formik.values.insured.patientRelationshipToInsured === 'Self'
                    ? 'not-allowed'
                    : 'default',
                WebkitTextFillColor:
                  formik.values.insured.patientRelationshipToInsured === 'Self'
                    ? '#666666a6'
                    : '#212121'
              }
            } }
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <InputMask
            mask='99-99-9999'
            disabled={
              (formik.values.insured.patientRelationshipToInsured === 'Self' ||
                disableAllFields) &&
              !formik?.errors?.insured?.insuredDOB
            }
            maskChar=''
            name='dob'
            value={ formik.values.insured.insuredDOB || '' }
            onChange={ formik.handleChange }
          >
            {/* @ts-ignore */ }
            { () => (
              <TextField
                label={ t('registerPatient.paymentInfo.insured.insuredDob') }
                helperText={
                  formik.touched.insured?.insuredDOB &&
                    formik.errors?.insured?.insuredDOB
                    ? t(formik.errors?.insured?.insuredDOB) as string
                    : ''
                }
                name='insured.insuredDOB'
                id='insuredDOB'
                placeholder='mm-dd-yyyy'
                fullWidth
                error={
                  formik.touched.insured?.insuredDOB &&
                  Boolean(formik.errors?.insured?.insuredDOB)
                }
                sx={ {
                  '& .MuiInputBase-root': {
                    color: formik.values.insured.insuredDOB
                      ? '#212121'
                      : '#666666'
                  },
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor:
                      formik.values.insured.patientRelationshipToInsured ===
                        'Self'
                        ? 'not-allowed'
                        : 'default',
                    WebkitTextFillColor:
                      formik.values.insured.patientRelationshipToInsured ===
                        'Self'
                        ? '#666666a6'
                        : '#212121'
                  }
                } }
              />
            ) }
          </InputMask>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <Autocomplete
            id='gender'
            filterOptions={ filterOptions }
            autoComplete={ false }
            options={ genderOptions }
            isOptionEqualToValue={ (option, value) => option.value === value.value }
            disabled={
              (formik.values.insured.patientRelationshipToInsured === 'Self' ||
                disableAllFields) &&
              !formik?.errors?.insured?.insuredGender
            }
            value={ genderOptions.find(option => option.value === formik.values.insured.insuredGender) || null }
            onChange={ (e, newValue) => {
              if (newValue?.value) {
                formik.setFieldValue('insured.insuredGender', newValue.value)
              } else {
                formik.setFieldValue('insured.insuredGender', '')
              }
            } }
            renderInput={ (params) => (
              <TextField
                { ...params }
                label={ t('registerPatient.paymentInfo.insured.insuredGender') }
                autoComplete='off'
                sx={ {
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor:
                      disableAllFields ||
                        formik.values.insured.patientRelationshipToInsured ===
                        'Self'
                        ? 'not-allowed'
                        : 'inherit',
                    WebkitTextFillColor:
                      disableAllFields ||
                        formik.values.insured.patientRelationshipToInsured ===
                        'Self'
                        ? '#666666a6'
                        : '#212121'
                  }
                } }
                name='insured.insuredGender'
                disabled={
                  formik.values.insured.patientRelationshipToInsured ===
                  'Self' || disableAllFields
                }
                error={
                  formik.touched.insured?.insuredGender &&
                  Boolean(formik.errors?.insured?.insuredGender)
                }
                helperText={ t(formik.getError('insured.insuredGender') as string) || '' }
              />
            ) }
          />
        </Grid>
        <Grid item xs={ 12 }>
          {
            !isAdmin
              ? (<Box sx={ { display: 'flex', alignItems: 'center', mb: 2 } }>
                <FormControlLabel
                  sx={ { p: 1, width: '100%' } }
                  control={
                    <Checkbox
                      name='copyAddress'
                      id='copyAddress'
                      color='primary'
                      checked={ copyAddress }
                      disabled={
                        formik.values.insured.patientRelationshipToInsured ===
                        'Self' || disableAllFields
                      }
                      onChange={ () => {
                        setCopyAddress(!copyAddress)
                        if (copyAddress) {
                          formik.setFieldValue('insuredAddress', {
                            addressLine1: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            plus4Code: '',
                            county: '',
                            country: 'US'
                          })
                        } else {
                          formik.setFieldValue(
                            'insuredAddress',
                            formik.values.medicalAddress
                          )
                        }
                      } }
                    />
                  }
                  label={ t('registerPatient.paymentInfo.insured.currentAddressSameAsPreviously') }
                />
              </Box>)
              : <></>
          }
          <Address
            formik={ formik }
            name='insuredAddress'
            disabled={
              (copyAddress ||
                formik.values.insured.patientRelationshipToInsured === 'Self' ||
                disableAllFields) &&
              (
                !formik?.errors?.insuredAddress?.addressLine1 &&
                !formik?.errors?.insuredAddress?.city &&
                !formik?.errors?.insuredAddress?.county &&
                !formik?.errors?.insuredAddress?.fips &&
                !formik?.errors?.insuredAddress?.plus4Code &&
                !formik?.errors?.insuredAddress?.state &&
                !formik?.errors?.insuredAddress?.zipCode
              )
            }
            copyAddress={ copyAddress }
          />
          { !hideCheckBox && (
            <Box sx={ { display: 'flex', alignItems: 'center', mt: 2 } }>
              <FormControl
                error={ formik.isError('insured.insuranceDisclaimer') }
              >
                <FormControlLabel
                  sx={ { p: 1 } }
                  control={
                    <Checkbox
                      color='primary'
                      disabled={ disableAllFields }
                      name='insured.insuranceDisclaimer'
                      checked={ formik.values.insured.insuranceDisclaimer }
                      onChange={ formik.handleChange }
                    />
                  }
                  label={ t('registerPatient.paymentInfo.insured.insuranceConfirmationText') }
                />
                { formik.isError('insured.insuranceDisclaimer')
                  ? (<FormHelperText>
                    { t(formik.getError('insured.insuranceDisclaimer')) as string }
                  </FormHelperText>)
                  : null }
              </FormControl>
            </Box>
          ) }

        </Grid>
      </Grid>
    </Stack>
  )
}

interface Props {
  formik: any
  copyAddress: boolean
  setCopyAddress: Dispatch<SetStateAction<boolean>>
  disableAllFields: boolean
  hideCheckBox?: boolean
  isAdmin?: boolean
}

export default Insured
