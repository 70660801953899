import { Link, Typography } from '@mui/material'
import React from 'react'
import { Trans } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { OpenInNew } from '@mui/icons-material'

function TermsPolicy () {
  const whiteLabelConfig = useWhiteLabelConfig()

  return (
    <Typography>
      { whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions) && isEmpty(whiteLabelConfig?.privacy_policy)
        ? ''
        : whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions)
          ? (
          <Trans i18nKey='signup.acknowledgeReadUnderstoodAcceptedPrivacy' >
            I acknowledge I have read, understood, and accepted the
            <Link
              href={whiteLabelConfig.isActive ? whiteLabelConfig?.privacy_policy || '' : 'https://www.simplehealthkit.com/privacy'}
              target='_blank'
              color='primary'
              rel='noreferrer'
            >
              Privacy Policy <OpenInNew sx={{ fontSize: 'inherit' }} />
            </Link>
          </Trans>
            )
          : whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.privacy_policy)
            ? (
          <Trans i18nKey='signup.acknowledgeReadUnderstoodAcceptedTerms' >
            I acknowledge I have read, understood, and accepted the
            <Link
              href={whiteLabelConfig.isActive ? whiteLabelConfig?.terms_conditions || '' : 'https://www.simplehealthkit.com/terms'}
              target='_blank'
              color='primary'
              rel='noreferrer'
            >
              Terms & Conditions <OpenInNew sx={{ fontSize: 'inherit' }} />
            </Link>
          </Trans>
              )
            : (
          <Trans i18nKey='signup.acknowledgeReadUnderstoodAccepted' 
            components={{ 
              open: <OpenInNew color='inherit' fontSize='inherit' /> ,
              links1: <Link
                href={whiteLabelConfig.isActive ? whiteLabelConfig?.terms_conditions || '' : 'https://www.simplehealthkit.com/terms'}
                target='_blank'
                color='primary'
                rel='noreferrer'
              />,
              links2: <Link
                href={whiteLabelConfig.isActive ? whiteLabelConfig?.privacy_policy || '' : 'https://www.simplehealthkit.com/privacy'}
                target='_blank'
                color='primary'
                rel='noreferrer'
              />
            }}>
          </Trans>
              )
      }

    </Typography>
  )
}

export default TermsPolicy
