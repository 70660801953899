import { OpenInNew } from '@mui/icons-material'
import { Box, Container, Link, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getSubdomain } from 'utils/utilFuntions'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
// import { useNavigate } from 'react-router'

const PREFIX = 'Footer'
const subdomain = getSubdomain()

const classes = {
  container: `${PREFIX}-container`,
  justifyContent: `${PREFIX}-justifyContent`
}

const StyledBox = styled(Box)(({ theme }) => ({
  paddingBottom: 0,
  [theme.breakpoints.down('md')]: {
    paddingBottom: subdomain === 'app' ? '4.75rem' : 0
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: subdomain === 'app' ? '.5rem' : 0,
    minHeight: subdomain === 'app' ? '3rem' : 0
  },
  [`&.${classes.container}`]: {
    backgroundColor: 'white',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  },
  [`& .${classes.justifyContent}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }
}))

function Copyright () {
  const { t } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()

  if (whiteLabelConfig?.isActive) {
    return (
      <></>
    )
  }
  return (
    <Typography variant='body2'>
      {t('footer.copyright', { year: new Date().getFullYear() })}{' '}
      <Link color='inherit' href='https://www.simplehealthkit.com/'>
        {t('footer.shk')}
      </Link>
    </Typography>
  )
}

export default function Footer () {
  const { t } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()
  return (
    <StyledBox component='footer' className={classes.container}>
      <Container maxWidth='lg'>
        <Stack
          sx={{
            px: 0,
            py: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: whiteLabelConfig?.isActive ? 'center' : 'space-between',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap'
          }}
        >
          <Typography variant='body2'>
            { whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions)
              ? ''
              : (
                <>
                  <Link
                    href={whiteLabelConfig.isActive ? whiteLabelConfig?.terms_conditions || '' : 'https://www.simplehealthkit.com/terms'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('login.termsAndCondition')} <OpenInNew fontSize='inherit' />
                  </Link>
                  {' | '}
                </>
                )
            }
            { whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.privacy_policy)
              ? ''
              : (
                <>
                  <Link
                    href={whiteLabelConfig.isActive ? whiteLabelConfig?.privacy_policy || '' : 'https://www.simplehealthkit.com/privacy'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('footer.privacyPolicy')} <OpenInNew fontSize='inherit' />
                  </Link>
                </>
                )
            }
          </Typography>
          <Copyright />
        </Stack>
      </Container>
    </StyledBox>
  )
}
