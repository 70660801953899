import React, { useState } from 'react'
import PageLayout from 'components/PageLayout'
import {
  Box,
  Typography,
  TextField,
  Link,
  Dialog,
  DialogContent,
  DialogContentText
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import { api } from 'Core'
import * as yup from 'yup'
import { Error } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  topic: yup
    .string()
    .required('Subject is required'),
  description: yup
    .string()
    .required('Please provide the details about your query')
})

const SupportPage = () => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      topic: '',
      description: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const emailsend = await api.auth.sendQuery({
          topic: values.topic,
          description: values.description,
          email: values.email,
          name: values.name
        })
        if (emailsend?.success === true) {
          setConfirmDialogOpen(true)
        }
        setConfirmDialogOpen(true)
      } catch (error) {
        setErrorDialogOpen(true)
      }
    }
  })

  return (
    <PageLayout title='Support' sx={{ p: 1 }}>
      <Typography>Send us a message</Typography>
      <Box component='form' onSubmit={formik.handleSubmit} autoComplete='off'>
        <TextField
          fullWidth
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          helperText=' '
          label='Your Name'
          autoFocus
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          label='Your Email'
          helperText={(formik.touched.email && formik.errors.email) || ' '}
          autoFocus
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name='topic'
          value={formik.values.topic}
          onChange={formik.handleChange}
          label='Subject'
          helperText='Enter a sentence which helps us connect to the relevant team faster'
          error={formik.touched.topic && Boolean(formik.errors.topic)}
          autoFocus
          sx={{ minBlockSize: 1, mt: 1 }}
        />
        <TextField
          multiline
          fullWidth
          name='description'
          rows={5}
          sx={{ mt: 1 }}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          label='Details'
          helperText='Please provide complete details about your query'
        />
        <Typography sx={{ py: 1 }} variant='body2'>When you submit this form an email will be sent to <Link href='mailto:help@simplehealthkit.com'>help@simplehealthkit.com</Link></Typography>
        <LoadingButton
          loading={formik.isSubmitting}
          variant='contained'
          type='submit'
        >
          Send
        </LoadingButton>
      </Box>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false)
          formik.resetForm()
        }}
      >
        <DialogContent>
          <CheckCircleOutlineIcon color='success' />
          <DialogContentText>
            Thank you for your message. We will revert back to you soon.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false)
          formik.resetForm()
        }}
      >
        <DialogContent>
          <Error color='error' />
          <DialogContentText>
            Sorry. Something went wrong while submitting your query. Please reach out to us directly as <Link href='mailto:help@simplehealthkit.com'>help@simplehealthkit.com</Link>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </PageLayout>
  )
}

export default SupportPage
