import { ArrowForwardSharp, CheckCircleRounded } from '@mui/icons-material'
import {
  AccordionDetails,
  Box,
  Button,
  Hidden,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Accordion, AccordionSummary, Paper, SkuDisplayCard } from 'components'
import { LanguageSelect } from 'components/Header'
import useFormik, { textFieldPropsGetter } from 'hooks/useFormik'
import _ from 'lodash'
import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Link, Navigate } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import * as yup from 'yup'
import { LandingPageSectionI } from '../../../types/db/SiteI'
import DOMPurify from 'dompurify'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

const components = {
  Hero,
  TestsOfferedSection,
  AboutSection,
  TestsHPOfferedSection,
  AboutPrepSection,
  WhoCanTestSection,
  GetTestTodaySection,
  HowTestWorksSection,
  WhyGetTestedSection,
  CommonQuestionsSection,
  GetTestedAndSeeResultsQuicklySection,
  GetTestedTableSection,
  ShareWithFriendsSection,
  SupportSection,
  Image,
  Button,
  FootNoteSection
}

export type LandingPageSectionNameI = keyof typeof components

function Hero (props: Pick<LandingPageSectionI['props'], 'heading' | 'features' | 'backgroundImageSrc' | 'getFreeTestLinkButton' | 'showFullImage'>) {
  const { logoUrl, siteName, logoDimensions } = useWhiteLabelConfig()
  return (
    <Paper sx={{ padding: 0, width: '100%' }} component='header'>
      <Stack sx={{ gap: props.showFullImage ? 1 : 10, p: 3, width: '100%' }}>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Stack />
          <Box
            component='img'
            src={logoUrl}
            alt={siteName}
            {..._.mapValues(logoDimensions, Number)}
          />
          <Stack sx={{ justifySelf: 'flex-end' }}>
            <LanguageSelect />
          </Stack>
        </Stack>
        <Stack
          sx={{
            gap: 3,
            flexDirection: 'row',
            flexWrap: 'wrap-reverse',
            position: 'relative'
          }}
        >

          { props.showFullImage
            ? (
            <Stack
              sx={{
                flex: 1,
                bottom: 0,
                left: 0,
                width: '100%',
                marginLeft: -3,
                marginRight: -3,
                marginBottom: -3
              }}
            >
              <Box
                component='img'
                src={props.backgroundImageSrc}
                alt='Background Image'
                sx={{ width: '100%', height: 'auto' }}
              />
            </Stack>
              )
            : (
            <>
              <Stack
                sx={{
                  flex: 1,
                  position: { sm: 'absolute' },
                  bottom: 0,
                  left: 0,
                  width: 'min(800px, 90%)'
                }}
              >
                <Box
                  component='img'
                  src={props.backgroundImageSrc}
                  alt='Background Image'
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Stack>
              <Stack
                sx={{
                  gap: 3,
                  width: { sm: '50%' },
                  ml: { sm: 'auto' },
                  pb: { sm: 12 }
                }}
              >
                <Typography
                  component='h1'
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 34, lg: 60 },
                    lineHeight: 1.2
                  }}
                >
                  {props.heading}
                </Typography>
                <Stack sx={{ gap: 1.5 }}>
                  {props?.features?.map(feature => (
                    <Feature key={feature}>{feature}</Feature>
                  ))}
                </Stack>
                <Button
                  variant={props?.getFreeTestLinkButton?.variant || 'contained'}
                  endIcon={<ArrowForwardSharp />}
                  sx={{ alignSelf: 'center' }}
                  to={props?.getFreeTestLinkButton?.href || './your-situation'}
                  component={Link}
                >
                  {props?.getFreeTestLinkButton?.text || ''}
                </Button>
              </Stack>
            </>
              ) }

        </Stack>
      </Stack>
    </Paper>
  )
}

function TestsOfferedSection ({
  heading,
  testsOffered,
  sectionMaxWidth
}: Pick<LandingPageSectionI['props'], 'heading' | 'testsOffered' | 'sectionMaxWidth'>) {
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack sx={{ py: 4, gap: 7 }}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          {heading}
        </Typography>
        <Stack
          sx={{
            gap: 30 / 8,
            flexDirection: { sm: 'row' },
            flexWrap: 'wrap',
            justifyContent: 'space-around'
          }}
        >
          {testsOffered?.map(test => (
            <TestOfferedItem key={test}>{test}</TestOfferedItem>
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

function FootNoteSection({
  features,
  sectionMaxWidth
} : Pick<LandingPageSectionI['props'], 'features'|'sectionMaxWidth'>){
  return(
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack sx={{ gap: 3, width: '100vw', p: 2 }}>
        { features?.map((feature) => (
            <Typography sx={{color: '#00000099'}} dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(feature || '') } }></Typography>
        )) }
        </Stack>
    </Paper>
  )
}

function AboutSection ({
  heading,
  description1,
  description2,
  sectionMaxWidth,
  showPoweredBy,
  features,
  section,
  button,
  image,
  bannerImage
}: Pick<LandingPageSectionI['props'], 'heading' | 'testsOffered' | 'sectionMaxWidth' | 'showPoweredBy' | 'description1' | 'features' | 'section' | 'button' | 'image' | 'description2' | 'bannerImage'>) {
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack sx={{ gap: 3 }}>
        <Typography variant='h4'>
          {heading}
        </Typography>
        { showPoweredBy
          ? (
          <Stack direction={'row'} justifyContent={'left'}>
            <Stack
              component="img"
              src={'/assets/poweredByText.svg'}
              alt={'logo Powered By SHK'}
            />
            <Stack
              component="img"
              src={'/assets/logoPoweredBy.svg'}
              alt={'logo Powered By SHK'}
            />
          </Stack>
            )
          : null }
        <Stack sx={{ background: '#F7F7F7', padding: 3, borderRadius: 1, gap: 2 }}>
          <Typography dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(description1 || '') } }></Typography>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(description2 || '') } }></Typography>
            { bannerImage 
              ? (
                <Stack
                  width={80}
                  height={80}
                  component="img"
                  src={bannerImage.src}
                  alt={bannerImage.alt}
                />
              )
              : null
            }
            
          </Stack>
        </Stack>
        <Stack gap={2}>
          { features?.map((feature, id) => (
            <Stack key={id} gap={1} alignItems={'center'} flexDirection={'row'}>
              <CheckCircleIcon color='success' fontSize='small' />
              <Typography fontWeight='bold' variant='body1'>{feature}</Typography>
            </Stack>
          )) }
        </Stack>
        {
          section
            ? (
            <Stack gap={2} sx={{ backgroundColor: 'success.main', color: '#FFF', padding: 3, borderRadius: 1, gap: 2 }} >
              <Typography fontWeight='bold' variant='body1'>{section?.heading}</Typography>
              <Typography dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(section?.description || '') } }></Typography>
            </Stack>
              )
            : null
        }
        <Button
          fullWidth
          variant={button?.variant || 'outlined'}
          endIcon={
            <Hidden smDown>
              <ArrowForwardSharp />
            </Hidden>
          }
          to={button?.href || './your-situation'}
          component={Link}
        >
          {button?.text || ''}
        </Button>
        <Box
          maxWidth={303}
          component='img'
          src={image?.src || ''}
          alt={image?.alt || ''}
        />
      </Stack>
    </Paper>
  )
}

function TestsHPOfferedSection ({
  heading,
  testOfferedCards,
  sectionMaxWidth,
  description1,
  button
}: Pick<LandingPageSectionI['props'], 'heading' | 'sectionMaxWidth' | 'description1' | 'testOfferedCards' | 'button'>) {
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack sx={{ gap: 3 }}>
        <Typography variant='h4'>
          {heading}
        </Typography>
        <Typography variant='body1'>{description1}</Typography>
        <Stack gap={2}>
          { testOfferedCards?.map((card, id) => (
            <SkuDisplayCard
              image={card?.image}
              title={card?.title}
              body={card?.body}
              key={id}
            />
            // <Stack key={id} display="flex" alignItems="center" gap={2}
            //   sx={{
            //     background: '#F7F7F7',
            //     padding: 3,
            //     borderRadius: 1,
            //     flexDirection: {
            //       xs: 'column',
            //       md: 'row'
            //     }
            //   }}
            // >
            //   { card?.image ? null : <BiotechIcon sx={{ bgcolor: '#333', color: '#fff', padding: '0 2px', height: '28px', width: '28px' }}/> }
            //   <Stack>
            //     <Typography variant='h6'>{card?.title}</Typography>
            //     <ul>
            //       <Stack padding={1}>
            //       { card?.body?.map((data, xid) => (
            //             <li key={xid}><Typography variant='body1' dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(data || '') } }></Typography></li>
            //       ))
            //         }
            //       </Stack>
            //     </ul>
            //   </Stack>
            //   { card?.image
            //     ? (
            //       <Box
            //         sx={{ alignSelf: 'center' }}
            //         width='100%'
            //         component='img'
            //         maxWidth={'170px'}
            //         src={card?.image || ''}
            //         alt={'Sku Bag'}
            //       />
            //       )
            //     : null
            //   }
            // </Stack>
          )) }
        </Stack>
        <Button
          variant={button?.variant || 'outlined'}
          endIcon={<ArrowForwardSharp />}
          to={button?.href || ''}
          component={Link}
        >
          {button?.text || ''}
        </Button>
      </Stack>
    </Paper>
  )
}

function AboutPrepSection ({
  heading,
  description,
  hivFactsLinkButton,
  prepProvidersLinkButton
}: Pick<LandingPageSectionI['props'], 'heading' | 'description' | 'prepProvidersLinkButton' | 'hivFactsLinkButton'>) {
  return (
    <Paper sx={{ backgroundColor: '#F5F5F5' }} component='section'>
      <Stack sx={{ gap: 3, py: 1, px: 1, flexDirection: { md: 'row' } }}>
        <Stack sx={{ gap: 1 }}>
          <Typography variant='h5'>{heading}</Typography>
          <Typography>{description}</Typography>
        </Stack>
        <Stack
          sx={{
            gap: 1.5,
            flexDirection: { sm: 'row', md: 'column', lg: 'row' },
            alignItems: 'center'
          }}
        >
          <Button
            fullWidth
            variant={prepProvidersLinkButton?.variant || 'outlined'}
            endIcon={
              <Hidden smDown>
                <ArrowForwardSharp />
              </Hidden>
            }
            href={prepProvidersLinkButton?.href}
          >
            {prepProvidersLinkButton?.text || ''}
          </Button>
          <Button
            fullWidth
            variant={hivFactsLinkButton?.variant || 'outlined'}
            endIcon={
              <Hidden smDown>
                <ArrowForwardSharp />
              </Hidden>
            }
            href={hivFactsLinkButton?.href}
          >
            {hivFactsLinkButton?.text}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}

function WhoCanTestSection ({
  heading,
  image,
  testCriteria
}: Pick<LandingPageSectionI['props'], 'heading' | 'image' | 'testCriteria'>) {
  return (
    <Paper component='section'>
      <Stack sx={{ gap: 3, alignItems: 'center', p: 2 }}>
        <Box
          height={115}
          width='auto'
          component='img'
          src={image?.src || ''}
          alt={image?.alt || ''}
        />
        <Typography variant='h5'>{heading}</Typography>
        <Stack sx={{ gap: 4, flexDirection: { sm: 'row' } }}>
          {testCriteria?.map(criteria => (
            <Stack key={criteria} sx={{ gap: 1.5, alignItems: 'center' }}>
              <Box
                height={32}
                width={26}
                component='img'
                src='https://www.simplehealthkit.com/wp-content/uploads/2023/05/blueIcon-Who-can-get-the-test.png'
              />
              <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
                {criteria}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

function GetTestTodaySection ({
  image,
  heading,
  description,
  getFreeTestLinkButton
}: Pick<LandingPageSectionI['props'], 'image' | 'heading' | 'description' | 'getFreeTestLinkButton'>) {
  return (
    <Paper sx={{ backgroundColor: '#F2EEEC' }} component='section'>
      <Stack sx={{ gap: 4, p: 4, flexDirection: { md: 'row' } }}>
        <Stack sx={{ width: { xs: '100%', md: '460px' } }}>
          <Box
            sx={{ alignSelf: 'center' }}
            width='100%'
            component='img'
            src={image?.src || ''}
            alt={image?.alt || ''}
          />
        </Stack>
        <Stack sx={{ flexDirection: { md: 'column' }, gap: 3 }}>
          <Stack sx={{ gap: 2.5 }}>
            <Typography
              variant='h3'
              sx={{
                width: { xs: 143, md: 360 },
                fontWeight: 500,
                fontSize: { xs: 30, md: 60 }
              }}
            >
              {heading}
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
              {description}
            </Typography>
          </Stack>
          <Button
            variant='outlined'
            endIcon={<ArrowForwardSharp />}
            to={getFreeTestLinkButton?.href || './your-situation'}
            component={Link}
          >
            {getFreeTestLinkButton?.text || ''}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}

function HowTestWorksSection ({
  heading,
  testSteps,
  sectionMaxWidth
}: Pick<LandingPageSectionI['props'], 'heading' | 'testSteps' | 'sectionMaxWidth'>) {
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack sx={{ gap: 3 }}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          {heading}
        </Typography>
        <Stack
          sx={{
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 3
          }}
        >
          {testSteps?.map((testStep, idx) => (
            <TestStep
              key={testStep.title}
              step={idx + 1}
              title={testStep.title}
              image={testStep.image}
            />
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

function WhyGetTestedSection ({
  heading,
  description1,
  description2,
  bannerImage,
  sectionMaxWidth
}: Pick<LandingPageSectionI['props'], 'heading' | 'description1' | 'description2' | 'bannerImage' | 'sectionMaxWidth'>) {
  const { logoUrl, siteName, logoDimensions } = useWhiteLabelConfig()
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack
        sx={{
          gap: 3
        }}
      >
        <Stack sx={{ gap: 3 }}>
          <Typography variant='h5'>{heading}</Typography>
          <Typography>{description1}</Typography>
          <Typography>{description2}</Typography>
          <Box
            component='img'
            src={logoUrl}
            alt={siteName}
            {..._.mapValues(logoDimensions, Number)}
          />
        </Stack>
        <Stack sx={{ width: { xs: 300, md: '100%' }, alignSelf: 'end', alignItems: 'center' }}>
          <Box

            maxWidth={350}
            component='img'
            src={bannerImage?.src || ''}
            alt={bannerImage?.alt || ''}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

function CommonQuestionsSection ({
  heading,
  commonQuestions,
  sectionMaxWidth
}: Pick<LandingPageSectionI['props'], 'heading' | 'commonQuestions' | 'sectionMaxWidth'>) {
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
      <Stack sx={{ gap: 2 }}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          {heading}
        </Typography>
        {commonQuestions?.map(question => (
          <Accordion
            key={question.summary}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }}
          >
            <AccordionSummary>{question?.summary}</AccordionSummary>
            <AccordionDetails>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {question.detail}
              </ReactMarkdown>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Paper>
  )
}

function GetTestedAndSeeResultsQuicklySection ({
  heading,
  description,
  image,
  getFreeTestLinkButton,
  sectionMaxWidth
}: Pick<LandingPageSectionI['props'], 'heading' | 'description' | 'image' | 'getFreeTestLinkButton' | 'sectionMaxWidth'>) {
  return (
    <Stack
      component='section'
      sx={{
        p: 6,
        backgroundColor: '#333333',
        maxWidth: sectionMaxWidth || '100%',
        gap: 4
      }}
    >
      <Stack sx={{ width: { xs: '100%', md: '460px' } }}>
        <Box width='100%' component='img' src={image?.src} alt={image?.alt} />
      </Stack>
      <Stack sx={{ gap: 4 }}>
        <Typography
          sx={{
            fontSize: { xs: 30 },
            color: 'white',
            fontWeight: 'bold',
            lineHeight: 1.2
          }}
        >
          {heading}
        </Typography>
        <Typography sx={{ fontSize: 20, color: 'white', fontWeight: 500 }}>
          {description}
        </Typography>
        <Button
          variant={getFreeTestLinkButton?.variant || 'text'}
          endIcon={<ArrowForwardSharp />}
          sx={{
            background: '#FFF',
            '&:hover': {
              backgroundColor: '#F7F7F7'
            }
          }}
          to={getFreeTestLinkButton?.href || './your-situation'}
          component={Link}
        >
          {getFreeTestLinkButton?.text}
        </Button>
      </Stack>
    </Stack>
  )
}

const getTestTableColumns: GridColDef[] = [
  {
    field: 'test',
    headerName: 'Tests',
    flex: 1
  },
  {
    field: 'you_get',
    headerName: 'You Get',
    flex: 1
  }
]

function GetTestedTableSection ({
  heading,
  description,
  image,
  getFreeTestLinkButton,
  sectionMaxWidth,
  description1,
  getTestedTableRowData
}: Pick<LandingPageSectionI['props'], 'heading' | 'description' | 'image' | 'getFreeTestLinkButton' | 'sectionMaxWidth' | 'description1' | 'getTestedTableRowData'>) {
  return (
    <Stack
      component='section'
      sx={{
        p: 6,
        backgroundColor: '#333333',
        gap: 4,
        maxWidth: sectionMaxWidth || '100%'
      }}
    >
      <Stack sx={{ width: { xs: '100%', md: '460px' } }}>
        <Box width='100%' component='img' src={image?.src} alt={image?.alt} />
      </Stack>
      <Stack sx={{ gap: 4 }}>
        <Typography
          sx={{
            fontSize: { xs: 30 },
            color: 'white',
            fontWeight: 'bold',
            lineHeight: 1.2
          }}
        >
          {heading}
        </Typography>
        <Typography sx={{ color: 'white', fontWeight: 500 }} dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(description || '') } }></Typography>
        <Typography sx={{ color: 'white', fontWeight: 'bold' }} dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(description1 || '') } }></Typography>
        <Stack sx={{ width: '100%' }}>
          <DataGrid
            rows={ getTestedTableRowData || [] }
            columns={ getTestTableColumns }
            disableColumnSelector={ true }
            hideFooterPagination={true}
            sx={ {
              '& .MuiDataGrid-main': {
                color: '#FFF'
              },
              '& .MuiDataGrid-row': { cursor: 'pointer' },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important'
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none !important'
              }
            } }
          />
        </Stack>
        <Button
          variant={getFreeTestLinkButton?.variant || 'outlined'}
          endIcon={<ArrowForwardSharp />}
          sx={{
            background: '#FFF',
            '&:hover': {
              backgroundColor: '#F7F7F7'
            }
          }}
          to={getFreeTestLinkButton?.href || './your-situation'}
          component={Link}
        >
          {getFreeTestLinkButton?.text}
        </Button>
      </Stack>
    </Stack>
  )
}

function SupportSection ({
  heading,
  description,
  emailUsLinkButton,
  callUsLinkButton,
  hideSupportForm,
  sectionMaxWidth
}: Pick<LandingPageSectionI['props'], 'heading' | 'description' | 'emailUsLinkButton' | 'callUsLinkButton' | 'hideSupportForm' | 'sectionMaxWidth' >) {
  return (
    <Paper sx={{ maxWidth: sectionMaxWidth || '100%' }} component='section'>
    <Stack
      component='section'
      sx={{
        gap: 4,
        justifyContent: 'space-between',
        flexDirection: { md: 'row' }
      }}
    >
      <Stack component='section' sx={{ gap: 4, flex: 1 }}>
        <Typography variant='h5'>{heading}</Typography>
        <Typography>{description}</Typography>
        <Stack sx={{ gap: 2 }}>
          <Button
            variant={emailUsLinkButton?.variant || 'outlined'}
            endIcon={<ArrowForwardSharp />}
            href={emailUsLinkButton?.href}
          >
            {emailUsLinkButton?.text}
          </Button>
          <Button
            variant={callUsLinkButton?.variant || 'outlined'}
            endIcon={<ArrowForwardSharp />}
            href={callUsLinkButton?.href}
          >
            {callUsLinkButton?.text}
          </Button>
        </Stack>
      </Stack>
      { hideSupportForm
        ? null
        : (
          <Stack sx={{ flex: 1, backgroundColor: '#F5F5F5', p: 4 }}>
            <SupportForm />
          </Stack>
          )
      }
    </Stack>
    </Paper>
  )
}

function ShareWithFriendsSection ({
  heading,
  copyLinkButton
}: Pick<LandingPageSectionI['props'], 'heading' | 'copyLinkButton'>) {
  return (
    <Stack
      sx={{
        backgroundColor: '#F2EEEC',
        p: 6,
        gap: 3,
        alignItems: 'center'
      }}
      component='section'
    >
      <Typography sx={{ fontSize: 34, fontWeight: 500, textAlign: 'center' }}>
        {heading}
      </Typography>
      <Button variant='outlined'>{copyLinkButton?.text || ''}</Button>
    </Stack>
  )
}

function FreeTestingProgramLandingPage () {
  const { ftpLandingPageConfig } = useWhiteLabelConfig()
  if (!ftpLandingPageConfig) return <Navigate to='./your-situation' replace />
  return (
    <Stack sx={{ backgroundColor: { md: 'white' } }}>
      <Stack
        sx={{
          gap: 3,
          p: 1.5,
          m: 'auto',
          width: 'min(1200px, 100%)'
        }}
        component='main'
      >
        {ftpLandingPageConfig.map((configJsonComponent, idx) => (
          <RenderComponent
            configJsonComponent={configJsonComponent}
            key={idx}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export function GenericProgramLandingPage ({ configJson } : { configJson : LandingPageSectionI[] }) {
  if (!configJson) return <></>
  return (
    <Stack sx={{ width: '100%' }}>
      <Stack
        sx={{
          gap: 3,
          padding: {
            xs: 1.5,
            md: 0
          },
          paddingBottom: 1.5,
          m: 'auto',
          alignItems: 'center',
          width: '100%'
          // width: 'min(1200px, 100%)'
        }}
        component='main'
      >
        {configJson.map((configJsonComponent, idx) => (
          <RenderComponent
            configJsonComponent={configJsonComponent}
            key={idx}
          />
        ))}
      </Stack>
    </Stack>
  )
}

function FTPLandingPagePreview ({
  ftpLandingPageConfig
}: {
  ftpLandingPageConfig: LandingPageSectionI[]
}) {
  return (
    <Stack justifyContent={'center'} alignItems={'center'} gap={2}>
      {ftpLandingPageConfig.map((configJsonComponent, idx) => (
        <RenderComponent
          configJsonComponent={configJsonComponent}
          key={idx}
        />
      ))}
    </Stack>
  )
}

export { FreeTestingProgramLandingPage, FTPLandingPagePreview }

function RenderComponent ({
  configJsonComponent
}: {
  configJsonComponent: LandingPageSectionI
}) {
  const Component = components[configJsonComponent.name]
  if (!Component) return null
  return <Component {...(configJsonComponent.props as any || {})} />
}

const validationSchema = yup.object({
  name: yup.string().trim().required('Please enter name').default(''),
  email: yup
    .string()
    .trim()
    .required('Please enter email')
    .email('Please enter a valid email')
    .default(''),
  description: yup
    .string()
    .trim()
    .required('Please enter description')
    .default('')
})

function SupportForm () {
  const formik = useFormik({
    initialValues: validationSchema.getDefault(),
    onSubmit: () => {},
    validationSchema
  })
  const getTextFieldProps = textFieldPropsGetter(formik)
  return (
    <Stack component='form' sx={{ gap: 4 }} onSubmit={formik.handleSubmit}>
      <Typography variant='h5'>Tell us what you are looking for</Typography>
      <Stack sx={{ gap: 2 }}>
        <TextField
          {...getTextFieldProps({ name: 'name', label: 'Your Name' })}
        />
        <TextField
          {...getTextFieldProps({ name: 'email', label: 'Your Email' })}
        />
        <TextField
          {...getTextFieldProps({
            name: 'description',
            label: 'Please describe your request',
            multiline: true,
            minRows: 4
          })}
        />
        <Button
          sx={{ alignSelf: 'end' }}
          variant='outlined'
          endIcon={<ArrowForwardSharp />}
          type='submit'
        >
          Send
        </Button>
      </Stack>
    </Stack>
  )
}

type TestStepProps = {
  step: number
  title: string
  image: {
    src: string
    alt: string
  }
}

function TestStep ({ step, title, image }: TestStepProps) {
  return (
    <Stack
      sx={{
        gap: 3,
        backgroundColor: '#F7F7F7',
        borderRadius: 1,
        flex: {
          xs: '1 1 max(calc(50% - 12px), 236px)',
          md: '0 1 calc(25% - 18px)'
        },
        p: 3
      }}
    >
      <Stack>
        <Typography sx={{ fontSize: 21, fontWeight: 700 }}>Step {step}:</Typography>
        <Typography sx={{  fontSize: 21 }}>{title}</Typography>
      </Stack>
      <Stack sx={{ alignSelf: 'center' }}>
        <Box
          component='img'
          src={image.src}
          alt={image.alt}
          height='auto'
          width='100%'
        />
      </Stack>
    </Stack>
  )
}

function Feature ({ children }: { children: React.ReactNode }) {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
      <CheckCircleRounded />
      <Typography sx={{ fontWeight: 400 }}>{children}</Typography>
    </Stack>
  )
}

function TestOfferedItem ({ children }: { children: React.ReactNode }) {
  return (
    <Stack sx={{ gap: 1.5, alignItems: 'center', flex: 1 }}>
      <Box
        width={35}
        component='img'
        src='https://www.simplehealthkit.com/wp-content/uploads/2023/05/blueIcon-What-tests-do-we-offer-2.png'
        sx={{
          background: 'white 0px 0px / 100% 100% no-repeat',
          mixBlendMode: 'luminosity'
        }}
        draggable={false}
      />
      <Typography sx={{ fontSize: 20, fontWeight: 500 }}>{children}</Typography>
    </Stack>
  )
}

function Image ({
  width = 260,
  alt,
  src
}: {
  width?: number
  src: string
  alt: string
}) {
  return <Box component='img' width={width} height='auto' alt={alt} src={src} />
}
