import { Button, Link, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import Loader from 'components/Loader'
import { api } from 'Core'
import { useNavigate, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useWhiteLabelConfig } from '../../utils/white-label/WhiteLabelConfig'

const OptOut = () => {
  const [finished, setFinished] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useParams()
  useEffect(() => {
    const FormSubmit = async () => {
      try {
        const cache = await api.feedback.getCheckHashToken(token, { key: 'freeTestingRemainder' })

        if (cache) {
          const optOut = await api.feedback.optOutFTP(token)

          if (optOut) {
            setFinished(true)
            dispatch(
              showSnackBar({
                severity: 'success',
                message: 'Opted out of the notification successfully',
                show: true
              })
            )
          } else throw Error('Could not opt out')
        }
      } catch (error) {
        dispatch(
          showSnackBar({
            show: true,
            severity: 'error',
            message: 'Something went wrong'
          })
        )
        navigate('/')
      }
    }

    FormSubmit()
  }, [])
  return (
    <Stack>
      {finished ? <SuccessOptOut /> : <LoadOptOut />}
    </Stack>
  )
}

const LoadOptOut = () => {
  return (
    <Stack>
      <Loader />
    </Stack>
  )
}

const SuccessOptOut = () => {
  const whitelabelConfig = useWhiteLabelConfig()

  return (
    <Stack gap={3} spacing={2}>
      <Typography variant="h4" component="h1" sx={{ textAlign: 'center' }}>
        {t('freeTestRemainder.feedback.optOutTitle')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center' }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t('freeTestRemainder.feedback.optOut'), {
            ADD_ATTR: ['target']
          })
        }}
      ></Typography>
      <Link href={`mailto:${whitelabelConfig?.emailForSupport || process.env.SUPPORT_EMAIL}`}>
        <Button variant="contained" fullWidth>
          {t('freeTestRemainder.feedback.emailBtn', {
            email: whitelabelConfig?.emailForSupport || process.env.SUPPORT_EMAIL
          })}
        </Button>
      </Link>
      <Link href="tel:+1(833)474-7745">
        <Button variant="outlined" fullWidth>
          {t('freeTestRemainder.feedback.callBtn')}
        </Button>
      </Link>
    </Stack>
  )
}

export default OptOut
