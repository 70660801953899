import { ReduxActionI } from '.'
import { SiteI } from '../../types/db/SiteI'
import { CLEAR_SITE_DATA, SAVE_PATIENT_DETAILS, SAVE_SITE_DETAILS } from '../actions/index'

const defaultState = {
  siteDetails: {} as SiteI
}

export default (state = defaultState, action: ReduxActionI) => {
  switch (action.type) {
    case SAVE_PATIENT_DETAILS: {
      const newState = action.payload
      return {
        ...state,
        ...newState
      }
    }
    case SAVE_SITE_DETAILS:
      return {
        ...state,
        siteDetails: { ...state.siteDetails, ...action.payload }
      }

    case CLEAR_SITE_DATA:
      return {
        ...state,
        siteDetails: {}
      }
    default:
      return state
  }
}
