import { auth, isAppPortal } from 'utils/auth0Service'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTokenSilentlyFailure,
  getTokenSilentlyInit,
  getTokenSilentlySuccess,
  showSnackBar,
  updateUser
} from 'state/actions'
import { ReduxState } from '../types/ReduxState'
import { useWhiteLabelConfig } from '../utils/white-label/WhiteLabelConfig'
import { api } from 'Core'
import cookieUtils from 'Core/utils/cookieUtils'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { getCurrentLanguage } from 'i18n'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { AuthUser } from '../types/AuthUser'
import jwtUtil from 'Core/utils/jwtUtil'
import { isShkDomain } from 'utils/utilFuntions'

const useAuth0 = () => {
  const variables = useWhiteLabelConfig()?.variables
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { isLoading, user } = useSelector((state: ReduxState) => state.auth)
  const [searchParams, setSearchParams] = useSearchParams()
  const isShk = isShkDomain()
  const isPatientPortal = isAppPortal()

  const signup = (email: string, password: string, userMetadata = {}): Promise<{user: AuthUser}> => {
    return new Promise((resolve, reject) => {
      let redirectUri = new URL(`https://${window.location.host}/${isShk && isPatientPortal? 'choose-portal': ''}`)
      const redirectTo = new URL(window.location.href).searchParams.get('redirectTo')
      if (redirectTo) {
        redirectUri = new URL(`https://${window.location.host}/callback`)
        redirectUri.searchParams.append('redirectTo', redirectTo)
      }
      api.portalAuth.signup({
        ...userMetadata,
        email,
        password
      }).then((data) => {
        // window.sessionStorage.setItem('token', data.accessToken)
        // window.sessionStorage.setItem('expiresIn', data.expiresIn)
        // dispatch(getTokenSilentlyInit())
        // dispatch(updateUser(data.user))
        // window.location.href = redirectUri.toString()
        resolve(data)
      }).catch((err) => {
        if (err) {
          console.log('Error', err)
          reject(err)
        }
      })
      // auth(variables).signup(
      //   {
      //     connection: 'Username-Password-Authentication',
      //     email,
      //     password,
      //     userMetadata
      //   },
      //   (err, res) => {
      //     if (err) reject(err)
      //     resolve(res)
      //   }
      // )
    })
  }

  async function checkMfalogIn (email: string, password: string, redirectTo: string | undefined) {
    const response = await api.auth.checkAccountExists({
      email /* token */
    })
    const isSsoUser = jwtUtil.checkSsoUser();

    if (!response.success) {
      console.log('User not found')
    } else {
      if (!response?.email_verified) {
        const data = await api.portalAuth.send2FaOtp({
          email,
          password,
          type: 'email',
          phone: response?.phone
        })
        if (data?.success) {
          return navigate(`/verify-otp/${data?.token}?redirectTo=${redirectTo || '/mfa-config/setup'}`, {
            state: {
              email,
              password,
              otpSend: true,
              emailVerified: response?.email_verified,
              phone: response?.phone,
              mfa_method: response.mfa_method
            }
          })
        } else {
          throw Error('Something went wrong!')
        }
      } else if (response.mfa_enabled && !isSsoUser) {
        const savedDate = cookieUtils.getCookie(`remember_device_${email}`);
        const today = moment()

        if (!savedDate || today.isAfter(moment(savedDate), 'day')) {
          const data = await api.portalAuth.send2FaOtp({
            email,
            password,
            type: response.mfa_method,
            checkPassword: !response.mfa_method,
            phone: response?.phone
          })
          if (data?.success) {
            return navigate(`/verify-otp/${data?.token}${redirectTo ? `?redirectTo=${redirectTo}` : ''}`, {
              state: {
                email,
                password,
                otpSend: !!response.mfa_method,
                emailVerified: response?.email_verified,
                phone: response?.phone,
                mfa_method: response.mfa_method
              }
            })
          } else {
            throw Error('Something went wrong!')
          }
        } else {
          if (redirectTo) { setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: redirectTo || '/mfa-config/setup' }) }
          await login(email, password, '', getCurrentLanguage(i18n))
        }
      } else {
        if (redirectTo) { setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: redirectTo || (isSsoUser ? '/' : '/mfa-config/setup') }) }
        await login(email, password, '', getCurrentLanguage(i18n))
      }
    }
  }

  const login = (username: string, password: string, _subdomain: string, lang: string = 'en-US') => {
    const isShk = isShkDomain()
    const isPatientPortal = isAppPortal()

    return new Promise((resolve, reject) => {
      let redirectUri = new URL(`https://${window.location.host}/${isShk && isPatientPortal? 'choose-portal': ''}`)
      const redirectTo = new URL(window.location.href).searchParams.get('redirectTo')
      if (redirectTo) {
        redirectUri = new URL(`https://${window.location.host}/callback`)
        redirectUri.searchParams.append('redirectTo', redirectTo)
      }
      if (lang) {
        redirectUri.searchParams.append('lang', lang)
      }

      api.portalAuth.login({
        email: username,
        password
      }).then((data) => {
        window.sessionStorage.setItem('token', data.accessToken)
        window.sessionStorage.setItem('expiresIn', data.expiresIn)
        dispatch(getTokenSilentlyInit())
        dispatch(updateUser(data.user))
        window.location.href = redirectUri.toString()
        resolve(data)
      }).catch((err) => {
        if (err) {
          console.log('Error', err)
          dispatch(getTokenSilentlyFailure())
          reject(err)
        }
      })
      // auth(variables).login(
      //   {
      //     realm: 'Username-Password-Authentication',
      //     username,
      //     password,
      //     redirectUri: redirectUri.toString(),
      //     responseType: 'token'
      //   },
      //   (err, authResult) => {
      //     if (err) {
      //       console.log('Error', err)
      //       dispatch(getTokenSilentlyFailure())
      //       reject(err)
      //     }
      //     if (authResult) {
      //       window.sessionStorage.setItem('token', authResult.accessToken)
      //       dispatch(getTokenSilentlyInit())
      //       auth(variables).client.userInfo(authResult.accessToken, (err, userInfo) => {
      //         if (err) console.log(err)
      //         dispatch(updateUser(userInfo))
      //       })
      //       resolve(authResult)
      //     }
      //   }
      // )
    })
  }

  const getAccessTokenSilently = (triggerLoading = true): Promise<GetAccessTokenSilentlyRes> => {
    return new Promise((resolve, reject) => {
      if (triggerLoading) dispatch(getTokenSilentlyInit())
      api.portalAuth.refresh().then((res) => {
        if (res) {
          window.sessionStorage.setItem('token', res.accessToken)
          window.sessionStorage.setItem('expiresIn', res.expiresIn)
          dispatch(updateUser(res.user))
          dispatch(getTokenSilentlySuccess(res))
          resolve(res)
        }
      }).catch((err) => {
        if (err) {
          console.log(err, 'Error')
          dispatch(getTokenSilentlyFailure())
          dispatch(updateUser(null))

          cookieUtils.deleteCookie('refresh')
          window.sessionStorage.removeItem('token')
          window.sessionStorage.removeItem('expiresIn')
          reject(err)
        }
      })
      // auth(variables).checkSession(
      //   {
      //     redirectUri: window.location.origin,
      //     responseType: 'token'
      //     // scope: 'read:current_user'
      //   },
      //   (err, res) => {
      //     if (err) {
      //       console.log(err, 'Error')
      //       dispatch(getTokenSilentlyFailure())
      //       reject(err)
      //     }

      //     if (res) {
      //       auth(variables).client.userInfo(res.accessToken, (err, userInfo) => {
      //         if (err) console.log(err)
      //         dispatch(updateUser(userInfo))
      //         dispatch(getTokenSilentlySuccess(res))
      //       })
      //       resolve(res)
      //     }
      //   }
      // )
    })
  }

  const processHash = (hash: string) => {
    return new Promise((resolve, reject) => {
      auth(variables).parseHash({ hash }, (err, res) => {
        if (err) {
          console.log(err, 'Error')
          reject(err)
        }
        if (res) {
          window.sessionStorage.setItem('token', res?.accessToken || '')
          auth(variables).client.userInfo(res?.accessToken || '', (err, userInfo) => {
            if (err) console.log(err)
            dispatch(updateUser(userInfo))
          })
          dispatch(getTokenSilentlySuccess())
          resolve(res)
        }
      })
    })
  }

  const logout = (pageInfo: string , additionalInfo = { actionBy: '', reason: '' }) => {
    return new Promise((resolve, reject) => {
      const redirectUri = new URL(`https://${window.location.host}`)
      // const redirectTo = new URL(window.location.href).searchParams.get('redirectTo')
      // if (redirectTo) {
      //   redirectUri = new URL(`https://${window.location.host}/callback`)
      //   redirectUri.searchParams.append('redirectTo', redirectTo)
      // }

      const finalInfo = {
        ...additionalInfo,
        url: window.location.href
      }

      // auth(variables).logout({ returnTo: redirectUri.toString() })
      api.portalAuth.logout({ pageInfo, additionalInfo: finalInfo }).then(() => {
        console.log('Logout success')
        // remove all the session information
        let domain = process.env.REACT_APP_URL?.split('.').slice(1).join('.') || ''
        if (domain.includes(':')) domain = domain.split(':')[0]

        // dispatch(updateUser(null))
        // @ts-ignore
        cookieUtils.deleteCookie('refresh', '/api', domain)
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('expiresIn')
        window.sessionStorage.removeItem('track_email_analytics')
        window.location.href = redirectUri.toString()
        resolve('done')
      }).catch((err) => {
        if (err) {
          console.log(err, 'Error')
          reject(err)
          dispatch(getTokenSilentlyFailure())
        }
      })
    })
  }

  const resetPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      window.sessionStorage.removeItem('token')
      auth(variables).changePassword(
        {
          connection: 'Username-Password-Authentication',
          email
        },
        (err, _res) => {
          if (err) {
            dispatch(
              showSnackBar({
                severity: 'error',
                message: 'Something went wrong',
                show: true
              })
            )
            reject(err)
          }
          resolve(true)
        }
      )
    })
  }

  const getUserInfo = () => {
    return new Promise((resolve, reject) => {
      const token = window.sessionStorage.getItem('token')
      if (!token) {
        reject(Error('invalid token'))
        return
      }
      auth(variables).client.userInfo(token, (err, userInfo) => {
        if (err) {
          console.log(err)
          reject(err)
          return
        }

        resolve(userInfo)
      })
    })
  }

  return {
    signup,
    login,
    getAccessTokenSilently,
    processHash,
    user,
    isLoading,
    logout,
    resetPassword,
    getUserInfo,
    checkMfalogIn
  }
}

interface GetAccessTokenSilentlyRes {
  accessToken: string
  appState: string
  expiresIn: number
  idToken: string | null
  idTokenPayload: {
    aud: string
    exp: number
    iat: number
    iss: string
    sub: string
  } | null
  refreshToken: string | null
  scope: string
  state: string
  tokenType: string
}

export default useAuth0
