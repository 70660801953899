// @ts-nocheck
import jwtUtil from 'Core/utils/jwtUtil'
import { useSelector } from 'react-redux'
import useAuth0 from './useAuth0'
import { AuthUser } from '../types/AuthUser'

export default function useMockedUser () {
  const { user }: { user: AuthUser } = useAuth0()
  const { firstName, lastName } = jwtUtil.getName()
  const notificationEmail = jwtUtil.getNotificationEmail()
  const { data: { user: mockUser }, isMocked } = useSelector(state => {
    return {
      data: state.user,
      isMocked: state.auth.mockUser
    }
  })
  if (isMocked) {
    const { firstName, lastName, email } = mockUser
    return { user: mockUser, email, firstName, lastName }
  }
  return { user, email: user?.email, notificationEmail, firstName, lastName }
}
