import { Text, View } from '@react-pdf/renderer'
import React from 'react'

export const SectionTitlePDF = ({ title }: { title: string }) => {
  return (<>
    <View
      style={ {
        backgroundColor: '#333333',
        color: 'white',
        width: '100%',
        padding: '0px 8px',
        display: 'flex',
        justifyContent: 'center',
        height: '25px'
      } }
    >
      <Text style={ { fontSize: '16px', fontWeight: 700 } }>{ title }</Text>
    </View>
  </>)
}

export const TextContentContainerPDF = ({ children }: { children: React.ReactNode }) => {
  return (<View
    style={ {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      borderBottom: '1px solid #F0F0F0'
    } }
  >
    { children }
  </View>)
}

export const TextContentPDF = ({ title, content }: { title: string, content: string }) => {
  return (
    <View
      style={ {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        flex: '0 0 50%',
        padding: '0px 8px',
        alignItems: 'center',
        height: '25px'
      } }
    >
      <Text style={ { fontWeight: 700 } }>{ title }</Text>
      <Text>{ content }</Text>
    </View>
  )
}
