import React, { useRef, useState } from 'react'
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  ListItem,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
  drawerClasses
} from '@mui/material'
import { api } from 'Core'
import { useQuery } from '@tanstack/react-query'
import RegisterPatient from 'pages/RegisterPatient'
import { Puller } from 'pages/RegisterKit/PatientInfoForm'
import { Heading } from '.'
import {
  MultiStepFormNavigation,
  useMultiStepForm
} from 'components/MultiStepForm'
import type { SiteI } from 'types/db/SiteI'
import type { TPrepProgram } from './schemas'
import * as schemas from './schemas'
import useFormik from 'hooks/useFormik'
import { PatientI } from 'types/db/PatientI'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

type PatientOption =
  | {
    id: number
    value: string
  }
  | { id: 'newPatient'; value: React.ReactChild }

function PatientSelection ({
  site
}: {
  site: Pick<
    SiteI,
    'id' | 'address' | 'code' | 'name' | 'consent' | 'prep_program_config'
  >
}) {
  const patientSelectionContent =
    site.prep_program_config.content.patientSelection

  const { data, update } = useMultiStepForm<TPrepProgram>()

  const [showPatientForm, setShowPatientForm] = useState(false)

  const drawerRef = useRef<any>(null)

  const {
    data: patients,
    isLoading,
    isError
  } = useQuery(['patients'], api.patient.getAll)

  const formik = useFormik({
    initialValues: data.patientSelection,
    onSubmit: async values => {
      update({
        ...data,
        patientSelection: values as TPrepProgram[ 'patientSelection' ]
      })
    },
    validationSchema: schemas.patientSelection
  })

  const handleClose = () => {
    setShowPatientForm(false)
  }

  const handlePatientChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue:
      | { id: number; value: string }
      | { id: 'newPatient'; value: React.ReactChild }
      | null
  ) => {
    if (!newValue) {
      formik.setValues({
        ...formik.values,
        patientId: null
      })
      return
    }

    if (newValue.id !== 'newPatient') {
      formik.setValues({
        ...formik.values,
        patientId: newValue.id
      })
      return
    }
    setShowPatientForm(true)
  }

  if (isLoading) return <Typography>Loading patients...</Typography>
  if (isError) return <Typography>Error loading patients!</Typography>

  const patientOptions: PatientOption[] = [
    ...patients.map(patient => ({
      id: patient.id,
      value: patient.first_name + ' ' + patient.last_name
    })),
    {
      id: 'newPatient',
      value: (
        <Stack flexDirection='row' gap={3}>
          <PersonAddIcon
            sx={{
              color: theme => theme.palette.primary.main,
              opacity: 0.54
            }}
          />
          <Typography>Add a new patient</Typography>
        </Stack>
      )
    }
  ]

  const selectedPatient =
    patientOptions.find(
      patientOption => patientOption.id === formik.values.patientId
    ) || null

  return (
    <Stack>
      <Stack component='form' onSubmit={formik.handleSubmit} gap={2}>
        <Heading>{patientSelectionContent.title}</Heading>
        <Autocomplete
          id='patientInfo'
          options={patientOptions}
          value={selectedPatient}
          getOptionLabel={option =>
            option.id !== 'newPatient' ? option.value : ''
          }
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              {option.value}
            </ListItem>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handlePatientChange}
          renderInput={props => (
            <TextField
              {...props}
              name='patients'
              label='Please Select a Patient'
              error={!!formik.errors.patientId}
              helperText={`${formik.errors.patientId || 'Please select a patient'}`}
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.myselfTck}
              name='myselfTck'
              onChange={formik.handleChange}
            />
          }
          label={
            <Typography variant='subtitle1'>
              {patientSelectionContent.myselfDisclaimer.labelText}
            </Typography>
          }
        />
        {formik.errors.myselfTck
          ? (
          <Alert
            severity='error'
            sx={{
              '& .MuiAlert-icon': {
                display: 'flex',
                alignItems: 'center'
              }
            }}
          >
            <Typography variant='body1'>
              {patientSelectionContent.myselfDisclaimer.errorText}
            </Typography>
          </Alert>
            )
          : (
              ''
            )}
        <MultiStepFormNavigation />
      </Stack>
      <SwipeableDrawer
        ref={drawerRef}
        anchor='bottom'
        open={showPatientForm}
        onClose={handleClose}
        onOpen={() => { }}
        sx={{
          '& .MuiPaper-root': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            height: '100%',
            maxHeight: { xs: '100%', md: '90%' },
            width: 'min(100%, 700px)',
            m: 'auto'
          }
        }}
      >
        <Stack sx={{ position: 'relative' }}>
          <Puller onClose={handleClose} />
        </Stack>
        <RegisterPatient
          onRegister={(patient: PatientI) => {
            setShowPatientForm(false)
            formik.setFieldValue('patientId', patient.id)
          }}
          key={formik.values.patientId}
          sitecode={site.code}
          kit={undefined}
          isAdmin={false}
          onFormChange={() => {
            if (drawerRef.current) {
              const muiDrawerPaper = drawerRef.current.querySelector(
                '.' + drawerClasses.paper
              )
              if (muiDrawerPaper) {
                muiDrawerPaper.scrollTo(0, 0)
              }
            }
          }}
        />
      </SwipeableDrawer>
    </Stack>
  )
}

export default PatientSelection
