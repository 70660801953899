import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'components/Footer'
import React from 'react'
import Loader from './Loader'
import Header from '../components/Header'
import { subdomainType } from 'utils/routerUtils'
import { getSubdomain } from 'utils/utilFuntions'
import { checkPortalApp } from 'utils/affiliateUtilFunctions'
import { Outlet } from 'react-router'

function Layout ({
  isLoading = false
}) {
  const subdomain = getSubdomain()

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        minWidth: subdomain === subdomainType.account || checkPortalApp() ? 'inherit' : 768
      }}
    >
      <Header />
      <Stack
        component='main'
        id='main'
        sx={{
          flex: 1
        }}
      >
        {isLoading
          ? (
            <Box sx={{ height: '100px' }}>
              <Loader />
            </Box>
            )
          : (
              <Outlet />
            )}
      </Stack>
      <Footer />
    </Stack>
  )
}

export default Layout
