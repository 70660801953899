import cookieUtils from 'Core/utils/cookieUtils'
import {
  FIND_USER_FAILURE,
  FIND_USER_INIT,
  FIND_USER_SUCCESS,
  GET_TOKEN_SILENTLY_FAILURE,
  GET_TOKEN_SILENTLY_INIT,
  GET_TOKEN_SILENTLY_SUCCESS,
  UPDATE_USER,
  TOGGLE_MOCK_USER
} from '../actions/index'

import { AuthUser } from '../../types/AuthUser'
import { ReduxActionI } from '.'

const initialState = {
  id: null,
  email: null,
  isLoading: false,
  user: null,
  isAuthenticated: false,
  mockUser: Boolean(cookieUtils.getCookie('override_email')) || false
} as ReduxAuth

const reducer: ReduxAuthReducer = (state = initialState, action: ReduxActionI) => {
  switch (action.type) {
    case GET_TOKEN_SILENTLY_INIT: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GET_TOKEN_SILENTLY_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }

    case GET_TOKEN_SILENTLY_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }

    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload
      }
    }

    case FIND_USER_INIT:
      return {
        ...state,
        isLoading: true
      }

    case FIND_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      }

    case FIND_USER_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case TOGGLE_MOCK_USER:
      return {
        ...state,
        mockUser: !state.mockUser
      }
    default:
      return state
  }
}

export interface ReduxAuth {
  id: null | string
  email: null | string
  isLoading: boolean | null
  user: AuthUser | null
  isAuthenticated: boolean
  mockUser: boolean
}

export type ReduxAuthReducer = (state: ReduxAuth, action: ReduxActionI) => ReduxAuth

export default reducer
