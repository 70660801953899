import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import { RenderLabel } from './EligibleTrue'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router'
import { TestingProgramNavigation, useTestingProgram } from '.'
import AuthForm from 'components/AuthForm'
import EditIcon from '@mui/icons-material/Edit'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import useAuth0 from 'hooks/useAuth0'
import { api } from 'Core'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import { getAffiliate } from 'utils/affiliateUtilFunctions'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { limsFormatDate } from 'utils/utilFuntions'
import { ConsentDialog } from 'components/SiteAffiliation/AddSiteAffiliation'
import Loader from 'components/Loader'
import { useQueryClient } from '@tanstack/react-query'
import { getCurrentLanguage } from 'i18n'
import { useTranslation } from 'react-i18next'
import TermsPolicy from 'components/TestResult/components/TermsPolicy'
import { isEmpty } from 'lodash'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
// import { useSearchParams } from 'react-router-dom'

const signInValidationSchema = t => yup.object({
  firstName: yup.string().required(t('signup.error.firstName')),
  lastName: yup.string().required(t('signup.error.lastName')),
  dob: yup.string().required('Date of Birth is required'),
  shippingFname: yup.string().required(t('signup.error.firstName')),
  shippingLname: yup.string().required(t('signup.error.lastName')),
  phone: yup.string().required(t('registerPatient.validation.phone')),
  addressLine1: yup.string().required(t('registerPatient.validation.addressLine1IsRequired')),
  city: yup.string().required(t('registerPatient.validation.city')),
  state: yup.string().required(t('registerPatient.validation.state')),
  zipCode: yup.string().required(t('registerPatient.validation.zipCode')),
  email: yup.string().email(t('registerPatient.validation.validEmail')).required(t('login.error.email')),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      t('signup.error.strongPassword')
    ).required(t('signup.error.password')),
  // confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('signup.error.passwordMatch')).required(t('signup.error.confirmPassword')),
  terms: yup.boolean().oneOf([true], t('signup.error.acceptTerms'))
})

const signUpValidationSchema = t => yup.object({
  firstName: yup.string().required(t('signup.error.firstName')),
  lastName: yup.string().required(t('signup.error.lastName')),
  dob: yup.string().required(t('registerPatient.validation.dob')),
  shippingFname: yup.string().required(t('signup.error.firstName')),
  shippingLname: yup.string().required(t('signup.error.lastName')),
  phone: yup.string().required(t('registerPatient.validation.phone')),
  addressLine1: yup.string().required(t('registerPatient.validation.addressLine1IsRequired')),
  city: yup.string().required(t('registerPatient.validation.city')),
  state: yup.string().required(t('registerPatient.validation.state')),
  zipCode: yup.string().required(t('registerPatient.validation.zipCode')),
  email: yup.string().email(t('registerPatient.validation.validEmail')).required(t('login.error.email')),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], t('signup.error.emailMatch')).required(t('signup.error.confirmEmail')),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      t('signup.error.strongPassword')
    ).required(t('signup.error.password')),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], t('signup.error.passwordMatch')).required(t('signup.error.confirmPassword')),
  terms: yup.boolean().oneOf([true], t('signup.error.acceptTerms'))
})

const loggedValidationSchema = t => yup.object({
  firstName: yup.string().required(t('signup.error.firstName')),
  lastName: yup.string().required(t('signup.error.lastName')),
  dob: yup.string().required('Date of Birth is required'),
  shippingFname: yup.string().required(t('signup.error.firstName')),
  shippingLname: yup.string().required(t('signup.error.lastName')),
  phone: yup.string().required(t('registerPatient.validation.phone')),
  addressLine1: yup.string().required(t('registerPatient.validation.addressLine1IsRequired')),
  city: yup.string().required(t('registerPatient.validation.city')),
  state: yup.string().required(t('registerPatient.validation.state')),
  zipCode: yup.string().required(t('registerPatient.validation.zipCode')),
  email: yup.string().email(t('registerPatient.validation.validEmail')).required(t('login.error.email')),
  terms: yup.boolean().oneOf([true], t('signup.error.acceptTerms'))
})

function OrderReview () {
  const { t } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()
  const { user, signup, checkMfalogIn } = useAuth0()
  const { slug } = useParams()
  const navigate = useNavigate()
  const { jsonData, data, updateData } = useTestingProgram()
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [authComponent, setAuthComponent] = useState('sign-up')
  const [errorAlert, setErrorAlert] = useState('')
  const [showPolicy, setShowPolicy] = useState(true)
  const [currentValidationSchema, setCurrentValidationSchema] = useState({})
  const queryClient = useQueryClient()

  useEffect(() => {
    if (user) {
      setCurrentValidationSchema(loggedValidationSchema(t))
      return
    }
    if (authComponent === 'sign-up' && !user) {
      setCurrentValidationSchema(signUpValidationSchema(t))
      return
    }
    if (authComponent === 'sign-in' && !user) {
      setCurrentValidationSchema(signInValidationSchema(t))
    }
  }, [authComponent])

  const { i18n } = useTranslation()

  // const [searchParams, setSearchParams] = useSearchParams()

  const formik = useFormik({
    initialValues: {
      email: user ? user.email : '',
      confirmEmail: user ? user.email : '',
      password: '',
      firstName: data.yourInfo?.firstName,
      lastName: data.yourInfo?.lastName,
      dob: data.yourInfo?.dob,
      phone: data.yourInfo?.phone,
      addressLine1: data.yourInfo?.addressLine1,
      addressLine2: data.yourInfo?.addressLine2,
      city: data.yourInfo?.city,
      state: data.yourInfo?.state,
      zipCode: data.yourInfo?.zipCode,
      plus4Code: data.yourInfo?.plus4Code,
      shippingFname: data.yourInfo?.shippingFname,
      shippingLname: data.yourInfo?.shippingLname,
      gender: data.yourInfo?.gender,
      sexAssignedAtBirth: data.yourInfo?.sexAssignedAtBirth,
      race: data.yourInfo?.race,
      ethnicity: data.yourInfo?.ethnicity,
      terms: false
    },
    validateOnChange: false,
    validationSchema: currentValidationSchema,
    onSubmit: async values => {
      try {
        const responsePause = await api.program.checkProgramPauseStatus(slug)
        if (responsePause.pauseStatus) {
          const programConfigData = queryClient.getQueryData([
            'program-content',
            slug
          ])
          queryClient.setQueryData(['program-content', slug], {
            // @ts-ignore
            ...programConfigData,
            isPaused: true
          })
          navigate('../program-paused')
          return
        }
        setErrorAlert('')
        const selectedSKU = jsonData.allowedSiteSkus.find((sku) => sku.skuID === data.eligibleTrue.selectedSKU)
        const requestObject = {
          auth: {
            email: values.email,
            password: values.password
          },
          patient: {
            addressLine1: values.addressLine1,
            street_address2: values.addressLine2,
            first_name: values.firstName,
            last_name: values.lastName,
            state: values.state,
            city: values.city,
            zipCode: values.zipCode,
            county: '',
            phonenumber: values.phone,
            email: values.email,
            plus4_code: values.plus4Code,
            address: `${values.addressLine1}, ${values.city}, ${values.state} ${values.zipCode}`
          },
          tests: {
            kittype_id: selectedSKU.kitTypeConfig.id,
            test_name: selectedSKU.skuName,
            sku_id: selectedSKU.skuID
          },
          slug,
          eligibility: {
            dob: limsFormatDate(values.dob),
            gender: values.gender,
            sexAssignedAtBirth: values.sexAssignedAtBirth,
            ethnicity: values.ethnicity,
            race: values.race
          }
        }

        if (!user && authComponent === 'sign-up') {
          await signup(values.email, values.password, {
            firstName: values.firstName,
            lastName: values.lastName,
            toBeLinked: 'true',
            userType: getAffiliate(),
            tncDate: `${moment().unix()}`,
            language: getCurrentLanguage(i18n)
          })
        }

        const response = await api.program.confirmFreeOrder(requestObject)

        if (response?.eligible === false) {
          // setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: `/program/${slug}/not-eligible` })
          await checkMfalogIn(values.email, values.password, `/program/${slug}/not-eligible`)
          // await login(values.email, values.password, '', getCurrentLanguage(i18n))
          return
        } else if (response?.msg === 'PATIENT_SKU_INELIGIBLE') {
          if (
            response.ineligibleSkus.includes(data.eligibleTrue.selectedSKU)
          ) {
            const programConfigData = queryClient.getQueryData([
              'program-content',
              slug
            ])

            const newAllowedSkus = jsonData?.allowedSiteSkus.filter(
              (sku) => !response.ineligibleSkus.includes(sku.skuID)
            )
            queryClient.setQueryData(['program-content', slug], {
              // @ts-ignore
              ...programConfigData,
              allowedSiteSkus: newAllowedSkus
            })

            if (!newAllowedSkus.length) {
              // setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: `/program/${slug}/not-eligible` })
              // await login(values.email, values.password, '', getCurrentLanguage(i18n))
              await checkMfalogIn(values.email, values.password, `/program/${slug}/not-eligible`)
              return
            }

            updateData((currData) => ({
              ...currData,
              err: 'PATIENT_SKU_INELIGIBLE'
            }))

            if (
              !data?.eligibleTrue?.selectedSKU ||
              response.ineligibleSkus.includes(
                data?.eligibleTrue?.selectedSKU
              )
            ) {
              // setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: `/program/${slug}/select-kit` })
              // await login(values.email, values.password, '', getCurrentLanguage(i18n))
              await checkMfalogIn(values.email, values.password, `/program/${slug}/select-kit`)
              return
            }
          }
        }

        if (response.success) {
          if (!user) {
            // setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: `/program/${slug}/order-complete` })
            // await login(values.email, values.password, '', getCurrentLanguage(i18n))
            await checkMfalogIn(values.email, values.password, `/program/${slug}/order-complete`)
          } else {
            updateData({})
            navigate('../order-complete')
          }
        }
      } catch (error) {
        if (!user) {
          if (error?.responseJSON?.error === 'User already exists. Please try to login!') {
            setErrorAlert(t('signup.alert.error'))
            window.scrollTo(0, 0)
          } else if (error.code === 'invalid_signup') {
            setErrorAlert(t('afterCare.review.snackbar.errorCreatingAccount'))
            window.scrollTo(0, 0)
          } else if (error?.responseJSON?.msg === 'INVALID_LOGIN') {
            setErrorAlert(t('login.alert.invalidPassword'))
            window.scrollTo(0, 0)
          } else {
            dispatch(
              showSnackBar({
                show: true,
                severity: 'error',
                message: t('afterCare.review.snackbar.error')
              })
            )
          }
        } else {
          dispatch(
            showSnackBar({
              show: true,
              severity: 'error',
              message: t('afterCare.review.snackbar.error')
            })
          )
        }
      }
    }
  })

  if (!data.yourInfo || !data.eligibleTrue) {
    navigate('../your-info')
    return <Loader />
  }

  if (whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions) && isEmpty(whiteLabelConfig?.privacy_policy) && !formik.values.terms) {
    formik.setFieldValue('terms', true)
    setShowPolicy(false)
  }

  return (
    <Stack>
      <LayoutWisFlow headerText={jsonData.orderReview.title}>
        <Stack gap={1} onSubmit={formik.handleSubmit} component="form">
          {/* Review order section */}
          <Stack gap={2}>
            <Stack>
              {
                errorAlert && <Alert severity='error'>{errorAlert}</Alert>
              }
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  {jsonData.orderReview.yourOrder.title}
                </Typography>
                <IconButton onClick={() => navigate('../select-kit')}>
                  <EditIcon />
                </IconButton>
              </Stack>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  p: '12px'
                }}
              >
                <RenderLabel
                  renderData={{
                    ...jsonData.allowedSiteSkus.find(
                      (sku) => sku.skuID === data.eligibleTrue.selectedSKU
                    )?.kitTypeConfig,
                    skuName: jsonData.allowedSiteSkus.find(
                      (sku) => sku.skuID === data.eligibleTrue.selectedSKU
                    )?.skuName
                  }}
                />
              </Box>
            </Stack>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  {jsonData.orderReview.personalInfo.title}
                </Typography>
                <IconButton onClick={() => navigate('../your-info')}>
                  <EditIcon />
                </IconButton>
              </Stack>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  p: '12px'
                }}
              >
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('registerKit.review.patientInformationFirstName')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.yourInfo?.firstName}
                  </Typography>
                </Stack>
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('registerKit.review.patientInformationLastName')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.yourInfo?.lastName}
                  </Typography>
                </Stack>
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('registerKit.review.patientInformationDateOfBirth')}:
                  </Typography>
                  <Typography variant="body1">{data.yourInfo?.dob}</Typography>
                </Stack>
              </Box>
            </Stack>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  {jsonData.orderReview.shippingInfo.title}
                </Typography>
                <IconButton onClick={() => navigate('../your-info')}>
                  <EditIcon />
                </IconButton>
              </Stack>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  p: '12px'
                }}
              >
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('registerKit.review.patientInformationFirstName')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.yourInfo?.shippingFname}
                  </Typography>
                </Stack>
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('registerKit.review.patientInformationLastName')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.yourInfo?.shippingLname}
                  </Typography>
                </Stack>
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('registerPatient.medicalDetails.phone')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.yourInfo?.phone}
                  </Typography>
                </Stack>
                {user?.email
                  ? <Stack direction={'row'} gap={1} justifyContent={'left'}>
                    <Typography variant="subtitle1" fontWeight={700}>
                      {t('afterCare.widget.button.email')}:
                    </Typography>
                    <Typography variant="body1">
                      {user?.email}
                    </Typography>
                  </Stack>
                  : ''
                }
                <Stack direction={'row'} gap={1} justifyContent={'left'}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {t('healthplan.patientinfoform.address')}:
                  </Typography>
                  <Typography variant="body1">
                    {`${data.yourInfo?.addressLine1} ${data.yourInfo?.addressLine2}, ${data.yourInfo?.city}, ${data.yourInfo?.state}, ${data.yourInfo?.zipCode}`}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
          <AuthForm
            formik={formik}
            authComponent={authComponent}
            setAuthComponent={setAuthComponent}
          />

          {showPolicy
            ? <Stack>
              <FormControl
                error={!!formik.errors.terms}
                sx={{ textAlign: 'start' }}
              >
                <FormControlLabel
                  sx={{ borderRadius: '0 0 5px 5px' }}
                  control={
                    <Checkbox
                      name="terms"
                      checked={formik.values.terms}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Typography>
                      <TermsPolicy />
                    </Typography>
                  }
                />
                <FormHelperText>{formik.errors.terms}</FormHelperText>
              </FormControl>
            </Stack>
            : ''
          }
          <TestingProgramNavigation
            back={
              <Button onClick={() => navigate('../your-info')}>{t('multiFormNavigation.back')}</Button>
            }
            next={
              <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                {t('ftp.confirmOrderBtn')}
              </LoadingButton>
            }
          />
          <ConsentDialog
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            site={jsonData.site}
          />
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

OrderReview.propTypes = {}

export default OrderReview
