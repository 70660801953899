import { Stack, Typography } from '@mui/material'
import { Paper } from '.'
import React from 'react'
import { useTranslation } from 'react-i18next'

const HowToRegisterYourKitInstructions = ({ isComponentVisible = false }: Props) => {
  const { t } = useTranslation()

  if (!isComponentVisible) return null
  return (<Paper sx={ { display: 'flex', gap: 3, flexDirection: 'column' } }>
    <>
      <Typography variant='h5' component='h2'>
        { t('howToRegisterYourKit.title') }
      </Typography>

      <Stack gap={ 1.5 }>
        <Stack
          direction='row'
          gap={ 1 }
          alignItems='center'
          justifyContent='space-between'
          p={ 2.5 }
          sx={ {
            bgcolor: '#F7F7F7',
            borderRadius: '5px'
          } }
        >
          <Typography variant='body1' fontWeight='bold'>
            { `1. ${t('howToRegisterYourKit.createAnAccount')}` }
          </Typography>

          <Stack
            component='img'
            src='/assets/mobileWithKitRegistration.svg'
            alt=''
            sx={ {
              width: '100%',
              height: '68px',
              maxWidth: '100px',
              objectFit: 'contain'
            } }
          />
        </Stack>

        <Stack
          direction='row'
          gap={ 1 }
          alignItems='center'
          justifyContent='space-between'
          p={ 2.5 }
          sx={ {
            bgcolor: '#F7F7F7',
            borderRadius: '5px'
          } }
        >
          <Typography variant='body1' fontWeight='bold'>
            { `2. ${t('howToRegisterYourKit.completeKitRegistration')}` }
          </Typography>

          <Stack
            component='img'
            src='/assets/mobileWithQuestions.svg'
            alt=''
            sx={ {
              width: '100%',
              height: '68px',
              maxWidth: '100px',
              objectFit: 'contain'
            } }
          />
        </Stack>
      </Stack>
    </>
  </Paper>)
}

interface Props {
  isComponentVisible: boolean
}

export default HowToRegisterYourKitInstructions
