import { ArrowDropDown } from '@mui/icons-material'
import { Button } from '@mui/material'
import { NavigationOptions } from './NavigationOptions'
import React, { useState } from 'react'

const OtherAppsButton = () => {
  const [navigationAnchorEl, setNavigationAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null)
  const open = Boolean(navigationAnchorEl)
  const handleClose = () => setNavigationAnchorEl(null)

  return (
    <>
      <Button
        onClick={
          (e) => {
            if (e) {
              setNavigationAnchorEl(e.currentTarget)
            }
          }
        }
        size='small'
        id='basic-button'
        aria-controls={ open ? 'basic-menu' : undefined }
        aria-haspopup='true'
        aria-expanded={ open ? 'true' : undefined }
      >
        Other Apps
        <ArrowDropDown />
      </Button>
      <NavigationOptions
        anchorEl={ navigationAnchorEl }
        open={ open }
        handleClose={ handleClose }
      />
    </>
  )
}

export default OtherAppsButton
