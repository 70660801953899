import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio as RadioMui,
  Tooltip,
  IconButton,
  Checkbox
} from '@mui/material'
import React, { useState } from 'react'
import { useQuestionnaire } from './QuestionnaireContext'
import { resetNestedFields } from './utils'
import moment from 'moment'
import InfoIcon from '@mui/icons-material/Info'
import { QuestionI } from 'types/db/KitTypeConfigI'

export function Question ({ question }: { question: QuestionI }) {
  switch (question.type) {
    case 'text':
      return <Text question={ question } />

    case 'radio':
      return <Radio question={ question } />

    case 'dropdown':
      return <Dropdown question={ question } />

    case 'date':
      return <Date question={ question } />

    case 'checkbox':
      return <CheckBox question={ question } />

    default:
      return null
  }
}

export function Date ({ question }: { question: QuestionI }) {
  // @ts-ignore
  const { formik } = useQuestionnaire()

  const error = formik.errors[question.id]

  const validation = question.config?.validation

  let minDate, maxDate

  if (validation?.uptoDays !== undefined) {
    maxDate = moment().add(validation?.uptoDays, 'days').valueOf()
  } else {
    maxDate = moment().valueOf()
  }

  if (validation?.fromDays !== undefined) {
    minDate = moment().subtract(validation?.fromDays, 'days').valueOf()
  }

  const dateValue = typeof formik.values[question.id] === 'string'
    ? moment(formik.values[question.id]).valueOf()
    : formik.values[question.id]

  return (
    <Stack sx={ { gap: 2 } }>
      <Typography>{question.name}</Typography>
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <DatePicker
          minDate={ minDate }
          maxDate={ maxDate }
          label={ question.config?.label || '' }
          onChange={ newValue => {
            formik.setFieldValue(question.id, newValue || '')
          } }
          value={ dateValue || '' } // 1970-01-22T14:38:49.000Z
          slotProps={ {
            textField: {
              error: !!error,
              helperText: error as string
            }
          } }
        />
      </LocalizationProvider>
    </Stack>
  )
}

export function Text ({ question }: { question: QuestionI }) {
  // @ts-ignore
  const { formik } = useQuestionnaire()

  const error = formik.errors[question.id]

  return (
    <Stack sx={ { gap: 1 } }>
      <Typography>
        { question.name }
        { question.config?.tooltip && (
          <Tooltip title={ question.config?.tooltip }>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) }
      </Typography>
      <TextField
        label={ question.config?.label || '' }
        name={ question.id }
        onChange={ formik.handleChange }
        value={ formik.values[question.id] || '' }
        error={ !!error }
        helperText={ error as string }
      />
    </Stack>
  )
}

export function Radio ({ question }: { question: QuestionI }) {
  const {
    config: { options }
  } = question

  // @ts-ignore
  const { formik } = useQuestionnaire()

  const selectedOption: string = formik.values[question.id]
  const error = formik.errors[question.id]

  const handleChange = (_evt: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    if (!newValue) return
    if (question.sub && question.sub.when[selectedOption]) {
      question.sub.when[selectedOption].questions.map(question =>
        resetNestedFields(question, formik)
      )
    }
    formik.setFieldValue(question.id, newValue)
  }

  return (
    <Stack sx={ { gap: 1 } }>
      <Typography>
        { question.name }
        { question.config?.tooltip && (
          <Tooltip title={ question.config?.tooltip }>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) }
      </Typography>
      <FormControl error={ !!error }>
        <RadioGroup
          name={ question.name }
          value={ selectedOption }
          onChange={ handleChange }
          sx={ { gap: 1 } }
        >
          { options.map(option => (
            <FormControlLabel
              value={ option.value }
              control={ <RadioMui /> }
              label={ option.name }
              key={ option.id }
              data-testid={ option.id }
            />
          )) }
        </RadioGroup>
        { error ? <FormHelperText>{ error as string }</FormHelperText> : null }
      </FormControl>
      { question.sub && question.sub.when[selectedOption]
        ? question.sub.when[selectedOption].questions.map(question => (
          <Question key={ question.id } question={ question } />
        ))
        : null }
    </Stack>
  )
}

export function Dropdown ({ question }: { question: QuestionI }) {
  // @ts-ignore
  const { formik } = useQuestionnaire()

  const options = question.config.options.map(option => option.name)

  const selectedOption = formik.values[question.id] || null
  const error = formik.errors[question.id]

  const handleChange = (_evt: React.SyntheticEvent<Element, Event>, newValue: string) => {
    formik.setFieldValue(question.id, newValue)
    if (question.sub && question.sub.when[selectedOption]) {
      question.sub.when[selectedOption].questions.map(question =>
        resetNestedFields(question, formik)
      )
    }
    formik.setFieldValue(question.id, newValue)
  }

  return (
    <Stack sx={ { gap: 1 } }>
      <Typography>{ question.name }</Typography>
      <Autocomplete
        value={ selectedOption }
        onChange={ handleChange }
        renderInput={ props => (
          <TextField
            label={ question.config.label }
            error={ !!error }
            helperText={ error as string }
            { ...props }
          />
        ) }
        options={ options }
      />
      { question.sub && question.sub.when[selectedOption]
        ? question.sub.when[selectedOption].questions.map(question => (
          <Question key={ question.id } question={ question } />
        ))
        : null }
    </Stack>
  )
}

export function CheckBox ({ question }: { question: QuestionI }) {
  const {
    config: { options }
  } = question

  // @ts-ignore
  const { formik } = useQuestionnaire()
  const [renderTextField, setRenderTextField] = useState(
    (formik?.values[question?.id]?.lenght && options.map(option => option.name).includes(formik?.values[question?.id][0]) === false) ||
    formik?.values[question?.id].includes('Other') ||
    false
  )
  const error = formik.errors[question.id]

  const handleChangeForTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(question.id, [`${e?.target?.value}`])
  }

  const isAtleastOneOptionSelected = formik?.values[question.id]?.length > 0

  const isAtleastOneOptionSelectedAndNotNone =
    isAtleastOneOptionSelected &&
    !formik?.values[question.id]?.includes('None of the above')

  return (
    <Stack sx={ { gap: 1 } }>
      <Typography>
        { question.name }
        { question.config?.tooltip && (
          <Tooltip title={ question.config.tooltip }>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) }
      </Typography>
      <FormControl error={ !!error }>
        { options.map(option => {
          return (
            <FormControlLabel
              sx={ { my: 0.5 } }
              control={
                <>
                  <Checkbox
                    checked={
                      formik?.values[question.id]?.includes(option.name) || (renderTextField && option.name === 'Other')
                    }
                    name={ option.name }
                    onChange={ (evt, newValue) => {
                      setRenderTextField(false)
                      if (option.name === 'Other' && newValue) {
                        setRenderTextField(true)
                      }

                      formik.registerCheckbox(question.id, option.name).onChange(evt)
                    } }
                  />
                  {
                    (renderTextField && option.name === 'Other')
                      ? <TextField
                        sx={ { mr: 1, backgroundColor: 'white', width: '100%' } }
                        label='Please describe the reason'
                        fullWidth
                        onChange={ handleChangeForTextField }
                        name='Other'
                        required={ renderTextField }
                        value={ formik.values[question.id][0] !== 'Other' ? formik.values[question.id][0] : '' }
                      />
                      : null
                  }
                </>
              }
              { ...formik.registerCheckbox(question.id, option.name) }
              label={
                (renderTextField && option.name === 'Other')
                  ? ''
                  : option.name
              }
              key={ option.id }
              data-testid={ option.id }
            />
          )
        }) }
        { error ? <FormHelperText>{ error as string }</FormHelperText> : null }
      </FormControl>
      { isAtleastOneOptionSelectedAndNotNone && question.sub && question.sub.when.anythingChecked
        ? question.sub.when.anythingChecked.questions.map(question => (
          <Question key={ question.id } question={ question } />
        ))
        : null }
    </Stack>
  )
}
