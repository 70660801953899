import { Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import DOMPurify from 'dompurify'

const UnsubscribeCompleted = () => {
  return (
    <Stack gap={3}>
      <Typography variant="h4" component="h1" sx={{ textAlign: 'center' }}>
        {t('unsubscribe.kitNotSentToLab.title')}
      </Typography>
      <Stack
        component="img"
        src={'/assets/hifi_hands.svg'}
        alt={'2 doctors'}
        sx={{ m: 'auto' }}
      />
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t('unsubscribe.kitNotSentToLab.body'), {
            ADD_ATTR: ['target']
          })
        }}
      ></Typography>
    </Stack>
  )
}

export default UnsubscribeCompleted
