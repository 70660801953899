import { Container } from "components";
import { fetchRoles } from "Core/utils/jwtUtil";
import React from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getAffiliateUrl } from "utils/affiliateUtilFunctions";
import { useWhiteLabelConfig } from "utils/white-label/WhiteLabelConfig";
import MfaHeader from "./MfaConfig/MfaHeader";
import { Stack, Typography } from "@mui/material";
import theme from "theme";

const domainsDesc = {
    health: {
        title: "Health Portal",
        baseUrl: `health.${process.env.REACT_APP_DOMAIN}`,
        desc: "For health plan admins to access member information, test results, program analytics, and program reports/data."
    },
    app: {
        title: "Patient Portal",
        baseUrl: `app.${process.env.REACT_APP_DOMAIN}`,
        desc: "For patients and members to register a kit, view results, or access program information. "
    },
    provider: {
        title: "Provider Portal",
        baseUrl: `provider.${process.env.REACT_APP_DOMAIN}`,
        desc: "For providers to manage patients, register kits, view and release results, etc."
    },
    ninjalab: {
        title: "Ninjalabs",
        baseUrl: `ninjalab.${process.env.REACT_APP_DOMAIN}`
    },
    admin: {
        title: "Admin Portal",
        baseUrl: `admin.${process.env.REACT_APP_DOMAIN}`
    },
    manage: {
        title: "Manage Portal",
        baseUrl: `manage.${process.env.REACT_APP_DOMAIN}`
    },
    customeradmin: {
        title: "Customer Admin Portal",
        baseUrl: `customeradmin.${process.env.REACT_APP_DOMAIN}`
    }
}

export const getUserDomains = () => {
    const roles = fetchRoles();

    // Define the role categories and the roles they include
    const domainCategories = {
        health: ['portal_admin', 'portal_payer_admin', 'portal_care_manager'],
        provider: ['portal_admin', 'portal_client_admin', 'portal_client_viewer', 'portal_site_admin'],
        manage: ['portal_admin', 'portal_manage'],
        admin: ['portal_admin'],
        ninjalab: ['portal_admin', 'labportalv2_role'],
        customeradmin: ['operations_manager', 'client_provider_admin', 'client_provider_viewer', 'portal_admin']
    };

    const domainsAccess = [];

    // Loop through each category and check if the user has any of those roles
    for (const [domainName, roleList] of Object.entries(domainCategories)) {
        if (roleList.some(role => roles.includes(role))) {
            domainsAccess.push(domainName);
        }
    }

    // Return the array of roles (empty if none are matched)
    return domainsAccess;
}


const ChoosePortal = () => {
    const whiteLabelConfig = useWhiteLabelConfig()
    const domains = getUserDomains()
    const [searchParams] = useSearchParams()
    const redirectTo = searchParams.get('redirectTo')

    if (!domains.length || redirectTo) return <Navigate to={redirectTo || '/'} />

    return (<>
        <MfaHeader />
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack gap={3} sx={{ maxWidth: '580px', width: '100%', backgroundColor: '#fff' }}>
                <Typography variant="h4" textAlign="center" p={1}>Select A Portal</Typography>
                <Stack p={4} gap={2}>
                    <Typography variant="body1" color="">Select the portal that you'd like to access.</Typography>
                    {/* @ts-ignore */}
                    {['app', ...domains].map(domain => <DomainCard key={domain} domain={domainsDesc[domain]} />)}
                </Stack>
            </Stack>
        </Container>
    </>)
}

const DomainCard = ({ domain }: { domain: any }) => {
    if (!domain) return null;
  
    const handleClick = () => {
      const protocol = window.location.protocol; // This will be "http:" or "https:"
  
      const baseUrl = domain.baseUrl;
      const url =`${protocol}//${baseUrl}`;
  
      window.location.href = url;
    };
  
    return (
      <Stack
        gap="12px"
        sx={{
          p: 3,
          gap: '12px',
          borderRadius: '5px',
          bgcolor: theme.palette.background.default,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: '#00000014',
          },
        }}
        onClick={handleClick} // Attach the handleClick function
      >
        <Stack sx={{ display: 'flex', flexDirection: 'row' }} gap="12px">
          <Typography variant="h6">{domain.title}</Typography>
          <Typography variant="caption" color="#00000099">
            {domain.baseUrl}
          </Typography>
        </Stack>
        <Typography>{domain.desc}</Typography>
      </Stack>
    );
  };
export default ChoosePortal