import React, { useState } from 'react'
import { TextField, Typography, IconButton, Stack } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ForgotPasswordDialog from 'components/ForgotPasswordDialog'
import { useTranslation } from 'react-i18next'

const SignIn = ({ formik, setAuthComponent, type, mt = 2, disableEmail = false }) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(showPassword => !showPassword)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Stack sx={{ gap: 2 }} mt={mt}>
        <Typography variant={type === 'page' ? 'h5' : 'h6'} component="h6">
          {t('login.title')}
        </Typography>

        <Typography variant="body1" component="p">
          {t('ftp.loginSubtitle')}
        </Typography>
        <TextField
          name='email'
          disabled={disableEmail}
          label={t('login.formfields.emailAddress') + '*'}
          onChange={formik.handleChange}
          value={formik.values.email}
          helperText={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />

        <TextField
          type={showPassword ? 'text' : 'password'}
          name='password'
          label={t('login.formfields.password') + '*'}
          onChange={formik.handleChange}
          helperText={
            formik.touched.password &&
            Boolean(formik.errors.password) &&
            formik.errors.password
          }
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowPassword} aria-label='visibility'>
                {showPassword
                  ? (
                    <VisibilityOffIcon />
                    )
                  : (
                    <VisibilityIcon />
                    )}
              </IconButton>
            )
          }}
          error={
            formik.touched.password && Boolean(formik.errors.password)
          }
        />

        <Typography
          variant='body2'
          component='p'
          role='button'
          onClick={() => setOpen(true)}
          tabIndex={0}
          sx={{
            fontWeight: 500,
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {t('login.forgotPassword.title') + '?'}
        </Typography>

        <Typography
          variant='body2'
          component='p'
          role='button'
          onClick={() => setAuthComponent('sign-up')}
          tabIndex={0}
          sx={{
            fontWeight: 500,
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {t('ftp.dontHaveAnAccount') + '?'}
        </Typography>
      </Stack>
      <ForgotPasswordDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default SignIn
