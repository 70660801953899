import React from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import { Alert, Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { TestingProgramNavigation, useTestingProgram } from '.'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
// @ts-ignore
import InputMask from 'react-input-mask'
import moment from 'moment'
import useAuth0 from 'hooks/useAuth0'
import { api } from 'Core'
import { useQueryClient } from '@tanstack/react-query'
import { LoadingButton } from '@mui/lab'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import { limsFormatDate } from 'utils/utilFuntions'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'

function CheckEligibility () {
  const { age: programMinimumAge, jsonData, updateData, data } = useTestingProgram()
  const navigate = useNavigate()
  const { user } = useAuth0()
  const queryClient = useQueryClient()
  const { slug } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      dob: data.eligibility?.dob,
      dobPrimary: data.eligibility?.dob ? moment(data.eligibility?.dob, 'MM-DD-YYYY') : '',
      zipcode: data.eligibility?.zipcode,
      myselfTck: false
    },
    validateOnChange: false,
    validationSchema: yup.object({
      dob: yup
        .string()
        .required(t('registerKit.insuranceForm.validation.dobRequired'))
        .test(
          'DOB',
          t('afterCare.eligibility.validation.dob'),
          (value) => {
            if (value) {
              const momentFormat = `${value.substring(6)}-${value.substring(
                0,
                2
              )}-${value.substring(3, 5)}`
              const isDateValid = moment(momentFormat, true).isValid()
              const date = moment(momentFormat, true)
              if (isDateValid && date.year() >= 1900) {
                return true
              } else {
                return false
              }
            }
            return false
          }
        ),
      zipcode: yup.string().required(t('registerPatient.validation.zipCode')),
      myselfTck: yup.bool().required().oneOf([true])
    }),
    onSubmit: async (values) => {
      try {
        const response = await api.program.checkProgramPauseStatus(slug)
        if (response.pauseStatus) {
          const programConfigData = queryClient.getQueryData([
            'program-content',
            slug
          ])
          queryClient.setQueryData(['program-content', slug], {
            // @ts-ignore
            ...programConfigData,
            isPaused: true
          })
          navigate('../program-paused')
          return
        }

        let eligible = true
        const pattern = new RegExp(jsonData?.zipCodeRegex || '')
        const age = moment().diff(formik.values.dob, 'years')
        if (
          age < programMinimumAge ||
          !pattern.test(formik.values.zipcode.toString())
        ) {
          eligible = false
        }

        if (user && eligible) {
          const isPatientEligible =
            await api.program.checkSKUEligibilityFromDob(slug, {
              dob: limsFormatDate(values.dob)
            })
          if (!isPatientEligible?.eligible) {
            eligible = false
          } else if (isPatientEligible?.msg === 'PATIENT_SKU_INELIGIBLE') {
            const programConfigData = queryClient.getQueryData([
              'program-content',
              slug
            ])
            const newAllowedSkus = jsonData?.allowedSiteSkus?.filter(
              (sku : any) => !isPatientEligible.ineligibleSkus.includes(sku.skuID)
            )
            queryClient.setQueryData(['program-content', slug], {
              // @ts-ignore
              ...programConfigData,
              allowedSiteSkus: newAllowedSkus
            })
          }
        }

        updateData((currentData) => ({
          ...currentData,
          eligibility: {
            dob: values.dob,
            zipcode: values.zipcode
          }
        }))
        eligible ? navigate('../select-kit') : navigate('../not-eligible')
      } catch (error) {
        dispatch(
          showSnackBar({
            show: true,
            severity: 'error',
            message: t('afterCare.review.snackbar.error')
          })
        )
      }
    }
  })

  return (
    <Stack>
      <LayoutWisFlow headerText={t('ftp.checkEligibility.header')}>
        <Stack component="form" onSubmit={formik.handleSubmit} gap={3}>
          <Stack gap={2} sx={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('afterCare.eligibility.dob')}
                value={formik.values.dobPrimary ? moment(formik.values.dobPrimary).toDate() : null}
                maxDate={moment().toDate()}
                onChange={(newValue) => {
                  const dob = moment(newValue).format('MM-DD-YYYY')
                  formik.setFieldValue('dobPrimary', newValue || '')
                  formik.setFieldValue('dob', dob || '')
                }}
                slotProps={{
                  textField: {
                    error: formik.touched?.dob && !!formik.errors.dob,
                    helperText:
                      formik.touched.dob && Boolean(formik.errors.dob)
                        ? formik.errors.dob
                        : null,
                    FormHelperTextProps: {
                      sx: { color: 'primary.red' }
                    }
                  }
                }}
              />
            </LocalizationProvider>
            <InputMask
              mask="99999"
              maskChar=""
              name="zipcode"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
            >
              {/* @ts-ignore */}
              {() => (
                <TextField
                  name="zipcode"
                  label={t('registerPatient.medicalDetails.address.zipCode')}
                  fullWidth
                  inputProps={{ maxLength: 5 }}
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.zipcode)}
                  helperText={formik.errors.zipcode}
                />
              )}
            </InputMask>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.myselfTck}
                  name='myselfTck'
                  onChange={formik.handleChange}

                />
              }
              label={
                <Typography variant='subtitle1'>{jsonData.myselfDisclaimer.labelText}</Typography>
              }
            />
            {formik.errors.myselfTck
              ? <Alert
                severity="error"
                sx={{
                  '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center'
                  }
                }}
              >
                <Typography variant="body1" >
                  {jsonData.myselfDisclaimer.errorText}
                </Typography>
            </Alert>
              : ''}
          </Stack>
          <TestingProgramNavigation
            back={
              <Button onClick={() => navigate('../your-situation')}>
                {t('multiFormNavigation.back')}
              </Button>
            }
            next={
              <LoadingButton
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                {t('multiFormNavigation.next')}
              </LoadingButton>
            }
          />
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

CheckEligibility.propTypes = {}

export default CheckEligibility
