import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
// @ts-ignore
import SignaturePad from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'

function SignatureBox ({
  onSave = () => {},
  onClear = () => {},
  error = null,
  label = '',
  defaultValue = null
}: Props) {
  const signRef = useRef<SignaturePad | null>(null)
  const { t } = useTranslation()

  const saveSign = () => {
    const url = signRef.current.getCanvas().toDataURL('image/png')
    onSave && onSave(url)
  }

  const clearSign = () => {
    signRef.current.clear()
    onClear && onClear()
  }

  useEffect(() => {
    if (signRef.current && defaultValue) {
      signRef.current.fromDataURL(defaultValue)
    }
  }, [])

  return (
    <Stack sx={ { gap: 1 } }>
      <Typography variant='body2' sx={ { mt: 1, mb: 0.5 } } fontWeight={ 500 }>
        { label }
      </Typography>
      <Box>
        <Box
          sx={ {
            height: '120px',
            width: '100%',
            border: '1px solid grey',
            margin: '0 auto',
            borderRadius: '5px'
          } }
        >
          <SignaturePad
            ref={ signRef }
            onEnd={ saveSign }
            canvasProps={ { style: { width: '100%', height: '100%' } } }
          />
        </Box>
        { error
          ? (<FormHelperText
            variant='filled'
            error={ !!error }
            sx={ { display: 'flex', justifyContent: 'center' } }
          >
            { error }
          </FormHelperText>)
          : null }
      </Box>
      <Button sx={ { ml: 'auto' } } onClick={ clearSign }>
        { t('registerPatient.disclaimer.clear') }
      </Button>
    </Stack>
  )
}

interface Props {
  onSave?: (url: string) => void
  onClear?: () => void
  error?: string | null
  label?: string
  defaultValue?: string | null
}

export default SignatureBox
