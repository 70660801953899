import React from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'
import ProgramStepper from './components-prep/ProgramStepper'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import DOMPurify from 'dompurify'
import { Link as RouterLink, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import invariant from 'tiny-invariant'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import { Container, ExpandGroupComponent, Paper } from 'components'
import moment from 'moment'

function ProgramDetails () {
  const { slug, id } = useParams()
  invariant(slug, 'slug is missing')
  invariant(id, 'id is missing')

  const { isLoading, isError, data: site } = useQuery(
    ['prep-config', slug],
    () => api.program.getPrepProgramConfig(slug),
    { select: response => response.site }
  )

  const { isLoading: isLoadingPrep, isError: isErrorPrep, data: prep } = useQuery(
    ['prep-config-id', slug, id],
    () => api.registeredPrepProgram.getById(Number(id))
  )

  if (isLoading || isLoadingPrep) return <Typography>Loading...</Typography>
  if (isError || isErrorPrep) return <Typography>Error fetching prep config!</Typography>

  const prepProgramConfig = site?.prep_program_config
  const programDetailsContent = prepProgramConfig?.content?.programDetails

  return (
    <>
    <Container sx={{ p: 1 }} >
    <Stack sx={{ alignItems: 'center' }}>
      <Paper
        sx={{
          width: {
            xs: '100%',
            sm: 560
          }
        }}
      >

        <Stack gap={2}>
          <Link
            component={RouterLink}
            to={'/'}
            replace
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <ArrowBackIcon />
            Back
          </Link>
          <Typography margin={3} textAlign={'center'} variant='h4' component='h1'>
            Your PrEP Program
          </Typography>
          <Typography textAlign={'center'} variant='h5' component='h5'>
            Program Details
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: '25px'
            }}
          >

            <Typography variant='h6' component='h6'>
              PrEP Program
            </Typography>
            <Stack
              direction='row'
              spacing={1.5}
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <CircleRoundedIcon
                sx={{
                  color: 'success.main',
                  fontSize: 16,
                  zIndex: 9
                }}
              />
              <Typography
                variant='h6'
                component='h6'
                color='success.main'
                sx={{
                  fontSize: 16,
                  zIndex: 9
                }}
              >
                Active
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography fontWeight={700} variant='subtitle1' component='h6'>
              Current Cycle :&nbsp;
            </Typography>

            <Typography variant='body1' component='h6'>
              {moment(prep?.createdAt).format('MMM YY')}’ - {moment(prep?.createdAt).add(90, 'days').format('MMM YY')}’
            </Typography>
          </Box>
          <ProgramStepper nextCycle={`${moment(prep?.createdAt).add(120, 'days').format('MMM YY')}’ - ${moment(prep?.createdAt).add(210, 'days').format('MMM YY')}’`} />
          <ExpandGroupComponent
            headerText={programDetailsContent.expandOptions.title}
            datas={programDetailsContent.expandOptions.data}
          />
          <Typography
            variant='body1'
            sx={{ mt: '10px' }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(programDetailsContent.footerText, {
                ADD_ATTR: ['target']
              })
            }}
          />
        </Stack>
      </Paper>
    </Stack>
    </Container>
    </>
  )
}

export default ProgramDetails
