import { api } from 'Core'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Unstable_Grid2'
import moment from 'moment'
// import PatientChat from './PatientChat'
import React, { useState, useEffect } from 'react'
// import socket from 'Core/utils/socketUtil'
import { useTranslation } from 'react-i18next'
import { ReduxState } from '../../../types/ReduxState'
import { PatientI } from '../../../types/db/PatientI'
import { AppNotificationI } from '../../../types/db/AppNotificationI'
import { useSearchParams } from 'react-router-dom'

const NotificationModal = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const notificationId = searchParams.get('notificationId')

  const { data, isLoading } = useQuery(
    ['notification', notificationId],
    () => api.notification.getNotification(notificationId as string),
    {
      enabled: Boolean(notificationId)
    }
  )
  const displayedNotification = data?.notification

  const { patients } = useSelector((state: ReduxState) => state)
  // const [displayChat, setDisplayChat] = useState(Boolean(displayedNotification?.chat))
  const [patient, setPatient] = useState<PatientI | null>(null)

  const handleClose = () => {
    // Remove the notificationId from the URL
    setSearchParams(prev => {
      prev.delete('notificationId')
      return prev
    })
  }
  const mutation = useMutation<Partial<AppNotificationI>>({
    mutationFn: (data) => api.notification.updateNotification(data)
  })

  useEffect(() => {
    if (!displayedNotification) return
    const currentPatient = patients.find(patient => patient.id === displayedNotification.patient_id)

    if (currentPatient) {
      setPatient(currentPatient)
    } else {
      setPatient(null)
    }

    if (!displayedNotification.read_at) {
      // @ts-ignore
      mutation.mutate({ id: displayedNotification?.id || '', read_at: moment().format() })
      queryClient.invalidateQueries(['patient-inbox'])
    }
  }, [displayedNotification])

  const handleTakeAction = () => {
    // @ts-ignore
    mutation.mutate({ id: displayedNotification?.id || '', action_taken: true })

    if (displayedNotification?.action_url) {
      navigate(displayedNotification?.action_url)
      queryClient.invalidateQueries(['patient-inbox'])
    }
  }

  const getButtonText = (type: string) => {
    if (type === 'update') return t('notification.modal.button.update')
    if (type === 'result') return t('notification.modal.button.goToResults')
    if (type === 'reject') return t('notification.modal.button.seeDetails')
    return ''
  }

  if (!notificationId || !displayedNotification) return null
  return (
    <Modal
      open={ Boolean(notificationId) }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={ { display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 2000 } }
    >
      <Box
        sx={ {
          backgroundColor: '#fff',
          borderRadius: '.25rem',
          display: 'flex',
          flexFlow: 'column',
          gap: '1rem',
          maxHeight: { xs: '100%', md: '90%' },
          overflowY: 'auto',
          maxWidth: '40rem',
          padding: { xs: '2rem 1rem', sm: '2rem' },
          position: 'relative',
          width: '100%',
          outline: 'none'
        } }
      >
        {
          isLoading || !displayedNotification
            ? <Typography variant='body1'>{ t('common.loading') }</Typography>
            : (
              <>
                <Grid container alignItems='center' spacing={ 1 }>
                  <Grid xs={ 1.5 }>
                    <NotificationIcon type={ displayedNotification.type } />
                  </Grid>
                  <Grid xs={ 10 }>
                    <Typography variant='h5' sx={ { fontSize: '1.5rem' } }>{ displayedNotification.subject }</Typography>
                  </Grid>
                  <Grid xs={ 0.5 }>
                    <IconButton onClick={ handleClose } sx={ { position: 'absolute', top: '1rem', right: '1rem' } }>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {
                  displayedNotification.patient_id && patient
                    ? <Typography variant='body1' fontWeight='bold'>
                      { t('notification.modal.caption.sentTo', { name: patient.first_name, email: patient.email }) }
                    </Typography>
                    : null
                }
                <Typography variant='body1'>{ displayedNotification.message }</Typography>
                <Typography variant='caption'>
                  { t('notification.modal.caption.sentFrom', { date: moment(displayedNotification.created_at).format('LLL'), interpolation: { escapeValue: false } }) }
                </Typography>
                {
                  !displayedNotification.action_url || (displayedNotification.action_taken && displayedNotification.type === 'update')
                    ? null
                    : (
                      <Button fullWidth variant='contained' onClick={ handleTakeAction } disabled={ mutation.isLoading }>
                        { getButtonText(displayedNotification.type) }
                      </Button>
                      )
                }
                {/* <PatientChat notification={displayedNotification} /> */ }
                {
                  // This code is to give the patient the ability to reply to the provider
                  // Should not be deleted as it might be used in the future

                  //  displayChat
                  //    ? <PatientChat notification={displayedNotification} />
                  //    : (<Tooltip title="Create a chat with the provider" placement='right' arrow>
                  //     <Typography
                  //       variant='body1'
                  //       sx={{ textDecoration: 'underline', cursor: 'pointer', marginRight: 'auto' }}
                  //       onClick={() => setDisplayChat(true)}
                  //     >
                  //       Reply
                  //     </Typography>
                  //   </Tooltip>)
                }
              </>
              ) }
      </Box>
    </Modal>
  )
}

const NotificationIcon = ({ type = 'message' }) => {
  return (
    <Box
      component='img'
      alt='Notification Icon'
      src={ `/assets/messageIcons/${type}.png` }
      sx={ { width: '100%', maxWidth: '3rem' } }
    />
  )
}

export default NotificationModal
