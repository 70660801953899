import { useEffect } from 'react'
import { isShkDomain } from 'utils/utilFuntions'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'

function useDocumentTitle (title) {
  const whiteLabelConfig = useWhiteLabelConfig()
  useEffect(() => {
    if (title){
      window.document.title = title;
      return;
    }
    window.document.title = isShkDomain() ? 'Simple HealthKit' : whiteLabelConfig.siteName || '';
    return () => {
      window.document.title = isShkDomain() ? 'Simple HealthKit' : whiteLabelConfig.siteName || '';
    }
  }, [title])
}

export default useDocumentTitle
