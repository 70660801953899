import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { api } from 'Core'
import { SiteI } from '../../types/db/SiteI'

export const useGetSites = ({ siteIds = [] }: Props) => {
  const query = useQuery(
    ['sites', ...siteIds],
    () => api.site.getSites({ ids: [...siteIds] }),
    {
      enabled: siteIds.length > 0
    }
  )

  return {
    ...query,
    // When the query is disabled due the siteIds being empty, the status of the isLoading is always true and the page is stuck in a loading state. This is a workaround to fix that.
    isLoading: query.isLoading && query.fetchStatus !== 'idle'
  } as UseQueryResult<SiteI[], unknown>
}

interface Props {
  siteIds: string[]
}
