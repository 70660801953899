import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, Stack } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useAuth0 from 'hooks/useAuth0'
import { Container, Paper } from 'components'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'

const AuthEmailVerified = () => {
  const { user } = useAuth0()
  const { token } = useParams()
  const [counter, setCounter] = useState(10)
  const navigate = useNavigate()

  useQuery(['lims-verify-email'], () => {
    if (!token) {
      return false
    } else {
      return api.patient.verifyLimsEmail(token)
    }
  })

  useEffect(() => {
    const counterInterval = setInterval(() => {
      setCounter((counter) => counter - 1)
    }, 1000)

    return () => {
      clearInterval(counterInterval)
    }
  }, [])

  useEffect(() => {
    if (counter === 0) {
      navigate('/')
    }
  }, [counter, navigate])

  return (
    <Container>
      <Paper>
        <Box py={1}>
          <Box mb={1} sx={{ textAlign: 'center' }}>
            <CheckCircleOutlineIcon sx={{ color: 'green' }} fontSize='large' />
            <Typography variant='h5' my={1}>
              Email Verified
            </Typography>
            <Stack sx={{ my: 1 }}>
              <Typography my={1}>
                Your email was successfully verified. You can continue using the
                application.
              </Typography>
              <Typography my={1}>
                You will be redirected to the app in {counter} seconds, or,
              </Typography>
            </Stack>
            <Button variant='contained' component={Link} to='/'>
              {!user ? 'Login to your account' : 'Go to dashboard'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default AuthEmailVerified
