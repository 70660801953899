import { AppBar, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import { isValidImageUrl } from 'utils/isValidImageUrl'
import { isShkDomain } from 'utils/utilFuntions'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'

const NpsHeader = () => {
  const whiteLabelConfig = useWhiteLabelConfig()
  return (
      <AppBar
        position='static'
        style={{
          backgroundColor: whiteLabelConfig?.colorScheme?.backgroundColor || '#ffffff',
          padding: '.5rem',
          height: 'clamp(5.25rem, 14vh, 5.8rem)',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
        }}
      >
        <Grid
          container
          spacing={1}
          alignItems='center'
          maxWidth='75rem'
          width='100%'
          height='100%'
          margin='0 auto'
        >
            <Grid xs={2.5}></Grid>

            <Grid
            xs={7}
            height='100%'
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <Box
                component='img'
                src={isShkDomain() ? '/assets/reskinning/shk-logo.svg' : isValidImageUrl(whiteLabelConfig?.logoUrl || '') ? whiteLabelConfig?.logoUrl : '' }
                sx={{ height: '100%', maxHeight: '3rem', width: '100%', objectFit: 'contain' }}
                alt='Logo'
            />
            </Grid>

        </Grid>
      </AppBar>
  )
}

export default NpsHeader
