import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import NotificationCard from './NotificationCard'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { AppNotificationI } from '../../../types/db/AppNotificationI'

const ListOfNotifications = ({ notifications }: Props) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState<number>(0)
  const [filteredNotifications, setFilteredNotifications] = useState<AppNotificationI[]>([])
  const handleChange = (_: SyntheticEvent<Element, Event>, value: number) => setTab(value)

  useEffect(() => {
    if (notifications?.length) {
      const toDisplay = notifications?.filter(notification => {
        const isDate = moment(notification.snoozed_until).isValid()
        const itIsInTheFuture = moment().isBefore(notification.snoozed_until)

        if (tab === 1) return isDate && itIsInTheFuture
        if (tab === 0) return !isDate || !itIsInTheFuture
        return false
      })
      setFilteredNotifications(toDisplay)
    }
  }, [tab, notifications])

  return (
    <Box sx={{
      minWidth: { md: '25rem' },
      width: '100%',
      maxWidth: { xs: '40rem', md: '25rem' },
      padding: { xs: '0rem', md: '1rem' },
      display: 'flex',
      flexDirection: 'column',
      alignItems: { xs: 'center', md: 'flex-start' }
    }}
    >
      <Tabs value={tab} onChange={handleChange} aria-label='Inbox tabs' >
        <Tab label={t('header.link.notification.modal.tab.all')} id='msg-tab' aria-controls='msg-tabpanel' sx={{ borderRadius: '.25rem' }} />
        <Tab label={t('header.link.notification.modal.tab.snoozed')} id='snoozed-tab' aria-controls='snoozed-tabpanel' sx={{ borderRadius: '.5rem' }} />
      </Tabs>
      <Stack sx={{
        gap: '.5rem',
        width: '100%',
        padding: '1rem 0rem',
        maxHeight: { xs: 'none', md: '400px' },
        overflowY: { xs: 'unset', md: 'auto' }
      }}
      >
        {
          filteredNotifications.length
            ? filteredNotifications
              ?.sort((a, b) => moment(a.created_at).isAfter(b.created_at) ? -1 : 1)
              ?.map((notification) => (
                <NotificationCard
                  key={notification?.id}
                  notification={notification}
                />
              ))
            : <Typography variant='body1' sx={{ textAlign: 'center', mt: '1rem' }}>
              {t('header.link.notification.noMessageToDisplay')}
            </Typography>
        }
      </Stack>
    </Box>
  )
}

interface Props {
  notifications: AppNotificationI[]
}

export default ListOfNotifications
