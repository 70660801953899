import IconButton from '@mui/material/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import React, { useEffect, useState } from 'react'
import { isShkDomain } from 'utils/utilFuntions'
import { ModalForBeacon } from 'components/BeaconNonSHK'

const HelpBeaconMobile = ({ displayed }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if(displayed && !isShkDomain()){
      window.addEventListener('isWhiteLabelHelpOpen', () => {
        setIsModalOpen(true);
      })
    }
    return () => {
      if (displayed && !isShkDomain()) {
        window.removeEventListener('isWhiteLabelHelpOpen', () => {});
      }
    }
  }, [displayed])

  if (!displayed) return null
  return (
    <>
      <IconButton
        aria-label="Help beacon"
        // @ts-ignore
        onClick={() => {
          if (isShkDomain()) { (window as any).Beacon('toggle') } else { setIsModalOpen(true) }
        }}
      sx={{ position: 'absolute', top: '1.6rem', right: '1rem' }}
      >
        <HelpIcon />
      </IconButton>
      <ModalForBeacon setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>
    </>
  )
}

export interface Props {
  displayed: boolean
}

export default HelpBeaconMobile
