import { useMutation } from '@tanstack/react-query'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import useAuth0 from './useAuth0'
import { getAffiliate } from 'utils/affiliateUtilFunctions'

function useSendEmailVerification ({ onSuccess = undefined, type = 'portal', token = null }: Props) {
  const dispatch = useDispatch()
  const { user } = useAuth0()

  return useMutation(() => {
    if (type === 'portal') {
      return api.auth.sendVerificationMailLink({ user, affiliate: getAffiliate() })
    } else {
      return api.patient.sendLimsVerificationMail({ token })
    }
  }, {
    onSuccess: (data) => {
      onSuccess && onSuccess(data)
      if (data.success) {
        dispatch(
          showSnackBar({
            show: true,
            message: 'Verification mail sent.',
            severity: 'success'
          })
        )
      }
    },
    onError: () => {
      dispatch(
        showSnackBar({
          show: true,
          message: 'Something were wrong verifying the email.',
          severity: 'error'
        })
      )
    }
  })
}

interface Props {
  onSuccess?: (data: any) => void | undefined
  type?: 'portal' | 'lims'
  token?: string | null
}

export default useSendEmailVerification
