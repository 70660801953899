import React, { useState } from 'react'
import {
  TextField,
  Typography,
  Stack,
  Link,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  IconButton,
  Button
} from '@mui/material'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import { Container, Paper } from 'components'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import { OpenInNew } from '@mui/icons-material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PasswordPolicy from 'components/PasswordPolicy'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { api } from 'Core'
import Loader from 'components/Loader'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { checkAffiliate } from 'utils/affiliateUtilFunctions'
import { getSubdomain } from 'utils/utilFuntions'

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(2, 'The First Name field must be at least 2 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(2, 'The Last Name field must be at least 2 characters')
    .required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Please choose a stronger password'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
  // confirmPassword: yup.string().required('Confirm password is required'),
  terms: yup.boolean().oneOf([true], 'Please accept terms and conditions')
  // might need confirm password
})

const PasswordRequest = () => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const [isPasswordSet, setIsPasswordSet] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () =>
    setShowPassword(showPassword => !showPassword)

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(showPassword => !showPassword)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      terms: false
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      try {
        const validateTicket = await api.auth.checkTicket(token)
        if (validateTicket.success) {
          // change the password
          const response = await api.auth.changePassword({
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            token
          })
          if (response.success) {
            dispatch(
              showSnackBar({
                show: true,
                severity: 'success',
                message: 'Password set successfully. Please Login to continue'
              })
            )
            setIsPasswordSet(true)
          } else {
            dispatch(
              showSnackBar({
                show: true,
                message: 'Something went wrong',
                severity: 'error'
              })
            )
          }
        }
      } catch (error) {
        console.log(error)
        dispatch(
          showSnackBar({
            show: true,
            message:
              "Something went wrong. Please make sure this user doesn't exist",
            severity: 'error'
          })
        )
      }
    }
  })

  const { isLoading } = useQuery(
    ['fetch-email'],
    () => api.admin.fetchEmailFromToken(token),
    {
      onSuccess: (res) => formik.setFieldValue('email', res)
    }
  )

  const { data, isLoading: isCheckingTicket } = useQuery(
    ['check-ticket'],
    () => api.auth.checkTicket(token),
    {
      refetchOnWindowFocus: false
    }
  )

  if (isLoading || isCheckingTicket) return <Loader />

  if (data?.success === false) {
    return (
      <Stack
        gap={2}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <CancelOutlinedIcon sx={{ fontSize: '64px ' }} color='error' />
        <Typography variant='h6' component='h1'>
          This link is invalid.
        </Typography>
        <Typography variant='body1'>
          To reset your password, return to the login page and select
          &quot;Forgot Your Password&quot; to send a new email.
        </Typography>
        <Button
          variant='contained'
          onClick={() => { window.location.href = '/' }}
          data-testid='backToPortalButton'
        >
          Back to Portal
        </Button>
      </Stack>
    )
  }

  if (isPasswordSet) {
    return (
      <Stack gap={2} display='flex' flexDirection='column' alignItems='center'>
        <CheckCircleOutlinedIcon sx={{ fontSize: '64px ' }} color='success' />
        <Typography variant='h5' component='h1'>Password Changed!</Typography>
        <Typography variant='body1'>
          Your password has been changed successfully.
        </Typography>
        <Button
          variant='contained'
          onClick={() => { window.location.href = '/' }}
        >
          Back to Portal
        </Button>
      </Stack>

    )
  }

  const subdomain = getSubdomain()

  return (
    <Container>
      <Stack
        sx={{ gap: 3, width: '100%', maxWidth: 550, m: 'auto' }}
        component='form'
        onSubmit={formik.handleSubmit}
        autoComplete='off'
      >
        <Typography variant='h4' component='h1' sx={{ textAlign: 'center' }}>
          {
            subdomain === 'app' ? 'Welcome to Portal' : checkAffiliate() ? 'At-home Diagnostic Portal' : 'Welcome to Simple HealthKit'
          }
        </Typography>
        <Paper>
          <Stack sx={{ gap: 3 }}>
            <Typography variant='h5' component='h2'>
              {subdomain === 'app' ? 'Create Your Account' : 'Finish Signing Up'}
            </Typography>
            <Stack
              gap={2}
              component='form'
              onSubmit={formik.handleSubmit}
              autoComplete='off'
            >
              <TextField
                id='first-name'
                name='firstName'
                label='First name *'
                onChange={formik.handleChange}
                helperText={
                  formik.touched.firstName &&
                  Boolean(formik.errors.firstName) &&
                  formik.errors.firstName
                }
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              />

              <TextField
                id='last-name'
                name='lastName'
                label='Last name * '
                onChange={formik.handleChange}
                helperText={
                  formik.touched.lastName &&
                  Boolean(formik.errors.lastName) &&
                  formik.errors.lastName
                }
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              />

              <TextField
                id='email'
                name='email'
                label='Email address *'
                onChange={formik.handleChange}
                disabled
                helperText={
                  formik.touched.email &&
                  Boolean(formik.errors.email) &&
                  formik.errors.email
                }
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />

              <TextField
                id='password'
                name='password'
                label='Password *'
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={toggleShowPassword} aria-label='visibility'>
                      {showPassword
                        ? (
                          <VisibilityOffIcon />
                          )
                        : (
                          <VisibilityIcon />
                          )}
                    </IconButton>
                  )
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              />

              <TextField
                id='confirm-password'
                name='confirmPassword'
                label='Confirm your password *'
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={toggleShowConfirmPassword} aria-label='visibility'>
                      {showConfirmPassword
                        ? (
                          <VisibilityOffIcon />
                          )
                        : (
                          <VisibilityIcon />
                          )}
                    </IconButton>
                  )
                }}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />

              <PasswordPolicy context={formik.values} type='invite' />

              <FormControl
                error={!!formik.errors.terms}
                sx={{ textAlign: 'start' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name='terms'
                      checked={formik.values.terms}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Typography>
                      I agree to{' '}
                      <Link
                        href='https://www.simplehealthkit.com/terms'
                        target='_blank'
                        color='primary'
                        rel='noreferrer'
                        sx={{ textDecoration: 'none' }}
                      >
                        Terms &amp; Conditions
                        <OpenInNew fontSize='inherit' />
                      </Link>{' '}
                      and{' '}
                      <Link
                        href='https://www.simplehealthkit.com/privacy'
                        target='_blank'
                        color='primary'
                        rel='noreferrer'
                        sx={{ textDecoration: 'none' }}
                      >
                        Privacy Policy
                        <OpenInNew fontSize='inherit' />
                      </Link>
                    </Typography>
                  }
                />
                <FormHelperText>{formik.errors.terms}</FormHelperText>
              </FormControl>
            </Stack>

            <LoadingButton
              type='submit'
              variant='contained'
              loading={formik.isSubmitting}
              fullWidth
            >
              Create Account
            </LoadingButton>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  )
}

export default PasswordRequest
