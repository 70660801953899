import * as React from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

export function useBlocker (blocker, when) {
  const { navigator } = React.useContext(NavigationContext)

  React.useEffect(() => {
    if (!when) return

    const push = navigator.push

    navigator.push = (...args) => {
      const result = blocker(...args)
      if (result !== false) {
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, blocker, when])
}
