import React from 'react'
import {
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText
} from '@mui/material'

interface RadioGroupComponentProps {
  name: string | undefined,
  value: string | undefined | null,
  options: Array<{
    value : string,
    label : string
  }>,
  error? : boolean,
  helperText? : string,
  headerText : string ,
  onChange : (any: any) => any
}

const RadioGroupComponent : React.FC<RadioGroupComponentProps> = ({
  onChange,
  name,
  value,
  options,
  error = false,
  helperText = '',
  headerText
}) => {
  return (
    <Stack gap={1}>
      <Typography>{headerText}</Typography>
      <RadioGroup name={name} onChange={onChange} sx={{ gap: 2 }} value={value}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={<Radio />}
            label={option.label}
            value={option.value}
          />
        ))}
        {error
          ? (
          <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>
            )
          : (
              ''
            )}
      </RadioGroup>
    </Stack>
  )
}


export default RadioGroupComponent
