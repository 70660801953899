import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import {
  TextField,
  Grid,
  Typography,
  Stack,
  Autocomplete,
  createFilterOptions,
  Box
} from '@mui/material'
import { insuranceNames } from '../data/insuranceNames'

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.label
})

const ForgottenInsurance = ({
  formik
}) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Stack sx={{ gap: 3, mb: 2 }}>
      <Typography variant='h6'>Insurance Info</Typography>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Autocomplete
            id='primaryInsuranceName'
            options={insuranceNames}
            renderOption={(props, data) => (
              <Box component='li' {...props} key={data?.id}>
                {data?.label}
              </Box>
            )}
            filterOptions={filterOptions}
            value={formik.values.insured?.primaryInsuranceName}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            onChange={(e, newValue) => {
              if (newValue?.label) {
                formik.setFieldValue(
                  'insured.primaryInsuranceName',
                  newValue.label
                )
                formik.setFieldValue(
                  'insured.primary_insurance_id',
                  newValue.id
                )
              } else {
                formik.setFieldValue('insured.primaryInsuranceName', '')
                formik.setFieldValue('insured.primary_insurance_id', null)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Primary Insurance Name'
                error={
                  formik.touched.insured?.primaryInsuranceName &&
                  Boolean(formik.errors?.insured?.primaryInsuranceName)
              }
              helperText={
                  formik.touched.insured?.primaryInsuranceName &&
                      formik.errors?.insured?.primaryInsuranceName
                    ? formik.errors?.insured?.primaryInsuranceName
                    : null
              }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Insured First Name'
            variant='outlined'
            type='text'
            name='insured.insuredFirstName'
            id='insuredFirstName'
            fullWidth
            value={formik.values.insured.insuredFirstName}
            onChange={formik.handleChange}
            error={
                            formik.touched.insured?.insuredFirstName &&
                            Boolean(formik.errors?.insured?.insuredFirstName)
                        }
            helperText={
                            formik.touched.insured?.insuredFirstName &&
                                formik.errors?.insured?.insuredFirstName
                              ? formik.errors?.insured?.insuredFirstName
                              : null
                        }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label='Insured Last Name '
            variant='outlined'
            type='text'
            name='insured.insuredLastName'
            id='insuredLastName'
            fullWidth
            value={formik.values.insured.insuredLastName}
            onChange={formik.handleChange}
            error={
                            formik.touched.insured?.insuredLastName &&
                            Boolean(formik.errors?.insured?.insuredLastName)
                        }
            helperText={
                            formik.touched.insured?.insuredLastName &&
                                formik.errors?.insured?.insuredLastName
                              ? formik.errors?.insured?.insuredLastName
                              : null
                        }
          />
        </Grid>

        <Grid item xs={12}>
          <InputMask
            mask='99-99-9999'
            maskChar=''
            name='dob'
            value={formik.values.insured.insuredDOB}
            onChange={formik.handleChange}
          >
            {() => (
              <TextField
                label='Insured Date of Birth'
                helperText={
                                    formik.touched.insured?.insuredDOB &&
                                        formik.errors?.insured?.insuredDOB
                                      ? formik.errors?.insured?.insuredDOB
                                      : null
                                }
                name='insured.insuredDOB'
                id='insuredDOB'
                placeholder='mm-dd-yyyy'
                fullWidth
                error={
                                    formik.touched.insured?.insuredDOB &&
                                    Boolean(formik.errors?.insured?.insuredDOB)
                                }
              />
            )}
          </InputMask>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ForgottenInsurance
