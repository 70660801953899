export interface InsuranceCompanyI {
  id: string;
  label: string;
  bill_type_id: string;
}

export const insuranceNames: InsuranceCompanyI[] = [
  {
    id: '4',
    label: '1199 NATIONAL BENEFIT FUND',
    bill_type_id: '8'
  },
  {
    id: '5',
    label: 'AAA INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '6',
    label: 'AARP',
    bill_type_id: '8'
  },
  {
    id: '7',
    label: 'AARP MEDICARE COMPLETE',
    bill_type_id: '1'
  },
  {
    id: '8',
    label: 'ACCESS ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '9',
    label: 'ACCLAIM',
    bill_type_id: '8'
  },
  {
    id: '10',
    label: 'ACCESSHEALTH TRICOUNTY NETWORK',
    bill_type_id: '8'
  },
  {
    id: '11',
    label: 'ACE AMERICAN INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '12',
    label: 'ADMINISTRATIVE CONCEPTS',
    bill_type_id: '8'
  },
  {
    id: '13',
    label: 'ADMINISTRATIVE SOLUTION',
    bill_type_id: '8'
  },
  {
    id: '14',
    label: 'ADVANTRA FREEDOM',
    bill_type_id: '8'
  },
  {
    id: '15',
    label: 'ADVENTIST HEALTH SYSTEM',
    bill_type_id: '8'
  },
  {
    id: '16',
    label: 'ADVANTEK BENEFIT ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '17',
    label: 'ADVENTIST HEALTH SYSTEMS',
    bill_type_id: '2'
  },
  {
    id: '18',
    label: 'ADVANTRA FREEDOM (COVENTRY)',
    bill_type_id: '8'
  },
  {
    id: '19',
    label: 'ADVENTHEALTH',
    bill_type_id: '8'
  },
  {
    id: '20',
    label: 'AETNA BETTER HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '21',
    label: 'AETNA BETTER HEALTH OF PENNSYLVANIA',
    bill_type_id: '8'
  },
  {
    id: '22',
    label: 'AETNA BETTER HEALTH OF TEXAS',
    bill_type_id: '2'
  },
  {
    id: '23',
    label: 'AETNA',
    bill_type_id: '8'
  },
  {
    id: '24',
    label: 'AETNA BETTER HEALTH OF NEW JERSEY',
    bill_type_id: '8'
  },
  {
    id: '25',
    label: 'Aetna Senior Supplemental Insurance',
    bill_type_id: '8'
  },
  {
    id: '26',
    label: 'AETNA BETTER HEALTH OF ILLINOIS',
    bill_type_id: '2'
  },
  {
    id: '27',
    label: 'AETNA BETTER HEALTH OF KENTUCKY',
    bill_type_id: '8'
  },
  {
    id: '28',
    label: 'AETNA BETTER HEALTH OF LOUISIANA',
    bill_type_id: '8'
  },
  {
    id: '29',
    label: 'AETNA BETTER HEALTH OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '30',
    label: 'AETNA BETTER HEALTH OF PENNSYLVANIA',
    bill_type_id: '8'
  },
  {
    id: '31',
    label: 'AETNA BETTER HEALTH OF VIRGINIA',
    bill_type_id: '8'
  },
  {
    id: '32',
    label: 'AETNA COVENTRY',
    bill_type_id: '8'
  },
  {
    id: '33',
    label: 'AETNA BETTER HEALTH OF KANSAS',
    bill_type_id: '8'
  },
  {
    id: '34',
    label: 'AETNA BETTER HEALTH OF FLORIDA',
    bill_type_id: '8'
  },
  {
    id: '35',
    label: 'AETNA COVENTRY',
    bill_type_id: '8'
  },
  {
    id: '36',
    label: 'AETNA BETTER HEALTH OF KANSAS',
    bill_type_id: '8'
  },
  {
    id: '37',
    label: 'AETNA CHOICE POS',
    bill_type_id: '8'
  },
  {
    id: '38',
    label: 'AETNA BETTER HEALTH OF PENNSYLVANIA',
    bill_type_id: '8'
  },
  {
    id: '39',
    label: 'AFFINITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '40',
    label: 'AFFORDASHARE',
    bill_type_id: '8'
  },
  {
    id: '41',
    label: 'AFLAC',
    bill_type_id: '8'
  },
  {
    id: '42',
    label: 'AHCCCS',
    bill_type_id: '8'
  },
  {
    id: '43',
    label: 'AIG',
    bill_type_id: '8'
  },
  {
    id: '44',
    label: 'ALASKA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '45',
    label: 'ALABAMA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '46',
    label: 'ALABAMA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '47',
    label: 'ALABAMA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '48',
    label: 'ALASKA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '49',
    label: 'ALICARE',
    bill_type_id: '8'
  },
  {
    id: '50',
    label: 'ALIERACARE',
    bill_type_id: '8'
  },
  {
    id: '51',
    label: 'ALIGNMENT HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '52',
    label: 'ALLIED BENEFIT SYSTEM',
    bill_type_id: '8'
  },
  {
    id: '53',
    label: 'ALLIANCE FOR HEALTH',
    bill_type_id: '8'
  },
  {
    id: '54',
    label: 'ALLEGIANCE BENEFIT PLAN MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '55',
    label: 'ALLSTATE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '56',
    label: 'ALLIANT HEALTH PLANS OF GEORGIA',
    bill_type_id: '8'
  },
  {
    id: '57',
    label: 'ALL SAVERS INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '58',
    label: 'ALLEGIANCE BENEFIT PLAN MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '59',
    label: 'ALLIED BENEFIT SYSTEMS',
    bill_type_id: '8'
  },
  {
    id: '60',
    label: 'ALLIANCE HEALTH AND LIFE',
    bill_type_id: '8'
  },
  {
    id: '61',
    label: 'ALLIANCE',
    bill_type_id: '8'
  },
  {
    id: '62',
    label: 'ALLWELL',
    bill_type_id: '8'
  },
  {
    id: '63',
    label: 'ALLWAYS HEALTH PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '64',
    label: 'ALTIUS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '65',
    label: 'AMA INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '66',
    label: 'AMA INSURANCE AGENCY',
    bill_type_id: '8'
  },
  {
    id: '67',
    label: 'AMERIHEALTH CARITAS DC',
    bill_type_id: '8'
  },
  {
    id: '68',
    label: 'AMBETTER FROM SUPERIOR HEALTH',
    bill_type_id: '8'
  },
  {
    id: '69',
    label: 'AMBETTER PEACH STATE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '70',
    label: 'AMBETTER FROM SUNSHINE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '71',
    label: 'AMBETTER ILLINICARE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '72',
    label: 'AMBETTER MAGNOLIA HEALTH',
    bill_type_id: '8'
  },
  {
    id: '73',
    label: 'AMBETTER NH HEALTHY FAMILIES',
    bill_type_id: '8'
  },
  {
    id: '74',
    label: 'AMBETTER ILLINICARE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '75',
    label: 'AMBETTER OF ARKANSAS',
    bill_type_id: '8'
  },
  {
    id: '76',
    label: 'AMBETTER OF ARIZONA',
    bill_type_id: '8'
  },
  {
    id: '77',
    label: 'AMBETTER FROM SUNFLOWER HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '78',
    label: 'AMERICAN INSURANCE ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '79',
    label: 'AMERIHEALTH MERCY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '80',
    label: 'AMERIGROUP CORPORATION',
    bill_type_id: '2'
  },
  {
    id: '81',
    label: 'AMERICAN FAMILY INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '82',
    label: 'AMERIHEALTH HMO',
    bill_type_id: '8'
  },
  {
    id: '83',
    label: 'AMERICHOICE OF NJ PERSONAL CARE',
    bill_type_id: '8'
  },
  {
    id: '84',
    label: 'AMERICAN REPUBLIC INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '85',
    label: 'AMERICAN WORKER HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '86',
    label: 'AMERICAN NATIONAL INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '87',
    label: 'AMERICAN HEALTHCARE ALLIANCE',
    bill_type_id: '8'
  },
  {
    id: '88',
    label: 'AMERICAN INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '89',
    label: 'AMERICAN SPECIALTY HEALTH',
    bill_type_id: '8'
  },
  {
    id: '90',
    label: 'AMERICAN BEHAVIOR',
    bill_type_id: '8'
  },
  {
    id: '91',
    label: 'AMERICHOICE TENNCARE',
    bill_type_id: '8'
  },
  {
    id: '92',
    label: 'AMERICAN MEDICAL SECURITY',
    bill_type_id: '8'
  },
  {
    id: '93',
    label: 'AMERIHEALTH CARITAS DELAWARE',
    bill_type_id: '8'
  },
  {
    id: '94',
    label: 'AMERIHEALTH CARITAS LOUISIANA',
    bill_type_id: '8'
  },
  {
    id: '95',
    label: 'AMERIHEALTH ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '96',
    label: 'AMERIBEN',
    bill_type_id: '8'
  },
  {
    id: '97',
    label: 'AMERICAN RETIREMENT LIFE INS CO',
    bill_type_id: '8'
  },
  {
    id: '98',
    label: 'AMERICAN CONTINENTAL INSURANCE COMP',
    bill_type_id: '8'
  },
  {
    id: '99',
    label: 'AMERICO INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '100',
    label: 'AMERICAN RETIREMENT LIFE',
    bill_type_id: '8'
  },
  {
    id: '101',
    label: 'AMERIVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '102',
    label: 'AMFIRST INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '103',
    label: 'AMICA',
    bill_type_id: '8'
  },
  {
    id: '104',
    label: 'AMERIHEALTH CARITAS',
    bill_type_id: '2'
  },
  {
    id: '105',
    label: 'ANTHEM BLUE ACCESS PRODUCTS',
    bill_type_id: '8'
  },
  {
    id: '106',
    label: 'ANTHEM BLUE CROSS',
    bill_type_id: '8'
  },
  {
    id: '107',
    label: 'APOSTROPHE',
    bill_type_id: '8'
  },
  {
    id: '108',
    label: 'APS HEALTHCARE INC',
    bill_type_id: '8'
  },
  {
    id: '109',
    label: 'APWU HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '110',
    label: 'ARIZONA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '111',
    label: 'ARIZONA PHYSICIANS IPA',
    bill_type_id: '8'
  },
  {
    id: '112',
    label: 'ARIZONA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '113',
    label: 'ARIZONA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '114',
    label: 'ARIC',
    bill_type_id: '8'
  },
  {
    id: '115',
    label: 'ARISE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '116',
    label: 'ARIZONA COMPLETE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '117',
    label: 'ARIZONA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '118',
    label: 'ARKANSAS MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '119',
    label: 'ARKANSAS BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '120',
    label: 'ARKANSAS MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '121',
    label: 'ARKANSAS TOTAL CARE',
    bill_type_id: '8'
  },
  {
    id: '122',
    label: 'ASR PHYSICIANS CARE',
    bill_type_id: '8'
  },
  {
    id: '123',
    label: 'ASRM CORPORATION',
    bill_type_id: '8'
  },
  {
    id: '124',
    label: 'ASSURED BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '125',
    label: 'ASSURANT HEALTH',
    bill_type_id: '8'
  },
  {
    id: '126',
    label: 'ASSURED LIFE ASSOCIATION',
    bill_type_id: '8'
  },
  {
    id: '127',
    label: 'ASURIS NORTHWEST',
    bill_type_id: '8'
  },
  {
    id: '128',
    label: 'ASURIS NORTHWEST MEDADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '129',
    label: 'ABSOLUTE TOTAL CARE',
    bill_type_id: '8'
  },
  {
    id: '130',
    label: 'ATRIO HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '131',
    label: 'AULTCARE OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '132',
    label: 'AUTOMATED BENEFIT SERVICES',
    bill_type_id: '8'
  },
  {
    id: '133',
    label: 'AUXIANT',
    bill_type_id: '8'
  },
  {
    id: '134',
    label: 'AVERA HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '135',
    label: 'AVESIS CLAIMS DEPARTMENT',
    bill_type_id: '8'
  },
  {
    id: '136',
    label: 'AVMED',
    bill_type_id: '8'
  },
  {
    id: '137',
    label: 'ALLWELL HEALTHPLAN',
    bill_type_id: '8'
  },
  {
    id: '138',
    label: 'AXA ASSISTANCE USA',
    bill_type_id: '8'
  },
  {
    id: '139',
    label: 'BANNER HEALTH ARIZONA',
    bill_type_id: '8'
  },
  {
    id: '140',
    label: 'BANKERS LIFE FIDELITY',
    bill_type_id: '8'
  },
  {
    id: '141',
    label: 'BARRETO HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '142',
    label: 'BAY BRIDGE ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '143',
    label: 'BAYLOR SCOOT AND WHITE RESEARCH INS',
    bill_type_id: '8'
  },
  {
    id: '144',
    label: 'BC REGENCE MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '145',
    label: 'FEDERAL EMPLOYEE HEALTH BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '146',
    label: 'BCBS FEDERAL EMPLOYEE PROGRAM',
    bill_type_id: '8'
  },
  {
    id: '147',
    label: 'BCN',
    bill_type_id: '8'
  },
  {
    id: '148',
    label: 'BEAUMONT EMPLOYEE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '149',
    label: 'BEACON HEALTH STRATEGIES',
    bill_type_id: '8'
  },
  {
    id: '150',
    label: 'BEAVER MEDICAL GROUP',
    bill_type_id: '8'
  },
  {
    id: '151',
    label: 'BEHAVIORAL HEALTH SERVICE',
    bill_type_id: '8'
  },
  {
    id: '152',
    label: 'BEHAVIORIAL HEALTH SYSTEMS',
    bill_type_id: '8'
  },
  {
    id: '153',
    label: 'BENEFIT SERVICES INC',
    bill_type_id: '8'
  },
  {
    id: '154',
    label: 'BENEFIT ADMINISTRATIVE SYSTEMS',
    bill_type_id: '8'
  },
  {
    id: '155',
    label: 'BENEFIT MANAGEMENT INC',
    bill_type_id: '8'
  },
  {
    id: '156',
    label: 'BENEFIT PLANNERS',
    bill_type_id: '8'
  },
  {
    id: '157',
    label: 'BENVEO/UCS MULTIPLAN',
    bill_type_id: '8'
  },
  {
    id: '158',
    label: 'BEST LIFE AND HEALTH INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '159',
    label: 'BETTER HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '160',
    label: 'BLESSING HEALTH SYSTEM',
    bill_type_id: '8'
  },
  {
    id: '161',
    label: 'BLUECHOICE SOUTH CAROLINA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '162',
    label: 'BLUE CARE NETWORK HMO',
    bill_type_id: '8'
  },
  {
    id: '163',
    label: 'BLUE CARE NETWORK HMO MICHIGAN',
    bill_type_id: '8'
  },
  {
    id: '164',
    label: 'BLUE CROSS BLUE SHIELD OF TEXAS MED',
    bill_type_id: '8'
  },
  {
    id: '165',
    label: 'BLUECHOICE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '166',
    label: 'BLUE MEDICARE NORTH CAROLINA',
    bill_type_id: '8'
  },
  {
    id: '167',
    label: 'ILLINOIS BLUE CROSS COMMUNITY HP',
    bill_type_id: '8'
  },
  {
    id: '168',
    label: 'BLUE CROSS MEDICARE ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '169',
    label: 'BCBS AZ MEDICARE ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '170',
    label: 'BLUECROSS BLUESHIELD OF TENNESSEE,',
    bill_type_id: '8'
  },
  {
    id: '171',
    label: 'BLUE CROSS COMMUNITY HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '172',
    label: 'BLUE CROSS MEDICARE ADVANTAGE TX',
    bill_type_id: '8'
  },
  {
    id: '173',
    label: 'BLUE CROSS MEDICARE ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '174',
    label: 'BLUE CROSS BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '175',
    label: 'BLUE CROSS COMMUNITY MMAI',
    bill_type_id: '8'
  },
  {
    id: '176',
    label: 'BLUE CROSS BLUE SHIELD OF GEORGIA',
    bill_type_id: '8'
  },
  {
    id: '177',
    label: 'BLUE BELL CREAMERIES INC',
    bill_type_id: '8'
  },
  {
    id: '178',
    label: 'BLUE CARE NETWORK HMO',
    bill_type_id: '8'
  },
  {
    id: '179',
    label: 'BOON CHAPMAN ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '180',
    label: 'BOSTON MEDICAL CENTER HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '181',
    label: 'BOSTON MEDICAL CENTER HEALTH PLAN N',
    bill_type_id: '8'
  },
  {
    id: '182',
    label: 'BRIGHT HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '183',
    label: 'BRIDGEFIELD EMPLOYERS INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '184',
    label: 'BRIDGESPAN',
    bill_type_id: '8'
  },
  {
    id: '185',
    label: 'BRIDGESTONE CLAIMS SERVICES',
    bill_type_id: '8'
  },
  {
    id: '186',
    label: 'BLUECARE TENESSEE',
    bill_type_id: '8'
  },
  {
    id: '187',
    label: 'BUCKEYE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '188',
    label: 'CALIFORNIA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '189',
    label: 'CALIFORNIA MEDICAL',
    bill_type_id: '2'
  },
  {
    id: '190',
    label: 'CAMBRIDGE PUBLIC HEALTH COMMISSION',
    bill_type_id: '8'
  },
  {
    id: '191',
    label: 'CAPITAL BLUE CROSS PHYSICIAN',
    bill_type_id: '8'
  },
  {
    id: '192',
    label: 'CAPITAL HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '193',
    label: 'CAPROCK HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '194',
    label: 'CAREOREGON',
    bill_type_id: '8'
  },
  {
    id: '195',
    label: 'CARE PLUS',
    bill_type_id: '8'
  },
  {
    id: '196',
    label: 'CAREMORE IPA',
    bill_type_id: '8'
  },
  {
    id: '197',
    label: 'CARPENTERS HEALTH AND WELFARE',
    bill_type_id: '8'
  },
  {
    id: '198',
    label: 'CARESOURCE OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '199',
    label: 'CARE WISCONSIN HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '200',
    label: 'CARE 1ST HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '201',
    label: 'CARESOURCE OF KENTUCKY',
    bill_type_id: '8'
  },
  {
    id: '202',
    label: 'CARE N CARE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '203',
    label: 'CARESOURCE OF GEORGIA',
    bill_type_id: '8'
  },
  {
    id: '204',
    label: 'CARDINAL CHOICE - ADMINISTRATIVE CO',
    bill_type_id: '8'
  },
  {
    id: '205',
    label: 'CARE 1ST HEALTH PLAN OF ARIZONA',
    bill_type_id: '8'
  },
  {
    id: '206',
    label: 'CAREFIRST ADMINISTRATORS / NCAS',
    bill_type_id: '8'
  },
  {
    id: '207',
    label: 'CARE IMPROVEMENT PLUS',
    bill_type_id: '8'
  },
  {
    id: '208',
    label: 'CAREPLUS HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '209',
    label: 'CARE NETWORK',
    bill_type_id: '8'
  },
  {
    id: '210',
    label: 'CARESOURCE HEALTHY INDIANA PLAN (HI',
    bill_type_id: '8'
  },
  {
    id: '211',
    label: 'CARE FIRST BLUE CROSS BLUE SHEILD',
    bill_type_id: '8'
  },
  {
    id: '212',
    label: 'CATAMARAN',
    bill_type_id: '8'
  },
  {
    id: '213',
    label: 'CEMENT MASONS PLASTERERS HEALTH',
    bill_type_id: '8'
  },
  {
    id: '214',
    label: 'CENTURY HEALTH SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '215',
    label: 'CENPATICO BEHAVIORAL HEALTH',
    bill_type_id: '8'
  },
  {
    id: '216',
    label: 'CENTRAL RESERVE LIFE',
    bill_type_id: '8'
  },
  {
    id: '217',
    label: 'CENTRAL STATES HEALTH AND WELFARE',
    bill_type_id: '8'
  },
  {
    id: '218',
    label: 'CENTRY CARE OF THE CRYSTAL COAST',
    bill_type_id: '8'
  },
  {
    id: '219',
    label: 'CENTRAL STATES INDEMNITY',
    bill_type_id: '8'
  },
  {
    id: '220',
    label: 'CERNER HEALTHPLAN SERVICES',
    bill_type_id: '8'
  },
  {
    id: '221',
    label: 'CGS',
    bill_type_id: '8'
  },
  {
    id: '222',
    label: 'CHAMPVA',
    bill_type_id: '8'
  },
  {
    id: '223',
    label: 'CHAMPUS',
    bill_type_id: '8'
  },
  {
    id: '224',
    label: 'CHOICE ONE CHIP HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '225',
    label: 'CHOICE MEDICAL GROUP',
    bill_type_id: '8'
  },
  {
    id: '226',
    label: 'CHOICECARE NETWORK OPERATIONS',
    bill_type_id: '8'
  },
  {
    id: '227',
    label: 'CHRISTUS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '228',
    label: 'CHRISTIAN BROTHERS SERVICES',
    bill_type_id: '8'
  },
  {
    id: '229',
    label: 'CHRISTIAN CARE MINISTRIES',
    bill_type_id: '8'
  },
  {
    id: '230',
    label: 'CHRISTUS HEALTH PLAN EXCHANGE',
    bill_type_id: '8'
  },
  {
    id: '231',
    label: 'CIGNA BEHAVIORAL HEALTH',
    bill_type_id: '8'
  },
  {
    id: '232',
    label: 'CIGNA PPO',
    bill_type_id: '8'
  },
  {
    id: '233',
    label: 'CIGNA HEALTHSPRING',
    bill_type_id: '8'
  },
  {
    id: '234',
    label: 'CIGNA HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '235',
    label: 'CIGNA',
    bill_type_id: '8'
  },
  {
    id: '236',
    label: 'CIGNA MEDICARE SUPPLEMENT INSURANCE',
    bill_type_id: '1'
  },
  {
    id: '237',
    label: 'CIGNA OPEN ACCESS',
    bill_type_id: '8'
  },
  {
    id: '238',
    label: 'CLOVER HEALTH',
    bill_type_id: '8'
  },
  {
    id: '239',
    label: 'CMDP',
    bill_type_id: '8'
  },
  {
    id: '240',
    label: 'COFINITY',
    bill_type_id: '8'
  },
  {
    id: '241',
    label: 'COLORADO BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '242',
    label: 'COLORADO MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '243',
    label: 'COLORADO MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '244',
    label: 'COLONIAL PENN LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '245',
    label: 'COLONIAL PENN',
    bill_type_id: '8'
  },
  {
    id: '246',
    label: 'COMMUNITY CARE MANAGED',
    bill_type_id: '8'
  },
  {
    id: '247',
    label: 'COMMONWEALTH CARE ALLIANCE',
    bill_type_id: '8'
  },
  {
    id: '248',
    label: 'COMMUNITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '249',
    label: 'COMMUNITY HEALTH CHOICE',
    bill_type_id: '8'
  },
  {
    id: '250',
    label: 'COMMUNITY FIRST',
    bill_type_id: '8'
  },
  {
    id: '251',
    label: 'COMPCARE',
    bill_type_id: '2'
  },
  {
    id: '252',
    label: 'COMMON GROUND HEALTHCARE COOP',
    bill_type_id: '8'
  },
  {
    id: '253',
    label: 'COMBINED INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '254',
    label: 'COMMUNITY CARE PLANS',
    bill_type_id: '8'
  },
  {
    id: '255',
    label: 'COMMUNITY HEALTH CHOICE MARKETPLACE',
    bill_type_id: '8'
  },
  {
    id: '256',
    label: 'COMMERCIAL GLOBAL LIFE',
    bill_type_id: '8'
  },
  {
    id: '257',
    label: 'COMMUNITY CARE MANAGED HEALTHCARE P',
    bill_type_id: '8'
  },
  {
    id: '258',
    label: 'COMMUNITY HEALTH CENTER NETWORK',
    bill_type_id: '8'
  },
  {
    id: '259',
    label: 'COMPANION LIFE',
    bill_type_id: '8'
  },
  {
    id: '260',
    label: 'CONNECTICUT BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '261',
    label: 'CONNECTICUT MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '262',
    label: 'CONSOCIATE GROUP',
    bill_type_id: '8'
  },
  {
    id: '263',
    label: 'CONTINENTAL GENERAL INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '264',
    label: 'CONNECTICUT MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '265',
    label: 'CONNECTICARE',
    bill_type_id: '8'
  },
  {
    id: '266',
    label: 'CONTINENTAL BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '267',
    label: 'CONSOLIDATED ASSOC OF RAILROAD EMPL',
    bill_type_id: '8'
  },
  {
    id: '268',
    label: 'CONSTITUTION LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '269',
    label: 'CONSECO HEALTH INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '270',
    label: 'CONSOLICARE',
    bill_type_id: '8'
  },
  {
    id: '271',
    label: 'COOK CHILDRENS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '272',
    label: 'COORDINATED BENEFIT PLAN',
    bill_type_id: '8'
  },
  {
    id: '273',
    label: 'COORDINATED CARE',
    bill_type_id: '8'
  },
  {
    id: '274',
    label: 'COORDINATED PODIATRY NETWORK',
    bill_type_id: '8'
  },
  {
    id: '275',
    label: 'CORNERSTONE BENEFIT ASSOCIATES',
    bill_type_id: '8'
  },
  {
    id: '276',
    label: 'CORE SOURCE',
    bill_type_id: '8'
  },
  {
    id: '277',
    label: 'CORESOURCE',
    bill_type_id: '8'
  },
  {
    id: '278',
    label: 'CORRIGAN LTC NURSING AND REHAB',
    bill_type_id: '8'
  },
  {
    id: '279',
    label: 'COSTCO HEALTH SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '280',
    label: 'COVENTRY HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '281',
    label: 'COVENANT ADMINISTATORS',
    bill_type_id: '8'
  },
  {
    id: '282',
    label: 'COUNTY INDIGENT HEALTH SERVICES',
    bill_type_id: '8'
  },
  {
    id: '283',
    label: 'COVENTRY HEALTH CARE OF GEORGIA',
    bill_type_id: '8'
  },
  {
    id: '284',
    label: 'COVENTRYONE',
    bill_type_id: '8'
  },
  {
    id: '285',
    label: 'COVID19 HRSA UNINSURED T&TF',
    bill_type_id: '8'
  },
  {
    id: '286',
    label: 'COX HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '287',
    label: 'HUMANA - CARESOURCE',
    bill_type_id: '2'
  },
  {
    id: '288',
    label: 'CSO',
    bill_type_id: '8'
  },
  {
    id: '289',
    label: 'CULINARY HEALTH FUND',
    bill_type_id: '8'
  },
  {
    id: '290',
    label: 'CURATIVE CARE',
    bill_type_id: '8'
  },
  {
    id: '291',
    label: 'CWIBENEFITS',
    bill_type_id: '8'
  },
  {
    id: '292',
    label: 'CYPRESS BENEFIT ADMINISTRATION',
    bill_type_id: '8'
  },
  {
    id: '293',
    label: 'DELTA HEALTH SYSTEMS',
    bill_type_id: '8'
  },
  {
    id: '294',
    label: 'DELAWARE MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '295',
    label: 'DELAWARE BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '296',
    label: 'DENVER HEALTH MEDICAL PLAN',
    bill_type_id: '8'
  },
  {
    id: '297',
    label: 'DESERET MUTUAL BENEFIT ASSOCIATES',
    bill_type_id: '8'
  },
  {
    id: '298',
    label: 'DESEIET MUTUAL DMBA',
    bill_type_id: '8'
  },
  {
    id: '299',
    label: 'DESERET ALLIANCE',
    bill_type_id: '8'
  },
  {
    id: '300',
    label: 'DEVOTED HEALTH',
    bill_type_id: '8'
  },
  {
    id: '301',
    label: 'DISTRICT OF COLUMBIA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '302',
    label: 'DEALERCOMP',
    bill_type_id: '8'
  },
  {
    id: '303',
    label: 'DMBA',
    bill_type_id: '8'
  },
  {
    id: '304',
    label: 'DRISCOLL CHILDRENS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '305',
    label: 'DUNN AND ASSOCIATES',
    bill_type_id: '8'
  },
  {
    id: '306',
    label: 'DXC TECHNOLOGY',
    bill_type_id: '8'
  },
  {
    id: '307',
    label: 'E-HEALTH',
    bill_type_id: '8'
  },
  {
    id: '308',
    label: 'EBIX HEALTH ADMINISTRATOR',
    bill_type_id: '8'
  },
  {
    id: '309',
    label: 'TBD',
    bill_type_id: '8'
  },
  {
    id: '310',
    label: 'ENCOMPASS HLTH DEACONESS',
    bill_type_id: '8'
  },
  {
    id: '311',
    label: 'EL PASO FIRST CHIP',
    bill_type_id: '8'
  },
  {
    id: '312',
    label: 'ELDERPLAN',
    bill_type_id: '8'
  },
  {
    id: '313',
    label: 'EMBLEMHEALTH',
    bill_type_id: '8'
  },
  {
    id: '314',
    label: 'EMI HEALTH',
    bill_type_id: '8'
  },
  {
    id: '315',
    label: 'EMI HEALTH',
    bill_type_id: '8'
  },
  {
    id: '316',
    label: 'EMPLOYERS MUTUAL',
    bill_type_id: '8'
  },
  {
    id: '317',
    label: 'EMPLOYEE BENEFIT SERVICES',
    bill_type_id: '8'
  },
  {
    id: '318',
    label: 'EMPLOYER PLAN SERVICES',
    bill_type_id: '8'
  },
  {
    id: '319',
    label: 'EMPLOYERS LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '320',
    label: 'EMPLOYEE BENEFIT MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '321',
    label: 'EMPIRE PLAN CLAIMS',
    bill_type_id: '8'
  },
  {
    id: '322',
    label: 'EMPLOYERS INSURANCE CO OF WAUSAU',
    bill_type_id: '8'
  },
  {
    id: '323',
    label: 'EMPLOYEE BENEFIT ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '324',
    label: 'ENTRUST',
    bill_type_id: '8'
  },
  {
    id: '325',
    label: 'EQUITABLE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '326',
    label: 'ERIN DEFRATES',
    bill_type_id: '8'
  },
  {
    id: '327',
    label: 'ESSENCE',
    bill_type_id: '8'
  },
  {
    id: '328',
    label: 'ESSENTIAL STAFFCARE',
    bill_type_id: '8'
  },
  {
    id: '329',
    label: 'EVERCARE',
    bill_type_id: '8'
  },
  {
    id: '330',
    label: 'EVERENCE',
    bill_type_id: '8'
  },
  {
    id: '331',
    label: 'EVOLUTIONS HEALTHCARE SYSTEMS',
    bill_type_id: '8'
  },
  {
    id: '332',
    label: 'EXPRESS SCRIPTS',
    bill_type_id: '8'
  },
  {
    id: '333',
    label: 'FALLON COMMUNITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '334',
    label: 'FAMILY LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '335',
    label: 'FARMERS INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '336',
    label: 'FARM FAMILY',
    bill_type_id: '8'
  },
  {
    id: '337',
    label: 'FARM BUREAU HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '338',
    label: 'FELRA UFCW',
    bill_type_id: '8'
  },
  {
    id: '339',
    label: 'FIDELIS CARE OF NEW YORK',
    bill_type_id: '8'
  },
  {
    id: '340',
    label: 'FIRST CAROLINA CARE',
    bill_type_id: '8'
  },
  {
    id: '341',
    label: 'FIRST HEALTH NETWORK',
    bill_type_id: '8'
  },
  {
    id: '342',
    label: 'FIRST CHOICE HEALTH NETWORK',
    bill_type_id: '8'
  },
  {
    id: '343',
    label: 'FIRSTCARE',
    bill_type_id: '8'
  },
  {
    id: '344',
    label: 'FIRST CHOICE VIP CARE PLUS',
    bill_type_id: '8'
  },
  {
    id: '345',
    label: 'FIRST HEALTH - PAI',
    bill_type_id: '8'
  },
  {
    id: '346',
    label: 'FIRST MEDICARE DIRECT',
    bill_type_id: '8'
  },
  {
    id: '347',
    label: 'FLORIDA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '348',
    label: 'FLORIDA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '349',
    label: 'FLORIDA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '350',
    label: 'FLORIDA HEALTH CARE PLAN',
    bill_type_id: '8'
  },
  {
    id: '351',
    label: 'FLORIDA HEALTH OPTIONS HMO',
    bill_type_id: '8'
  },
  {
    id: '352',
    label: 'FLORIDA HOSPITAL HEALTHCARE SYSTEMS',
    bill_type_id: '8'
  },
  {
    id: '353',
    label: 'FLORIDA HEALTH CARE PLUS',
    bill_type_id: '8'
  },
  {
    id: '354',
    label: 'FLORIDA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '355',
    label: 'FLORIDA UROLOGY PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '356',
    label: 'FOOT AND ANKLE NHP',
    bill_type_id: '8'
  },
  {
    id: '357',
    label: 'FORE THOUGHT LIFE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '358',
    label: 'FOUNTAINBLEAU LODGE',
    bill_type_id: '8'
  },
  {
    id: '359',
    label: 'FOX EVERETT',
    bill_type_id: '8'
  },
  {
    id: '360',
    label: 'FREEDOM HEALTH',
    bill_type_id: '8'
  },
  {
    id: '361',
    label: 'FREEDOM LIFE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '362',
    label: 'FRINGE BENEFIT MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '363',
    label: 'FRONTPATH HEALTH COALITION',
    bill_type_id: '8'
  },
  {
    id: '364',
    label: 'GALLAGHER BASSETT WORK COMPANY',
    bill_type_id: '8'
  },
  {
    id: '365',
    label: 'GATEWAY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '366',
    label: 'GATEWAY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '367',
    label: 'GEHA HIGH OPTION',
    bill_type_id: '8'
  },
  {
    id: '368',
    label: 'GEHA-ASA',
    bill_type_id: '8'
  },
  {
    id: '369',
    label: 'GEHA',
    bill_type_id: '8'
  },
  {
    id: '370',
    label: 'GEISINGER HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '371',
    label: 'GENESEE COUNTY HEATLH PLAN',
    bill_type_id: '8'
  },
  {
    id: '372',
    label: 'GENERATIONS HEALTH',
    bill_type_id: '8'
  },
  {
    id: '373',
    label: 'GENWORTH LIFE',
    bill_type_id: '8'
  },
  {
    id: '374',
    label: 'GEO GROUP INC',
    bill_type_id: '1'
  },
  {
    id: '375',
    label: 'GEORGIA BANKERS',
    bill_type_id: '8'
  },
  {
    id: '376',
    label: 'GEORGIA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '377',
    label: 'GEORGIA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '378',
    label: 'GEORGIA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '379',
    label: 'GERBER LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '380',
    label: 'GHI HMO',
    bill_type_id: '8'
  },
  {
    id: '381',
    label: 'GIC',
    bill_type_id: '8'
  },
  {
    id: '382',
    label: 'GILSBAR',
    bill_type_id: '8'
  },
  {
    id: '383',
    label: 'GLOBAL CARE',
    bill_type_id: '8'
  },
  {
    id: '384',
    label: 'GLOBAL HEALTH OKC',
    bill_type_id: '8'
  },
  {
    id: '385',
    label: 'GLOBAL LIFE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '386',
    label: 'GLOBAL CARE',
    bill_type_id: '8'
  },
  {
    id: '387',
    label: 'GPM HEALTH AND LIFE',
    bill_type_id: '8'
  },
  {
    id: '388',
    label: 'GMP EMPLOYERS RETIREE TRUST',
    bill_type_id: '8'
  },
  {
    id: '389',
    label: 'GOLDEN STATE PHYSICIANS MEDICAL',
    bill_type_id: '8'
  },
  {
    id: '390',
    label: 'GOLDEN RULE',
    bill_type_id: '8'
  },
  {
    id: '391',
    label: 'GOLDEN RULE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '392',
    label: 'GOLDEN RULE',
    bill_type_id: '8'
  },
  {
    id: '393',
    label: 'GPA',
    bill_type_id: '8'
  },
  {
    id: '394',
    label: 'GREAT WEST LIFE',
    bill_type_id: '8'
  },
  {
    id: '395',
    label: 'GREEN COUNTRY CARE CENTER',
    bill_type_id: '8'
  },
  {
    id: '396',
    label: 'GROUP AND PENSION ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '397',
    label: 'GROUP HEALTH COOPERATIVE',
    bill_type_id: '8'
  },
  {
    id: '398',
    label: 'GROUP RESOURCES',
    bill_type_id: '8'
  },
  {
    id: '399',
    label: 'GUARDIAN HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '400',
    label: 'GUARANTEE TRUST LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '401',
    label: 'HAA PREFERRED PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '402',
    label: 'HALIFAX HEALTH',
    bill_type_id: '8'
  },
  {
    id: '403',
    label: 'HAP',
    bill_type_id: '8'
  },
  {
    id: '404',
    label: 'HARMONY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '405',
    label: 'HARTFORD SRS',
    bill_type_id: '8'
  },
  {
    id: '406',
    label: 'HARVARD PILGRIM HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '407',
    label: 'HASTINGS MUTUAL INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '408',
    label: 'HAWAII MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '409',
    label: 'HAWAII HMSA',
    bill_type_id: '8'
  },
  {
    id: '410',
    label: 'HAWAII MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '411',
    label: 'HBC FOX EVERETTE',
    bill_type_id: '8'
  },
  {
    id: '412',
    label: 'HCA SOUTHERN HILLS HOSPITAL',
    bill_type_id: '8'
  },
  {
    id: '413',
    label: 'HCA SOUTHERN HILLS HOSPITAL',
    bill_type_id: '8'
  },
  {
    id: '414',
    label: 'HEALTHCARE MANAGEMENT ADMINISTRATOR',
    bill_type_id: '8'
  },
  {
    id: '415',
    label: 'HEALTH NET ENCOUNTERS',
    bill_type_id: '8'
  },
  {
    id: '416',
    label: 'HEALTH FIRST',
    bill_type_id: '8'
  },
  {
    id: '417',
    label: 'HEALTHLINK HMO',
    bill_type_id: '8'
  },
  {
    id: '418',
    label: 'HEALTH FIRST HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '419',
    label: 'HEALTH PLAN OF NEVADA',
    bill_type_id: '8'
  },
  {
    id: '420',
    label: 'HEALTHGRAM',
    bill_type_id: '8'
  },
  {
    id: '421',
    label: 'HEALTHCARE HIGHWAY HEALTH',
    bill_type_id: '8'
  },
  {
    id: '422',
    label: 'HEALTHCHOICE',
    bill_type_id: '8'
  },
  {
    id: '423',
    label: 'HEALTH ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '424',
    label: 'HEALTH FIRST HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '425',
    label: 'HEALTHGRAM',
    bill_type_id: '8'
  },
  {
    id: '426',
    label: 'HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '427',
    label: 'HEALTH ENTERPRISES',
    bill_type_id: '8'
  },
  {
    id: '428',
    label: 'HEALTH SUN',
    bill_type_id: '8'
  },
  {
    id: '429',
    label: 'HEALTHSPRING DIRECT',
    bill_type_id: '8'
  },
  {
    id: '430',
    label: 'HEALTH NETWORK ONE',
    bill_type_id: '8'
  },
  {
    id: '431',
    label: 'HEALTHSCOPE BENEFITS, INC',
    bill_type_id: '8'
  },
  {
    id: '432',
    label: 'HEALTH SHARE OF OREGON',
    bill_type_id: '8'
  },
  {
    id: '433',
    label: 'HEALTH EXCHANGE',
    bill_type_id: '8'
  },
  {
    id: '434',
    label: 'HEALTH ALLIANCE MEDICAL PLAN',
    bill_type_id: '8'
  },
  {
    id: '435',
    label: 'HEALTHSMART PREFERRED CARE',
    bill_type_id: '8'
  },
  {
    id: '436',
    label: 'HEALTHSPRING OF ALABAMA',
    bill_type_id: '8'
  },
  {
    id: '437',
    label: 'HEALTHSPRING INC',
    bill_type_id: '1'
  },
  {
    id: '438',
    label: 'HEALTHSPRING TENNESSEE',
    bill_type_id: '1'
  },
  {
    id: '439',
    label: 'HEALTHSPRING TEXAS',
    bill_type_id: '8'
  },
  {
    id: '440',
    label: 'HEALTH PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '441',
    label: 'HEALTH NETWORK',
    bill_type_id: '8'
  },
  {
    id: '442',
    label: 'HEALTH DESIGN PLUS',
    bill_type_id: '8'
  },
  {
    id: '443',
    label: 'HEALTH FIRST HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '444',
    label: 'HEALTHCOMP INC',
    bill_type_id: '8'
  },
  {
    id: '445',
    label: 'HEALTH PLAN OF SAN JOAQUIN',
    bill_type_id: '8'
  },
  {
    id: '446',
    label: 'HEALTH ADVANTAGE BCCCP',
    bill_type_id: '8'
  },
  {
    id: '447',
    label: 'HEALTH COST SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '448',
    label: 'HEALTHCHOICE',
    bill_type_id: '8'
  },
  {
    id: '449',
    label: 'HEALTHCARE SOLUTIONS GROUP',
    bill_type_id: '8'
  },
  {
    id: '450',
    label: 'HEALTHLINK PPO',
    bill_type_id: '8'
  },
  {
    id: '451',
    label: 'HEALTHNET OF THE NORTHEAST',
    bill_type_id: '8'
  },
  {
    id: '452',
    label: 'HEALTH CHOICE GENERATIONS',
    bill_type_id: '8'
  },
  {
    id: '453',
    label: 'HEALTH FIRST TPA',
    bill_type_id: '8'
  },
  {
    id: '454',
    label: 'HEALTH NETWORK ONE',
    bill_type_id: '8'
  },
  {
    id: '455',
    label: 'HEALTH ALLIANCE PLAN',
    bill_type_id: '8'
  },
  {
    id: '456',
    label: 'HEALTH SCOPE BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '457',
    label: 'HEALTH PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '458',
    label: 'HEALTH NEW ENGLAND',
    bill_type_id: '8'
  },
  {
    id: '459',
    label: 'HEALTH EZ',
    bill_type_id: '8'
  },
  {
    id: '460',
    label: 'HEALTHSCOPE BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '461',
    label: 'HEALTHSMART BENEFIT SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '462',
    label: 'HEALTH CHOICE UTAH',
    bill_type_id: '8'
  },
  {
    id: '463',
    label: 'HEALTH NET',
    bill_type_id: '8'
  },
  {
    id: '464',
    label: 'HEALTHY BLUE',
    bill_type_id: '8'
  },
  {
    id: '465',
    label: 'HEALTHCHOICE',
    bill_type_id: '8'
  },
  {
    id: '466',
    label: 'HEALTH SUN HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '467',
    label: 'HEALTHKEEPERS',
    bill_type_id: '8'
  },
  {
    id: '468',
    label: 'HEALTHSMART BENEFIT SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '469',
    label: 'HEALTHTEAM ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '470',
    label: 'HEALTHTEAM ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '471',
    label: 'HEALTHDEPOT CO-ORDINATED BE',
    bill_type_id: '8'
  },
  {
    id: '472',
    label: 'HEALTH AND FAMILY SERVICES',
    bill_type_id: '8'
  },
  {
    id: '473',
    label: 'HEARTLAND NATIONAL LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '474',
    label: 'HERITAGE NATIONAL HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '475',
    label: 'HFN',
    bill_type_id: '8'
  },
  {
    id: '476',
    label: 'HIGHMARK',
    bill_type_id: '8'
  },
  {
    id: '477',
    label: 'HILSBOROUGH COUNTY',
    bill_type_id: '8'
  },
  {
    id: '478',
    label: 'HMA HAWAII',
    bill_type_id: '8'
  },
  {
    id: '479',
    label: 'HMA',
    bill_type_id: '8'
  },
  {
    id: '480',
    label: 'HMA - HEALTH MANAGEMENT ADMINISTRAT',
    bill_type_id: '8'
  },
  {
    id: '481',
    label: 'HMO BLUE',
    bill_type_id: '8'
  },
  {
    id: '482',
    label: 'HOMESTATE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '483',
    label: 'HOMETOWN HEALTH',
    bill_type_id: '8'
  },
  {
    id: '484',
    label: 'HOOSIER HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '485',
    label: 'HOP ADMINISTRATION',
    bill_type_id: '8'
  },
  {
    id: '486',
    label: 'HOPKINS HEALTH ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '487',
    label: 'HORIZON NJ HEALTH',
    bill_type_id: '8'
  },
  {
    id: '488',
    label: 'HORIZON BLUE SHIELD OF NJ FEP',
    bill_type_id: '8'
  },
  {
    id: '489',
    label: 'HORIZON BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '490',
    label: 'HEALTHPARTNERS, INC',
    bill_type_id: '8'
  },
  {
    id: '491',
    label: 'HPN VHS EMPLOYEES',
    bill_type_id: '8'
  },
  {
    id: '492',
    label: 'COVID19 HRSA UNINSURED T&TF',
    bill_type_id: '8'
  },
  {
    id: '493',
    label: 'HSA HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '494',
    label: 'HUMANA',
    bill_type_id: '8'
  },
  {
    id: '495',
    label: 'MEDICARE',
    bill_type_id: '8'
  },
  {
    id: '496',
    label: 'HUMANA GOLD PLAN',
    bill_type_id: '8'
  },
  {
    id: '497',
    label: 'HUMANA GROUP HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '498',
    label: 'HUMANA HEALTH BENEFIT PLAN OF LOUIS',
    bill_type_id: '8'
  },
  {
    id: '499',
    label: 'HUMANA HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '500',
    label: 'IBA-MULTIPLAN',
    bill_type_id: '8'
  },
  {
    id: '501',
    label: 'IBG INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '502',
    label: 'INTEGRA BMS',
    bill_type_id: '8'
  },
  {
    id: '503',
    label: 'IDAHO BLUE SHIELD REGENCE',
    bill_type_id: '8'
  },
  {
    id: '504',
    label: 'IDAHO MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '505',
    label: 'IDAHO BLUE CROSS BOISE',
    bill_type_id: '8'
  },
  {
    id: '506',
    label: 'IDEALCARE',
    bill_type_id: '8'
  },
  {
    id: '507',
    label: 'ILL DEPT OF HEALTHCARE & FAMILY',
    bill_type_id: '8'
  },
  {
    id: '508',
    label: 'ILLINOIS BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '509',
    label: 'ILLINOIS MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '510',
    label: 'ILLINOIS MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '511',
    label: 'ILLINICARE GROUP HEALTH',
    bill_type_id: '8'
  },
  {
    id: '512',
    label: 'IMA INC',
    bill_type_id: '8'
  },
  {
    id: '513',
    label: 'IMG MEDICAL CLIAMS',
    bill_type_id: '8'
  },
  {
    id: '514',
    label: 'IMAGINE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '515',
    label: 'IMPERIAL INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '516',
    label: 'INDIANA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '517',
    label: 'INDIANA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '518',
    label: 'INDIANA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '519',
    label: 'INDEPENDENCE ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '520',
    label: 'INDIVIDUAL HEALTH INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '521',
    label: 'INDIAN HEALTH SERVICES',
    bill_type_id: '8'
  },
  {
    id: '522',
    label: 'INDEPENDENCE KEYSTONE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '523',
    label: 'INDEPENDENCE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '524',
    label: 'INDIVIDUAL ASSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '525',
    label: 'INGHAM HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '526',
    label: 'INLAND EMPIRE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '527',
    label: 'INNOVAGE GREATER COLORADO',
    bill_type_id: '8'
  },
  {
    id: '528',
    label: 'INNOVATIVE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '529',
    label: 'INTERCOMMUNITY HEALTH NET',
    bill_type_id: '8'
  },
  {
    id: '530',
    label: 'INTEGRANET',
    bill_type_id: '8'
  },
  {
    id: '531',
    label: 'INTERGROUP SERVICES CORPORATION',
    bill_type_id: '8'
  },
  {
    id: '532',
    label: 'INTERNATIONAL MEDICAL GROUP',
    bill_type_id: '8'
  },
  {
    id: '533',
    label: 'IOWA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '534',
    label: 'IOWA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '535',
    label: 'IOWA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '536',
    label: 'IOWA TOTAL CARE',
    bill_type_id: '8'
  },
  {
    id: '537',
    label: 'MEDICARE IU HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '538',
    label: 'IU HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '539',
    label: 'JACKSON MEMORIAL HEALTH PLAN',
    bill_type_id: '2'
  },
  {
    id: '540',
    label: 'JACKSON MEMORIAL HEALTH MEDICAID',
    bill_type_id: '8'
  },
  {
    id: '541',
    label: 'JOHN DEERE HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '542',
    label: 'JFP BENEFIT',
    bill_type_id: '8'
  },
  {
    id: '543',
    label: 'JOHNS HOPKINS USFHP',
    bill_type_id: '8'
  },
  {
    id: '544',
    label: 'JOYRICH HEALTH CARE CENTERS',
    bill_type_id: '8'
  },
  {
    id: '545',
    label: 'KAISER PERMANENTE OF S CA REGION',
    bill_type_id: '8'
  },
  {
    id: '546',
    label: 'KAISER PERMANENTE OF GEORGIA',
    bill_type_id: '8'
  },
  {
    id: '547',
    label: 'KAISER PERMANENTE OF THE NORTHWEST',
    bill_type_id: '8'
  },
  {
    id: '548',
    label: 'KAISER PERMANENTE OF COLORADO',
    bill_type_id: '8'
  },
  {
    id: '549',
    label: 'KAISER PERMANENTE',
    bill_type_id: '8'
  },
  {
    id: '550',
    label: 'KAISER PERMANENTE OF N CA REGION',
    bill_type_id: '8'
  },
  {
    id: '551',
    label: 'KAISER PERMANENTE OF COLORADO',
    bill_type_id: '8'
  },
  {
    id: '552',
    label: 'KAISER PERMANETE COLORADO',
    bill_type_id: '8'
  },
  {
    id: '553',
    label: 'KAISER FOUNDATION HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '554',
    label: 'KANSAS MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '555',
    label: 'KANSAS BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '556',
    label: 'KANSAS MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '557',
    label: 'KANSAS CITY BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '558',
    label: 'KANSAS HEALTH ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '559',
    label: 'KANSAS BUILDING TRADES HEALTH',
    bill_type_id: '8'
  },
  {
    id: '560',
    label: 'KELSEY SEYBOLD',
    bill_type_id: '8'
  },
  {
    id: '561',
    label: 'KEMPTON',
    bill_type_id: '8'
  },
  {
    id: '562',
    label: 'KEMPTOM GROUP ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '563',
    label: 'KENTUCKY MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '564',
    label: 'KENTUCKY BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '565',
    label: 'KENTUCKY MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '566',
    label: 'KENTUCKY HEALTH ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '567',
    label: 'KEYSTONE MERCY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '568',
    label: 'KEY BENEFIT ADMINISTRATORS (KBA)',
    bill_type_id: '8'
  },
  {
    id: '569',
    label: 'KICKAPOO TRIBE OF TEXAS',
    bill_type_id: '8'
  },
  {
    id: '570',
    label: 'LACARE',
    bill_type_id: '8'
  },
  {
    id: '571',
    label: 'HMO LOUISIANA BLUE ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '572',
    label: 'LIBERTY MUTUAL',
    bill_type_id: '8'
  },
  {
    id: '573',
    label: 'LIBERTY HEALTHSHARE',
    bill_type_id: '8'
  },
  {
    id: '574',
    label: 'LIFEPRINT',
    bill_type_id: '8'
  },
  {
    id: '575',
    label: 'LIFEWISE OF WASHINGTON',
    bill_type_id: '8'
  },
  {
    id: '576',
    label: 'LIFESTYLE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '577',
    label: 'LIFEWORKS ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '578',
    label: 'LIFE AT LOURDES',
    bill_type_id: '8'
  },
  {
    id: '579',
    label: 'LIFE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '580',
    label: 'LIGHTHOUSE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '581',
    label: 'LINCOLN HERITAGE',
    bill_type_id: '8'
  },
  {
    id: '582',
    label: 'LOCKARD AND WILLIAMS',
    bill_type_id: '8'
  },
  {
    id: '583',
    label: 'LOOMIS COMPANY',
    bill_type_id: '8'
  },
  {
    id: '584',
    label: 'LOUISIANA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '585',
    label: 'LOUISIANA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '586',
    label: 'LOUISIANA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '587',
    label: 'LOUISIANA HEALTH CONNECTIONS',
    bill_type_id: '8'
  },
  {
    id: '588',
    label: 'LOUISIANA HEALTH COOPERATIVE',
    bill_type_id: '8'
  },
  {
    id: '589',
    label: 'LOVELACE SALUD',
    bill_type_id: '8'
  },
  {
    id: '590',
    label: 'LOYAL AMERICAN LIFE',
    bill_type_id: '8'
  },
  {
    id: '591',
    label: 'LTC INC  PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '592',
    label: 'LUMICO',
    bill_type_id: '8'
  },
  {
    id: '593',
    label: 'MAESTRO HEALTH',
    bill_type_id: '8'
  },
  {
    id: '594',
    label: 'MAGNACARE',
    bill_type_id: '8'
  },
  {
    id: '595',
    label: 'MAGNOLIA HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '596',
    label: 'MAGELLAN COMPLETE CARE',
    bill_type_id: '8'
  },
  {
    id: '597',
    label: 'MAGELLAN COMPLETE CARE',
    bill_type_id: '8'
  },
  {
    id: '598',
    label: 'MAGNOLIA HEALTH PLAN',
    bill_type_id: '2'
  },
  {
    id: '599',
    label: 'MAIL HANDLERS BENEFIT PLAN',
    bill_type_id: '8'
  },
  {
    id: '600',
    label: 'MAINE MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '601',
    label: 'MAINE BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '602',
    label: 'MARYLAND BLUE SHIELD CAREFIRST',
    bill_type_id: '8'
  },
  {
    id: '603',
    label: 'MANAGED CARE SERVICES',
    bill_type_id: '8'
  },
  {
    id: '604',
    label: 'MANAGED HEALTH SERVICES',
    bill_type_id: '2'
  },
  {
    id: '605',
    label: 'MANHATTAN LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '606',
    label: 'MARYLAND MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '607',
    label: 'MARYLAND BLUE SHIELD CAREFIRST',
    bill_type_id: '8'
  },
  {
    id: '608',
    label: 'MARYLAND PHYSICIANS CARE',
    bill_type_id: '8'
  },
  {
    id: '609',
    label: 'MARTINS POINT HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '610',
    label: 'MARYLAND MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '611',
    label: 'MASSACHUSETTS BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '612',
    label: 'MASSACHUSETTS MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '613',
    label: 'MASSACHUSETTS MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '614',
    label: 'MAYO MANAGEMENT SERVICES',
    bill_type_id: '8'
  },
  {
    id: '615',
    label: 'MAYO CLINIC HEALTH SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '616',
    label: 'MAYO HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '617',
    label: 'MCGHI SELECT PLAN',
    bill_type_id: '8'
  },
  {
    id: '618',
    label: 'MCHD',
    bill_type_id: '8'
  },
  {
    id: '619',
    label: 'MCLAREN HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '620',
    label: 'MCLAREN HEALTH ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '621',
    label: 'Blue Cross Medicare Advantage',
    bill_type_id: '8'
  },
  {
    id: '622',
    label: 'MDICAL MUTUAL OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '623',
    label: 'MDWISE HEALTHY INDIANA PLAN',
    bill_type_id: '8'
  },
  {
    id: '624',
    label: 'MEDICAID MAGELLAN PSYCH NETWORK',
    bill_type_id: '8'
  },
  {
    id: '625',
    label: 'MEDICA',
    bill_type_id: '8'
  },
  {
    id: '626',
    label: 'MEDCOST BENEFIT SERVICES',
    bill_type_id: '8'
  },
  {
    id: '627',
    label: 'MEDICO INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '628',
    label: 'MEDICARE ADVANTAGE DME',
    bill_type_id: '8'
  },
  {
    id: '629',
    label: 'MEDICA',
    bill_type_id: '8'
  },
  {
    id: '630',
    label: 'MEDICARE PLUS BLUE',
    bill_type_id: '8'
  },
  {
    id: '631',
    label: 'MEDICAL ASSOCIATES HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '632',
    label: 'MEDICAL MUTUAL OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '633',
    label: 'MEDIGOLD HMO',
    bill_type_id: '8'
  },
  {
    id: '634',
    label: 'MED3000 HEALTH SELECT',
    bill_type_id: '8'
  },
  {
    id: '635',
    label: 'MEDSTAR FAMILY CHOICE',
    bill_type_id: '8'
  },
  {
    id: '636',
    label: 'MEDPARTNERS ADMINISTRATIVE SERVICES',
    bill_type_id: '8'
  },
  {
    id: '637',
    label: 'MEDICAL VALUE PLAN',
    bill_type_id: '8'
  },
  {
    id: '638',
    label: 'MEDCOST',
    bill_type_id: '8'
  },
  {
    id: '639',
    label: 'MEDICARE DME MAC JURISDICTION B',
    bill_type_id: '1'
  },
  {
    id: '640',
    label: 'MEDICA',
    bill_type_id: '8'
  },
  {
    id: '641',
    label: 'MEDCO CONTAINMENT LIFE AND MEDCO CO',
    bill_type_id: '8'
  },
  {
    id: '642',
    label: 'MEDI SHARE',
    bill_type_id: '8'
  },
  {
    id: '643',
    label: 'MEDIVEST',
    bill_type_id: '8'
  },
  {
    id: '644',
    label: 'MEDICO CORP',
    bill_type_id: '8'
  },
  {
    id: '645',
    label: 'MEDICA GOLD PLUS',
    bill_type_id: '8'
  },
  {
    id: '646',
    label: 'MEDISHARE',
    bill_type_id: '8'
  },
  {
    id: '647',
    label: 'MEDIPAK',
    bill_type_id: '8'
  },
  {
    id: '648',
    label: 'MEDIPLUS',
    bill_type_id: '8'
  },
  {
    id: '649',
    label: 'MEDICAL ASSISTANCE',
    bill_type_id: '8'
  },
  {
    id: '650',
    label: 'MEDBEN',
    bill_type_id: '8'
  },
  {
    id: '651',
    label: 'MEMORIAL INTEGRATED HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '652',
    label: 'MEMORIAL HERMANN',
    bill_type_id: '8'
  },
  {
    id: '653',
    label: 'MERITAIN HEALTH',
    bill_type_id: '8'
  },
  {
    id: '654',
    label: 'MERCY CARE PLAN OF ARIZONA',
    bill_type_id: '8'
  },
  {
    id: '655',
    label: 'MERIDIAN HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '656',
    label: 'MERCY CARE',
    bill_type_id: '8'
  },
  {
    id: '657',
    label: 'MERIDIAN HEALTH PLAN OF ILLINOIS',
    bill_type_id: '8'
  },
  {
    id: '658',
    label: 'MERITAIN HEALTH MINNEAPOLIS',
    bill_type_id: '8'
  },
  {
    id: '659',
    label: 'MERIBEN',
    bill_type_id: '8'
  },
  {
    id: '660',
    label: 'METRO PLUS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '661',
    label: 'MHBP',
    bill_type_id: '8'
  },
  {
    id: '662',
    label: 'MHEALTH',
    bill_type_id: '8'
  },
  {
    id: '663',
    label: 'MIAMI CHILDRENS',
    bill_type_id: '8'
  },
  {
    id: '664',
    label: 'MICHIGAN HEALTH BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '665',
    label: 'MICHIGAN BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '666',
    label: 'MICHIGAN MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '667',
    label: 'MICHIGAN MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '668',
    label: 'MICHIGAN BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '669',
    label: 'MIDLANDS CHOICE',
    bill_type_id: '8'
  },
  {
    id: '670',
    label: 'MIDWEST EMPLOYERS CASUALTY',
    bill_type_id: '8'
  },
  {
    id: '671',
    label: 'MINNESOTA BLUE PLUS',
    bill_type_id: '8'
  },
  {
    id: '672',
    label: 'MINNESOTA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '673',
    label: 'MISSOURI BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '674',
    label: 'MISSOURI MEDICARE EASTERN',
    bill_type_id: '1'
  },
  {
    id: '675',
    label: 'MISSOURI MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '676',
    label: 'MISSOURI CARE',
    bill_type_id: '8'
  },
  {
    id: '677',
    label: 'MISSISSIPPI MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '678',
    label: 'MISSISSIPPI PHYSICIANS CARE NETWORK',
    bill_type_id: '8'
  },
  {
    id: '679',
    label: 'MISSISSIPPI BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '680',
    label: 'MISSISSIPPI MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '681',
    label: 'MO HEALTHNET',
    bill_type_id: '8'
  },
  {
    id: '682',
    label: 'MOAA MEDIPLUS',
    bill_type_id: '8'
  },
  {
    id: '683',
    label: 'MODA HEALTH',
    bill_type_id: '8'
  },
  {
    id: '684',
    label: 'MOLINA HEALTHCARE OF WASHINGTON',
    bill_type_id: '8'
  },
  {
    id: '685',
    label: 'MOLINA HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '686',
    label: 'MOLINA HEALTHCARE OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '687',
    label: 'MOLINA HEALTHCARE OF NEW MEXICO',
    bill_type_id: '8'
  },
  {
    id: '688',
    label: 'MOLINA HEALTHCARE OF TEXAS',
    bill_type_id: '8'
  },
  {
    id: '689',
    label: 'MOLINA HEALTHCARE OF UTAH',
    bill_type_id: '8'
  },
  {
    id: '690',
    label: 'MOLINA HEALTHCARE OF FLORIDA',
    bill_type_id: '8'
  },
  {
    id: '691',
    label: 'MOLINA HEALTHCARE OF MICHIGAN',
    bill_type_id: '8'
  },
  {
    id: '692',
    label: 'MOLINA HEALTHCARE OF ILLINOIS',
    bill_type_id: '8'
  },
  {
    id: '693',
    label: 'MOLINA HEALTHCARE OF SOUTH CAROLINA',
    bill_type_id: '8'
  },
  {
    id: '694',
    label: 'MOLINA HEALTHCARE OF MISSISSIPPI',
    bill_type_id: '8'
  },
  {
    id: '695',
    label: 'MOLINA HEALTHCARE OF OHIO',
    bill_type_id: '8'
  },
  {
    id: '696',
    label: 'MOLINA HEALTHCARE OF NEW MEXICO',
    bill_type_id: '8'
  },
  {
    id: '697',
    label: 'MONTANA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '698',
    label: 'MONTANA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '699',
    label: 'MONUMENTAL LIFE',
    bill_type_id: '8'
  },
  {
    id: '700',
    label: 'MPE SERVICES INC',
    bill_type_id: '8'
  },
  {
    id: '701',
    label: 'MPEEBT',
    bill_type_id: '8'
  },
  {
    id: '702',
    label: 'MUNICIPAL HEALTH BENEFIT FUND',
    bill_type_id: '8'
  },
  {
    id: '703',
    label: 'MUTUAL OF OMAHA',
    bill_type_id: '8'
  },
  {
    id: '704',
    label: 'MUTUAL ASSURANCE ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '705',
    label: 'MVP HEALTH PLANS OF NEW YORK',
    bill_type_id: '8'
  },
  {
    id: '706',
    label: 'NALC HEALTH BENEFIT PLAN',
    bill_type_id: '8'
  },
  {
    id: '707',
    label: 'NALC HEALTH BENEFIT PLAN',
    bill_type_id: '8'
  },
  {
    id: '708',
    label: 'NATIONAL HEALTH ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '709',
    label: 'NATIONAL HEALTH BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '710',
    label: 'NATIONAL ASSOCIATION OF LETTER',
    bill_type_id: '8'
  },
  {
    id: '711',
    label: 'NATIONWIDE HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '712',
    label: 'NATIONAL GENERAL INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '713',
    label: 'NATIONAL GOVERNMENT SERVICES INC',
    bill_type_id: '8'
  },
  {
    id: '714',
    label: 'NATIONAL AUTOMATIC SPRINKLER INDUST',
    bill_type_id: '8'
  },
  {
    id: '715',
    label: 'CAREFIRST ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '716',
    label: 'NEBRASKA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '717',
    label: 'NEBRASKA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '718',
    label: 'NEBRASKA TOTAL CARE',
    bill_type_id: '8'
  },
  {
    id: '719',
    label: 'NECA/IBEU FAMILY MEDICAL CARE PLAN',
    bill_type_id: '8'
  },
  {
    id: '720',
    label: 'NEIGHBORHOOD HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '721',
    label: 'NEIGHBORHOOD HEALTH PARTNERSHIP',
    bill_type_id: '8'
  },
  {
    id: '722',
    label: 'NEIGHBORHOOD HEALTH PLAN RI',
    bill_type_id: '8'
  },
  {
    id: '723',
    label: 'NEIGHBORHOOD HEALTH PLAN OF RHODE I',
    bill_type_id: '8'
  },
  {
    id: '724',
    label: 'NETWORK HEALTH PLAN OF WISCONSIN',
    bill_type_id: '8'
  },
  {
    id: '725',
    label: 'NETWORK HEALTH',
    bill_type_id: '8'
  },
  {
    id: '726',
    label: 'NETWORK HEALTH MA PLANS',
    bill_type_id: '8'
  },
  {
    id: '727',
    label: 'NEVADA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '728',
    label: 'NEVADA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '729',
    label: 'NEVADA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '730',
    label: 'NEW YORK MEDICAID PHASE II',
    bill_type_id: '2'
  },
  {
    id: '731',
    label: 'NEW MEXICO MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '732',
    label: 'NEW JERSEY MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '733',
    label: 'NEW ERA LIFE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '734',
    label: 'NEW JERSEY MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '735',
    label: 'NEW MEXICO MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '736',
    label: 'NEW YORK STATE INSURANCE FUND',
    bill_type_id: '8'
  },
  {
    id: '737',
    label: 'NEW JERSEY BLUE CHOICE',
    bill_type_id: '8'
  },
  {
    id: '738',
    label: 'NEW JERSEY BLUE SELECT',
    bill_type_id: '8'
  },
  {
    id: '739',
    label: 'NEW YORK BLUE SHIELD  CENTRAL',
    bill_type_id: '8'
  },
  {
    id: '740',
    label: 'NEW YORK EMPIRE BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '741',
    label: 'NEW MEXICO BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '742',
    label: 'NEW HAMPSHIRE BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '743',
    label: 'NEW HAMPSHIRE MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '744',
    label: 'New Mexico Health Connections',
    bill_type_id: '8'
  },
  {
    id: '745',
    label: 'NEXCALIBER',
    bill_type_id: '8'
  },
  {
    id: '746',
    label: 'NHC HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '747',
    label: 'NIPPON LIFE INSURANCE CO OF AMERICA',
    bill_type_id: '8'
  },
  {
    id: '748',
    label: 'NORTH CAROLINA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '749',
    label: 'NORTH CAROLINA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '750',
    label: 'NORTH CAROLINA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '751',
    label: 'NORTH DAKOTA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '752',
    label: 'NORTHERN ILLINOIS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '753',
    label: 'NORTH DAKOTA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '754',
    label: 'NORTHSHORE PHYSICIANS ASSOCIATES',
    bill_type_id: '8'
  },
  {
    id: '755',
    label: 'NORTH CAROLINA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '756',
    label: 'NY EMPLIRE',
    bill_type_id: '8'
  },
  {
    id: '757',
    label: 'OAKRIDGE COMMUNITY CARE',
    bill_type_id: '8'
  },
  {
    id: '758',
    label: 'ODS HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '759',
    label: 'OHANA HEALTH PLAN HAWAII',
    bill_type_id: '8'
  },
  {
    id: '760',
    label: 'OHIO MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '761',
    label: 'OHIO BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '762',
    label: 'OHIO MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '763',
    label: 'OHIO PPO CONNECT',
    bill_type_id: '8'
  },
  {
    id: '764',
    label: "OHIO BUREAU OF WORKER'S COMPENSA",
    bill_type_id: '8'
  },
  {
    id: '765',
    label: 'OKLAHOMA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '766',
    label: 'OKLAHOMA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '767',
    label: 'OKLAHOMA STATE MEDICAL ASSOCIATION',
    bill_type_id: '8'
  },
  {
    id: '768',
    label: 'OKLAHOMA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '769',
    label: 'OKLAHOMA HEALTHCARE AUTHORITY',
    bill_type_id: '8'
  },
  {
    id: '770',
    label: 'OKLAHOMA SUPERIOR SELECT, INC',
    bill_type_id: '8'
  },
  {
    id: '771',
    label: 'OLD SURETY LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '772',
    label: 'OMNICARE',
    bill_type_id: '8'
  },
  {
    id: '773',
    label: 'ONESOURCE ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '774',
    label: 'OPERATING ENGINEERS HEALTH AND WELF',
    bill_type_id: '8'
  },
  {
    id: '775',
    label: 'OPTIMUM HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '776',
    label: 'OPTUM HEALTH SPECIALTY BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '777',
    label: 'OPTIMA INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '778',
    label: 'OPTIMA HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '779',
    label: 'OPTUM CARE',
    bill_type_id: '8'
  },
  {
    id: '780',
    label: 'OREGON MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '781',
    label: 'OREGON MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '782',
    label: 'OREGON BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '783',
    label: 'OREGON HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '784',
    label: 'OSCAR',
    bill_type_id: '8'
  },
  {
    id: '785',
    label: 'OXFORD HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '786',
    label: 'OXFORD HEALTH UHC',
    bill_type_id: '8'
  },
  {
    id: '787',
    label: 'PA HEALTH & WELLNESS',
    bill_type_id: '8'
  },
  {
    id: '788',
    label: 'PACIFICARE ENCOUNTERS',
    bill_type_id: '8'
  },
  {
    id: '789',
    label: 'PACIFICSOURCE HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '790',
    label: 'PACIFICSOURCE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '791',
    label: 'PACIFICSOURCE MEDICARE',
    bill_type_id: '8'
  },
  {
    id: '792',
    label: 'PACE OF THE TRIAD',
    bill_type_id: '8'
  },
  {
    id: '793',
    label: 'PACE BATON ROUGE',
    bill_type_id: '8'
  },
  {
    id: '794',
    label: 'PACIFICARE OF COLORADO',
    bill_type_id: '8'
  },
  {
    id: '795',
    label: 'PACIFICSOURCE COMMUNITY SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '796',
    label: 'PAI',
    bill_type_id: '8'
  },
  {
    id: '797',
    label: 'PAN AMERICAN LIFE INSURANCE GROUP',
    bill_type_id: '8'
  },
  {
    id: '798',
    label: 'PARKLAND COMMUNITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '799',
    label: 'PARAMOUNT',
    bill_type_id: '8'
  },
  {
    id: '800',
    label: 'PARTNERSHIP HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '801',
    label: 'PARTNERS HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '802',
    label: 'PASSPORT HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '803',
    label: 'PEACH STATE HEALTH PLAN',
    bill_type_id: '2'
  },
  {
    id: '804',
    label: 'PEAK PACE SOLUTIONS LLC',
    bill_type_id: '8'
  },
  {
    id: '805',
    label: 'PEBA HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '806',
    label: 'PEHP',
    bill_type_id: '8'
  },
  {
    id: '807',
    label: 'PEKIN INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '808',
    label: 'PENNSYLVANIA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '809',
    label: 'PENNSYLVANIA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '810',
    label: 'PENNSYLVANIA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '811',
    label: 'PEOPLE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '812',
    label: 'PERFERRED BLUE - PHCS',
    bill_type_id: '8'
  },
  {
    id: '813',
    label: 'PHCS SAVILITY',
    bill_type_id: '8'
  },
  {
    id: '814',
    label: 'PHCS - AMERICAN BUSINESS COALITION',
    bill_type_id: '8'
  },
  {
    id: '815',
    label: 'PHILADELPHIA AMERICAN LIFE INSURANC',
    bill_type_id: '8'
  },
  {
    id: '816',
    label: 'PHP OF MID-MICHIGAN',
    bill_type_id: '8'
  },
  {
    id: '817',
    label: 'PHP KANSAS CITY',
    bill_type_id: '8'
  },
  {
    id: '818',
    label: 'PHYSICIANS HEALTH CHOICE',
    bill_type_id: '8'
  },
  {
    id: '819',
    label: 'PHYSICIANS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '820',
    label: 'PHYSICIANS MUTUAL INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '821',
    label: 'PHYSICIANS CARE NETWORK',
    bill_type_id: '8'
  },
  {
    id: '822',
    label: 'PHYSICIANS LIFE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '823',
    label: 'PHYSICIANS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '824',
    label: 'PIEDMONT ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '825',
    label: 'ARIZONA BLUE SHIELD MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '826',
    label: 'PINELLAS COUNTY',
    bill_type_id: '8'
  },
  {
    id: '827',
    label: 'PLANNED ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '828',
    label: 'PLUMBERS AND STEAMFITTERS',
    bill_type_id: '8'
  },
  {
    id: '829',
    label: 'PODIATRY NETWORK OF FLORIDA',
    bill_type_id: '8'
  },
  {
    id: '830',
    label: 'PPO PLUS LLC',
    bill_type_id: '8'
  },
  {
    id: '831',
    label: 'PRESTIGE HEALTH CHOICE',
    bill_type_id: '8'
  },
  {
    id: '832',
    label: 'PREFERRED CARE PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '833',
    label: 'PRESBYTERIAN',
    bill_type_id: '8'
  },
  {
    id: '834',
    label: 'PREFERRED BLUE',
    bill_type_id: '8'
  },
  {
    id: '835',
    label: 'PREFERRED ONE',
    bill_type_id: '8'
  },
  {
    id: '836',
    label: 'PRESBYTERIAN SALUD',
    bill_type_id: '8'
  },
  {
    id: '837',
    label: 'PRESBYTERIAN HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '838',
    label: 'PREMERA BLUE CROSS',
    bill_type_id: '8'
  },
  {
    id: '839',
    label: 'PREFERRED COMMUNITY CHOICE',
    bill_type_id: '8'
  },
  {
    id: '840',
    label: 'PREFERRED UNITED PLANS',
    bill_type_id: '8'
  },
  {
    id: '841',
    label: 'PREMIER ACCESS',
    bill_type_id: '8'
  },
  {
    id: '842',
    label: 'PRIMESOURCE HEALTH NETWORK',
    bill_type_id: '8'
  },
  {
    id: '843',
    label: 'PRIMETIME HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '844',
    label: 'PRINCIPAL LIFE INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '845',
    label: 'PRIORITY HEALTH',
    bill_type_id: '8'
  },
  {
    id: '846',
    label: 'PROCARE',
    bill_type_id: '8'
  },
  {
    id: '847',
    label: 'PROVIDENCE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '848',
    label: 'PROCARE HEALTH PLAN MEDICAID',
    bill_type_id: '8'
  },
  {
    id: '849',
    label: 'PROVIDERS CARE NETWORK',
    bill_type_id: '8'
  },
  {
    id: '850',
    label: 'PROMINENCE HEALTH PLAN OF NEVADA',
    bill_type_id: '8'
  },
  {
    id: '851',
    label: 'PRUITT HEALTH PREMIER',
    bill_type_id: '1'
  },
  {
    id: '852',
    label: 'PSWA PACIFIC SOUTHWEST ADMINISTRATO',
    bill_type_id: '8'
  },
  {
    id: '853',
    label: 'PUBLIC EMPLOYEE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '854',
    label: 'PURITAIN LIFE',
    bill_type_id: '8'
  },
  {
    id: '855',
    label: 'QCC INSURANCE CO',
    bill_type_id: '8'
  },
  {
    id: '856',
    label: 'QMB GATEWAY TO BETTER HEALTH',
    bill_type_id: '8'
  },
  {
    id: '857',
    label: 'QUALCARE INC',
    bill_type_id: '8'
  },
  {
    id: '858',
    label: 'QUALCHOICE OF ARKANSAS',
    bill_type_id: '8'
  },
  {
    id: '859',
    label: 'QUALITY CARE PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '860',
    label: 'QUARTZ',
    bill_type_id: '8'
  },
  {
    id: '861',
    label: 'QUIKTRIP CORPORATION',
    bill_type_id: '8'
  },
  {
    id: '862',
    label: 'RAILROAD MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '863',
    label: 'RAILROAD MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '864',
    label: 'REGIONAL CARE',
    bill_type_id: '8'
  },
  {
    id: '865',
    label: 'REGENCE BLUE CROSS',
    bill_type_id: '8'
  },
  {
    id: '866',
    label: 'REGENCE GROUP ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '867',
    label: 'RENAISSANCE PHYSICIANS ORGANIZATION',
    bill_type_id: '8'
  },
  {
    id: '868',
    label: 'RESERVE NATIONAL INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '869',
    label: 'RETIRED RAILROAD MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '870',
    label: 'REVCLAIMS',
    bill_type_id: '8'
  },
  {
    id: '871',
    label: 'RHODE ISLAND MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '872',
    label: 'RHODE ISLAND BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '873',
    label: 'RHODE ISLAND MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '874',
    label: 'RIGHTCARE SCOTT AND WHITE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '875',
    label: 'RIVERSIDE PACE',
    bill_type_id: '8'
  },
  {
    id: '876',
    label: 'ROCKY MOUNTAIN HMO',
    bill_type_id: '8'
  },
  {
    id: '877',
    label: 'ROYAL NEIGHBORS',
    bill_type_id: '8'
  },
  {
    id: '878',
    label: 'RUE HEALTH NEW MEXICO',
    bill_type_id: '8'
  },
  {
    id: '879',
    label: 'RYAN WHITE PROGRAM',
    bill_type_id: '8'
  },
  {
    id: '880',
    label: 'S AND S HEALTHCARE STRATEGIES',
    bill_type_id: '8'
  },
  {
    id: '881',
    label: 'SIMPRA ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '882',
    label: 'SAFECO INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '883',
    label: 'SAMBA',
    bill_type_id: '8'
  },
  {
    id: '884',
    label: 'SANFORD HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '885',
    label: 'SANA HEALTH',
    bill_type_id: '8'
  },
  {
    id: '886',
    label: 'SAS MEMORIAL',
    bill_type_id: '8'
  },
  {
    id: '887',
    label: 'SCAN HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '888',
    label: 'SCOTT AND WHITE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '889',
    label: 'SECURE HORIZONS',
    bill_type_id: '8'
  },
  {
    id: '890',
    label: 'SECURITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '891',
    label: 'SECUREONE BENEFIT ADMINISTRATORS, I',
    bill_type_id: '8'
  },
  {
    id: '892',
    label: 'SECURE HORIZONS DIRECT',
    bill_type_id: '8'
  },
  {
    id: '893',
    label: 'SEDGWICK CLAIMS MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '894',
    label: 'SELF PAY',
    bill_type_id: '8'
  },
  {
    id: '895',
    label: 'SELECT BENEFIT ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '896',
    label: 'SELECT HEALTH OF SOUTH CAROLINA',
    bill_type_id: '8'
  },
  {
    id: '897',
    label: 'SELECTCARE OF TEXAS',
    bill_type_id: '8'
  },
  {
    id: '898',
    label: 'SELECT ADMINISTRATIVE SERVICES',
    bill_type_id: '8'
  },
  {
    id: '899',
    label: 'SELECTHEALTH',
    bill_type_id: '8'
  },
  {
    id: '900',
    label: 'SELECTCARE OF MICHIGAN',
    bill_type_id: '8'
  },
  {
    id: '901',
    label: 'SELMAN AND COMPANY',
    bill_type_id: '8'
  },
  {
    id: '902',
    label: 'SELECT HEALTH OF SOUTH CAROLINA',
    bill_type_id: '8'
  },
  {
    id: '903',
    label: 'SELMAN AND COMPANY',
    bill_type_id: '8'
  },
  {
    id: '904',
    label: 'SEMINOLE TRIBE',
    bill_type_id: '8'
  },
  {
    id: '905',
    label: 'SENIOR WHOLE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '906',
    label: 'SENIOR CARE PARTNERS',
    bill_type_id: '8'
  },
  {
    id: '907',
    label: 'SENTRY INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '908',
    label: 'SENDERO HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '909',
    label: 'SENTARA HEALTH MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '910',
    label: 'SETON HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '911',
    label: 'SHASTA ADMINISTRATIVE SERVICES',
    bill_type_id: '8'
  },
  {
    id: '912',
    label: 'SHEET METAL WORKERS HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '913',
    label: 'SELECT HEALTH OF SOUTH CAROLINA INC',
    bill_type_id: '8'
  },
  {
    id: '914',
    label: 'SIERRA HEALTH SERVICES INC',
    bill_type_id: '8'
  },
  {
    id: '915',
    label: 'SIERRA HEALTH SERVICES',
    bill_type_id: '8'
  },
  {
    id: '916',
    label: 'SIERRA HEALTH AND LIFE INSURANCE CO',
    bill_type_id: '8'
  },
  {
    id: '917',
    label: 'SIGNATURE HEALTH ALLIANCE',
    bill_type_id: '8'
  },
  {
    id: '918',
    label: 'SIGNET MARITIME',
    bill_type_id: '8'
  },
  {
    id: '919',
    label: 'SIGNAL MUTUAL INDEMNITY',
    bill_type_id: '8'
  },
  {
    id: '920',
    label: 'SILVERSUMMIT HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '921',
    label: 'SILVER SUMMIT HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '922',
    label: 'SILVER SCRIPT',
    bill_type_id: '8'
  },
  {
    id: '923',
    label: 'SIMPLY HEALTHCARE PLANS',
    bill_type_id: '8'
  },
  {
    id: '924',
    label: 'SISCO',
    bill_type_id: '8'
  },
  {
    id: '925',
    label: 'SMARTCHOICE',
    bill_type_id: '8'
  },
  {
    id: '926',
    label: 'SOLIDARITY HEALTHSHARE',
    bill_type_id: '8'
  },
  {
    id: '927',
    label: 'SOONERCARE',
    bill_type_id: '8'
  },
  {
    id: '928',
    label: 'SOUTH CAROLINA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '929',
    label: 'SOUTH CAROLINA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '930',
    label: 'SOUTH CAROLINA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '931',
    label: 'SOUTH DAKOTA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '932',
    label: 'SOUTH DAKOTA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '933',
    label: 'SOUTHWEST SERVICE LIFE',
    bill_type_id: '8'
  },
  {
    id: '934',
    label: 'SPINA BIFIDA',
    bill_type_id: '8'
  },
  {
    id: '935',
    label: 'STATE EMPLOYEES GROUP BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '936',
    label: 'STAYWELL FLORIDA',
    bill_type_id: '8'
  },
  {
    id: '937',
    label: 'STATE INSURANCE FUND',
    bill_type_id: '8'
  },
  {
    id: '938',
    label: 'STATE FARM INSURANCE COMPANIES',
    bill_type_id: '8'
  },
  {
    id: '939',
    label: 'STARMARK',
    bill_type_id: '8'
  },
  {
    id: '940',
    label: 'STANDARD LIFE ACCIDENT INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '941',
    label: 'STATE FARM INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '942',
    label: "STATE LA PATIENT'S COMPENSATION",
    bill_type_id: '8'
  },
  {
    id: '943',
    label: 'STATE MUTUAL',
    bill_type_id: '8'
  },
  {
    id: '944',
    label: 'STATE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '945',
    label: 'STEWARD HEALTH CHOICE',
    bill_type_id: '8'
  },
  {
    id: '946',
    label: 'STIRLING AND STIRLING',
    bill_type_id: '8'
  },
  {
    id: '947',
    label: 'STUDENT INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '948',
    label: 'SUMMACARE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '949',
    label: 'SUMMIT HOLDINGS',
    bill_type_id: '8'
  },
  {
    id: '950',
    label: 'SUNSHINE STATE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '951',
    label: 'SUNFLOWER STATE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '952',
    label: 'SUNFLOWER HEALTH PLAN KS',
    bill_type_id: '8'
  },
  {
    id: '953',
    label: 'SUNSHINE HEALTH',
    bill_type_id: '8'
  },
  {
    id: '954',
    label: 'SUPERIOR HEALTH PLAN',
    bill_type_id: '2'
  },
  {
    id: '955',
    label: 'SUPERIOR SELECT',
    bill_type_id: '8'
  },
  {
    id: '956',
    label: 'TALL TREE ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '957',
    label: 'TAYLOR BENEFIT RESOURCE',
    bill_type_id: '8'
  },
  {
    id: '958',
    label: 'TBD',
    bill_type_id: '8'
  },
  {
    id: '959',
    label: 'TEACHERS HEALTH TRUST',
    bill_type_id: '8'
  },
  {
    id: '960',
    label: 'TEAMSTERS HEALTH BENEFITS FUND',
    bill_type_id: '8'
  },
  {
    id: '961',
    label: 'TEAMCARE',
    bill_type_id: '8'
  },
  {
    id: '962',
    label: 'TENNESSEE MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '963',
    label: 'TENNESSEE BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '964',
    label: 'TENNESSEE BLUECARE',
    bill_type_id: '2'
  },
  {
    id: '965',
    label: 'TENNESSEE MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '966',
    label: 'TENNCARE MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '967',
    label: 'TEXAS WORKERS COMPENSATION',
    bill_type_id: '8'
  },
  {
    id: '968',
    label: 'TEXAS BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '969',
    label: 'TEXAS MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '970',
    label: 'TEXAS MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '971',
    label: 'TEXAS CHILDRENS HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '972',
    label: 'TEXAS HEALTHSPRING',
    bill_type_id: '8'
  },
  {
    id: '973',
    label: 'TEXAN PLUS SOUTHEAST',
    bill_type_id: '8'
  },
  {
    id: '974',
    label: 'THE LOOMIS COMPANY',
    bill_type_id: '8'
  },
  {
    id: '975',
    label: 'THERA CARE',
    bill_type_id: '8'
  },
  {
    id: '976',
    label: 'THIRD PARTY ADMINISTRATORS',
    bill_type_id: '8'
  },
  {
    id: '977',
    label: 'THOMAS COOPER AGENCY',
    bill_type_id: '8'
  },
  {
    id: '978',
    label: 'THREE RIVERS PREFERRED PLUS',
    bill_type_id: '8'
  },
  {
    id: '979',
    label: 'TLC BENEFIT SOLUTIONS',
    bill_type_id: '8'
  },
  {
    id: '980',
    label: 'TLC ADVANTAGE IN SIOUX FALLS',
    bill_type_id: '8'
  },
  {
    id: '981',
    label: 'TML HEALTH BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '982',
    label: 'TOTAL HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '983',
    label: 'TRAVELERS WORKERS COMPENSATION',
    bill_type_id: '8'
  },
  {
    id: '984',
    label: 'TRANSAMERICA LIFE INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '985',
    label: 'TRAIL BLAZER',
    bill_type_id: '8'
  },
  {
    id: '986',
    label: 'TRI WEST HEALTH',
    bill_type_id: '8'
  },
  {
    id: '987',
    label: 'TRICARE EAST',
    bill_type_id: '8'
  },
  {
    id: '988',
    label: 'TRICARE NORTH AND SOUTH REGION',
    bill_type_id: '8'
  },
  {
    id: '989',
    label: 'TRICARE FOR LIFE',
    bill_type_id: '8'
  },
  {
    id: '990',
    label: 'TRICARE WEST REGION',
    bill_type_id: '8'
  },
  {
    id: '991',
    label: 'TRILLIUM COMMUNITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '992',
    label: 'TRUSTMARK INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '993',
    label: 'TRUE HEALTH NEW MEXICO',
    bill_type_id: '8'
  },
  {
    id: '994',
    label: 'TRUSTMARK HEALTH BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '995',
    label: 'TUFTS HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '996',
    label: 'UCARE OF MINNESOTA',
    bill_type_id: '2'
  },
  {
    id: '997',
    label: 'UFCW LOCAL 1529',
    bill_type_id: '8'
  },
  {
    id: '998',
    label: 'UHA',
    bill_type_id: '8'
  },
  {
    id: '999',
    label: 'UHC COMMUNITY PLAN OF KANSAS',
    bill_type_id: '8'
  },
  {
    id: '1000',
    label: 'UHC COMMUNITY PLAN',
    bill_type_id: '8'
  },
  {
    id: '1001',
    label: 'UHP MANAGEMENT',
    bill_type_id: '8'
  },
  {
    id: '1002',
    label: 'UNKNOWN INSURANCE',
    bill_type_id: '8'
  },
  {
    id: '1003',
    label: 'ULTIMATE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1004',
    label: 'UMR MIDWEST SECURITIES',
    bill_type_id: '8'
  },
  {
    id: '1005',
    label: 'UMR',
    bill_type_id: '8'
  },
  {
    id: '1006',
    label: 'UMWA HEALTH & RETIREMENT FUNDS',
    bill_type_id: '8'
  },
  {
    id: '1007',
    label: 'UNIVERSITY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1008',
    label: 'UNITED MINE WORKERS OF AMERICA',
    bill_type_id: '8'
  },
  {
    id: '1009',
    label: 'UNIVERSAL HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '1010',
    label: 'UNITED OF OMAHA',
    bill_type_id: '8'
  },
  {
    id: '1011',
    label: 'UNIVERSITY FAMILY CARE',
    bill_type_id: '8'
  },
  {
    id: '1012',
    label: 'UNITED COMMERCIAL TRAVELERS',
    bill_type_id: '8'
  },
  {
    id: '1013',
    label: 'UNITED HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '1014',
    label: 'UNITED AGRICULTURE BENEFIT TRUST',
    bill_type_id: '8'
  },
  {
    id: '1015',
    label: 'UNITED HEALTHCARE',
    bill_type_id: '8'
  },
  {
    id: '1016',
    label: 'UNITED BEHAVIORAL HEALTH',
    bill_type_id: '8'
  },
  {
    id: '1017',
    label: 'UNION PACIFIC RAILROAD',
    bill_type_id: '8'
  },
  {
    id: '1018',
    label: 'UNITED FOOD AND COMMERCIAL WORKERS',
    bill_type_id: '8'
  },
  {
    id: '1019',
    label: 'UNITED MEDICAL RESOURCES',
    bill_type_id: '8'
  },
  {
    id: '1020',
    label: 'UNIFIED HEALTH SERVICES',
    bill_type_id: '8'
  },
  {
    id: '1021',
    label: 'UNITED SERVICES AUTOMOBILE',
    bill_type_id: '8'
  },
  {
    id: '1022',
    label: 'UNIFORM MEDICAL PLAN',
    bill_type_id: '8'
  },
  {
    id: '1023',
    label: 'UNICARE',
    bill_type_id: '8'
  },
  {
    id: '1024',
    label: 'UNITEDHEALTHCARE COMMUNITY PLAN KS',
    bill_type_id: '8'
  },
  {
    id: '1025',
    label: 'UNITED AMERICAN INSURANCE COMPANY',
    bill_type_id: '8'
  },
  {
    id: '1026',
    label: 'UNICARE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1027',
    label: 'UNITEDHEALTHCARE COMMUNITY PLAN',
    bill_type_id: '8'
  },
  {
    id: '1028',
    label: 'UNITED HEALTHCARE MEDICARE',
    bill_type_id: '8'
  },
  {
    id: '1029',
    label: 'UNITEDHEALTHCARE CLAIM DIVISION',
    bill_type_id: '8'
  },
  {
    id: '1030',
    label: 'UNITED HEALTHCARE SHARED SERVICES',
    bill_type_id: '8'
  },
  {
    id: '1031',
    label: 'UNITED HEALTHONE',
    bill_type_id: '8'
  },
  {
    id: '1032',
    label: 'UNITED HEALTHCARE ALL SAVERS',
    bill_type_id: '8'
  },
  {
    id: '1033',
    label: 'UNITED HARVARD PILGRIM',
    bill_type_id: '8'
  },
  {
    id: '1034',
    label: 'UNITED WORLD LIFE INSURANCE CO.',
    bill_type_id: '8'
  },
  {
    id: '1035',
    label: 'UNITED WORLD LIFE INSURANCE CO.',
    bill_type_id: '8'
  },
  {
    id: '1036',
    label: 'UNITED HEALTHCARE COMMUNITY PLAN',
    bill_type_id: '8'
  },
  {
    id: '1037',
    label: 'UNIVERSITY OF UTAH HEALTH PLANS',
    bill_type_id: '8'
  },
  {
    id: '1038',
    label: 'UNIVERSITY OF MARYLAND HEALTH PARTN',
    bill_type_id: '8'
  },
  {
    id: '1039',
    label: 'UPMC HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1040',
    label: 'UPPER PENINSULA HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1041',
    label: 'US BENEFITS',
    bill_type_id: '8'
  },
  {
    id: '1042',
    label: 'US FAMILY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1043',
    label: 'USAA',
    bill_type_id: '8'
  },
  {
    id: '1044',
    label: 'UTAH MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '1045',
    label: 'UTAH BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '1046',
    label: 'UTAH MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '1047',
    label: 'VALUE OPTIONS',
    bill_type_id: '8'
  },
  {
    id: '1048',
    label: 'VALLEY HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1049',
    label: 'VANTAGE HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1050',
    label: 'VA PATIENT CARE REGION 3',
    bill_type_id: '8'
  },
  {
    id: '1051',
    label: 'VETERANS ADMINISTRATION',
    bill_type_id: '8'
  },
  {
    id: '1052',
    label: 'VETERANS AFFAIRS FINANCIAL SERVICES',
    bill_type_id: '8'
  },
  {
    id: '1053',
    label: 'VETERANS CHOICE PROGRAM ',
    bill_type_id: '8'
  },
  {
    id: '1054',
    label: 'VETERANS AFFAIRS CCN',
    bill_type_id: '8'
  },
  {
    id: '1055',
    label: 'VETERANS CHOICE PROGRAM - VACAA',
    bill_type_id: '8'
  },
  {
    id: '1056',
    label: 'VIBRA HEALTH PLAN,',
    bill_type_id: '8'
  },
  {
    id: '1057',
    label: 'VILLAGES AT SOUTHERN HILLS',
    bill_type_id: '8'
  },
  {
    id: '1058',
    label: 'VIRGINIA BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '1059',
    label: 'VIRGINIA MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '1060',
    label: 'VIRGINIA MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '1061',
    label: 'VIRGINIA PREMIER HEALTH PLAN',
    bill_type_id: '8'
  },
  {
    id: '1062',
    label: 'VIRGINIA PREMIER ELITE',
    bill_type_id: '8'
  },
  {
    id: '1063',
    label: 'VIVA HEALTH CARE',
    bill_type_id: '8'
  },
  {
    id: '1064',
    label: 'WACHTER',
    bill_type_id: '8'
  },
  {
    id: '1065',
    label: 'WALK IN MEDICAL CARE',
    bill_type_id: '8'
  },
  {
    id: '1066',
    label: 'WASHINGTON MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '1067',
    label: 'WASHINGTON MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '1068',
    label: 'WASHINGTON WORKERS COMPENSATION',
    bill_type_id: '8'
  },
  {
    id: '1069',
    label: 'WASHINGTON BLUE SHIELD REGENCE',
    bill_type_id: '8'
  },
  {
    id: '1070',
    label: 'WAUSAU UMR',
    bill_type_id: '8'
  },
  {
    id: '1071',
    label: 'WEA TRUST',
    bill_type_id: '8'
  },
  {
    id: '1072',
    label: 'WEB TPA',
    bill_type_id: '8'
  },
  {
    id: '1073',
    label: 'WEIMAR LTC INC',
    bill_type_id: '8'
  },
  {
    id: '1074',
    label: 'WELLCARE HEALTH PLANS STAYWELL',
    bill_type_id: '8'
  },
  {
    id: '1075',
    label: 'WELLCARE PFFS',
    bill_type_id: '8'
  },
  {
    id: '1076',
    label: 'WELLCARE',
    bill_type_id: '8'
  },
  {
    id: '1077',
    label: 'WELFARE PENSION PLAN',
    bill_type_id: '8'
  },
  {
    id: '1078',
    label: 'WELLMED MEDICARE',
    bill_type_id: '8'
  },
  {
    id: '1079',
    label: 'WELLCARE HEALTH PLAN MEDICARE',
    bill_type_id: '1'
  },
  {
    id: '1080',
    label: 'WELLCARE PFFS',
    bill_type_id: '8'
  },
  {
    id: '1081',
    label: 'WELLCARE OF KENTUCKY HEALTH PLANS',
    bill_type_id: '1'
  },
  {
    id: '1082',
    label: 'WELLFIRST',
    bill_type_id: '8'
  },
  {
    id: '1083',
    label: 'WESTERN SOUTHERN FINANCIAL GROUP',
    bill_type_id: '8'
  },
  {
    id: '1084',
    label: 'WESTERN SKY COMMUNITY CARE',
    bill_type_id: '8'
  },
  {
    id: '1085',
    label: 'WESTERN SKY COMMUNITY CARE',
    bill_type_id: '8'
  },
  {
    id: '1086',
    label: 'WESTERN SKY COMMUNITY CARE',
    bill_type_id: '8'
  },
  {
    id: '1087',
    label: 'WESTERN HEALTH ADVANTAGE',
    bill_type_id: '8'
  },
  {
    id: '1088',
    label: 'WICHITA CENTER FOR REHABILITATION',
    bill_type_id: '8'
  },
  {
    id: '1089',
    label: 'WINDSOR MEDICARE EXTRA',
    bill_type_id: '8'
  },
  {
    id: '1090',
    label: 'WISCONSIN BLUE SHIELD',
    bill_type_id: '8'
  },
  {
    id: '1091',
    label: 'WISCONSIN MEDICAID',
    bill_type_id: '2'
  },
  {
    id: '1092',
    label: 'WISCONSIN HEALTH INSURANCE RISK',
    bill_type_id: '8'
  },
  {
    id: '1093',
    label: 'WELLMED',
    bill_type_id: '8'
  },
  {
    id: '1094',
    label: 'WORKMENS COMPENSATION',
    bill_type_id: '8'
  },
  {
    id: '1095',
    label: 'WPPA PROVIDERS CARE NETWORK',
    bill_type_id: '8'
  },
  {
    id: '1096',
    label: 'WPS VAPC',
    bill_type_id: '8'
  },
  {
    id: '1097',
    label: 'WPS REGULAR BUSINESS',
    bill_type_id: '8'
  },
  {
    id: '1098',
    label: 'YAMHILL COMMUNITY HEALTH',
    bill_type_id: '8'
  },
  {
    id: '1099',
    label: 'YAMHILL COMMUNITY HEALTH',
    bill_type_id: '8'
  },
  {
    id: '1100',
    label: 'YAMHILL COUNTY CARE',
    bill_type_id: '8'
  },
  {
    id: '1101',
    label: 'ZURICH- ALIGN',
    bill_type_id: '8'
  },
  {
    id: '1102',
    label: 'Other',
    bill_type_id: '8'
  },
  {
    id: '1103',
    label: 'Medical/Kaiser',
    bill_type_id: '8'
  },
  {
    id: '1104',
    label: 'Sutter Health Plus',
    bill_type_id: '8'
  },
  {
    id: '1105',
    label: 'Blue Shield',
    bill_type_id: '8'
  },
  {
    id: '1106',
    label: 'Kaiser',
    bill_type_id: '8'
  },
  {
    id: '1107',
    label: 'United Healthcare1',
    bill_type_id: '8'
  },
  {
    id: '1108',
    label: 'Western Advantage',
    bill_type_id: '8'
  },
  {
    id: '1109',
    label: 'HealthNet',
    bill_type_id: '8'
  },
  {
    id: '1110',
    label: 'UnitedHealthcare',
    bill_type_id: '8'
  },
  {
    id: '1111',
    label: 'Sutter Health',
    bill_type_id: '8'
  },
  {
    id: '1112',
    label: 'Blue Shield HMO',
    bill_type_id: '8'
  },
  {
    id: '1113',
    label: 'Wha',
    bill_type_id: '8'
  },
  {
    id: '1114',
    label: 'Healthnet PPO',
    bill_type_id: '8'
  },
  {
    id: '1115',
    label: 'Trader Joe?s Blue Cross Anthem',
    bill_type_id: '8'
  },
  {
    id: '1116',
    label: 'United Healthcare Choice Plus',
    bill_type_id: '8'
  },
  {
    id: '1117',
    label: 'TriCare',
    bill_type_id: '8'
  },
  {
    id: '1118',
    label: 'SutterSelect/UMR',
    bill_type_id: '8'
  },
  {
    id: '1119',
    label: 'Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1120',
    label: 'Sutter Select',
    bill_type_id: '8'
  },
  {
    id: '1121',
    label: 'Signa',
    bill_type_id: '8'
  },
  {
    id: '1122',
    label: 'Kaiser Permanente Elk Grove',
    bill_type_id: '8'
  },
  {
    id: '1123',
    label: 'BlueCross',
    bill_type_id: '8'
  },
  {
    id: '1124',
    label: 'Blue Shield Of California',
    bill_type_id: '8'
  },
  {
    id: '1125',
    label: 'Kaiser Permanmente',
    bill_type_id: '8'
  },
  {
    id: '1126',
    label: 'Healthnet UC BLlue And Gold HMO',
    bill_type_id: '8'
  },
  {
    id: '1127',
    label: 'PersChoice Anthem Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1128',
    label: 'Tri Net Blue Shield PPO 800.894.5565',
    bill_type_id: '8'
  },
  {
    id: '1129',
    label: 'Kaiser California',
    bill_type_id: '8'
  },
  {
    id: '1130',
    label: 'UnitedHealthcarew',
    bill_type_id: '8'
  },
  {
    id: '1131',
    label: 'UnitedHealthCares',
    bill_type_id: '8'
  },
  {
    id: '1132',
    label: 'Kaiser Permanent',
    bill_type_id: '8'
  },
  {
    id: '1133',
    label: 'Meritain Health By Aetna',
    bill_type_id: '8'
  },
  {
    id: '1134',
    label: 'Blue Shield Of CA PPO',
    bill_type_id: '8'
  },
  {
    id: '1135',
    label: 'Anthem Blue Cross PPO',
    bill_type_id: '8'
  },
  {
    id: '1136',
    label: 'Anthem',
    bill_type_id: '8'
  },
  {
    id: '1137',
    label: 'Medical For Families',
    bill_type_id: '8'
  },
  {
    id: '1138',
    label: 'Cinga',
    bill_type_id: '8'
  },
  {
    id: '1139',
    label: 'Anthem BC',
    bill_type_id: '8'
  },
  {
    id: '1140',
    label: 'Medi Cal/ Bluecross',
    bill_type_id: '8'
  },
  {
    id: '1141',
    label: 'GEICO',
    bill_type_id: '8'
  },
  {
    id: '1142',
    label: 'Kaiser South',
    bill_type_id: '8'
  },
  {
    id: '1143',
    label: 'United Healthcare HMO',
    bill_type_id: '8'
  },
  {
    id: '1144',
    label: 'Sutter Select / Anthem Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1145',
    label: 'Anthem Blue Cros',
    bill_type_id: '8'
  },
  {
    id: '1146',
    label: 'BlueCross Blue Shield',
    bill_type_id: '8'
  },
  {
    id: '1147',
    label: 'Blue Shield Of Ca',
    bill_type_id: '8'
  },
  {
    id: '1148',
    label: 'Anthem-BC/BS',
    bill_type_id: '8'
  },
  {
    id: '1149',
    label: 'SutterSelect EPO Plus',
    bill_type_id: '8'
  },
  {
    id: '1150',
    label: 'Phc',
    bill_type_id: '8'
  },
  {
    id: '1151',
    label: 'Anthem Blue',
    bill_type_id: '8'
  },
  {
    id: '1152',
    label: 'United Heath Care',
    bill_type_id: '8'
  },
  {
    id: '1153',
    label: 'Health Net Federal',
    bill_type_id: '8'
  },
  {
    id: '1154',
    label: 'Bcbs',
    bill_type_id: '8'
  },
  {
    id: '1155',
    label: 'Blue Shield Of TN',
    bill_type_id: '8'
  },
  {
    id: '1156',
    label: 'Blue Shield Of CA, PPO',
    bill_type_id: '8'
  },
  {
    id: '1157',
    label: 'BlueCross BlueShield',
    bill_type_id: '8'
  },
  {
    id: '1158',
    label: 'Blue Cross Blue Shield Of Minnesota',
    bill_type_id: '8'
  },
  {
    id: '1159',
    label: 'Kaiser/Medical',
    bill_type_id: '8'
  },
  {
    id: '1160',
    label: 'Sutter Select UMR',
    bill_type_id: '8'
  },
  {
    id: '1161',
    label: 'Kaiser - Medical',
    bill_type_id: '8'
  },
  {
    id: '1162',
    label: 'Anthem Blue Cross Medi-Cal',
    bill_type_id: '8'
  },
  {
    id: '1163',
    label: 'Uc Blue And Gold Hmo Health Net',
    bill_type_id: '8'
  },
  {
    id: '1164',
    label: 'Blue Cross Anthem',
    bill_type_id: '8'
  },
  {
    id: '1165',
    label: 'Kasier Permanente',
    bill_type_id: '8'
  },
  {
    id: '1166',
    label: 'Health Net Blue And Gold HMO',
    bill_type_id: '8'
  },
  {
    id: '1167',
    label: 'Healthnet UC Blue And Gold HMO',
    bill_type_id: '8'
  },
  {
    id: '1168',
    label: 'Healthnet UC Blue And Gold',
    bill_type_id: '8'
  },
  {
    id: '1169',
    label: 'Blue Shield California',
    bill_type_id: '8'
  },
  {
    id: '1170',
    label: 'FEP Blue Cross Blue Shield',
    bill_type_id: '8'
  },
  {
    id: '1171',
    label: 'Anthem Blue Cross Medical',
    bill_type_id: '8'
  },
  {
    id: '1172',
    label: 'Blue Cross Of South Carolina',
    bill_type_id: '8'
  },
  {
    id: '1173',
    label: 'Medical',
    bill_type_id: '8'
  },
  {
    id: '1174',
    label: 'Kaiser Permenante',
    bill_type_id: '8'
  },
  {
    id: '1175',
    label: 'United HealthCare PPO',
    bill_type_id: '8'
  },
  {
    id: '1176',
    label: 'Blue Shield/Blue Cross MA',
    bill_type_id: '8'
  },
  {
    id: '1177',
    label: 'State Farm',
    bill_type_id: '8'
  },
  {
    id: '1178',
    label: 'Kaiser Medical',
    bill_type_id: '8'
  },
  {
    id: '1179',
    label: 'United Health Care- Signature Value- HMO- Sutter',
    bill_type_id: '8'
  },
  {
    id: '1180',
    label: 'United Health',
    bill_type_id: '8'
  },
  {
    id: '1181',
    label: 'Blue Cross Blue Shield - Federal Employee Plan',
    bill_type_id: '8'
  },
  {
    id: '1182',
    label: 'Medi-Share',
    bill_type_id: '8'
  },
  {
    id: '1183',
    label: 'Anthem BlueCross',
    bill_type_id: '8'
  },
  {
    id: '1184',
    label: 'Sutter EPO',
    bill_type_id: '8'
  },
  {
    id: '1185',
    label: 'United Healthn Care',
    bill_type_id: '8'
  },
  {
    id: '1186',
    label: 'Capital Blue',
    bill_type_id: '8'
  },
  {
    id: '1187',
    label: 'Health Net Blue And Gold',
    bill_type_id: '8'
  },
  {
    id: '1188',
    label: 'Mercy Medical And Tricare Prime (UC Davis)',
    bill_type_id: '8'
  },
  {
    id: '1189',
    label: 'Blue Shield CA',
    bill_type_id: '8'
  },
  {
    id: '1190',
    label: 'I Don?t Know',
    bill_type_id: '8'
  },
  {
    id: '1191',
    label: 'Healthnet PPO / Centene Corporation',
    bill_type_id: '8'
  },
  {
    id: '1192',
    label: 'Medi-Cal GMC/PHP',
    bill_type_id: '8'
  },
  {
    id: '1193',
    label: 'APWU Cigna',
    bill_type_id: '8'
  },
  {
    id: '1194',
    label: 'Capital Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1195',
    label: 'Aetna Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1196',
    label: 'Aaetna',
    bill_type_id: '8'
  },
  {
    id: '1197',
    label: 'Blue Of California',
    bill_type_id: '8'
  },
  {
    id: '1198',
    label: 'Blue Shield Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1199',
    label: 'Blue Cross Of California/Blue Shield',
    bill_type_id: '8'
  },
  {
    id: '1200',
    label: 'SutterSelect',
    bill_type_id: '8'
  },
  {
    id: '1201',
    label: 'Healthcare',
    bill_type_id: '8'
  },
  {
    id: '1202',
    label: 'Blue Shield Blue Cross Of Tennessee',
    bill_type_id: '8'
  },
  {
    id: '1203',
    label: 'Sutter',
    bill_type_id: '8'
  },
  {
    id: '1204',
    label: 'Cigna HealthCare PPO',
    bill_type_id: '8'
  },
  {
    id: '1205',
    label: 'BCBS Federal',
    bill_type_id: '8'
  },
  {
    id: '1206',
    label: 'Health Plan Of San Joaquin: Medi-cal',
    bill_type_id: '8'
  },
  {
    id: '1207',
    label: 'UMR (Issuer) 911-39026-02; Member ID 19588473; Group No. 76-560008',
    bill_type_id: '8'
  },
  {
    id: '1208',
    label: 'Bcbsma',
    bill_type_id: '8'
  },
  {
    id: '1209',
    label: 'Anthem PersChoice',
    bill_type_id: '8'
  },
  {
    id: '1210',
    label: 'Blue Shield PPO',
    bill_type_id: '8'
  },
  {
    id: '1211',
    label: 'Kaiser And Sutter Health',
    bill_type_id: '8'
  },
  {
    id: '1212',
    label: 'SutterHealth Plus',
    bill_type_id: '8'
  },
  {
    id: '1213',
    label: 'Blue Cross; Anthem',
    bill_type_id: '8'
  },
  {
    id: '1214',
    label: 'BlueCross/BlueShield',
    bill_type_id: '8'
  },
  {
    id: '1215',
    label: 'Medi_Cal Blue Cross',
    bill_type_id: '8'
  },
  {
    id: '1216',
    label: '2018 Sutter Health Plus Gold HMO A',
    bill_type_id: '8'
  },
  {
    id: '1217',
    label: 'Blue Cross HMO',
    bill_type_id: '8'
  },
  {
    id: '1218',
    label: 'River City Medical',
    bill_type_id: '8'
  },
  {
    id: '1219',
    label: 'Anthem Blue Card',
    bill_type_id: '8'
  },
  {
    id: '1220',
    label: 'Blue Shield Access+',
    bill_type_id: '8'
  },
  {
    id: '1221',
    label: 'Blue Shield Trio',
    bill_type_id: '8'
  },
  {
    id: '1222',
    label: 'BS/BC',
    bill_type_id: '8'
  },
  {
    id: '1223',
    label: 'Blue Sheild',
    bill_type_id: '8'
  },
  {
    id: '1224',
    label: 'Anthem PERSCare PPO',
    bill_type_id: '8'
  },
  {
    id: '1225',
    label: 'MediCal/CCS',
    bill_type_id: '8'
  },
  {
    id: '1226',
    label: 'Sutter Select EPO',
    bill_type_id: '8'
  },
  {
    id: '1227',
    label: 'Blur Shield',
    bill_type_id: '8'
  },
  {
    id: '1228',
    label: 'K?iser',
    bill_type_id: '8'
  },
  {
    id: '1229',
    label: 'BCBS FEP',
    bill_type_id: '8'
  },
  {
    id: '1230',
    label: 'Blue Shield Of California Full PPO',
    bill_type_id: '8'
  },
  {
    id: '1231',
    label: 'Kaiser Permennete',
    bill_type_id: '8'
  },
  {
    id: '1232',
    label: 'Kasier',
    bill_type_id: '8'
  },
  {
    id: '1233',
    label: 'Blue Shield Covered Ca',
    bill_type_id: '8'
  },
  {
    id: '1234',
    label: 'Kaiser Permamnente',
    bill_type_id: '8'
  },
  {
    id: '1235',
    label: 'Western Health Advantage/ Hill Physicians',
    bill_type_id: '8'
  },
  {
    id: '1236',
    label: 'Blue Shied Of California',
    bill_type_id: '8'
  },
  {
    id: '1237',
    label: 'Health Net UC Blue & Gold HMO',
    bill_type_id: '8'
  },
  {
    id: '1238',
    label: 'Blue Shield Of California PPO',
    bill_type_id: '8'
  },
  {
    id: '1239',
    label: 'Tokio Marine Hcc',
    bill_type_id: '8'
  },
  {
    id: '1240',
    label: 'Blue Anthem',
    bill_type_id: '8'
  },
  {
    id: '1241',
    label: 'Anthem Blue Cross Blue Shield',
    bill_type_id: '8'
  },
  {
    id: '1242',
    label: 'Kaiser Hospital',
    bill_type_id: '8'
  },
  {
    id: '1243',
    label: 'Kaiser-Roseville CA',
    bill_type_id: '8'
  },
  {
    id: '1244',
    label: 'Kaiser Permanante',
    bill_type_id: '8'
  },
  {
    id: '1245',
    label: 'Partnership HealthPlan Of California',
    bill_type_id: '8'
  }
]
