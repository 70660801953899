import React, { useState, useCallback, useEffect } from 'react'
import { Box, TextField, Grid, Autocomplete } from '@mui/material'
import _ from 'lodash'
import { smartyStreetApi } from './autoComplete'
import { api } from 'Core'
import CircularProgress from '@mui/material/CircularProgress'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { useTranslation } from 'react-i18next'

const filter = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.label
})

const statesData = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']

const Address = ({
  formik,
  name,
  disabled,
  copyAddress,
  defaultCountry = 'US',
  testProgramSetting = false
}) => {
  const [inputValue, setInputValue] = useState('')
  const [inputValue1, setInputValue1] = useState('')
  const [addressData, setAddressData] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [manualMode, setManualMode] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    setInputValue1(formik.values[name]?.addressLine1)
  }, [formik.values[name]?.addressLine1])

  useEffect(() => {
    if (name === 'insuredAddress') {
      if (formik.values.patientId === 'new') {
        if (copyAddress) {
          formik.setFieldValue(
            'insuredAddress.city',
            formik.values.medicalAddress.city
          )
          formik.setFieldValue(
            'insuredAddress.addressLine1',
            formik.values.medicalAddress.addressLine1
          )
          formik.setFieldValue(
            'insuredAddress.state',
            formik.values.medicalAddress.state
          )
          formik.setFieldValue(
            'insuredAddress.zipCode',
            formik.values.medicalAddress.zipCode
          )
          formik.setFieldValue(
            'insuredAddress.county',
            formik.values.medicalAddress.county
          )
          formik.setFieldValue(
            'insuredAddress.fips',
            formik.values.medicalAddress.countyFips
          )
          formik.setFieldValue(
            'insuredAddress.plus4Code',
            formik.values.medicalAddress.plus4Code
          )
        } else {
          formik.setFieldValue('insuredAddress.city', '')
          formik.setFieldValue('insuredAddress.addressLine1', '')
          formik.setFieldValue('insuredAddress.state', '')
          formik.setFieldValue('insuredAddress.zipCode', '')
          formik.setFieldValue('insuredAddress.county', '')
          formik.setFieldValue('insuredAddress.fips', '')
          formik.setFieldValue('insuredAddress.plus4Code', '')
          setInputValue1('')
        }
      }
    }
  }, [copyAddress, formik.values.patientId])

  const stateOptions = statesData.map((data, i) => ({
    label: data,
    id: i
  }))

  const addressOptions = addressData
    ? addressData.map((data, i) => ({
      label: `${data?.streetLine}, ${data?.city}, ${data?.state}, ${data.zipcode}`,
      id: i
    }))
    : []

  // const clearAddress = () => {
  //   formik.setFieldValue(name, {
  //     addressLine1: '',
  //     city: '',
  //     state: '',
  //     zipCode: '',
  //     plus4Code: '',
  //     county: '',
  //     country: 'US'
  //   })
  // }

  const fetchApi = useCallback(
    _.debounce(async function (value) {
      try {
        if (!value) return
        if (
          value ===
          `${addressData[0]?.streetLine}, ${addressData[0]?.city}, ${addressData[0]?.state}, ${addressData[0]?.zipcode}`
        ) { return }
        setSearchLoading(true)
        const addresses = await smartyStreetApi(value)
        setAddressData(addresses)
        setSearchLoading(false)
      } catch (error) {
        setSearchLoading(false)
        dispatch(
          showSnackBar({
            show: true,
            message: t('afterCare.review.snackbar.error'),
            severity: 'error'
          })
        )
      }
    }, 500),
    []
  )

  const addressChangeHandler = (e, newValue) => {
    try {
      if (!e) return
      if (e.type === 'click') return
      if (newValue === '') setManualMode(false)
      if (newValue === 'Manually Add Address') return
      setInputValue1(newValue)
      if (!newValue) {
        formik.setFieldValue(`${name}.addressLine1`, '')
        return
      }
      if (
        newValue ===
        `${addressData[0]?.streetLine}, ${addressData[0]?.city}, ${addressData[0]?.state}, ${addressData[0]?.zipcode}`
      ) { return }
      if (!manualMode) fetchApi(newValue)
      // if (manualMode)
      formik.setFieldValue(`${name}.addressLine1`, newValue)
    } catch (error) {
      console.log(error)
    }
  }

  const addressSelectHandler = async (e, newVal) => {
    try {
      if (loading || searchLoading) return
      if (!newVal) {
        formik.setFieldValue(`${name}.addressLine1`, '')
        return
      }
      const val = newVal.label
      if (val === 'Manually Add Address') {
        setManualMode(true)
        formik.setFieldValue(`${name}.addressLine1`, inputValue1)
        return
      }
      const data = addressData.find(
        (d) => `${d.streetLine}, ${d.city}, ${d.state}, ${d.zipcode}` === val
      )
      setLoading(true)
      setSearchLoading(true)
      const completedData = await api.patient.streetSearch(data)
      formik.setFieldValue(`${name}.city`, completedData?.components?.cityName)
      formik.setFieldValue(
        `${name}.addressLine1`,
        completedData?.deliveryLine1
      )
      setInputValue1(completedData?.deliveryLine1)
      formik.setFieldValue(`${name}.state`, completedData?.components?.state)
      formik.setFieldValue(
        `${name}.zipCode`,
        completedData?.components?.zipCode
      )
      formik.setFieldValue(
        `${name}.county`,
        completedData?.metadata?.countyName
      )
      formik.setFieldValue(`${name}.fips`, completedData?.metadata?.countyFips)
      formik.setFieldValue(
        `${name}.plus4Code`,
        completedData?.components?.plus4Code
      )
      setAddressData([])
      setLoading(false)
      setSearchLoading(false)
    } catch (error) {
      setLoading(false)
      setAddressData([])
      setSearchLoading(false)
      console.log(error)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="addressLine1"
            disabled={disabled}
            options={addressOptions}
            isOptionEqualToValue={(option, value) => option.label === value}
            value={formik.values[name]?.addressLine1 || null}
            inputValue={inputValue1 || ''}
            disableClearable
            freeSolo
            onInputChange={addressChangeHandler}
            onChange={addressSelectHandler}
            renderOption={(props, data) => {
              if (data?.label === 'Manually Add Address') {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={data?.id}
                    sx={{ color: '#8b0000' }}
                  >
                    {data?.label}
                  </Box>
                )
              } else {
                return (
                  <Box component="li" {...props} key={data?.id}>
                    {data?.label}
                  </Box>
                )
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              // const { inputValue } = params
              // if (inputValue1 !== '') {
              // if (!manualMode) {
              //   filtered.push({
              //     inputValue1,
              //     label: 'Manually Add Address'
              //   })
              // }
              return filtered
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  testProgramSetting
                    ? `${t('registerPatient.paymentInfo.insured.address')} *`
                    : `${t('registerPatient.paymentInfo.insured.currentAddress')} *`
                }
                name={`${name}.addressLine1`}
                error={
                  formik.touched?.[name]?.addressLine1 &&
                  Boolean(formik.errors?.[name]?.addressLine1)
                }
                helperText={
                  formik.touched?.[name]?.addressLine1 &&
                  Boolean(formik.errors?.[name]?.addressLine1)
                    ? t(formik.errors?.[name]?.addressLine1)
                    : null
                }
                sx={{
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disabled || loading ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor:
                      disabled || loading ? '#666666a6' : '#212121'
                  }
                }}
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
                autoComplete="off"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchLoading
                        ? (
                        <CircularProgress color="inherit" size={20} />
                          )
                        : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Grid>
        {testProgramSetting
          ? (
          <Grid item xs={12}>
            <TextField
              label={t('registerPatient.paymentInfo.insured.addressLineTwo')}
              variant="outlined"
              type="text"
              name={`${name}.addressLine2`}
              id="addressLine2"
              sx={{
                '& .Mui-disabled': {
                  color: '#666666a6'
                },
                '& .MuiInputBase-input': {
                  cursor: disabled || loading ? 'not-allowed' : 'inherit',
                  WebkitTextFillColor:
                    disabled || loading ? '#666666a6' : '#212121'
                }
              }}
              value={formik.values[name]?.addressLine2 || ''}
              onChange={formik.handleChange}
              error={
                formik.touched?.[name]?.addressLine2 &&
                Boolean(formik.errors?.[name]?.addressLine2)
              }
              helperText={
                formik.touched?.[name]?.addressLine2 &&
                Boolean(formik.errors?.[name]?.addressLine2)
                  ? t(formik.errors?.[name]?.addressLine2)
                  : null
              }
              fullWidth
              disabled={disabled || loading}
              FormHelperTextProps={{
                sx: { color: 'primary.red' }
              }}
            />
          </Grid>
            )
          : (
              ''
            )}

        <Grid item md={6} xs={12}>
          <TextField
            label={`${t('registerPatient.paymentInfo.insured.city')} *`}
            variant="outlined"
            type="text"
            name={`${name}.city`}
            id="city"
            sx={{
              '& .Mui-disabled': {
                color: '#666666a6'
              },
              '& .MuiInputBase-input': {
                cursor: disabled || loading ? 'not-allowed' : 'inherit',
                WebkitTextFillColor:
                  disabled || loading ? '#666666a6' : '#212121'
              }
            }}
            value={formik.values[name]?.city || ''}
            onChange={formik.handleChange}
            error={
              formik.touched?.[name]?.city &&
              Boolean(formik.errors?.[name]?.city)
            }
            helperText={
              formik.touched?.[name]?.city &&
              Boolean(formik.errors?.[name]?.city)
                ? t(formik.errors?.[name]?.city)
                : null
            }
            fullWidth
            disabled={disabled || loading}
            FormHelperTextProps={{
              sx: { color: 'primary.red' }
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            id="state"
            disabled={disabled || loading}
            options={stateOptions}
            isOptionEqualToValue={(option, value) => option.label === value}
            filterOptions={filter}
            value={formik.values[name]?.state || null}
            sx={{
              '& .Mui-disabled': {
                color: '#666666a6'
              },
              '& .MuiInputBase-input': {
                cursor: disabled || loading ? 'not-allowed' : 'inherit',
                WebkitTextFillColor:
                  disabled || loading ? '#666666a6' : '#212121'
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            onChange={(e, newValue) => {
              if (newValue?.label) {
                formik.setFieldValue(`${name}.state`, newValue.label)
              } else {
                formik.setFieldValue(`${name}.state`, '')
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t('registerPatient.paymentInfo.insured.state')} *`}
                sx={{
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disabled || loading ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor:
                      disabled || loading ? '#666666a6' : '#212121'
                  }
                }}
                name={`${name}.state`}
                helperText={
                  formik.touched?.[name]?.state &&
                  Boolean(formik.errors?.[name]?.state)
                    ? t(formik.errors?.[name]?.state)
                    : null
                }
                error={
                  formik.touched?.[name]?.state &&
                  Boolean(formik.errors?.[name]?.state)
                }
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={testProgramSetting ? 12 : 6} xs={12}>
          <TextField
            label={`${t('registerPatient.paymentInfo.insured.zipCode')} *`}
            variant="outlined"
            type="text"
            name={`${name}.zipCode`}
            id="zipCode"
            value={formik.values[name]?.zipCode || ''}
            onChange={formik.handleChange}
            error={
              formik.touched?.[name]?.zipCode &&
              Boolean(formik.errors?.[name]?.zipCode)
            }
            helperText={
              formik.touched?.[name]?.zipCode &&
              Boolean(formik.errors?.[name]?.zipCode)
                ? t(formik.errors?.[name]?.zipCode)
                : null
            }
            fullWidth
            inputProps={{ maxLength: 5 }}
            disabled={disabled || loading}
            FormHelperTextProps={{
              sx: { color: 'primary.red' }
            }}
            sx={{
              '& .Mui-disabled': {
                color: '#666666a6'
              },
              '& .MuiInputBase-input': {
                cursor: disabled || loading ? 'not-allowed' : 'inherit',
                WebkitTextFillColor:
                  disabled || loading ? '#666666a6' : '#212121'
              }
            }}
          />
        </Grid>

        {testProgramSetting
          ? (
              ''
            )
          : (
          <>
            {/* <Grid item md={6} xs={12}>
              <TextField
                label="Plus4 Code"
                variant="outlined"
                type="text"
                name={`${name}.plus4Code`}
                id="plus4Code"
                value={formik.values[name]?.plus4Code || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched?.[name]?.plus4Code &&
                  Boolean(formik.errors?.[name]?.plus4Code)
                }
                fullWidth
                inputProps={{ maxLength: 4 }}
                disabled={disabled || loading}
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
                sx={{
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disabled || loading ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor:
                      disabled || loading ? '#666666a6' : '#212121'
                  }
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="County *"
                variant="outlined"
                type="text"
                name={`${name}.county`}
                id="county"
                value={formik.values[name]?.county || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched?.[name]?.county &&
                  Boolean(formik.errors?.[name]?.county)
                }
                helperText={
                  formik.touched?.[name]?.county &&
                  Boolean(formik.errors?.[name]?.county)
                    ? formik.errors?.[name]?.county
                    : null
                }
                fullWidth
                disabled={disabled || loading}
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
                sx={{
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: disabled || loading ? 'not-allowed' : 'inherit',
                    WebkitTextFillColor:
                      disabled || loading ? '#666666a6' : '#212121'
                  }
                }}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                label={`${t('registerPatient.paymentInfo.insured.country')} *`}
                variant="outlined"
                type="text"
                name={`${name}.country`}
                id="country"
                disabled
                value={formik.values[name]?.country || defaultCountry}
                onChange={formik.handleChange}
                error={
                  formik.touched?.[name]?.country &&
                  Boolean(formik.errors?.[name]?.country)
                }
                fullWidth
                FormHelperTextProps={{
                  sx: { color: 'primary.red' }
                }}
                sx={{
                  '& .Mui-disabled': {
                    color: '#666666a6'
                  },
                  '& .MuiInputBase-input': {
                    cursor: 'not-allowed',
                    WebkitTextFillColor: '#666666a6'
                  }
                }}
              />
            </Grid>
          </>
            )}
      </Grid>
    </Box>
  )
}

export default Address
