import { Stack } from '@mui/material'
import React, { Context } from 'react'
import { Question } from './Fields'
import { buildValidationSchema, getInitialValues } from './utils'
import * as yup from 'yup'
import useFormik from 'hooks/useFormik'
import { QuestionI } from '../../types/db/KitTypeConfigI'

const QuestionnaireContext: Context<QuestionnaireContextI> = React.createContext({} as QuestionnaireContextI)
QuestionnaireContext.displayName = 'QuestionnaireContext'

export function useQuestionnaire () {
  const context = React.useContext(QuestionnaireContext)

  if (!context) {
    throw Error(
      'useQuestionnaire must be in scope with <QuestionnaireContext/>'
    )
  }

  return context
}

function Questionnaire ({
  questions = [],
  customQuestions = {},
  answers = {},
  onSubmit,
  children
}: Props) {
  const validationSchema = buildValidationSchema(questions)
  const formik = useFormik({
    initialValues: getInitialValues(questions, answers),
    onSubmit: async formData => {
      await onSubmit(formData)
    },
    validationSchema: yup.object().shape(validationSchema)
  })

  return (
    <QuestionnaireContext.Provider value={{ ...QuestionnaireContext, formik } as QuestionnaireContextI}>
      <Stack component='form' sx={{ gap: 2 }} onSubmit={formik.handleSubmit} autoComplete='off'>
        {questions.map(question => {
          if (question.type === 'custom') {
            const CustomQuestion = customQuestions[question.id]
            if (!CustomQuestion) {
              throw Error(
                `No custom question component found for question with id ${question.id}`
              )
            }
            return <CustomQuestion key={question.id} />
          }
          return <Question key={question.id} question={question} />
        })}
        {children}
      </Stack>
    </QuestionnaireContext.Provider>
  )
}

interface Props {
  questions: QuestionI[]
  customQuestions?: Record<string, React.ComponentType>
  answers?: Record<string, any>
  onSubmit: (data: Record<string, any>) => Promise<void>
  children: React.ReactNode
}

interface QuestionnaireContextI {
  formik: ReturnType<typeof useFormik>
  displayName: string
}

export default Questionnaire
