import React from 'react'
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'
import { RegisterKitNavigation, useRegisterKit } from '.'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import useFormik from 'hooks/useFormik'
import { Prompt } from 'components/Prompt'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import useStateCallback from 'hooks/useStateCallback'
import Grid from '@mui/material/Unstable_Grid2'
import {
  DatePicker,
  LocalizationProvider,
  TimePicker
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { checkScreeningQuestions } from 'utils/kit'
import api from 'Core/api'
import { useQuery } from '@tanstack/react-query'
import WarningAmberIcon from '@mui/icons-material/WarningAmberOutlined'
import { Trans, useTranslation } from 'react-i18next'
import { analyticEventTracker } from 'utils/analytics'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'

function getValidationSchema (t, whiteLabelConfig) {
  return yup.object().shape({
    intendToCollect: yup
      .string()
      .required(t('registerKit.sampleCollection.intendToCollectRequiredError'))
      .nullable(),
    collectedDate: yup.string().when('intendToCollect', {
      is: 'Pick a time',
      then: yup
        .string()
        .required(t('registerKit.sampleCollection.collectedDateRequiredError'))
        .test('testDateInput', t('registerKit.sampleCollection.collectedDateInvalidDateError'), val => {
          const date = moment().diff(moment(val), 'days')
          return date <= 5 && date >= 0
        })
    }),
    collectedTime: yup.string().when('intendToCollect', {
      is: 'Pick a time',
      then: yup
        .string()
        .required(t('registerKit.sampleCollection.collectedTimeRequiredError'))
        .test(
          'testTimeInput',
          t('registerKit.sampleCollection.collectedTimeInvalidTimeError'),
          value => value !== 'Invalid Date'
        )
    }),
    nameCheckbox: yup
      .boolean()
      .oneOf([true], t('registerKit.sampleCollection.nameCheckboxRequiredError')),
    dobCheckbox: whiteLabelConfig?.featureFlags?.includes('remove_dob_from_kit_registration') 
      ? yup.boolean()  
      : yup
        .boolean()
        .oneOf(
          [true],
          t('registerKit.sampleCollection.dobCheckboxRequiredError')
      ),
    collectiondateCheckbox: yup
      .boolean()
      .oneOf(
        [true],
        t('registerKit.sampleCollection.collectionDateCheckboxRequiredError')
      )
  })
}

function SampleCollectionForm () {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()

  const [showPromptOnExit, setShowPromptOnExit] = useStateCallback(true)

  const { data, updateData, saveKitAction } = useRegisterKit()

  const { data: faultyCheck } = useQuery(['faultykit-check'], () => api.kit.isFaultyKit(data.kit.code))

  const maxAllowedDate = moment().add(1, 'days').valueOf()
  const formik = useFormik({
    initialValues: {
      ...data.sampleCollectionInfoForm
    },
    validationSchema: getValidationSchema(t, whiteLabelConfig),
    onSubmit: async formData => {
      analyticEventTracker(`(E) Next Button Clicked on Sample Collection Page`, {
        category: 'Register kit'
      })
      saveKitAction('SampleCollection');
      if (data.kit?.is_health_plan_kit) {
        await api.kit.update(data.kit.id, { pageInfo: 'SampleCollection' })
      }
      setShowPromptOnExit(false, () => {
        updateData(state => ({ ...state, sampleCollectionInfoForm: formData }))
        navigate('../review')
      })
    }
  })

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showPromptOnExit
  )

  return (
    <Stack component='form' autoComplete='off' onSubmit={formik.handleSubmit} sx={{ gap: 3 }}>
      <Prompt
        open={showPrompt && showPromptOnExit}
        onClose={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
      <Typography variant='h5' component='h2'>
        {t('registerKit.sampleCollection.title')}
      </Typography>

      <Typography>{t('registerKit.sampleCollection.intentToCollect.questionName')}</Typography>

      <Stack gap={2} spacing={1}>
        <FormControl error={formik.isError('intendToCollect')}>
          <RadioGroup
            name='intendToCollect'
            value={formik.values.intendToCollect}
            onChange={(evt, value) => {
              if (evt.target.value === 'Right now') {
                formik.setValues({
                  ...formik.values,
                  collectedDate: moment().toDate(),
                  collectedTime: moment().toDate(),
                  intendToCollect: value
                })
                return
              }
              formik.handleChange(evt)
            }}
            sx={{ gap: 1 }}
          >
            <FormControlLabel
              control={<Radio />}
              label={t('registerKit.sampleCollection.intentToCollect.option1Label')}
              value='Right now'
            />
            <FormControlLabel
              control={<Radio />}
              label={t('registerKit.sampleCollection.intentToCollect.option2Label')}
              value='Pick a time'
            />
          </RadioGroup>
          {formik.isError('intendToCollect')
            ? (
              <FormHelperText>
                <>{formik.getError('intendToCollect')}</>
              </FormHelperText>
              )
            : null}
        </FormControl>

        {formik.values.intendToCollect
          ? (
            <>
              <Stack sx={{ gap: 2 }} display={formik.values.intendToCollect === 'Pick a time' ? 'flex' : 'none' }>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    maxDate={maxAllowedDate}
                    minDate={moment()
                      .subtract(5, 'days')
                      .valueOf()}
                    label={t('registerKit.sampleCollection.collectedDate.questionLabel')}
                    onChange={newValue => {
                      formik.setFieldValue('collectedDate', newValue || '')
                    }}
                    disabled={formik.values.intendToCollect !== 'Pick a time'}
                    value={formik.values.collectedDate}
                    slotProps={{
                      textField: {
                        error: formik.isError('collectedDate'),
                        helperText: <>{formik.getError('collectedDate')}</>
                      }
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    disabled={formik.values.intendToCollect !== 'Pick a time'}
                    label={t('registerKit.sampleCollection.collectedTime.questionLabel')}
                    value={formik.values.collectedTime}
                    onChange={newValue => {
                      formik.setFieldValue('collectedTime', newValue || '')
                    }}
                    slotProps={{
                      textField: {
                        error: formik.isError('collectedTime'),
                        helperText: <>{formik.getError('collectedTime')}</>
                      }
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              <Typography variant='h5'>{t('registerKit.sampleCollection.labelYourSample.questionName')}</Typography>
              {
                faultyCheck?.isMislabeled
                  ? <Stack
                    alignItems="center"
                    justifyContent="center">
                    <Alert severity='warning' icon={<WarningAmberIcon sx={{ alignSelf: 'center' }}/> }>
                      <Stack direction='row' gap={1}>
                        <Stack alignItems='center'>
                        <AlertTitle>{t('registerKit.sampleCollection.mislabeled.alertTitle')}</AlertTitle>
                          {t('registerKit.sampleCollection.mislabeled.alertDescription')}
                        </Stack>
                        <Stack
                          component='img'
                          src='/assets/faultykit.svg'
                          alt='faultykit'
                          sx={{ m: 'auto' }}
                        />
                      </Stack>
                    </Alert>
                  </Stack>
                  : null
              }
              <Grid
                container
                alignItems='center'
                spacing={1}
              >
                <Grid xs={10} sm={6}>
                  <Typography variant='body2'>
                  {t('registerKit.sampleCollection.labelYourSample.alertDescription')}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={5}>
                  <Stack direction='row' gap={1} width='fit-content' sx={{ margin: '0 auto' }}>
                    <Stack
                      component='img'
                      src='/assets/testTube.svg'
                      alt='kitdetails-img-2'
                      sx={{ m: 'auto' }}
                    />
                    <Stack
                      component='img'
                      src='/assets/kitdetails2.svg'
                      alt='kitdetails-img-3'
                      sx={{ m: 'auto' }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <FormControl>
                <Stack>
                <FormControlLabel
                  sx={{
                    fontSize: 14,
                    backgroundColor: 'white'
                  }}
                  control={
                    <Checkbox
                      checked={formik.values.nameCheckbox}
                      name='nameCheckbox'
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Typography>
                      {t('registerKit.sampleCollection.review.nameLabel')} <strong>{data.patientInfoForm?.patient.first_name} {data.patientInfoForm?.patient.last_name}</strong>
                    </Typography>
                  }
                />
                {formik.touched.nameCheckbox && formik.errors.nameCheckbox && (
                  <FormHelperText error>{formik.errors.nameCheckbox}</FormHelperText>
                )}
                {
                  whiteLabelConfig?.featureFlags?.includes('remove_dob_from_kit_registration') 
                  ? null
                  : (
                    <>
                      <FormControlLabel
                        sx={{
                          fontSize: 14,
                          backgroundColor: 'white'
                        }}
                        control={
                          <Checkbox
                            checked={formik.values.dobCheckbox}
                            name='dobCheckbox'
                            onChange={formik.handleChange}
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {t('registerKit.sampleCollection.review.dobLabel')} <strong>{moment(data.patientInfoForm?.patient.dob).format('MM/DD/YYYY')}</strong>
                          </Typography>
                        }
                      />
                       {formik.touched.dobCheckbox && formik.errors.dobCheckbox && (
                        <FormHelperText error>{formik.errors.dobCheckbox}</FormHelperText>
                      )}
                    </>
                  )
                }
               
                <FormControlLabel
                  sx={{
                    fontSize: 14,
                    backgroundColor: 'white'
                  }}
                  control={
                    <Checkbox
                      checked={formik.values.collectiondateCheckbox}
                      name='collectiondateCheckbox'
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                  <Typography>
                    {t('registerKit.sampleCollection.review.collectedAtLabel')} {formik.values.collectedDate && formik.values.collectedTime ? <strong>{moment(formik.values.collectedDate).format('MM/DD/YYYY')}</strong> : <strong>N/A</strong>}
                  </Typography>
                }
                />
                {formik.touched.collectiondateCheckbox && formik.errors.collectiondateCheckbox && (
                  <FormHelperText error>{formik.errors.collectiondateCheckbox}</FormHelperText>
                )}
                </Stack>
              </FormControl>
              <Typography variant='body2'>{t('registerKit.sampleCollection.descriptionApplyLabel')}</Typography>
              <Alert severity="info" sx={{ alignItems: 'center' }}>
                 <AlertTitle>
                  {t('registerKit.sampleCollection.incorrectDetailsAlertTitle')}
                 </AlertTitle>
                  <Trans i18nKey='registerKit.sampleCollection.incorrectDetailsAlert'>
                    <strong>Make sure your sample label(s) has the same details as above</strong>. Missing or inconsistent details could slow down your results or lead to a rejected sample.
                  </Trans>
              </Alert>
              {faultyCheck?.isShippedBadKit && <Alert severity="warning" sx={{ alignItems: 'center' }}>
                 <AlertTitle>
                    {t('registerKit.sampleCollection.faultyKitWarning.title')}
                 </AlertTitle>
                    <div dangerouslySetInnerHTML={{ __html: t('registerKit.sampleCollection.faultyKitWarning.desc') }}/>
              </Alert>}
            </>
            )
          : null}

      </Stack>

      <RegisterKitNavigation
        back={
          <Button
            onClick={() => {
              analyticEventTracker(`(E) Back Button Clicked on Sample Collection Page`, {
                category: 'Register kit'
              })
              if(data.payment === 'prepaid'){
                if(!checkScreeningQuestions(data?.kit?.kitTypeConfig, data.site)){
                  setShowPromptOnExit(false, () => navigate('../patient-info'))
                } else {
                  setShowPromptOnExit(false, () => navigate('../screening'))
                }
              } else {
                setShowPromptOnExit(false, () => navigate('../payment-method'))
              }
            }
          }
          >
            {t('multiFormNavigation.back')}
          </Button>
        }
        next={
          <LoadingButton
            loading={formik.isSubmitting}
            type='submit'
            variant='contained'
          >
            {t('multiFormNavigation.next')}
          </LoadingButton>
        }
      />
    </Stack>
  )
}

export default SampleCollectionForm
