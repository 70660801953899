import React from 'react'
import { Typography, Stack } from '@mui/material'
import PropTypes from 'prop-types'

function LayoutWisFlow ({ headerText, children, footerComponent }) {
  return (
    <Stack gap={2}>
      <Typography variant="h5" component="h2" textAlign="left">
        {headerText}
      </Typography>
      {children}
      {footerComponent}
    </Stack>
  )
}

LayoutWisFlow.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.element,
  footerComponent: PropTypes.element
}

export default LayoutWisFlow
