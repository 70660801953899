import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import * as React from 'react'
import * as Square from '@square/web-sdk'
import useFormik from 'hooks/useFormik'

const {
  REACT_APP_APPLICATION_ID: applicationId,
  REACT_APP_LOCATION_ID: locationId
} = process.env

export function getSquareCredentials () {
  return { applicationId, locationId }
}

async function initializeSquarePaymentForm (ref) {
  const payments = await Square.payments(applicationId, locationId)
  const card = await payments.card({ includeInputLabels: true })
  card.attach(ref)
  return card
}

export async function tokenize (paymentMethod) {
  try {
    const tokenResult = await paymentMethod.tokenize()
    if (tokenResult.status === 'OK') {
      return tokenResult
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`
      }

      throw new Error(errorMessage)
    }
  } catch (error) {
    console.error(error)
  }
}

function SquarePaymentForm ({ onSubmit = undefined, onInitialize = undefined, children = null }) {
  const squarePaymentFormRef = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)
  const [isError, setIsError] = React.useState(false)

  const [card, setCard] = React.useState(null)

  React.useEffect(() => {
    initializeSquarePaymentForm(squarePaymentFormRef.current)
      .then(card => {
        setCard(card)
        onInitialize && onInitialize(card)
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))

    const handleDestroy = async () => {
      if (!card) return
      await card.destroy()
    }

    return () => {
      handleDestroy()
    }
  }, [])

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onSubmit(card)
    }
  })

  return (
    <Stack component='form' onSubmit={formik.handleSubmit} sx={{ gap: 1 }}>
      <Box ref={squarePaymentFormRef} />
      {isLoading
        ? (
        <Typography>
          Initializing the payment form
          <CircularProgress size={16} sx={{ mx: 1 }} />
        </Typography>
          )
        : isError
          ? (
        <Typography>Error initializing the payment form!</Typography>
            )
          : (
              children
            )}
    </Stack>
  )
}

export default SquarePaymentForm
