import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function MultiStepNavigation ({ back, next, totalSteps, currentStep }: Props) {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {back}
      <Typography variant='body2' sx={{ color: '#00000061', fontWeight: 700 }}>
        {t('multiStepNavigation.steps', { currentStep, totalSteps })}
      </Typography>
      {next}
    </Stack>
  )
}

interface Props {
  back: React.ReactNode
  next: React.ReactNode
  totalSteps: number
  currentStep: number
}

export default MultiStepNavigation
