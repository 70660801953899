import Analytics, { PageData } from 'analytics'
import { api } from '../Core'

const analytics = Analytics({
  app: 'simple-health-kit',
  plugins: [
    customPlugin()
  ]
})

function customPlugin () {
  return {
    name: 'custom-plugin',
    track: async ({ payload }: TrackPayloadI) => {
      try {
        const { type, resourceId, ...restPayload } = payload
        const trackingEmail = window.sessionStorage.getItem('track_email_analytics');
        if(trackingEmail){
          restPayload.properties.meta = restPayload.properties.meta 
            ? {
              ...restPayload.properties.meta,
              email: trackingEmail
            }
            : {
              email: trackingEmail
            }
        }
        await api.event.create({ type, resource_id: resourceId, payload: restPayload })
      } catch (error) {
        console.error('Error in custom-plugin', error)
      }
    }
  }
}

// This send an event to the analytics services
const analyticEventTracker = (
  eventName: string,
  payload: any = {},
  options: any = {},
  callback?: (...params: any[]) => any
) => {
  if (!eventName) {
    console.error('EventName is required in analyticEventTracker')
    return null
  }
  return analytics.track(eventName, payload, options, callback)
}

// This is the page view tracker and all the params are optional
const analyticPageTracker = (
  data: PageData = {},
  options: any = {},
  callback: (...params: any[]) => any
) => {
  return analytics.page(data, options, callback)
}

// This is fires the callback when all analytics services are ready
const analyticsReady = (callback: (...params: any[]) => any) => {
  return analytics.ready(callback)
}

interface TrackPayloadI {
  payload: any
  type: string
  resourceId: string
}

export { analyticEventTracker, analyticPageTracker, analyticsReady }
