import { useFormik as useBaseFormik } from 'formik'
import _ from 'lodash'

function isNoneOption (option) {
  return ['None of the above', 'None'].includes(option)
}

function useFormik (
  /** @type {import('formik').FormikConfig<import('formik').FormikValues>} */
  formikValues
) {
  const formik = useBaseFormik({ validateOnChange: false, ...formikValues })

  function isError (key = '') {
    const isTouched = _.result(formik.touched, key)
    const isError = !!_.result(formik.errors, key)

    return isTouched && isError
  }

  function getError (key = '') {
    const error = _.result(formik.errors, key)

    if (isError(key)) return error

    return null
  }

  function getCheckboxChangeHandler (key) {
    return function checkboxChangeHandler (evt) {
      const { name, checked } = evt.target
      let updatedValues = formik.values[key] || []

      if (checked) {
        updatedValues = updatedValues.concat(name)

        if (isNoneOption(name)) {
          updatedValues = updatedValues.filter(isNoneOption)
          formik.setFieldValue(key, updatedValues)
          return
        }

        if (name === 'Other') {
          formik.setFieldValue(key, ['Other'])
          return
        }

        updatedValues = updatedValues.filter(
          item => !isNoneOption(item) && item !== 'Other'
        )

        formik.setFieldValue(key, updatedValues)
        return
      }

      updatedValues = updatedValues.filter(currentItem => currentItem !== name)

      formik.setFieldValue(key, updatedValues)
    }
  }

  function isChecked (key, item) {
    return (formik.values[key] || []).includes(item)
  }

  function registerCheckbox (key, item) {
    return {
      name: item,
      checked: isChecked(key, item),
      onChange: getCheckboxChangeHandler(key)
    }
  }

  return { ...formik, getError, isError, registerCheckbox }
}

export default useFormik

export function textFieldPropsGetter (formik) {
  return function (
    /** @type {import('@mui/material').TextFieldProps} */
    { name, ...overrides }
  ) {
    return {
      name,
      value: formik.getFieldProps(name).value,
      onChange: formik.handleChange,
      error: formik.isError(name),
      helperText: formik.getError(name),
      ...overrides
    }
  }
}
