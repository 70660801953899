import AppProvider from 'AppProvider'
import React, { useEffect, useState } from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider,
  useQuery
} from '@tanstack/react-query'
import App from 'App'
import 'mapbox-gl/dist/mapbox-gl.css'
import './css/beacon.css'

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

import './i18n'
import './beacon'
import { useTranslation } from 'react-i18next'
import { WhiteLabelConfigProvider } from './utils/white-label/WhiteLabelConfig'
import { api } from 'Core'
import Loader from 'components/Loader'
import { isShkDomain } from 'utils/utilFuntions'

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } }
})

// eslint-disable-next-line react/no-deprecated
const container = document.getElementById('root')
// @ts-ignore
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <DetectLanguageFromUrlSearchParam>
      <QueryClientProvider client={queryClient}>
        <PingAPI />
        <WhiteLabelConfigProvider>
          <AppProvider>
            <App />
          </AppProvider>
        </WhiteLabelConfigProvider>
      </QueryClientProvider>
    </DetectLanguageFromUrlSearchParam>
  </BrowserRouter>
)

// @ts-ignore
function DetectLanguageFromUrlSearchParam ({ children }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const isShk = isShkDomain()

  useEffect(() => {
    const lang = searchParams.get('lang')
    const refresh = searchParams.get('refresh')
    if (lang) {
      i18n.changeLanguage(lang)
      searchParams.delete('lang')
      setSearchParams(new URLSearchParams(searchParams))
    }
    if (refresh) {
      const setRefresh = async () => {
        try {
          setLoading(true)
          await api.portalAuth.setCookie({ token: refresh })
        } catch (e) {
          console.log(e)
        }
        searchParams.delete('refresh')
        setSearchParams(new URLSearchParams(searchParams))
        setLoading(false)
        if(isShk)
          navigate('/choose-portal')
        else
          navigate('/')
      }
      setRefresh()
    }
  }, [])

  if (loading) { return <Loader/> }
  return <>{children}</>
}

function PingAPI () {
  useQuery(['ping-api'], api.ping.checkConnection)
  return null
}

export function useStoreLastVisitedRoute () {
  const location = useLocation()
  useEffect(() => {
    window.sessionStorage.setItem('lastVisited', location.pathname)
  }, [location.pathname])
}
