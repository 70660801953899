export const paymentTypes: PaymentTypesI = Object.freeze({
  insured: 'insured',
  uninsured: 'uninsured',
  forgottenInsurance: 'forgottenInsurance',
  prepaid: 'prepaid',
  cash: 'cash'
})

export interface PaymentTypesI {
  insured: 'insured',
  uninsured: 'uninsured',
  forgottenInsurance?: 'forgottenInsurance',
  prepaid: 'prepaid'
  cash: 'cash'
  clientBill?: 'clientBill'
}
