import React, { SyntheticEvent, useState } from 'react'
import { Alert, Link, Stack, TextField, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Container, Paper } from 'components'
import { Link as RouterLink } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getDomainName } from 'utils/utilFuntions'
import { api } from 'Core'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { LoadingButton } from '@mui/lab'
import { analyticEventTracker } from 'utils/analytics'

const GoogleSaml = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [accountState, setAccountState] = useState('init')
  const dispatch = useDispatch()

  const handleSignUp = async (e: SyntheticEvent<Element, Event>) => {
    e.preventDefault()
    setLoading(true)
    setAccountState('init')
    const subdomain = getDomainName()

    try {
      const checkDomain = await api.portalAuth.checkDomain({ email })
      if (checkDomain) {
        const isLocal = ['local.com'].includes(subdomain)
        analyticEventTracker(`Login to ${subdomain} with SSO by user ${email}`, { category: 'Authentication' })
        window.open(`${isLocal ? 'http://localhost:8080' : process.env.REACT_APP_URL}/api/portal-auth/login-saml?email=${email}`, '_self')
      }
    } catch (e : any) {
      analyticEventTracker(`Login to ${subdomain} with SSO by user ${email} error: ${e?.responseJSON?.error}`, { category: 'Authentication' })
      if(e?.responseJSON?.error === 'Domain has no SSO config!'){
        setLoading(false);
        return setAccountState('invalidSso');
      }
      dispatch(showSnackBar({
        show: true,
        message: e?.responseJSON?.error,
        severity: 'error'
      }))
      setLoading(false)
    }
  }

  return (
    <Container>
      <Stack
        sx={{ gap: 3, width: '100%', maxWidth: 550, m: 'auto' }}
        component='form'
        autoComplete='off'
        onSubmit={handleSignUp}
      >
        <Typography variant='h4' component='h1' sx={{ textAlign: 'center' }}>{t('homepage.title')}</Typography>
        <Paper sx={{ width: '100%', maxWidth: 550, m: 'auto' }}>
          <Stack sx={{ gap: 3 }}>
            <Link
              component={ RouterLink }
              to={ '../' }
              replace
              sx={ { display: 'flex', alignItems: 'center', gap: 1 } }
            >
              <ArrowBackIcon />
              {t('profile.editPatient.button.back')}
            </Link>
            <Typography variant='h5' component='h2'>{t('homepage.ssoTitle')}</Typography>
            <Typography variant='body1'>{t('homepage.ssoBody')}</Typography>
            <Stack sx={{ gap: 2 }}>
              <TextField
                name='email'
                label={`${t('homepage.ssoWorkEmail')} *`}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
              />
              { accountState === 'invalidSso'
                ? (<Alert severity='error'
                    sx={{ alignItems: 'center' }}
                    >
                    <Typography
                      variant='body2'
                      sx={ {
                        color: 'error.main',
                        fontWeight: 500
                      } }
                    >
                      <Trans i18nKey={'login.alert.noSsoError'} components={{
                        links: <Link
                          component={ RouterLink }
                          to={ '/' }
                          replace
                          sx={{ color: 'error.main', }}
                        />
                      }} />
                    </Typography>
                </Alert>)
              : null }
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={loading}
              >
                 {t('dashboard.kitcard.button.continueRegister')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  )
}

export default GoogleSaml
