import { combineReducers } from 'redux'
import authReducer from './authReducer'
import { restfulBuilder, uiBuilder } from '../restReducer'
import notificationReducer from './notificationReducer'
import patientRegistrationReducer from './patientRegistrationReducer'
import loaderReducer from './loaderReducer'
import snackBarReducer from './snackBarReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationReducer,
  content: restfulBuilder('content', { singleton: true }),
  account: restfulBuilder('account', { singleton: true }),
  user: restfulBuilder('user', { singleton: true }),
  patients: restfulBuilder('patients', {}),
  kits: restfulBuilder('kits', {}),
  ui: uiBuilder(),
  patientRegistration: patientRegistrationReducer,
  loader: loaderReducer,
  snackbar: snackBarReducer
})

const reducers = (state: any, action: ReduxActionI) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    const { content } = state

    // @ts-ignore
    state = { content }
  }

  return rootReducer(state, action)
}

export interface ReduxActionI {
  type: string
  payload: any
  key?: string
  dismissAll?: boolean
  notification?: {
    key: string
    message: string
    options: any
  }
}

export default reducers
