import Loader from 'components/Loader'
import useAuth0 from 'hooks/useAuth0'
import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import jwtUtil from 'Core/utils/jwtUtil'
import cookieUtils from 'Core/utils/cookieUtils'
import Layout from 'components/Layout'
import moment from 'moment'
import TestResultExternalProvider from 'admin-app/pages/TestResultExternalProvider'
import { getAffiliateUrl } from './affiliateUtilFunctions'
import ConsultFeedback from 'pages/Feedback/ConsultFeedback'

import HeaderFreeTestsProgram from 'components/HeaderFreeTestsProgram'
import TestingProgram from 'pages/TestingProgram'
import NpsReview from 'pages/NpsReview'
import NpsHeader from 'pages/NpsReview/NpsHeader'
import { PrepProgram } from 'pages/PrepProgram'
import ProgramDetails from 'pages/PrepProgram/ProgramDetails'
import FreeTestingReview from 'pages/FreeTestingReview'
import UnsubscribeView from 'pages/UnsubscribeView'
import HealthPlanSurvey from 'pages/HealthPlanSurvey'
import { useWhiteLabelConfig } from './white-label/WhiteLabelConfig'

const PatientPortalApp = lazy(() => import('apps/PatientPortalApp'))
const ProviderApp = lazy(() => import('apps/ProviderApp'))
const AccountApp = lazy(() => import('apps/AccountApp'))
const ManageApp = lazy(() => import('apps/ManageApp'))
const LabPortalApp = lazy(() => import('apps/LabPortalApp'))
const AdminApp = lazy(() => import('apps/AdminApp'))
const HealthApp = lazy(() => import('apps/HealthApp'))
const HealthDemoApp = lazy(() => import('apps/HealthDemoApp'))
const TestResultExternal = lazy(() => import('pages/TestResultExternal'))
const CustomerAdminApp = React.lazy(() => import('apps/CustomerAdminApp'))

export const subdomainType = Object.freeze({
  app: 'app',
  provider: 'provider',
  account: 'account',
  manage: 'manage',
  admin: 'admin',
  labPortal: 'ninjalab',
  walmart: 'walmart',
  lacare: 'lacare',
  cvs: 'cvs',
  walgreens: 'walgreens',
  health: 'health',
  healthdemo: 'healthdemo',
  customeradmin: 'customeradmin'
})

export const APPS = [
  {
    subdomain: subdomainType.app,
    app: <PatientPortalApp />
  },
  {
    subdomain: subdomainType.walmart,
    app: <PatientPortalApp />
  },
  {
    subdomain: subdomainType.lacare,
    app: <PatientPortalApp />
  },
  {
    subdomain: subdomainType.cvs,
    app: <PatientPortalApp />
  },
  {
    subdomain: subdomainType.walgreens,
    app: <PatientPortalApp />
  },
  {
    subdomain: subdomainType.provider,
    app: <ProviderApp />
  },
  {
    subdomain: subdomainType.account,
    app: <AccountApp />
  },
  {
    subdomain: subdomainType.manage,
    app: <ManageApp />
  },
  {
    subdomain: subdomainType.admin,
    app: <AdminApp />
  },
  {
    subdomain: subdomainType.labPortal,
    app: <LabPortalApp />
  },
  {
    subdomain: subdomainType.health,
    app: <HealthApp />
  },
  {
    subdomain: subdomainType.healthdemo,
    app: <HealthDemoApp />
  },
  {
    subdomain: subdomainType.customeradmin,
    app: <CustomerAdminApp />
  }
]

export const getSubDomain = location => {
  const locationParts = location.split('.')
  return locationParts[0]
}

export const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts?.pop()?.split(';')?.shift()
  }
}

const checkRoleAndRedirect = (subdomain, APP_URL) => {
  const isSuperAdmin = jwtUtil.checkSuperAdmin()
  const isClientViewer = jwtUtil.checkClientViewer() || jwtUtil.checkSiteOwner()
  const isManageAdmin = jwtUtil.checkManageAdmin()
  const isLabPortal = jwtUtil.checkLabPortal()
  const isHealthPortal = jwtUtil.checkHealthUser()

  const appUrl = getAffiliateUrl(APP_URL)

  if (subdomain === 'admin' && !isSuperAdmin) {
    window.location.replace(appUrl)
  } else if (subdomain === 'provider' && !isClientViewer) {
    window.location.replace(appUrl)
  } else if (subdomain === 'manage' && !isManageAdmin) {
    window.location.replace(appUrl)
  } else if (subdomain === 'ninjalab' && !isLabPortal) {
    window.location.replace(appUrl)
  } else if (subdomain === 'health' && !isHealthPortal) {
    window.location.replace(appUrl)
  }
}

export const AppBasedOnSubDomain = () => {
  const whiteLabelConfig = useWhiteLabelConfig()
  const { user } = useAuth0()
  const subdomain = getSubDomain(window.location.hostname)

  if (window.location.pathname.includes('view-result')) {
    switch (subdomain) {
      case subdomainType.app:
      case subdomainType.walmart:
      case subdomainType.lacare:
      case subdomainType.cvs:
      case subdomainType.walgreens:
        return (
          <Routes>
            <Route path='/view-result'>
              <Route path='' element={<TestResultExternal />} />
              <Route path=':id' element={<TestResultExternal />} />
            </Route>
          </Routes>
        )
      case subdomainType.provider:
        return (
          <Routes>
            <Route
              path='/view-result/:id'
              element={<TestResultExternalProvider />}
            />
          </Routes>
        )
      default:
        break
    }
  }

  if (window.location.pathname.includes('consult-feedback')) {
    return (
      <Routes>
        <Route path='/consult-feedback/:token' element={<ConsultFeedback />} />
      </Routes>
    )
  }

  if (window.location.pathname.includes('/programs/')) {
    if (subdomain !== 'app') {
      window.location.href = window.location.href.replace(subdomain, 'app')
    }
    return (
      <Routes>
        <Route path='/programs'>
          <Route path='prep-details/:slug/:id' element={
            <Layout>
              <ProgramDetails />
            </Layout>
          } />
          <Route path='prep/:slug/*' element={<PrepProgram />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Routes>
    )
  }

  if (window.location.pathname.includes('/program/')) {
    return (
      <>
        <HeaderFreeTestsProgram />
        <Routes>
          <Route path='/program/:slug/*' element={<TestingProgram />} />
        </Routes>
      </>
    )
  }

  if (window.location.pathname.includes('/satisfaction-survey/')) {
    return (
      <>
        <NpsHeader />
        <Routes>
          <Route path={'/satisfaction-survey/*'} element={<NpsReview />} />
        </Routes>
      </>
    )
  }

  if (window.location.pathname.includes('/return-remainder/')) {
    return (
      <>
        <NpsHeader />
        <Routes>
          <Route path={'/return-remainder/*'} element={<FreeTestingReview />} />
        </Routes>
      </>
    )
  }

  if (window.location.pathname.includes('/unsubscribe-remainder/')) {
    return (
      <>
        <NpsHeader />
        <Routes>
          <Route path={'/unsubscribe-remainder/*'} element={<UnsubscribeView />} />
        </Routes>
      </>
    )
  }

  if (window.location.pathname.includes('/health-plan-survey/')) {
    return (
      <>
        <NpsHeader />
        <Routes>
          <Route path={'/health-plan-survey/*'} element={<HealthPlanSurvey />} />
        </Routes>
      </>
    )
  }

  if (
    window.location.pathname.includes('view-result') &&
    ![subdomainType.app, subdomainType.provider, subdomainType.walmart, subdomainType.lacare, subdomainType.cvs, subdomainType.walgreens].includes(subdomain)
  ) {
    if (user) {
      window.location.replace(
        window.location.href.replace(subdomain, subdomainType.app)
      )
      return <Loader />
    } else {
      return <AccountApp />
    }
  }

  if (
    subdomain !== 'account' &&
    !user &&
    !window.location.pathname.includes('view-result')
  ) {
    const listedSubdomains = APPS.map(app => app.subdomain)
    if (listedSubdomains.includes(subdomain)) {
      cookieUtils.setCookie(
        'redirectTo',
        window.location.href,
        moment()
          .add(1, 'day')
          .utc()
      )
      cookieUtils.deleteCookie('keepRedirectionCookie')
    }
    const appMainUrl = whiteLabelConfig?.variables?.appMainUrl || process.env.REACT_APP_MAIN_URL || ''
    window.location.replace(appMainUrl)
    return <Loader />
  }
  const redirectionCookie = getCookie('redirectTo')
  const keepRedirectionCookie = getCookie('keepRedirectionCookie') === 'true'
  const token = window.sessionStorage.getItem('token')
  if (redirectionCookie && user && token && !keepRedirectionCookie) {
    const redirectionURL = new URL(redirectionCookie)
    const redirectionPath = redirectionURL.pathname
    cookieUtils.deleteCookie('redirectTo')
    setTimeout(() => {
      const newRedirectionURL = `${redirectionPath}`
      window.location.replace(newRedirectionURL)
    }, 100)
  }

  const app = APPS.find(ap => subdomain === ap.subdomain)
  checkRoleAndRedirect(subdomain, whiteLabelConfig?.variables?.appUrl)

  return <Layout>{app.app}</Layout>
}
