import moment from 'moment'
import { AppNotificationI, NotificationChat } from '../../../types/db/AppNotificationI'

export const calculateNewNotificationsNumber = (notifications: AppNotificationI[]) => {
  const notReadAndNotSnoozed = notifications?.filter(notification => {
    const isDate = moment(notification.snoozed_until).isValid()
    const itIsInTheFuture = moment().isBefore(notification.snoozed_until)
    const itWasRead = moment(notification.read_at).isValid()

    const unreadMessagesInChat = notification.chat && typeof notification.chat === 'string'
      ? JSON.parse(notification.chat)?.reduce((acc: number, cur: NotificationChat) => {
        if ((window?.location?.hostname?.split('.')[0] !== cur.origin) && !cur.readAt) {
          return acc + 1
        }
        return acc
      }, 0)
      : 0

    return ((!isDate || !itIsInTheFuture) && !itWasRead) || (unreadMessagesInChat > 0)
  })

  return notReadAndNotSnoozed.length || 0
}
