import axios from 'axios'
import qs from 'qs'

const patientPortalApi = {
  validateSite: async (siteCode: string) => {
    const res = await axios.get(`https://shk-api.mytests.io/validate/site/${siteCode}`)
    return res
  },
  getQuestions: async (siteCode: string) => {
    const res = await axios.get(`https://shk-api.mytests.io/questions/${siteCode}`)
    return res
  },
  getRequirements: async (siteCode: string) => {
    const res = await axios.get(`https://shk-api.mytests.io/requirements/${siteCode}`)
    return res
  },
  getInsurance: async (siteCode: string) => {
    const res = await axios.get<{name: string, id: string}[]>(`https://shk-api.mytests.io/insurance/${siteCode}`)
    return res
  },
  getEthnicity: async (siteCode: string) => {
    const res = await axios.get<{name: string}[]>(`https://shk-api.mytests.io/ethnicity/${siteCode}`)
    return res
  },
  getRace: async (siteCode: string) => {
    const res = await axios.get<{name: string}[]>(`https://shk-api.mytests.io/race/${siteCode}`)
    return res
  },
  getGender: async (siteCode: string) => {
    const res = await axios.get<{name: string}[]>(`https://shk-api.mytests.io/gender/${siteCode}`)
    return res
  },
  register: async (siteCode: string, data: any) => {
    const res = await axios.post<{ name: string }>(`https://shk-api.mytests.io/register/${siteCode}`, data)
    return res
  },
  validateAddress: async (data: any) => {
    try {
      const strignifiedData = qs.stringify(data)
      const res = axios({
        method: 'post',
        url: 'https://tools.usps.com/tools/app/ziplookup/zipByAddress',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
        },
        data: strignifiedData
      })
      return res
    } catch (error) {
      if (error instanceof Error) {
        return error.message
      } else {
        return error
      }
    }
  }
}

export default patientPortalApi
