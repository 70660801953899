import { OpenInNew } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import moment from 'moment'
import AuthForm from 'components/AuthForm'
import useAuth0 from 'hooks/useAuth0'
import useFormik from 'hooks/useFormik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'
import { getAffiliate } from 'utils/affiliateUtilFunctions'
import cookieUtils from 'Core/utils/cookieUtils'
import { ConsentDialog } from 'components/SiteAffiliation/AddSiteAffiliation'
import { isPasswordContainsUserInfo } from 'utils/utilFuntions'
import { SiteI } from 'types/db/SiteI'
import { getCurrentLanguage } from 'i18n'
import { useTranslation } from 'react-i18next'

const signInValidationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Your password seems incorrect'
    )
    .required('Required'),
  confirmPassword: yup.string().nullable(),
  terms: yup.boolean().oneOf([true], 'Please accept terms and conditions')
})

const signUpValidationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email address').required('Required'),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], 'Emails entered do not match').required('Required'),
  password: yup
    .string()
    .trim('Please remove the white spaces')
    .strict(true)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Please choose a stronger password'
    )
    .required('Password is required').test('checkPasswordAgainstUserInfo', 'Password cannot include personal info', (value = '', context) => {
      const formData = { ...context.parent, password: value }
      // If the user has not filled the form, the value will be undefined, so we need to set it to empty string
      Object.keys(formData).forEach(key => {
        if (formData[key] === undefined) {
          formData[key] = ''
        }
      })
      return !isPasswordContainsUserInfo(formData)
    }),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Required'),
  terms: yup.boolean().oneOf([true], 'Please accept terms and conditions')
})

type AuthPageProps = {
  siteDetails: Pick<
    SiteI,
    'id' | 'address' | 'code' | 'name' | 'consent'
  >
}

function AuthPage ({ siteDetails }: AuthPageProps) {
  const { user, signup, login } = useAuth0()
  const [authComponent, setAuthComponent] = useState('sign-up')
  const [errorAlert, setErrorAlert] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const [currentValidationSchema, setCurrentValidationSchema] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    if (authComponent === 'sign-up' && !user) {
      setCurrentValidationSchema(signUpValidationSchema)
      formik.setErrors({})
    }
    if (authComponent === 'sign-in' && !user) {
      setCurrentValidationSchema(signInValidationSchema)
      formik.setErrors({})
    }
  }, [authComponent])

  const { i18n } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      confirmPassword: '',
      terms: false
    },
    validationSchema: currentValidationSchema,
    onSubmit: async (formData) => {
      try {
        setErrorAlert('')

        if (authComponent === 'sign-up') {
          await signup(formData.email, formData.password, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            toBeLinked: 'true',
            userType: getAffiliate(),
            tncDate: `${moment().unix()}`,
            language: getCurrentLanguage(i18n)
          })
        }

        cookieUtils.setCookie(
          'redirectTo',
          window.location.href,
          moment()
            .add(1, 'day')
            .utc()
        )
        cookieUtils.deleteCookie('keepRedirectionCookie')

        await login(formData.email, formData.password, 'app', getCurrentLanguage(i18n))
      } catch (error: any) {
        if (!user) {
          if (error.code === 'invalid_signup') {
            setErrorAlert('There was an error creating your account. Please try again or log in instead.')
            window.scrollTo(0, 0)
          } else if (error?.responseJSON?.msg === 'INVALID_LOGIN') {
            setErrorAlert('Wrong email or password')
            window.scrollTo(0, 0)
          } else {
            dispatch(
              showSnackBar({
                show: true,
                severity: 'error',
                message: 'Something Went Wrong'
              })
            )
          }
        } else {
          dispatch(
            showSnackBar({
              show: true,
              severity: 'error',
              message: 'Something Went Wrong'
            })
          )
        }
      }
    }
  })

  return (
    <Stack gap={1} onSubmit={formik.handleSubmit} component="form">
      {
        errorAlert && (
          <Stack>
            <Alert severity='error'>{errorAlert}</Alert>
          </Stack>
        )
      }
      <Stack gap={2}>
        <AuthForm
          formik={formik}
          authComponent={authComponent}
          setAuthComponent={setAuthComponent}
          showFirstName
          showLastName
          type='page'
        />
        <Stack>
          <FormControl
            error={!!formik.errors.terms}
            sx={{ textAlign: 'start' }}
          >
            <FormControlLabel
              sx={{ borderRadius: '0 0 5px 5px' }}
              control={
                <Checkbox
                  name="terms"
                  checked={formik.values.terms}
                  onChange={formik.handleChange}
                />
              }
              label={
                <Typography>
                  I acknowledge I have read, understood, and accepted the{' '}
                  <Link
                    href="https://www.simplehealthkit.com/terms"
                    target="_blank"
                    color="primary"
                    rel="noreferrer"

                  >
                    Terms &amp; Conditions
                    <OpenInNew fontSize="inherit" />
                  </Link>{' '}
                  ,{' '} including the {' '}
                  <Link
                    href="https://www.simplehealthkit.com/privacy"
                    target="_blank"
                    color="primary"
                    rel="noreferrer"

                  >
                    Privacy Policy
                    <OpenInNew fontSize="inherit" />
                  </Link>{' '}
                  and{' '}
                  <Link
                    onClick={() => setShowDrawer(true)}
                    color="primary"
                    rel="noreferrer"

                  >
                    Consent
                  </Link>
                </Typography>
              }
            />
            <FormHelperText>{`${formik.errors.terms || ''}`}</FormHelperText>
          </FormControl>
        </Stack>

        <LoadingButton
          variant='contained'
          fullWidth
          loading={formik.isSubmitting}
          type='submit'
        >
          {
            authComponent === 'sign-up'
              ? 'Create Account'
              : 'Login'
          }
        </LoadingButton>

        <ConsentDialog
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          site={siteDetails}
        />
      </Stack>
    </Stack>
  )
}

export default AuthPage
