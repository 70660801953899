import { AppBar, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import theme from 'theme'
import type { PrEPProgramConfig } from 'types/db/SiteI'
import { isValidImageUrl } from 'utils/isValidImageUrl'

const HeaderPrepProgram = ({
  prepProgramConfig
}: {
  prepProgramConfig: PrEPProgramConfig
}) => {
  return (
    <AppBar
      position='static'
      style={{
        backgroundColor: theme.palette.common.white,
        padding: '.5rem',
        height: 'clamp(5.25rem, 14vh, 5.8rem)',
        boxShadow:
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems='center'
        maxWidth='75rem'
        width='100%'
        height='100%'
        margin='0 auto'
      >
        <Grid xs={2.5}></Grid>

        <Grid
          xs={7}
          height='100%'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            component='img'
            src={
              isValidImageUrl(prepProgramConfig.logoUrl)
                ? prepProgramConfig.logoUrl
                : '/assets/reskinning/shk-logo.svg'
            }
            sx={{
              height: '100%',
              maxHeight: '3rem',
              width: '100%',
              objectFit: 'contain'
            }}
            alt='Logo'
          />
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default HeaderPrepProgram
