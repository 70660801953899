const cookieDomain = `.${window.location.hostname.split('.').slice(1).join('.')}`

const cookieUtils = {
  setCookie: (name, value, expiry, path = '/', domain = null) => {
    const expires = `expires=${expiry}`
    document.cookie = `${name}=${value}; ${expires}; path=${path}; domain=${domain || cookieDomain}`
  },

  getCookie: (name) => {
    if (document.cookie.length > 0) {
      let cStart = document.cookie.indexOf(name + '=')
      if (cStart !== -1) {
        cStart = cStart + name.length + 1
        let cEnd = document.cookie.indexOf(';', cStart)
        if (cEnd === -1) {
          cEnd = document.cookie.length
        }
        return unescape(document.cookie.substring(cStart, cEnd))
      }
    }
    return ''
  },

  deleteCookie: (name, path = '/', domain = null) => {
    document.cookie = `${name}=; expires=Fri, 23 Apr 2021 22:34:37 GMT; path=/api; domain=${cookieDomain}`
    document.cookie = `${name}=; expires=Fri, 23 Apr 2021 22:34:37 GMT; path=${path}; domain=${cookieDomain}`
    document.cookie = `${name}=; expires=Fri, 23 Apr 2021 22:34:37 GMT; path=${path}; domain=${domain || cookieDomain}`
  }

}

export default cookieUtils
