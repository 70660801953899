import { api } from 'Core'
import { LoadingButton } from '@mui/lab'
import { showSnackBar } from 'state/actions'
import { TextField, Stack, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import React, { useState } from 'react'
import useFormik from 'hooks/useFormik'
import { useTranslation } from 'react-i18next'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'

const ForgotPasswordDialog = ({ open, onClose }) => {
  const whilteLabelConfig = useWhiteLabelConfig()
  const dispatch = useDispatch()
  const [showPasswordDialog, setShowPasswordDialog] = useState(false)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: async formData => {
      try {
        await api.auth.sendResetPasswordMail({ ...formData, affiliate: whilteLabelConfig.name })
        setShowPasswordDialog(true)
        handleClose()
      } catch (error) {
        dispatch(
          showSnackBar({
            message: t('login.forgotPassword.alert.error'),
            severity: 'error',
            show: true
          })
        )
      }
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('login.forgotPassword.error.validEmail')
        .required('login.forgotPassword.error.validEmail')
    })
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{t('login.forgotPassword.enterEmail')}</DialogTitle>
        <DialogContent>
          <Stack
            component='form'
            sx={{ gap: 2, py: 1 }}
            autoComplete='off'
            onSubmit={formik.handleSubmit}
          >
            <TextField
              name='email'
              onChange={formik.handleChange}
              value={formik.values.email}
              label={t('login.forgotPassword.email')}
              error={!!formik.errors.email}
              helperText={t(formik.errors.email)}
            />
            <Stack sx={{ flexDirection: 'row', gap: 1, ml: 'auto' }}>
              <Button type='button' onClick={onClose}>
                {t('login.forgotPassword.button.cancel')}
              </Button>
              <LoadingButton
                type='submit'
                loading={formik.isSubmitting}
                variant='contained'
              >
                {t('login.forgotPassword.button.resetPassword')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog open={showPasswordDialog}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {t('login.forgotPassword.emailSent')} <CheckCircleOutlineIcon sx={{ color: 'green', mx: 1 }} />{' '}
        </DialogTitle>
        <DialogContent>
          {t('login.forgotPassword.resetPasswordMessage')}
        </DialogContent>
        <DialogActions sx={{ p: 1, pt: 0 }}>
          <Button
            variant='contained'
            onClick={() => {
              setShowPasswordDialog(false)
              handleClose()
            }}
          >
            {t('login.forgotPassword.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ForgotPasswordDialog
