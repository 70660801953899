import { AnalyteConfigI } from '../../types/db/AnalyteConfigI'
import { GroupedSamplesByTypeI } from '.'
import { Kit } from '../../types/db/Kit'
import { KitTypeConfigI } from '../../types/db/KitTypeConfigI'
import { Page, Text, View } from '@react-pdf/renderer'
import { PatientI } from '../../types/db/PatientI'
import { SiteI, WhiteLabelConfig } from '../../types/db/SiteI'
import FooterPDF from './FooterPDF'
import HeaderPDF from './HeaderPDF'
import OtherInformationPDF from './OtherInformationPDF'
import PatientInformationPDF from './PatientInformationPDF'
import React from 'react'
import SampleInformationPDF from './SampleInformationPDF'
import TestResultsPDF from './TestResultsPDF'

const CustomPagePDF = ({ patient, site, kit, samplesByType, sampleType, kitTypeConfig, analytes, resultedAt, whiteLabelConfig, isCorrected = false, displaySHKLabData = false }: Props) => {
  const doctor = `${site?.doctor_config?.first_name || ''}${site?.doctor_config?.last_name ? ` ${site?.doctor_config?.last_name}` : ''}`

  return (<>
    <Page
      size="LETTER"
      style={ {
        fontSize: '11px',
        fontFamily: 'Open Sans',
        position: 'relative',
        padding: '87px 0px 72px 0px'
      } }
    >
      <HeaderPDF kit={ kit } whiteLabelConfig={ whiteLabelConfig } displaySHKLabData={displaySHKLabData}/>

      {/* Content */ }
      <View style={ {
        margin: '0px 16px 0px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
      } }>
        <View
          style={ {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px'
          } }
        >
          <Text style={ { fontSize: '18px', fontWeight: 700 } }>
            { isCorrected ? 'Corrected Report' : 'Final Report' }
          </Text>

          <View
            style={ {
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              fontSize: '11px'
            } }
          >
            <Text style={ { fontWeight: 700 } }>{ 'Reported Date:' }</Text>
            <Text>{ `${resultedAt}` }</Text>
          </View>
        </View>

        <PatientInformationPDF patient={ patient } doctor={ doctor } />

        <SampleInformationPDF
          testOrdered={ kitTypeConfig?.name || '' }
          code={ kit?.code || '' }
          sampleType={ sampleType || '' }
          collectionDate={ kit?.collected_at || '' }
          samplesByType={ samplesByType }
        />

        <TestResultsPDF
          samplesByType={ samplesByType }
          analytes={ analytes }
          kit={ {
            ...kit,
            patient: patient as PatientI
          } }
        />

        <OtherInformationPDF
          kitTypeConfig={ kitTypeConfig }
          samplesByType={ samplesByType }
          displaySHKLabData={displaySHKLabData}
        />
      </View>
      {/* End Content */ }

      <FooterPDF site={ site } whiteLabelConfig={ whiteLabelConfig } displaySHKLabData={displaySHKLabData}/>
    </Page>
  </>)
}

interface Props {
  patient: Partial<PatientI> | undefined
  site: Partial<SiteI> | undefined
  kit: Partial<Kit> | undefined
  samplesByType: GroupedSamplesByTypeI
  sampleType: string
  kitTypeConfig: Partial<KitTypeConfigI> | undefined
  analytes: Partial<AnalyteConfigI>[] | undefined
  resultedAt: string | undefined
  whiteLabelConfig: WhiteLabelConfig | undefined
  isCorrected: boolean
  displaySHKLabData: boolean
}

export default CustomPagePDF
