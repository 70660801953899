import { Kit } from '../../types/db/Kit'
import { Text, View, Image } from '@react-pdf/renderer'
import { WhiteLabelConfig } from '../../types/db/SiteI'
import React from 'react'

const HeaderPDF = ({ kit, whiteLabelConfig, displaySHKLabData }: Props) => {
  const logo = whiteLabelConfig?.isActive && whiteLabelConfig?.logoUrl
    ? whiteLabelConfig.logoUrl
    : 'https://simplehealthkit.s3.us-west-1.amazonaws.com/emailimages/updated-logo.png'

  const logoDimensions = whiteLabelConfig?.isActive && whiteLabelConfig?.logoDimensions
    ? whiteLabelConfig.logoDimensions
    : { width: 250, height: 45 }

  let { city = '', clia_license = '', first_lane = '', second_lane = '', state = '', phone = '', zip = '', laboratory_director_name = '', processing_laboratory_name = '' } = kit?.meta?.labInfo || {}

  if (displaySHKLabData) {
    city = 'Fremont'
    clia_license = '05D2203710'
    first_lane = '46421 Landing Pkwy'
    state = 'CA'
    zip = '94538'
    phone = '(833) 474-7745'
    laboratory_director_name = 'Mir H Noorbakhsh'
  }

  const address = `${first_lane}${second_lane ? `, ${second_lane}` : ''}${city ? `, ${city}` : ''}${state ? `, ${state}` : ''}${zip ? `, ${zip}` : ''}${phone ? ` / ${phone}` : ''}`
  const directorName = laboratory_director_name

  return (<>
    <View
      fixed
      style={ {
        backgroundColor: '#F0F0F0',
        padding: '8px 16px',
        height: '75px',
        maxHeight: '75px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10
      } }
    >
      <Image
        src={ {
          uri: logo,
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
          body: ''
        } }
        style={ {
          width: 'auto',
          height: '100%',
          maxHeight: `${logoDimensions.height}px`,
          maxWidth: `${logoDimensions.width}px`,
          objectFit: 'contain'
        } }
      />

      <View
        style={ {
          fontSize: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          gap: '4px'
        } }
      >
        {
          displaySHKLabData
            ? <Text style={ { fontWeight: 700 } }>Simple HealthKit</Text>
            : processing_laboratory_name
              ? (<Text style={ { fontWeight: 700 } }>
                { `Processing Laboratory: ${processing_laboratory_name}` }
              </Text>)
              : null
        }

        <Text>
          { `CLIA#: ${clia_license} / Laboratory Director: ${directorName}` }
        </Text>

        <Text>
          { `${address}` }
        </Text>

      </View>
    </View>
  </>)
}

interface Props {
  kit: Partial<Kit> | undefined,
  whiteLabelConfig: WhiteLabelConfig | undefined
  displaySHKLabData: boolean
}

export default HeaderPDF
