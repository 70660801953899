import React from 'react'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'
import PatientCard from './PatientCard'
import { checkPatientExistsInSite } from 'utils/utilFuntions'

interface PatientCardListProps {
  sitePatients: any[] // Assuming sitePatients is an array of strings
  setShowEditPatientForm: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentPatient: React.Dispatch<React.SetStateAction<any>>
  setIsPatientInSite: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentEditPatient: React.Dispatch<React.SetStateAction<any>>
  formik: any
  data: any
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
}

const PatientCardList: React.FC<PatientCardListProps> = ({ sitePatients, setShowEditPatientForm, setCurrentEditPatient, setCurrentPatient, setIsPatientInSite, formik, data, setSelectedOption, selectedOption }) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPatient = JSON.parse(event.target.value)
    setSelectedOption(event.target.value)
    setCurrentPatient(selectedPatient)
    formik.setValues({
      ...formik.values,
      patient: selectedPatient,
      shouldDuplicatePatient: 'no'
    })
    setIsPatientInSite(
      checkPatientExistsInSite(selectedPatient, data.site)
    )
  }

  return (
    <RadioGroup value={ selectedOption } onChange={ handleOptionChange }>
      { sitePatients.map((patient, index) => (
        <FormControlLabel
          key={ index }
          value={ JSON.stringify(patient) }
          control={ <Radio /> }
          label={ <PatientCard data={ data } patient={ patient } setShowEditPatientForm={ setShowEditPatientForm } setCurrentEditPatient={ setCurrentEditPatient } setCurrentPatient={ setCurrentPatient } setSelectedOption={ setSelectedOption } formik={ formik } /> }
        />
      )) }
    </RadioGroup>
  )
}

export default PatientCardList
