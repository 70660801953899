import React from 'react'
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import useFormik, { textFieldPropsGetter } from 'hooks/useFormik'
import { Heading } from '.'
import {
  MultiStepFormNavigation,
  useMultiStepForm
} from 'components/MultiStepForm'
import { TPrepProgram, additionalQuestions } from './schemas'
import { PrEPProgramConfig } from 'types/db/SiteI'

function AdditionalQuestions( {
  prepProgramConfig
}: {
  prepProgramConfig: PrEPProgramConfig
} ) {
  const { data, update } = useMultiStepForm<TPrepProgram>()

  const additionQuestionsContent = prepProgramConfig.content.additionalQuestions

  const formik = useFormik( {
    initialValues: data.additionalQuestions,
    validationSchema: additionalQuestions,
    onSubmit: formData => {
      update( {
        ...data,
        additionalQuestions: formData as TPrepProgram[ 'additionalQuestions' ]
      } )
    }
  } )

  const getTextFieldProps = textFieldPropsGetter( formik )

  return (
    <>
      <Heading>{additionQuestionsContent.title}</Heading>
      <Stack
        gap={2}
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{ py: '18px' }}
      >
        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {additionQuestionsContent.questions.diagnosedWithConditions.title}
        </Typography>
        <FormControl error={formik.isError( 'diagnosedWithConditions' )}>
          <FormGroup>
            {additionQuestionsContent.questions.diagnosedWithConditions.options.map(
              ( option: any ) => (
                <>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={option}
                    key={option}
                    {...formik.registerCheckbox(
                      'diagnosedWithConditions',
                      option
                    )}
                  />
                </>
              )
            )}
            {formik.isError( 'diagnosedWithConditions' ) ? (
              <FormHelperText>
                {`${formik.errors[ 'diagnosedWithConditions' ] || ''}`}
              </FormHelperText>
            ) : null}
          </FormGroup>
        </FormControl>

        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {additionQuestionsContent.questions.medicalConditions.title}
        </Typography>
        <FormControl error={formik.isError( 'medicalConditions' )}>
          <TextField
            {...getTextFieldProps( {
              name: 'medicalConditions',
              label: 'Enter Answer Here*'
            } )}
            rows={4}
            multiline
          />
        </FormControl>

        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {additionQuestionsContent.questions.currentPrescriptionMeds.title}
        </Typography>
        <FormControl error={formik.isError( 'currentPrescriptionMeds' )}>
          <TextField
            {...getTextFieldProps( {
              name: 'currentPrescriptionMeds',
              label: 'Enter Answer Here*'
            } )}
            rows={4}
            multiline
          />
        </FormControl>

        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {additionQuestionsContent.questions.medAllergy.title}
        </Typography>
        <FormControl error={formik.isError( 'medAllergy' )}>
          <TextField
            {...getTextFieldProps( {
              name: 'medAllergy',
              label: 'Enter Answer Here*'
            } )}
            rows={4}
            multiline
          />
        </FormControl>

        <Typography variant='body1' component='label' sx={{ mt: '15px' }}>
          {additionQuestionsContent.questions.extraInfo.title}
        </Typography>
        <FormControl error={formik.isError( 'extraInfo' )}>
          <TextField
            {...getTextFieldProps( {
              name: 'extraInfo',
              label: 'Enter Answer Here*'
            } )}
            rows={4}
            multiline
          />
        </FormControl>

        <Alert severity='info'>{additionQuestionsContent.alertText}</Alert>
        <MultiStepFormNavigation />
      </Stack>
    </>
  )
}

export default AdditionalQuestions
