import { isShkDomain } from '../../utils/utilFuntions'
import { Text, View } from '@react-pdf/renderer'
import { SiteI, WhiteLabelConfig } from '../../types/db/SiteI'
import React from 'react'

const FooterPDF = ({ site, whiteLabelConfig, displaySHKLabData }: Props) => {
  const isSHK = isShkDomain()
  const supportEmail = whiteLabelConfig?.supportEmail || undefined
  const supportNumber = whiteLabelConfig?.supportNumber || undefined

  return (<>
    <View
      fixed
      style={ {
        backgroundColor: '#333333',
        color: 'white',
        padding: '8px 16px',
        height: '60px',
        maxHeight: '60px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        fontSize: '8px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10
      } }
    >
      <View
        style={ {
          display: 'flex',
          gap: '4px'
        } }
      >
        <View
          style={ {
            display: 'flex',
            flexDirection: 'row',
            gap: '4px'
          } }
        >
          <Text>
            { `${displaySHKLabData ? 'Simple HealthKit' : site?.name}` }
          </Text>

          <Text>
            { ` / ${displaySHKLabData ? '46421 Landing Parkway, Fremont CA 94538' : site?.address}` }
          </Text>
        </View>
        <View
          style={ {
            display: 'flex',
            flexDirection: 'row',
            gap: '4px'
          } }
        >
          {
            displaySHKLabData
              ? (<Text>help@simplehealthkit.com / (833) 474 - 7745</Text>)
              : (<Text>
                { `${isSHK ? 'help@simplehealthkit.com' : supportEmail || ''}${!isSHK && supportEmail && supportNumber ? ' / ' : ''}${supportNumber || ''}` }
              </Text>)
          }
        </View>

      </View>
      <Text render={ ({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      ) } />
    </View>
  </>)
}

interface Props {
  site: Partial<SiteI> | undefined
  whiteLabelConfig: WhiteLabelConfig | undefined
  displaySHKLabData: boolean
}

export default FooterPDF
