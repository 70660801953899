import _ from 'lodash'
import { AnalyteConfigI, QualitativeResultState, QuantitativeConfigI, QuantitativeResultState } from '../../types/db/AnalyteConfigI'
import { api } from 'Core'
import { isPositiveResult } from 'utils/utilFuntions'
import { Kit } from 'types/db/Kit'
import { KitFromLimsI } from '../../types/KitFromLimsI'
import { KitTypeConfigI, KitTypeConfigAnalyte } from 'types/db/KitTypeConfigI'
import { ResultBoxI } from './components/ResultBox'
import { SampleI } from '../../types/db/SampleI'
import moment from 'moment'

export const registerEvent: RegisterEventI = async (event = '', siteId = '', kitCode = '') => {
  try {
    await api.event.create({
      type: 'aftercare',
      resource_id: siteId,
      payload: { event, kitCode }
    })
  } catch (error) { console.log(error) }
}

export const getLatestKitOfKitType: GetLatestKitOfKitTypeI = (kits = [], kitTypeIdentifier = '') => {
  const sortedKits = kits.filter(k => k.status === 'Approved' && k.kitTypeConfig!.identifier === kitTypeIdentifier)
    .map(k => ({
      ...k,
      approvalTime: k?.lims_status?.approved_date
        ? moment(k?.lims_status?.approved_date).valueOf()
        : 0
    }))
    .sort((a, b) => b.approvalTime - a.approvalTime)
  return sortedKits[0]
}

export const getAnalyteScientificName: GetAnalyteScientificNameI = (testResult) => {
  const analyteScientificName = testResult.analyte_scientific_name
    ? testResult.analyte_scientific_name
    : testResult.sample_test_name

  return analyteScientificName || 'unknown'
}

export const organizeTestResultsByPanel: OrganizeTestResultsByPanelI = (testResults = []) => {
  const organizedTestResults: { [key: string]: SampleI[] } = {}

  testResults.forEach(testResult => {
    let { sample_panel_name } = testResult
    if (!sample_panel_name) sample_panel_name = 'unknown'

    if (organizedTestResults[sample_panel_name]) {
      organizedTestResults[sample_panel_name] = [...organizedTestResults[sample_panel_name], testResult]
      return
    }
    organizedTestResults[sample_panel_name] = [testResult]
  })

  return organizedTestResults
}

export const organizeTestResultsByTests: OrganizeTestResultsByTestsI = (testResults = []) => {
  const organizedTestResults: { [key: string]: SampleI[] } = {}

  testResults.forEach(testResult => {
    const { sample_test_name = 'unknown' } = testResult
    if (organizedTestResults[sample_test_name]) {
      organizedTestResults[sample_test_name] = [...organizedTestResults[sample_test_name], testResult]
      return
    }
    organizedTestResults[sample_test_name] = [testResult]
  })

  return organizedTestResults
}

export const isPatientPositive = (testArray: SampleI[]) => testArray.some(isPositiveResult)

export const isPatientInvalidInconclusive = (testArray: SampleI[]) => testArray.some(test => ['Invalid', 'Inconclusive'].includes(test?.result))

export const isBetaHCG = (testArray: SampleI[]) => testArray.some(test => (test?.external_id === '720') && test?.analyte_lab_type === 'limsabc')

export const isMultiSiteKit = (kitTypeConfig: KitTypeConfigI) => {
  const { analytes } = kitTypeConfig
  const isAnalyteTestedByMultipleSampleTypes = (analyte: KitTypeConfigAnalyte) => (analyte?.samplesUsed?.length || 0) > 1
  const hasAnalyteTestedByMultipleSampleTypes = analytes.some(isAnalyteTestedByMultipleSampleTypes)
  return hasAnalyteTestedByMultipleSampleTypes
}

export const getAggregateResult = (results: SampleI[]) => {
  const isDetected = results.find(
    result =>
      result.result === 'Detected' ||
      result.result === 'Reactive'
  )
  if (isDetected) {
    return isDetected
  }

  const isChlamydiaOrGonorrhea = results.some(result =>
    ['662', '663'].includes(result.external_id) && result.analyte_lab_type === 'limsabc'
  )

  if (isChlamydiaOrGonorrhea) {
    const isUniqueResults =
      _.uniqBy(results, 'result').length === results.length

    if (isUniqueResults) {
      return { ...results[0], result: 'Inconclusive' } as SampleI
    }

    const mostCommonResultState = _.chain(results)
      .countBy('result')
      .toPairs()
      .maxBy(_.last)
      .head()
      .value()

    const mostCommonResult = _.find(results, {
      result: mostCommonResultState
    }) as SampleI

    return mostCommonResult
  }

  return results[0] || {} as SampleI
}

export const getAnalyteResultDescription = (analyteConfig: AnalyteConfigI | undefined, kit: Kit | KitFromLimsI | undefined, sample: SampleI | undefined) => {
  if (!kit || !sample || !analyteConfig || kit?.reason_rejection || sample?.reason_rejection || !analyteConfig?.type) {
    return null
  }

  if (analyteConfig?.type === 'qualitative') {
    if (!Array.isArray(analyteConfig.result_states)) return null

    return (analyteConfig.result_states as QualitativeResultState[])?.find(resultState => resultState.value === sample.result)?.comments
  }

  if (analyteConfig?.type === 'quantitative') {
    const quantitaveConfig = analyteConfig?.result_states as QuantitativeConfigI
    if (!quantitaveConfig || !quantitaveConfig?.overallInterpretation) return null

    return quantitaveConfig?.overallInterpretation
  }

  return null
}

export const getResultForResultBox = (sample: SampleI, kit: KitFromLimsI | Kit | undefined, analyteConfig: AnalyteConfigI | undefined): ResultBoxI | undefined => {
  if (!analyteConfig) return undefined

  let referenceRange = analyteConfig?.reference_range || sample?.reference_range || ''
  let variantKey = ''
  if(referenceRange.split(':').length > 1) {
    variantKey = (kit as Kit)?.patient?.sex_assigned_at_birth?.toLowerCase() || (kit as any)?.sex_assigned_at_birth?.toLowerCase() || ''
    if (!['male', 'female'].includes(variantKey)) {
      variantKey = 'male'
    }

    const [referenceRangeMale, referenceRangeFemale] = referenceRange.split(':');
    if(variantKey === 'male'){
      referenceRange = referenceRangeMale;
    } else {
      referenceRange = referenceRangeFemale;
    }
  }

  if (sample.result?.toLowerCase() === 'cancelled') {
    return { text: 'Cancelled', referenceRange }
  }

  const rejectionReasons = [
    (kit as Kit)?.lims_status?.reason_rejection,
    sample.reason_rejection,
    kit?.reason_rejection
  ]

  if (rejectionReasons.some(reason => !!reason)) {
    return { text: 'Rejected', backgroundColor: 'warning.main', color: '#fff', referenceRange }
  }

  if (analyteConfig?.type === 'qualitative') {
    const resultState = (analyteConfig.result_states as QualitativeResultState[]).find(resultState => resultState.value === sample.result)

    const color = '#fff'
    const backgroundColor = resultState?.color || '#8f8f8f'
    const text = resultState?.name || ''

    return {
      text,
      color,
      backgroundColor,
      referenceRange
    }
  }

  if (analyteConfig?.type === 'quantitative') {
    if (['invalid', 'inconclusive'].includes(sample.result?.toLowerCase() || '')) {
      return {
        text: sample.result?.toLowerCase() || '',
        backgroundColor: 'warning.main',
        color: '#fff',
        referenceRange
      }
    }

    const quantitativeConfig = analyteConfig?.result_states as QuantitativeConfigI

    if (quantitativeConfig?.reportAsSingleNumber) {
      const color = '#fff'
      const backgroundColor = quantitativeConfig?.color || '#8f8f8f'

      return {
        text: `${sample.numeric_result || ''}`,
        color,
        backgroundColor,
        referenceRange
      }
    }

    const result = {
      text: '',
      color: '#fff',
      backgroundColor: '#8f8f8f',
      referenceRange
    }

    // Check if the numeric_result is a valid number
    const numberWithoutSpecialCharacters = sample?.numeric_result?.replace(/[^0-9.]/g, '') || ''
    const isValidNumber = !isNaN(+numberWithoutSpecialCharacters || NaN)

    // If the numeric_result is not a valid number, return the numeric_result as the result
    if (!isValidNumber) {
      result.text = sample?.numeric_result || ''
      return result
    }

    switch (quantitativeConfig?.rangeVaryDependingOn) {
      case 'sex_assigned_at_birth':
        variantKey = (kit as Kit)?.patient?.sex_assigned_at_birth?.toLowerCase() || (kit as any)?.sex_assigned_at_birth?.toLowerCase() || ''
        if (!['male', 'female'].includes(variantKey)) {
          variantKey = 'male'
        }

        break

      // In the future, we can add more cases here
      default:
        break
    }

    quantitativeConfig?.resultStates?.forEach(resultState => {
      const isInRange = checkRange(resultState, sample, variantKey)

      if (isInRange) {
        result.text = resultState.resultFlag || ''
        result.backgroundColor = resultState.color || '#8f8f8f'
      }
    })

    if (result) return result
  }
}

const checkRange = (resultState: QuantitativeResultState, sample: SampleI, variantKey: string) => {
  if (!resultState) return false

  // If there are variants, the lowerLimit and upperLimit should be undefined in the resultState but valid in the variant
  const lowerLimit = resultState.lowerLimit == null ? resultState.variants?.[variantKey]?.lowerLimit : resultState.lowerLimit
  const upperLimit = resultState.upperLimit == null ? resultState.variants?.[variantKey]?.upperLimit : resultState.upperLimit

  // The double equals are intentional here to validate null and undefined
  if (sample.numeric_result == null || lowerLimit == null || upperLimit == null) return false

  const numeric_result = +sample.numeric_result?.replace(/[^0-9.]/g, '')
  return (numeric_result >= +lowerLimit) && (numeric_result < +upperLimit)
}

// Types and interfaces
type RegisterEventI = (event: string, siteId: string, kitCode: string) => Promise<void>
type GetLatestKitOfKitTypeI = (kits: Kit[], kitTypeIdentifier: string) => Kit | undefined
type GetAnalyteScientificNameI = (testResult: SampleI) => string
type OrganizeTestResultsByPanelI = (testResults: SampleI[]) => { [key: string]: SampleI[] }
type OrganizeTestResultsByTestsI = (testResults: SampleI[]) => { [key: string]: SampleI[] }
