import { AppBar, Box, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { LanguageSelect } from 'components/Header'
import LogoutDialog from 'components/Header/components/LogoutDialog'
import useAuth0 from 'hooks/useAuth0'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidImageUrl } from 'utils/isValidImageUrl'
import { isShkDomain } from 'utils/utilFuntions'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'

const MfaHeader = () => {
  const whiteLabelConfig = useWhiteLabelConfig()
  const [openAuthenticationModal, setOpenAuthenticationModal] = useState(false);
  const { isLoading, user } = useAuth0();
  const { t } = useTranslation();

  const handleClose = () => setOpenAuthenticationModal(false)
  const handleOpen = () => {
    if (!isLoading && user) {
      setOpenAuthenticationModal(true)
    }
  }

  return (
      <AppBar
        position='static'
        style={{
          backgroundColor: whiteLabelConfig?.theme?.customComponents?.header?.bgColor || '#ffffff',
          padding: '.5rem',
          height: 'clamp(5.25rem, 14vh, 5.8rem)',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
        }}
      >
        <Grid
          container
          spacing={1}
          alignItems='center'
          maxWidth='75rem'
          width='100%'
          height='100%'
          margin='0 auto'
        >
            <Grid xs={2.5}></Grid>

            <Grid
            xs={7}
            height='100%'
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <Box
                component='img'
                src={isShkDomain() ? '/assets/reskinning/shk-logo.svg' : isValidImageUrl(whiteLabelConfig?.logoUrl || '') ? whiteLabelConfig?.logoUrl : '' }
                sx={{ height: '100%', maxHeight: '3rem', width: '100%', objectFit: 'contain' }}
                alt='Logo'
            />
            </Grid>
            <Grid xs={ 2.5 } sx={ { textAlign: 'right' } }>
            { user && !openAuthenticationModal
                ? (
                <Button
                    variant='text'
                    onClick={ handleOpen }
                    sx={ {
                    textDecoration: 'underline !important',
                    color: '#000',
                    padding: '.25rem'
                    } }
                >
                    { t('header.logoutDialog.button.logout') }
                </Button>
                )
                : null
            }
            <LanguageSelect />
            </Grid>

        </Grid>
        <LogoutDialog
            id='user-logout'
            keepMounted
            isLogoutConfirmationOpen={openAuthenticationModal}
            setIsLogoutConfirmationOpen={handleClose}
        />
      </AppBar>
  )
}

export default MfaHeader
