import { Button, MenuItem } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UserPermissionsI } from '.'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { getSubdomain } from '../../utils/utilFuntions'

export const visibilityStates = Object.freeze({
  page: {
    portalPage: 'portalPage',
    managePage: 'managePage',
    adminPage: 'adminPage',
    accountPage: 'accountPage',
    providerPage: 'providerPage',
    labportalPage: 'labportalPage',
    healthPortal: 'healthPortal',
    healthDemoPortal: 'healthDemoPortal',
    customerAdminPage: 'customerAdminPage'
  },
  role: {
    portal_admin: 'portal_admin',
    portal_manage: 'portal_manage',
    portal_client_admin: 'portal_client_admin',
    portal_client_viewer: 'portal_client_viewer',
    labportalv2_role: 'labportalv2_role'
  },
  features: {
    consultSection: 'consultSection'
  }
})

export const checkVisibilityMatch = ({
  userPermissions = {
    page: [],
    role: [],
    features: [],
    isHidden: false
  },
  componentRestrictions = {
    page: [],
    role: [],
    features: [],
    isHidden: false
  }
}: CheckVisibilityMatchProps) => {
  return (
    !componentRestrictions.isHidden &&
    filterPermissions({ userPermissions: userPermissions.page, componentRestrictions: componentRestrictions.page }) &&
    filterPermissions({ userPermissions: userPermissions.role || [], componentRestrictions: componentRestrictions.role }) &&
    filterPermissions({ userPermissions: userPermissions.features, componentRestrictions: componentRestrictions.features })
  )
}

const filterPermissions = ({ userPermissions = [], componentRestrictions = [] }: FilterPermissionsProps) => {
  if (componentRestrictions.length === 0) return true

  if (componentRestrictions.find(restriction =>
    userPermissions.some(userPermission => userPermission === restriction)
  )) return true

  return false
}

export function LinkButton (linkButtonProps: LinkButtonProps) {
  const { to, children, ...props } = linkButtonProps
  const path = children === 'Analytics' ? to.split('/').slice(0, 2).join('/') : to
  const location = useLocation()
  const pathSegments = location.pathname.split('/')
  const isActive = pathSegments && pathSegments.length > 1 && `/${pathSegments[1]}` === path
  const whiteLabelConfig = useWhiteLabelConfig()
  const subdomain = getSubdomain()
  const shouldUseWhiteLabel = ['app'].includes(subdomain)

  return (
    <Button
      size='medium'
      sx={{
        color: shouldUseWhiteLabel ? whiteLabelConfig?.theme?.customComponents?.header?.textColor : '#333333',
        position: 'relative',
        textDecoration: isActive ? 'underline' : 'none',
        py: { xs: 0.6, md: 0 },
        flex: { xs: 1, md: 'auto' },
        '&::after': {
          // display: isActive ? 'block' : 'none',
          content: '""',
          position: 'absolute',
          backgroundColor: shouldUseWhiteLabel ? whiteLabelConfig?.theme?.customComponents?.header?.bgColor : '#FFF',
          height: 3,
          width: '100%',
          top: '100%',
          transform: 'translateY(-100%)'
        }
      }}
      {...props}
      component={Link}
      to={to}
    >
      {children}
    </Button>
  )
}

function Item ({ name }: { name: string }) {
  const whiteLabelConfig = useWhiteLabelConfig()
  const appDomain = whiteLabelConfig?.variables?.appDomain || process.env.REACT_APP_DOMAIN

  return (
    <MenuItem
      onClick={() => {
        window.open(`//${name}.${appDomain}`, `shkportal${name}`)
      }}
    >
      {name}
    </MenuItem>
  )
}

export function MenuItems ({ roles }: { roles: string[] }) {
  const menuItems = []

  if (roles.includes('portal_admin')) {
    menuItems.push(<Item name='admin' />)
    menuItems.push(<Item name='manage' />)
    menuItems.push(<Item name='provider' />)
  } else if (roles.includes('portal_manage')) {
    menuItems.push(<Item name='manage' />)
  } else if (roles.includes('portal_client_admin') || roles.includes('portal_client_viewer')) {
    menuItems.push(<Item name='provider' />)
  }

  return (
    <>
      {menuItems}
    </>
  )
}

// interfaces

interface CheckVisibilityMatchProps {
  userPermissions?: UserPermissionsI
  componentRestrictions?: UserPermissionsI
}

interface LinkButtonProps {
  to: string
  children: React.ReactNode
  [key: string]: any
}

interface FilterPermissionsProps {
  userPermissions: string[]
  componentRestrictions?: string[]
}
