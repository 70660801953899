import React from 'react'
import Button from '@mui/material/Button'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Stack, Typography } from '@mui/material'
import { t } from 'i18next'

interface AddPatientButtonProps {
    moreThanOnePatient: boolean;
    setShowNewPatientForm: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentPatient: React.Dispatch<React.SetStateAction<any>>;
    formik: any;
}

const AddPatientButton: React.FC<AddPatientButtonProps> = ({ moreThanOnePatient, setShowNewPatientForm, setCurrentPatient, formik }) => {
  if (moreThanOnePatient) {
    return (
      <Button
        sx={{
          textDecoration: 'underline',
          width: {
            xs: '100%',
            sm: 'fit-content'
          }
        }}
        onClick={() => {
          setShowNewPatientForm(true)
          setCurrentPatient(null)
          formik.setValues({
            ...formik.values,
            patient: null,
            shouldDuplicatePatient: 'no'
          })
        }}
      >
        <Stack flexDirection='row' gap={3}>
          <PersonAddIcon
            sx={{ color: theme => theme.palette.primary.main }}
          />
          <Typography>
            {t('registerKit.patientDetails.patientForm.patient.addNewPatient')}
          </Typography>
        </Stack>
      </Button>
    )
  } else {
    return (
      <Button
        variant='outlined'
        sx={{
          borderColor: theme => theme.palette.primary.main,
          width: {
            xs: '100%',
            sm: 'fit-content'
          }
        }}
        onClick={() => {
          setShowNewPatientForm(true)
          setCurrentPatient(null)
          formik.setValues({
            ...formik.values,
            patient: null,
            shouldDuplicatePatient: 'no'
          })
        }}
      >
        <Stack flexDirection='row' gap={3}>
          <PersonAddIcon
            sx={{ color: theme => theme.palette.primary.main }}
          />
          <Typography>
            {t('registerKit.patientDetails.patientForm.patient.addNewPatient')}
          </Typography>
        </Stack>
      </Button>
    )
  }
}

export default AddPatientButton
