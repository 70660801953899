import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackBar } from 'state/actions'

export const NetworkStatus = () => {
  const dispatch = useDispatch()

  const handleOnlineStatus = () => dispatch(showSnackBar({
    severity: 'success',
    message: 'You are online again',
    show: true
  }))

  const handleOfflineStatus = () => dispatch(showSnackBar({
    severity: 'error',
    message: 'Lost internet connection',
    show: true
  }))

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOfflineStatus)

    return () => {
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOfflineStatus)
    }
  })

  return <></>
}
