import React from 'react'
import LayoutWisFlow from './components/LayoutWisconsonFlow'
import { Alert, Button, Stack, Typography } from '@mui/material'

function OrderSuccess () {
  const jsonData = {
    img: {
      src: 'order_confirm.svg',
      alt: 'order confirm'
    },
    successAlert:
      'We have received your test order! A confirmation email has been sent. Kudos on staying on top of your health!',
    bodyText:
      'Your test is estimated to be delivered in 1-3 days. We will send updates via email and text notifications.',
    nextStep: {
      title: 'What next?',
      alertText:
        'A verification email has been sent to <u>david1234@gmail.com.</u> Please check your email and complete the verification!'
    }
  }
  return (
    <Stack>
      <LayoutWisFlow headerText="">
        <Stack gap={1}>
          <Stack>
            <Stack
              component="img"
              src={`/assets/${jsonData.img.src}`}
              alt={jsonData.img.alt}
              sx={{ m: 'auto' }}
            />
            <Alert
              severity="success"
              sx={{
                '& .MuiAlert-icon': {
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
            >
              <Typography variant="body1">{jsonData.successAlert}</Typography>
            </Alert>
          </Stack>
          <Typography variant="body1">{jsonData.bodyText}</Typography>

          {jsonData.nextStep
            ? (
            <Stack>
              <Typography sx={{ my: '8px' }} variant="h6" component="h6">
                {jsonData.nextStep.title}
              </Typography>
              <Alert
                severity="info"
                sx={{
                  '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center'
                  }
                }}
              >
                <Typography variant="body1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jsonData.nextStep.alertText
                    }}
                  />
                </Typography>
              </Alert>
              <Button sx={{ my: '8px' }} fullWidth variant="outlined">
                Resend verification email
              </Button>
            </Stack>
              )
            : (
                ''
              )}
        </Stack>
      </LayoutWisFlow>
    </Stack>
  )
}

OrderSuccess.propTypes = {}

export default OrderSuccess
